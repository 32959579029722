import { Colors } from 'theme/colors';

export const isValidAlpha = (alpha: number): boolean => alpha >= 0 && alpha <= 1;

export const splitHexToRgb = (hexColor: Colors): { R: number; G: number; B: number } => {
  const R = parseInt(hexColor.slice(1, 3), 16);
  const G = parseInt(hexColor.slice(3, 5), 16);
  const B = parseInt(hexColor.slice(5, 7), 16);

  return { R, G, B };
};

export const createRGBAWithHex = (hexColor: Colors, alpha = 1) => {
  const { R, G, B } = splitHexToRgb(hexColor);
  return `rgba(${R},${G},${B},${isValidAlpha(alpha) ? alpha : 1})`;
};
