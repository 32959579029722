import { LegacyDownloadTask, TaskStatus, downloadActions, taskListActions } from 'modules/taskList';
import React, { useEffect, useRef } from 'react';
import styled, { css, keyframes } from 'styled-components';

import { FlexColumn } from 'components/commons/Layout';
import { fireError } from 'utils/SwalUtil';
import { translate } from 'utils/LocaleUtil';
import { useDispatch } from 'react-redux';

type Props = {
  task: LegacyDownloadTask;
};

const animateStripes = keyframes`
  from { 
    background-position: 6px 0;
  }
  to {
    background-position: 0 0;
  }
`;

const InfoContainer = styled.div`
  flex: 1;
  overflow: hidden;
`;

const Title = styled.p`
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  color: #000000;
  font-size: 13px;
  letter-spacing: -0.22px;
  line-height: normal;
`;

const ProgressBar = styled.div<{ status: LegacyDownloadTask['status'] }>`
  margin-top: 4px;
  height: 6px;
  border-radius: 3px;
  ${props =>
    props.status === 'downloading' &&
    css`
      animation: ${animateStripes} 1s linear infinite;
    `}
  ${props => {
    if (props.status === 'downloading') {
      return `
      background-color: #007bff;
      background-image: linear-gradient(45deg,rgba(255,255,255,.15) 25%,transparent 25%,transparent 50%,rgba(255,255,255,.15) 50%,rgba(255,255,255,.15) 75%,transparent 75%,transparent);
      background-size: 6px 6px;
      `;
    }
    if (props.status === 'success') {
      return `background-color: #d1d1d1;`;
    }
    return `background-color: #e9ecef;`;
  }}
`;

const ButtonRow = styled.div`
  display: flex;
  margin-top: 4px;
  height: 17px;
`;

const Description = styled.p`
  color: #7d7d7d;
  font-size: 11px;
  line-height: normal;
  letter-spacing: -0.18px;
`;
const StyledButton = styled.a`
  padding: 0;
  height: auto;
  border: 0;

  color: #7d7d7d;
  background-color: transparent;
  font-size: 11px;
  font-weight: normal;
  line-height: normal;
  letter-spacing: -0.18px;
  text-decoration: none;
  &:hover {
    box-shadow: none;
    text-decoration: underline;
  }
`;

export const DownloadTaskCardInfo: React.FC<React.PropsWithChildren<Props>> = ({ task: { id, status, task } }) => {
  const dispatch = useDispatch();

  const timerRef = useRef(-1);

  const handleHideClick = () => {
    dispatch(taskListActions.hideCard({ id: task.id }));
  };

  const handleCancelClick = (event: React.MouseEvent<HTMLAnchorElement>) => {
    event.preventDefault();
    dispatch(downloadActions.cancel({ id }));
  };

  const handleOpenClick = (event: React.MouseEvent<HTMLAnchorElement>) => {
    event.preventDefault();
    if (task.fileURL) {
      const new_window = window.open(task.fileURL);
      if (!new_window || new_window.closed || typeof new_window.closed === 'undefined') {
        fireError(translate('popup_setting_message'));
      }
    }
  };

  useEffect(() => {
    if (timerRef.current) {
      clearTimeout(timerRef.current);
    }

    if (status === 'success') {
      timerRef.current = window.setTimeout(() => {
        dispatch(downloadActions.hide({ id }));
      }, 5000);
    }
    return () => {
      if (timerRef.current) {
        clearTimeout(timerRef.current);
      }
    };
  }, [id, status, dispatch]);

  return (
    <InfoContainer>
      <Title>{`${task.fileName}.${task.fileExtension}`}</Title>
      <ProgressBar status={status} />
      <ButtonRow>
        <FlexColumn justifyContent="flex-start">
          <Description>{task.description}</Description>
        </FlexColumn>
        <FlexColumn justifyContent="flex-end">
          {status === 'downloading' && (
            <StyledButton href="#" onClick={handleCancelClick}>
              {translate('label_cancel')}
            </StyledButton>
          )}
          {status === 'success' && task.fileURL && (
            <StyledButton href={task.fileURL} onClick={handleOpenClick}>
              {translate('label_open')}
            </StyledButton>
          )}
          {status === 'failed' && (
            <HideButton onClick={handleHideClick}>
              <svg width="9" height="9" viewBox="0 0 9 9" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path
                  fillRule="evenodd"
                  clipRule="evenodd"
                  d="M8.09357 0L4.5 3.59357L0.906429 0L0 0.906429L3.59357 4.5L0 8.09357L0.906429 9L4.5 5.40643L8.09357 9L9 8.09357L5.40643 4.5L9 0.906429L8.09357 0Z"
                  fill="#022855"
                />
              </svg>
            </HideButton>
          )}
        </FlexColumn>
      </ButtonRow>
    </InfoContainer>
  );
};

const HideButton = styled.button`
  top: 13px;
  right: 13px;
  width: 9px;
  height: 9px;
  position: absolute;
  background-color: transparent;
  border: 0;
  display: inline-block;
  padding: 0;
  font-size: 0;
`;
