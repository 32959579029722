import { ActionType, createReducer, createStandardAction } from 'typesafe-actions';
import { SIGN_OUT_SUCCESS, waitForSignInSuccess } from 'modules/session';
import { call, put, select, take, takeLatest, takeLeading } from 'redux-saga/effects';
import { canRedirectAfterSignIn, isWithoutAuthPath, match, paths } from './paths';

import { RootState } from 'modules/reducers';
import { produce } from 'immer';

export type NavigatePayload = { from?: string; to: string };

export const NAVIGATE = 'router/NAVIGATE';
export const SET_SSO_REDIRECT_PATH = 'router/SET_SSO_REDIRECT_PATH';

/**
 * 로그인 된 경우, axios에 token이 set 된 것을 보장한다.
 */
export const NAVIGATE_SUCCESS = 'router/NAVIGATE_SUCCESS';
const CLEAR = 'router/CLEAR';

export const routerActions = {
  navigate: createStandardAction(NAVIGATE)<NavigatePayload>(),
  navigateSuccess: createStandardAction(NAVIGATE_SUCCESS)<{ currentPath: string; previousPath?: string }>(),
  setSsoRedirectPath: createStandardAction(SET_SSO_REDIRECT_PATH)<{ path?: string }>(),
  clear: createStandardAction(CLEAR)(),
};

type RouterReducerState = {
  currentPath?: string;
  previousPath?: string;
  ssoRedirectPath?: string;
};

export const routerReducer = createReducer<RouterReducerState, ActionType<typeof routerActions>>(
  {},
  {
    [NAVIGATE_SUCCESS]: (state, { payload }) =>
      produce(state, draft => {
        draft.previousPath = payload.previousPath;
        draft.currentPath = payload.currentPath;
      }),
    [SET_SSO_REDIRECT_PATH]: (state, { payload }) =>
      produce(state, draft => {
        draft.ssoRedirectPath = payload.path;
      }),
    [CLEAR]: () => ({}),
  }
);

const selectRouter = (state: RootState) => state.router;

export const routerSelector = {
  currentPath: (state: RootState) => selectRouter(state).currentPath,
  previousPath: (state: RootState) => selectRouter(state).previousPath,
};

function* handleNavigate(action: ActionType<typeof routerActions.navigate>) {
  yield call(waitForSignInSuccess);

  const previousPath: ReturnType<typeof routerSelector.previousPath> = yield select(routerSelector.previousPath);
  let nextPreviousPath = action.payload.from;

  if ((nextPreviousPath && isWithoutAuthPath(nextPreviousPath)) || !nextPreviousPath) {
    nextPreviousPath = previousPath;
  }
  yield put(routerActions.navigateSuccess({ currentPath: action.payload.to, previousPath: nextPreviousPath }));
}

function* handleSignOutSuccess() {
  yield take(NAVIGATE_SUCCESS);
  yield put(routerActions.clear());
}

export function* routerSaga() {
  yield takeLatest(NAVIGATE, handleNavigate);
  yield takeLeading(SIGN_OUT_SUCCESS, handleSignOutSuccess);
}

export * from './paths';
