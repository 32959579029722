import IconSpenditLogo from 'images/icons/icon-spendit-logo.png';
import IconSpenditLogoBlack from 'images/icons/icon-spendit-logo-black.svg';
import React from 'react';
import styled from 'styled-components';

type SpenditLogoProps = {
  width?: number;
  height?: number;
  alt?: string;
  onClick?(event: React.MouseEvent<HTMLElement>): void;
  black?: boolean;
} & Styleable;

const LogoImage = styled.img``;

export const SpenditLogo: React.FC<React.PropsWithChildren<SpenditLogoProps>> = ({
  className,
  width = 100,
  height = 27,
  alt = 'Spendit Logo',
  onClick,
  black = false,
}) => (
  <>
    {black === false && (
      <LogoImage
        onClick={onClick}
        className={className}
        src={IconSpenditLogo}
        width={width}
        height={height}
        alt={alt}
      />
    )}
    {black && (
      <LogoImage
        onClick={onClick}
        className={className}
        src={IconSpenditLogoBlack}
        width={width}
        height={height}
        alt={alt}
      />
    )}
  </>
);
