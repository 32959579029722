import { ActionType, createReducer, createStandardAction } from 'typesafe-actions';
import { all, call, delay, fork, put, select, take, takeLatest } from 'redux-saga/effects';
import { sessionActions, sessionSelectors } from 'modules/session';

import { DEFAULT_LANGUAGE } from 'utils/LocaleUtil';
import { MaintenanceErrorResponse } from 'modules/axios';
import { RootState } from 'modules/reducers';
import { envSelectors } from 'modules/env';
import { getStatus } from 'apis/InternalAPI';
import { localeActions } from 'modules/locale';
import { produce } from 'immer';
import { setBaseUrl } from 'apis/API';
import storage from 'redux-persist/lib/storage';

const INITIALIZE_APP = 'app/INITIALIZE_APP';
const INITIALIZE_APP_SUCCESS = 'app/INITIALIZE_APP_SUCCESS';

const SERVICE_UNAVAILABLE = 'app/SERVICE_UNAVAILABLE';
const SERVICE_AVAILABLE = 'app/SERVICE_AVAILABLE';
const SHOW_BROWSER_INFO_MODAL = 'app/SHOW_BROWSER_INFO_MODAL';
const HIDE_BROWSER_INFO_MODAL = 'app/HIDE_BROWSER_INFO_MODAL';

export const appActions = {
  init: createStandardAction(INITIALIZE_APP)(),
  initSuccess: createStandardAction(INITIALIZE_APP_SUCCESS)(),
  serviceUnavailable: createStandardAction(SERVICE_UNAVAILABLE)<MaintenanceErrorResponse>(),
  serviceAvailable: createStandardAction(SERVICE_AVAILABLE)(),
  showBrowserInfoModal: createStandardAction(SHOW_BROWSER_INFO_MODAL)(),
  hideBrowserInfoModal: createStandardAction(HIDE_BROWSER_INFO_MODAL)(),
};

type AppReducerState = {
  initialized: boolean;
  maintenance?: {
    start?: Date;
    end?: Date;
  };
  modals: {
    browserInfo: {
      visible: boolean;
    };
  };
};

const getInitialState = (): AppReducerState => ({
  initialized: false,
  modals: {
    browserInfo: {
      visible: false,
    },
  },
});

export const appReducer = createReducer<AppReducerState, ActionType<typeof appActions>>(getInitialState(), {
  [INITIALIZE_APP_SUCCESS]: state =>
    produce(state, draft => {
      draft.initialized = true;
    }),
  [SERVICE_UNAVAILABLE]: (state, { payload }) =>
    produce(state, draft => {
      draft.maintenance = {};
      draft.maintenance.start = new Date(payload.start);
      draft.maintenance.end = new Date(payload.end);
    }),
  [SERVICE_AVAILABLE]: state =>
    produce(state, draft => {
      delete draft.maintenance;
    }),
  [SHOW_BROWSER_INFO_MODAL]: state =>
    produce(state, draft => {
      draft.modals.browserInfo.visible = true;
    }),
  [HIDE_BROWSER_INFO_MODAL]: state =>
    produce(state, draft => {
      draft.modals.browserInfo.visible = false;
    }),
});

const selectApp = (state: RootState) => state.app;
const selectAppMaintenance = (state: RootState) => selectApp(state).maintenance;
const selectModals = (state: RootState) => selectApp(state).modals;

export const appSelectors = {
  isInitialized: (state: RootState) => selectApp(state).initialized,
  isUnderMaintenance: (state: RootState) => selectAppMaintenance(state) !== undefined,
  maintenanceStartDate: (state: RootState) => selectAppMaintenance(state)?.start,
  maintenanceEndDate: (state: RootState) => selectAppMaintenance(state)?.end,
  isBrowserInfoModalVisible: (state: RootState) => selectModals(state).browserInfo.visible,
};

type LegacyPersistRoot = {
  stores?: string;
};

type LegacyPersistStores = {
  user?: SpenditUser | null;
};

const LEGACY_SESSION_PERSIST_KEY = 'persist:root';

function* getSessionToken() {
  const sessionToken: ReturnType<typeof sessionSelectors.sessionToken> = yield select(sessionSelectors.sessionToken);

  if (sessionToken) {
    return sessionToken;
  }

  const persistedSessionJSON: string | null = yield call(storage.getItem, LEGACY_SESSION_PERSIST_KEY);

  if (persistedSessionJSON) {
    const persistedSession: LegacyPersistRoot = yield call(JSON.parse, persistedSessionJSON);
    const storesJSON: string | undefined = persistedSession.stores;

    if (storesJSON) {
      const stores: LegacyPersistStores = yield call(JSON.parse, storesJSON);
      const user = stores?.user;

      if (user) {
        const legacySessionToken = user.session_token;

        if (legacySessionToken) {
          return legacySessionToken;
        }
      }
    }
  }
  return null;
}

function* handleInitializeApp() {
  const sessionToken: string | null = yield call(getSessionToken);
  const baseUrl: string = yield select(envSelectors.url);

  yield call(setBaseUrl, baseUrl);

  if (sessionToken !== null) {
    // 저장된 토큰이 있는 경우
    // 세션 체크
    yield put(sessionActions.signInWithToken({ sessionToken }));
  } else {
    yield put(localeActions.setLocale({ locale: DEFAULT_LANGUAGE }));
  }

  yield put(appActions.initSuccess());
}

const CHECK_API_AVAILABLE_DELAY_MS = 600000; // 10 minutes

function* watchAPIAvailable() {
  while (yield select(appSelectors.isUnderMaintenance)) {
    yield delay(CHECK_API_AVAILABLE_DELAY_MS);
    try {
      yield call(getStatus);
      yield put(appActions.serviceAvailable());
    } catch (error) {
      console.log('SERVICE UNDER MAINTENANCE');
    }
  }
}

function* maintenanceFlow() {
  while (true) {
    yield take(SERVICE_UNAVAILABLE);
    yield fork(watchAPIAvailable);

    yield take(SERVICE_AVAILABLE);

    window.location.reload();
  }
}

function* watcherSaga() {
  yield takeLatest(INITIALIZE_APP, handleInitializeApp);
}

export function* appSaga() {
  yield all([fork(watcherSaga), fork(maintenanceFlow)]);
}
