import IndexedDBService from '@N/service/outbound/IndexedDB/adapter';
import { useAuthService } from '@N/service/inbound/Auth/adapter';
import { useOktaService } from '@N/service/outbound/Okta/adapter';

export const useAuthenticateApplication = () => {
  const OktaService = useOktaService();
  const AuthService = useAuthService();

  const handleSignOut = () => {
    OktaService.resetOktaSession();
    AuthService.signOutGlobalSession();
    IndexedDBService.clearAll();
  };

  return { handleSignOut };
};
