import {
  CANCEL,
  CANCEL_DOWNLOAD,
  DELETE,
  DOWNLOAD_FAILED,
  DOWNLOAD_SUCCESS,
  ENQUEUE_DOWNLOAD,
  REQUEST_DOWNLOAD,
  downloadActions,
  taskListActions,
} from './actions';
import { DownloadTask, LegacyDownloadTask, Task, isLegacyDownloadTask } from './types';
import { actionChannel, all, call, fork, put, select, take, takeLatest } from 'redux-saga/effects';
import { cancelFirebaseJob, deleteFirebaseJob } from 'apis/FirebaseAPI';
import { selectTaskById, selectTasks } from './selectors';

import { ActionType } from 'typesafe-actions';
import ErrorHandler from 'utils/ErrorHandler';
import { MAX_DOWNLOAD_COUNT_LIMIT } from './consts';
import { SIGN_OUT_SUCCESS } from 'modules/session';
import { fireError } from 'utils/SwalUtil';
import { getBlobFromURL } from 'apis/DownloadAPI';
import { saveAs } from 'file-saver';
import { translate } from 'utils/LocaleUtil';
import { uiActions } from 'modules/ui';

function* handleCancelDownload(action: ActionType<typeof downloadActions.cancel>) {
  const tasks: Task[] = yield select(selectTasks);

  const found = tasks.filter(isLegacyDownloadTask).find(({ task }) => task.id === action.payload.id);
  if (found) {
    found.task.cancellation.cancel();
    yield put(downloadActions.hide({ id: found.id }));
  }
}

function* handleDownloadSuccess(action: ActionType<typeof downloadActions.success>) {
  const blob: Blob = yield call(getBlobFromURL, action.payload.fileURL);

  yield call(saveAs, blob, `${action.payload.fileName}.${action.payload.fileExtension}`);
}

function* handleDownloadFailed(action: ActionType<typeof downloadActions.failed>) {
  if (action.payload.error) {
    yield call(ErrorHandler.fromAxios, action.payload.error);
  }
}

function* watchDownloadCompleteActions() {
  yield takeLatest(CANCEL_DOWNLOAD, handleCancelDownload);
  yield takeLatest(DOWNLOAD_SUCCESS, handleDownloadSuccess);
  yield takeLatest(DOWNLOAD_FAILED, handleDownloadFailed);
}

function* watchEnqueueDownload() {
  const requestChan = yield actionChannel(ENQUEUE_DOWNLOAD);
  while (true) {
    const { payload }: { payload: LegacyDownloadTask } = yield take(requestChan);

    const handleBeforeUnload = (event: BeforeUnloadEvent) => {
      event.preventDefault();
      // eslint-disable-next-line no-param-reassign
      event.returnValue = '';
    };

    window.addEventListener('beforeunload', handleBeforeUnload);

    try {
      yield put(downloadActions.start({ id: payload.id }));
      const data = yield call(payload.task.request.func, ...payload.task.request.args);
      yield put(
        downloadActions.success({
          id: payload.id,
          fileName: payload.task.fileName,
          fileExtension: payload.task.fileExtension,
          fileURL: data.result,
        })
      );
    } catch (error) {
      yield put(downloadActions.failed({ id: payload.id, error }));
    } finally {
      window.removeEventListener('beforeunload', handleBeforeUnload);
    }
  }
}

function* watchRequestDownload() {
  const requestChan = yield actionChannel(REQUEST_DOWNLOAD);
  while (true) {
    const { payload }: { payload: DownloadTask<any> } = yield take(requestChan);
    const tasks: Task[] = yield select(selectTasks);

    if (tasks.length < MAX_DOWNLOAD_COUNT_LIMIT) {
      const duplicatedTask: Task | undefined = yield select(selectTaskById, payload.id);

      if (duplicatedTask) {
        if (duplicatedTask.status === 'waiting') {
          yield call(fireError, translate('error_report_download_already_queued'));
        } else if (duplicatedTask.status === 'downloading') {
          yield call(fireError, translate('error_report_download_already_in_progress'));
        }
      } else {
        yield put(
          downloadActions.enqueue({
            id: payload.id,
            type: 'legacy',
            status: 'waiting',
            task: payload,
          })
        );
      }
    }
  }
}

function* handleCancelUploadClick(action: ActionType<typeof taskListActions.cancel>) {
  try {
    yield put(uiActions.showSpinner());
    yield call(cancelFirebaseJob, action.payload.id);
  } catch (error) {
    yield call(fireError, translate('error_cancellation_not_possible_during_registration'));
  } finally {
    yield put(uiActions.hideSpinner());
  }
}

function* handleDeleteUploadClick(action: ActionType<typeof taskListActions.cancel>) {
  try {
    yield put(uiActions.showSpinner());
    yield call(deleteFirebaseJob, action.payload.id);
  } catch (error) {
    yield call(ErrorHandler.fromAxios, error);
  } finally {
    yield put(uiActions.hideSpinner());
  }
}

function* watchUploadActions() {
  yield takeLatest(CANCEL, handleCancelUploadClick);
  yield takeLatest(DELETE, handleDeleteUploadClick);
}

function* handleSignOutSuccess() {
  yield put(taskListActions.clear());
}

function* watchSession() {
  yield takeLatest(SIGN_OUT_SUCCESS, handleSignOutSuccess);
}

export function* fileSaga() {
  yield all(
    [watchDownloadCompleteActions, watchEnqueueDownload, watchRequestDownload, watchUploadActions, watchSession].map(
      saga => fork(saga)
    )
  );
}
