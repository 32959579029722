import { ActionType, createReducer } from 'typesafe-actions';
import {
  FETCH_MY_POLICIES_SUCCESS,
  GET_SESSION_DATA_SUCCESS,
  GET_TOKEN_SUCCESS,
  HIDE_SCRAPING_LOGIN_POPUP_SUCCESS,
  SESSION_EXPIRED,
  SET_CURRENT_POLICY,
  SET_CURRENT_USER,
  SIGN_IN_SUCCESS,
  SIGN_OUT_SUCCESS,
  UPDATE_COMPANY_NAME,
  UPDATE_CURRENT_POLICY,
  sessionActions,
} from './actions';

import { MyPolicy } from 'apis/PolicyAPI';
import { produce } from 'immer';

type SessionReducerState = {
  sessionToken: string | undefined;
  user: SpenditUser | null;
  policy: SessionCurrentPolicy | null;
  myPolicies: MyPolicy[];
  privatePolicy?: MyPolicy;
  myGroups: CompanyGroup[];
};

const initialState: SessionReducerState = {
  sessionToken: undefined,
  user: null,
  policy: null,
  myPolicies: [],
  myGroups: [],
};

export const sessionReducer = createReducer<SessionReducerState, ActionType<typeof sessionActions>>(initialState, {
  [GET_SESSION_DATA_SUCCESS]: (state, { payload }) =>
    produce(state, draft => {
      draft.user = payload.user;
    }),
  [GET_TOKEN_SUCCESS]: (state, { payload }) =>
    produce(state, draft => {
      draft.sessionToken = payload.sessionToken;
    }),
  [SIGN_IN_SUCCESS]: (state, { payload }) =>
    produce(state, draft => {
      draft.user = payload.user;
      draft.myGroups = payload.groups;
      draft.sessionToken = payload.user.session_token;
    }),
  [SESSION_EXPIRED]: () => initialState,
  [SIGN_OUT_SUCCESS]: () => initialState,
  [SET_CURRENT_USER]: (state, { payload }) =>
    produce(state, draft => {
      draft.user = payload.user;
    }),
  [SET_CURRENT_POLICY]: (state, { payload }) =>
    produce(state, draft => {
      draft.policy = payload;
    }),
  [UPDATE_CURRENT_POLICY]: (state, { payload }) =>
    produce(state, draft => {
      if (draft.policy) {
        draft.policy.name = payload.name;
      }
    }),
  [FETCH_MY_POLICIES_SUCCESS]: (state, { payload }) =>
    produce(state, draft => {
      draft.myPolicies = payload.policies;
      draft.privatePolicy = payload.private_policy;
    }),
  [HIDE_SCRAPING_LOGIN_POPUP_SUCCESS]: state =>
    produce(state, draft => {
      if (draft.user) {
        draft.user.show_scraping_login = false;
      }
    }),
  [UPDATE_COMPANY_NAME]: (state, { payload }) =>
    produce(state, draft => {
      if (draft.user) {
        draft.user.company_name = payload ?? '';
      }
    }),
});
