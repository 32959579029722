import {
  AxiosInterceptor,
  AxiosInterceptorFn,
  AxiosInterceptorType,
  CustomInterceptorKey,
} from 'apis/interceptor/InterceptorManager';
import axios, { AxiosRequestConfig, CancelTokenSource } from 'axios';

import { ConfigPipe } from 'apis/shared';
import DatadogLogUtil from 'utils/DatadogLogUtil';
import LogRocketUtil from 'utils/LogRocketUtil';
import _ from 'lodash-es';
import { v1 as uuidv1 } from 'uuid';

/**
 * @see https://spendit.atlassian.net/wiki/spaces/FE/pages/3074949443/API+Client+Architecture
 *
 * @desc 1. API Response의 interceptor를 선언하는 모듈로, 레거시 인터셉터들이 있어 책임분리가 진행되지 않은 인터셉터도 존재합니다.
 *       2. Interceptor 작성방법은 다음과 같습니다.
 *          2-1. 요청 인터셉터의 경우 다음 타입의 함수를 작성하면 됩니다. AxiosInterceptorFn<'req'> = (requestConfig: AxiosRequestConfig) => {}
 *          2-2. 작성한 인터셉터를 requestPipeline에 등록합니다. 이 때 key값은 interceptor를 참조할 수 있는 고유한 키값이 됩니다.
 *               ex) requestEntryInterceptor의 경우 다음과 같이 eject 가능합니다. APIController.ejectInterceptor(BASE_INSTANCE_KEY, 'entryReq');
 *          2-3. 하나의 인터셉터 키값에 대해 fulfilled, rejected 두 경우에 대한 인터셉터를 작성할 수 있습니다.
 *       3. initRequestPipeline()함수로부터 의존성을 주입받을 수 있습니다. ex:) store
 *       4. request.config.pipe값을 통해 인터셉터간 컨텍스트를 공유할 수 있습니다. 또한 필요한 경우 pipe의 타입을 수정하여 필요한 값을 추가, 제거, 변경 가능합니다. (/apis/shared.ts)
 *       5. 이때 pipe에 값을 바인딩 하는 것은 API.get, API.post 처럼 호출 시 동적으로 주입 가능하며, entryInterceptor에서 기본값을 할당해 주어야 합니다.
 *       6. request.config.pipe는 response.config.pipe와 같은 reference를 가집니다. 즉 요청 인터셉터에서 주입한 파이프 값을 응답 인터셉터에서 그대로 사용 가능합니다.
 */

export const isPipeObjectExist = (pipe?: AxiosRequestConfig['pipe']): pipe is ConfigPipe => {
  return !!pipe;
};

const MAX_REQUESTS_COUNT = 5;

// TODO :기존에 동작하지 않던 로직이 정상화되어 적용됨 => maxcount에 대한 기준이나 초과에 대한 명확한 핸들링 로직이 존재하지 않아, 벌크 다운로드 등에서 이슈가 발생할 가능성 농후하여 고도화 필요
// 때문에 임시로 관련 로직 제거하고 변수만 남겨둔 상태
export const pendingRequestsCount = 0;

const previousRequestsMap: Map<string, CancelTokenSource> = new Map();

const takeLatestRequest = (requestKey: string, axiosRequestConfig: AxiosRequestConfig) => {
  if (previousRequestsMap.has(requestKey)) {
    const cancelTokenSource = previousRequestsMap.get(requestKey);
    cancelTokenSource?.cancel('동일한 요청이 있어 취소합니다.');
    deleteRequestKey(requestKey);
  }
  const cancelTokenSource: CancelTokenSource = axios.CancelToken.source();
  axiosRequestConfig.cancelToken = cancelTokenSource.token;
  previousRequestsMap.set(requestKey, cancelTokenSource);
};
export const makeRequestKey = (axiosRequestConfig: AxiosRequestConfig) => {
  return `${axiosRequestConfig?.method}${axiosRequestConfig?.url}`;
};
export const deleteRequestKey = (requestKey: string) => {
  previousRequestsMap.delete(requestKey);
};

const requestEntryInterceptor: AxiosInterceptorFn<'req'> = (requestConfig: AxiosRequestConfig) => {
  const resultConfig = _.merge(requestConfig, {
    pipe: {
      requestId: uuidv1(),
      requestTimeStamp: Date.now(),
      pendingRequests: pendingRequestsCount,
      useCamelize: requestConfig.pipe?.useCamelize ?? false,
      abortable: requestConfig.pipe?.abortable ?? false,
    },
  });

  return resultConfig;
};

const requestLogInterceptor: AxiosInterceptorFn<'req'> = (requestConfig: AxiosRequestConfig) => {
  if (!isPipeObjectExist(requestConfig.pipe)) {
    Promise.reject('there is no pipe to reference');
    return;
  }

  const {
    method,
    params,
    url,
    data,
    pipe: { requestId, requestTimeStamp },
  } = requestConfig;

  DatadogLogUtil.info(`axios.request.onFulfilled - ${method} ${url}`, {
    requestId,
    requestTimeStamp,
    method,
    params,
    data,
    url,
  });

  return requestConfig;
};

const requestInjectingDynamicHeaderInterceptor: AxiosInterceptorFn<'req'> = (requestConfig: AxiosRequestConfig) => {
  if (!isPipeObjectExist(requestConfig.pipe)) {
    Promise.reject('there is no pipe to reference');
  }

  const {
    pipe: { requestId, requestTimeStamp },
    timeout,
  } = requestConfig;
  // eslint-disable-next-line no-param-reassign
  requestConfig.headers['X-LogRocket-URL'] = LogRocketUtil.getSessionURL();
  // eslint-disable-next-line no-param-reassign
  requestConfig.headers['Spendit-Request-ID'] = requestId;
  // eslint-disable-next-line no-param-reassign
  requestConfig.headers['Spendit-Request-Timestamp'] = requestTimeStamp;
  // eslint-disable-next-line no-param-reassign
  requestConfig.timeout = timeout ?? Number(process.env.REACT_APP_DEFAULT_API_TIMEOUT);

  return requestConfig;
};

const reqeustAbortDuplicatedRequestsInterceptor: AxiosInterceptorFn<'req'> = (reqeustConfig: AxiosRequestConfig) => {
  if (reqeustConfig.pipe.abortable) {
    takeLatestRequest(makeRequestKey(reqeustConfig), reqeustConfig);
  }

  return reqeustConfig;
};

const requestErrorInterceptor: AxiosInterceptorFn<'req'> = (error: any) => {
  DatadogLogUtil.debug(`axios.request.onRejected`, error);
  return new Error(error);
};

const requestPipeline = (): Record<CustomInterceptorKey, AxiosInterceptor<AxiosInterceptorType>> => ({
  entryReq: {
    type: 'req',
    interceptFn: {
      fulfilled: requestEntryInterceptor,
    },
  },
  logReq: {
    type: 'req',
    interceptFn: {
      fulfilled: requestLogInterceptor,
    },
  },
  headerReq: {
    type: 'req',
    interceptFn: {
      fulfilled: requestInjectingDynamicHeaderInterceptor,
    },
  },
  abortReq: {
    type: 'req',
    interceptFn: {
      fulfilled: reqeustAbortDuplicatedRequestsInterceptor,
      rejected: reqeustAbortDuplicatedRequestsInterceptor,
    },
  },
  errorReq: {
    type: 'req',
    interceptFn: {
      rejected: requestErrorInterceptor,
    },
  },
});

export default requestPipeline;
