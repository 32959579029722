import { ModalFooterProps } from './types';
import React from 'react';
import styled from 'styled-components';

export const StyledFooter = styled.footer`
  padding: 0 26px;
  > div {
    padding: 12px 0 26px;
    border-top: 1px solid ${({ theme }) => theme.colors.grey[300]};
    text-align: right;
  }
`;

/**
 * @deprecated
 */
const ModalFooter: React.FC<React.PropsWithChildren<ModalFooterProps>> = ({ className, children }) => {
  return (
    <StyledFooter className={className}>
      <div>{children}</div>
    </StyledFooter>
  );
};

export default ModalFooter;
