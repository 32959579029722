/* eslint-disable no-param-reassign */
import $ from 'jquery';
import { ObjectUtil } from './object_util';

var HttpQueryUtil = {};
HttpQueryUtil.Objects = function (obj, prefix) {
  var str = {};
  for (var p in obj) {
    if (Object.prototype.hasOwnProperty.call(obj, p)) {
      var k = prefix ? prefix + '[' + p + ']' : p,
        v = obj[p];
      if (ObjectUtil.isObject(v)) {
        str = $.extend(str, HttpQueryUtil.Objects(v, k));
      } else {
        str[k] = v;
      }
    }
  }
  return str;
};

HttpQueryUtil.Arrays = function (name, array, formData) {
  if (!formData) formData = new FormData();

  array.forEach(function (value) {
    formData.append(name + '[]', value);
  });

  return formData;
};

HttpQueryUtil.UrlParams = function (params) {
  return params && $.param(params);
};

HttpQueryUtil.FormData = function (params, formData) {
  if (params) {
    $.each(params, function (key, value) {
      if (value !== undefined) {
        if (!formData) formData = new FormData();

        if (Array.isArray(value)) {
          formData = HttpQueryUtil.Arrays(key, value, formData);
        } else if (ObjectUtil.isObject(value)) {
          formData = HttpQueryUtil.FormData(HttpQueryUtil.Objects(value, key), formData);
        } else {
          formData.append(key, value);
        }
      }
    });
  }

  return formData;
};

HttpQueryUtil.nullsToEmptyStrings = function (origObj) {
  return JSON.parse(JSON.stringify(origObj, (k, v) => (v === null ? '' : v)));
};

export { HttpQueryUtil };
