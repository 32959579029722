export class IndexedDBService implements AbstractIndexedDBService {
  static async getDB(dbName: string, storeName: string): Promise<IDBDatabase> {
    return new Promise<IDBDatabase>((resolve, reject) => {
      const request = indexedDB.open(dbName);

      request.onerror = () => reject(request.error);
      request.onsuccess = () => resolve(request.result);

      // 데이터베이스가 처음 열리거나 업그레이드될 때, 주어진 storeName으로 객체 저장소를 생성
      request.onupgradeneeded = () => {
        const db = request.result;
        db.createObjectStore(storeName, { keyPath: 'id' });
      };
    });
  }

  static async getStore(dbName: string, storeName: string): Promise<IDBObjectStore> {
    const db = await this.getDB(dbName, storeName);
    // 여러 클라이언트가 동시에 데이터베이스에 접근하더라도 동시성 문제가 발생하지 않도록 트랜잭션을 생성해 DB에 접근
    return db.transaction(storeName, 'readwrite').objectStore(storeName);
  }

  static async get<T>(dbName: string, storeName: string, id: string): Promise<T> {
    const store = await this.getStore(dbName, storeName);
    return new Promise((resolve, reject) => {
      const request = store.get(id);
      request.onerror = () => reject(request.error);
      request.onsuccess = () => resolve(request.result);
    });
  }

  static async getAll<T>(dbName: string, storeName: string): Promise<T[]> {
    const store = await this.getStore(dbName, storeName);
    return new Promise((resolve, reject) => {
      const request = store.getAll();

      request.onerror = () => reject(request.error);
      request.onsuccess = () => resolve(request.result);
    });
  }

  static async set<T>(dbName: string, storeName: string, data: T): Promise<IDBValidKey> {
    const store = await this.getStore(dbName, storeName);
    return new Promise((resolve, reject) => {
      const request = store.put(data);

      request.onerror = () => reject(request.error);
      request.onsuccess = () => resolve(request.result);
    });
  }

  static async remove<T>(dbName: string, storeName: string, id: string): Promise<T> {
    const store = await this.getStore(dbName, storeName);
    return new Promise((resolve, reject) => {
      const request = store.delete(id);

      request.onerror = () => reject(request.error);
      request.onsuccess = () => resolve(request.result);
    });
  }

  // 해당 store의 모든 데이터를 삭제
  static async clear<T>(dbName: string, storeName: string): Promise<T> {
    const store = await this.getStore(dbName, storeName);
    return new Promise((resolve, reject) => {
      const request = store.clear();

      request.onerror = () => reject(request.error);
      request.onsuccess = () => resolve(request.result);
    });
  }

  // 모든 데이터베이스 삭제
  static async clearAll(): Promise<void> {
    if (!window.indexedDB.databases) {
      return;
    }

    try {
      const databases = await indexedDB.databases();
      for (const db of databases) {
        if (db.name) {
          const request = indexedDB.deleteDatabase(db.name);
          request.onerror = () => {
            console.error(`Failed to delete database: ${db.name}`);
          };
          request.onsuccess = () => {};
        }
      }
    } catch (error) {
      console.error('Error deleting databases:', error);
    }
  }
}

export default IndexedDBService;
