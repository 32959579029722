import ReactGA from 'react-ga';

type SpendtGATrackerType = 'web' | 'landing';

type SpenditGATracker = {
  trackingId: string;
  name: string;
};

type SpenditGATrackers = {
  [type in SpendtGATrackerType]: SpenditGATracker;
};

const trackers: SpenditGATrackers = {
  web: {
    trackingId: process.env.REACT_APP_GA_TRACKING_ID_FOR_WEB ?? '',
    name: process.env.REACT_APP_GA_TRACKER_NAME_FOR_WEB ?? '',
  },
  landing: {
    trackingId: process.env.REACT_APP_GA_TRACKING_ID_FOR_LANDING ?? '',
    name: process.env.REACT_APP_GA_TRACKER_NAME_FOR_LANDING ?? '',
  },
};

const isProduction = process.env.NODE_ENV === 'production';

const initialize = () => {
  if (isProduction) {
    ReactGA.initialize(
      [
        {
          trackingId: trackers.web.trackingId,
          gaOptions: { name: trackers.web.name },
        },
        {
          trackingId: trackers.landing.trackingId,
          gaOptions: { name: trackers.landing.name },
        },
      ],
      { debug: false, alwaysSendToDefaultTracker: false }
    );
  }
};

const pageview = (trackerType: SpendtGATrackerType, pathname: string) => {
  if (isProduction) {
    const tracker = trackers[trackerType];
    return ReactGA.pageview(pathname, [tracker.name]);
  }
};

export default {
  initialize,
  pageview,
};
