import * as Sentry from '@sentry/react';
import * as _ from 'lodash-es';

import { Integration } from '@sentry/types';
import LogRocketUtil from './LogRocketUtil';
import { createBrowserHistory } from 'history';
import { matchPath } from 'react-router-dom';
import { paths } from 'modules/router';

const history = createBrowserHistory();

// eslint-disable-next-line @typescript-eslint/ban-types
const extractInstrumentalRoutingPaths = (object: object): { path: string }[] => {
  return _.flatMapDeep(object, value => {
    if (_.isObject(value) && !_.isArray(value)) {
      // 중첩된 객체인 경우 재귀적으로 함수 호출
      return extractInstrumentalRoutingPaths(value);
    }
    // Sentry가 요구하는 형식에 맞게 각 path 반환

    if (typeof value === 'string') return { path: value };

    throw new Error('the object value was not matched the type string or object');
  });
};

const routingPaths = extractInstrumentalRoutingPaths(paths);
const isProduction = process.env.REACT_APP_ENV === 'production';

const APPCUES_ERROR = "Cannot read properties of null (reading 'querySelector')";

const getSentryIntegration = () => {
  const integration: Integration[] = [
    new Sentry.BrowserTracing({
      routingInstrumentation: Sentry.reactRouterV5Instrumentation(history, routingPaths, matchPath),
    }),
    new Sentry.HttpContext(),
  ];

  if (isProduction) {
    integration.push(
      new Sentry.Replay({
        maskAllText: true,
        maskAllInputs: true,
      })
    );
  }

  return integration;
};

const SentryUtil = {
  initSentry: () => {
    const environment = process.env.REACT_APP_ENV;
    if (environment === 'development.local') {
      return;
    }

    const tracesSampleRate = 1;

    Sentry.init({
      dsn: process.env.REACT_APP_SENTRY_DSN,
      environment,
      integrations: getSentryIntegration(),
      tracesSampleRate,
      beforeSend(event) {
        const logRocketSession = LogRocketUtil.getSessionURL();
        if (logRocketSession !== null) {
          if (event.extra) {
            event.extra.LogRocket = logRocketSession;
          } else {
            event.extra = {
              LogRocket: logRocketSession,
            };
          }
          return event;
        }
        return event;
      },
      replaysSessionSampleRate: 0.1,
      replaysOnErrorSampleRate: isProduction ? 1.0 : 0.1,
      normalizeDepth: 10,
      ignoreErrors: [APPCUES_ERROR],
    });
  },

  /**
   * redux enhancer
   * 추후 해당 문서를 통해 고도화
   * https://docs.sentry.io/platforms/javascript/guides/react/configuration/integrations/redux/
   */
  reduxEnhancer: () => Sentry.createReduxEnhancer({}),

  withScope: Sentry.withScope,
  captureException: Sentry.captureException,

  /**
   * Captures a message and sends it to Sentry.
   */
  captureMessage: (message: string) => {
    Sentry.captureMessage(message);
  },

  /**
   * Captures a warning event and sends it to Sentry.
   */
  captureWarningEvent: (message: string) => {
    const event: Sentry.Event = {
      message,
      level: 'warning',
    };
    Sentry.captureEvent(event);
  },

  /**
   * Clear scope
   */
  clearUser: () => {
    Sentry.configureScope(scope => {
      scope.clear();
    });
  },

  /**
   * Configure user scope
   */
  configureUser: ({ id, email, name }: { id: number; email: string; name: string }) => {
    Sentry.configureScope(scope => {
      scope.setUser({
        id: id.toString(),
        email,
        username: name,
      });
    });
  },

  /**
   * Set extra data
   */
  setExtra: ({ companyId, companyName }: { companyId: number; companyName: string }) => {
    Sentry.configureScope(scope => {
      scope.setExtra('companyId', companyId);
      scope.setExtra('companyName', companyName);
    });
  },
};

export default SentryUtil;
