import { Camelized } from 'utils/parser/types';

export const camelizeKey = (key: string) => {
  return key.replace(/([-_][a-z])/gi, $1 => {
    return $1.toUpperCase().replace('-', '').replace('_', '');
  });
};

export const camelizeObject = <T>(obj: T): Camelized<T> => {
  if (Array.isArray(obj)) {
    return obj.map(camelizeObject) as unknown as Camelized<T>;
  } else if (obj !== null && typeof obj === 'object') {
    return Object.keys(obj).reduce((acc, key) => {
      const camelizedKey = camelizeKey(key);
      // eslint-disable-next-line @typescript-eslint/ban-ts-comment
      // @ts-ignore
      acc[camelizedKey as keyof Camelized<T>] = camelizeObject((obj as any)[key]);
      return acc;
    }, {} as Camelized<T>);
  }
  return obj as unknown as Camelized<T>;
};

// key를 스네이크 케이스로 변환하는 함수
export const snakeCaseKey = (key: string) => {
  return key.replace(/[A-Z]/g, letter => `_${letter.toLowerCase()}`);
};

// 객체의 키를 재귀적으로 스네이크 케이스로 변환하는 함수
export const snakeCaseObject = <T>(obj: T): T => {
  if (Array.isArray(obj)) {
    return obj.map(snakeCaseObject) as unknown as T;
  } else if (obj !== null && typeof obj === 'object') {
    return Object.keys(obj).reduce((acc, key) => {
      const snakeKey = snakeCaseKey(key);
      acc[snakeKey as keyof T] = snakeCaseObject((obj as any)[key]);
      return acc;
    }, {} as T);
  }
  return obj;
};
