import { SSOLoginType, SignInFormType } from 'modules/session';

import API from 'apis/API';
import { CancelToken } from 'axios';
import { DEFAULT_LANGUAGE } from 'utils/LocaleUtil';
import randomBytes from 'randombytes';

export type GetSessionResponse = {
  user: SpenditUser;
};

export type GetSessionPayload = {
  acceptLanguage?: SpenditLocale;
  authentication?: string;
};

/**
 * GET /web/session
 */
export const getSession = async (payload?: GetSessionPayload, cancelToken?: CancelToken) => {
  const res = await API.get<GetSessionResponse>('/web/session', {
    headers: {
      'Accept-Language': payload?.acceptLanguage ?? DEFAULT_LANGUAGE,
      Authentication: payload?.authentication,
    },
    cancelToken,
  });

  return res.data;
};

type PostHideScrapingLoginResponse = {
  user: SpenditUser;
};

/**
 * POST /web/hide_scraping_login
 */
export const postHideScrapingLogin = async () => {
  const res = await API.post<PostHideScrapingLoginResponse>('/web/hide_scraping_login');

  return res.data;
};

export type DeviceType = 'ios' | 'android' | 'web';

export type PostSessionLoginParams = {
  device_type: DeviceType;
  device_token: string;
} & (SignInFormType | SSOLoginType);

export type PostSessionLoginResponse = {
  user: { session_token: string };
};

/**
 * POST /v4/session/login
 */
export const postSessionLogin = async (params: PostSessionLoginParams, cancelToken?: CancelToken) => {
  const res = await API.post<PostSessionLoginResponse>('/v4/session/login', params, { cancelToken });
  return res.data;
};

type PostMSAuthOAuthTokenResponse = {
  email: string;
  token: string;
};

/**
 * POST /ms/auth/oauth/token
 */

export const postMSAuthOAuthToken = async (accessToken: string) => {
  const res = await API.post<PostMSAuthOAuthTokenResponse>(
    '/ms/auth/oauth/token',
    {
      device_type: 'WEB',
      device_token: randomBytes(20).toString('hex'),
    },
    {
      headers: {
        Authorization: `Bearer ${accessToken}`,
      },
    }
  );
  return res.data;
};
