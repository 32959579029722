export type ReactAppEnv = 'development.local' | 'development' | 'qa' | 'production' | 'project' | 'restore';

const REACT_APP_ENV = process.env.REACT_APP_ENV as ReactAppEnv;

export const REACT_APP_API_URL = process.env.REACT_APP_API_URL;
export const REACT_APP_OPEN_API_URL = process.env.REACT_APP_OPEN_API_URL;

export const isDevelopmentEnv = (): boolean => isReactAppEnv('development', 'development.local');

export const isReactAppEnv = (...targets: ReactAppEnv[]): boolean => targets.some(target => target === REACT_APP_ENV);
