import axios, { AxiosInstance, AxiosRequestConfig } from 'axios';

export type InstanceKey = string;

export interface ManagedAxiosInstance {
  instanceOrigin: AxiosInstance;
  config: AxiosRequestConfig;
}

/**
 * @see https://spendit.atlassian.net/wiki/spaces/FE/pages/3074949443/API+Client+Architecture
 */
class AxiosInstanceManager {
  private readonly instanceMap: Map<InstanceKey, ManagedAxiosInstance> = new Map();

  public register(key: InstanceKey, config?: AxiosRequestConfig) {
    const instance = axios.create(config);
    const managed = { instanceOrigin: instance, config: config ?? {} };
    this.instanceMap.set(key, managed);
  }

  public get(key: InstanceKey): ManagedAxiosInstance {
    const instance = this.instanceMap.get(key);
    if (instance) return instance;
    else {
      throw new Error('there is no instance to match with key');
    }
  }

  public getRegisteredInstanceCount(): number {
    return this.instanceMap.size;
  }

  public getRegisteredInstanceEntries() {
    return this.instanceMap.entries();
  }
  public getRegisteredInstanceKeys() {
    return this.instanceMap.keys();
  }

  public hasInstanceBeenRegistered(key: InstanceKey) {
    return !!(this.instanceMap.has(key) && this.instanceMap.get(key));
  }
}

export default AxiosInstanceManager;
