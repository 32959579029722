import { ActionType, createReducer, createStandardAction } from 'typesafe-actions';

import { RootState } from 'modules/reducers';

type EnvReducerState = {
  url: string;
};

const CHANGE_ENDPOINT = 'env/CHANGE_ENDPOINT';

export const envActions = {
  changeEndpoint: createStandardAction(CHANGE_ENDPOINT)<string>(),
};

const initialState: EnvReducerState = {
  url: process.env.REACT_APP_API_URL as string,
};

export const envReducer = createReducer<EnvReducerState, ActionType<typeof envActions>>(initialState, {
  [CHANGE_ENDPOINT]: (state, action) => ({
    ...state,
    url: action.payload,
  }),
});

const selectEnv = (state: RootState) => state.env;

export const envSelectors = {
  url: (state: RootState) => selectEnv(state).url,
};
