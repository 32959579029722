/* eslint-disable no-param-reassign */
/* eslint-disable no-extend-native */
import smoothscroll from 'smoothscroll-polyfill';

const applyNativePolyfills = () => {
  if (!Element.prototype.matches) {
    Element.prototype.matches = Element.prototype.msMatchesSelector || Element.prototype.webkitMatchesSelector;
  }

  if (!Element.prototype.closest) {
    Element.prototype.closest = function closest(s: string) {
      let el: Node | null = this;

      do {
        if (el.matches(s)) return el;
        el = el.parentElement || el.parentNode;
      } while (el !== null && el.nodeType === 1);
      return null;
    };
  }

  if (!Object.values) {
    Object.values = (o: any) => Object.keys(o).map(k => o[k]);
  }

  if (!String.prototype.includes) {
    String.prototype.includes = function includes(search, start) {
      if (typeof start !== 'number') {
        start = 0;
      }

      if (start + search.length > this.length) {
        return false;
      }
      return this.indexOf(search, start) !== -1;
    };
  }

  if (typeof (Promise as any).withResolvers !== 'function') {
    // @ts-expect-error This does not exist outside of polyfill which this is doing
    Promise.withResolvers = function () {
      let resolve, reject;

      const promise = new Promise((res, rej) => {
        resolve = res;
        reject = rej;
      });

      return { promise, resolve, reject };
    };
  }

  if (typeof (Promise as any).allSettled !== 'function') {
    // @ts-expect-error This does not exist outside of polyfill which this is doing
    Promise.allSettled = function (promises: any[]) {
      return Promise.all(
        promises.map(promise =>
          Promise.resolve(promise).then(
            value => ({
              status: 'fulfilled',
              value,
            }),
            reason => ({
              status: 'rejected',
              reason,
            })
          )
        )
      );
    };
  }
};

export default function applyPolyfills() {
  // scroll 함수들에 대한 polyfills
  smoothscroll.polyfill();
  applyNativePolyfills();
}
