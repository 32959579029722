import { reportListActions, reportListSelectors } from 'modules/reports/list';
import { useDispatch, useSelector } from 'react-redux';

import { BulkReportsExportResultModal } from 'components/modals/BulkReportsExportResultModal';
import GlobalPortal from 'components/commons/GlobalPortal';

export const GlobalModalContainer: React.FC<React.PropsWithChildren<unknown>> = () => {
  const dispatch = useDispatch();

  const isBulkReportsExportResultModalVisible = useSelector(reportListSelectors.isBulkReportsExportResultModalVisible);
  const bulkReportsExportResultModalData = useSelector(reportListSelectors.bulkReportsExportResultModalData);
  return (
    <GlobalPortal>
      {isBulkReportsExportResultModalVisible && bulkReportsExportResultModalData && (
        <BulkReportsExportResultModal
          data={bulkReportsExportResultModalData}
          onConfirmClick={() => dispatch(reportListActions.hideBulkReportsExportResultModal())}
        />
      )}
    </GlobalPortal>
  );
};
