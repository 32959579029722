import React, { MouseEvent, useCallback, useContext, useEffect, useRef, useState } from 'react';

import Pagination from 'components/commons/Pagination';
import SwitchButton from 'components/commons/SwitchButton';
import { TableContext } from 'components/commons/Table/TableContext';
import styled from 'styled-components';

const SortableIcon = styled.i<{ sortType?: SortType }>`
  position: relative;
  display: inline-block;
  margin-right: 6px;
  width: 6px;
  height: 9px;

  ${props => {
    if (props.sortType !== 'desc') {
      return `
      &:before {
        position: absolute;
        top: 0;
        left: 0;

        width: 0;
        height: 0;
        border-right: 3px solid transparent;
        border-bottom: 3px solid ${props.sortType ? '#047de0' : '#acacac'};
        border-left: 3px solid transparent;

        content: '';
      }
      `;
    }
    return ``;
  }}
  ${props => {
    if (props.sortType !== 'asc') {
      return `
      &:after {
        position: absolute;
        bottom: 0;
        left: 0;

        width: 0;
        height: 0;
        border-top: 3px solid ${props.sortType ? '#047de0' : '#acacac'};
        border-right: 3px solid transparent;
        border-left: 3px solid transparent;

        content: '';
      }
      `;
    }
    return ``;
  }}
`;

const SortableColumnWrap = styled.span`
  display: inline-flex;
  align-items: center;
  cursor: pointer;
`;

const TableColumnWrap = styled.div<{ styles?: TableColumnStyles }>`
  display: flex;
  flex: 1;
  flex-direction: ${props => props.styles?.flexDirection || 'row'};
  justify-content: ${props => (props.styles?.justifyContent ? props.styles?.justifyContent : 'flex-start')};
  align-items: ${props => (props.styles?.alignItems ? props.styles?.alignItems : 'center')};
  ${props => props.styles?.margin && `margin: ${props.styles.margin};`};
  padding: ${props => props.styles?.padding || '10px 15px 10px 0'};
  ${props => props.styles?.paddingRight && `padding-right: ${props.styles?.paddingRight};`};
  min-width: 0;
  ${props => props.styles?.width && `width: ${props.styles.width};`};
  ${props => props.styles?.borderRight && `border-right: ${props.styles.borderRight};`};
  box-sizing: ${props => props.styles?.boxSizing || 'border-box'};

  color: ${props => props.styles?.color || '#000'};
  font-size: ${props => props.styles?.fontSize || '13px'};
  ${props => props.styles?.fontWeight && `font-weight: ${props.styles.fontWeight};`};
  ${props => props.styles?.letterSpacing && `letter-spacing: ${props.styles.letterSpacing}`};
  line-height: ${props => props.styles?.lineHeight || 'normal'};
  white-space: ${props => props.styles?.whiteSpace || 'nowrap'};
  ${props => props.styles?.wordBreak && `word-break: ${props.styles.wordBreak};`};

  ${props => props.styles?.cursor && `cursor: ${props.styles.cursor};`};
`;

const TableRowWrap = styled.div<TableRowWrapProps>`
  display: ${props => props.styles?.display || 'flex'};
  padding: ${props => props.styles?.padding || '0 10px'};
  width: ${props => props.styles?.width || '100%'};
  ${props => props.styles?.height && `height: ${props.styles.height};`}
  ${props => props.styles?.minHeight && `min-height: ${props.styles.minHeight};`}
  ${props => props.styles?.borderTop && `border-top: ${props.styles.borderTop};`}
  border-bottom: ${props => props.styles?.borderBottom || '1px solid #efefef'};
  box-sizing: border-box;

  background-color: ${props => props.styles?.backgroundColor || '#fff'};
  &:hover {
    background-color: ${props => props.styles?.onHover?.backgroundColor || 'rgba(2, 40, 85, 0.04)'};
  }
  ${props => props.clickable && `cursor: pointer;`}
  ${props => props.styles?.cursor && `cursor: ${props.styles.cursor};`}
`;

const TableHeaderWrap = styled.div<TableHeaderProps>`
  border-top: ${props => props.styles?.borderTop || '1px solid #efefef'};
  ${props => props.styles?.borderBottom && `border-bottom: ${props.styles.borderBottom};`}
  ${props => props.styles?.overflowY && `overflow-y: ${props.styles.overflowY};`}

  background-color: ${props => props.styles?.backgroundColor || '#f7f8fa'};

  ${TableColumnWrap} {
    padding: ${props => props.columnStyle?.padding || '8px 15px 8px 0px;'};
    ${props => props.columnStyle?.boxSizing && `box-sizing: ${props.columnStyle.boxSizing};`}
    color: ${props => props.columnStyle?.color || '#030303'};
    font-size: ${props => props.columnStyle?.fontSize || '10px'};
    ${props => props.columnStyle?.lineHeight && `line-height: ${props.columnStyle.lineHeight};`}
    white-space: pre-wrap;
  }
`;

const TableBodyWrap = styled.div<TableBodyProps>`
  overflow: ${props => props.styles?.overflow || 'auto'};
  padding-bottom: ${props => props.styles?.paddingBottom || 30}px;
  height: ${props => props.styles?.height || '100%'};
  border-top: ${props => (props.styles?.borderTop ? props.styles.borderTop : 'none')};
  max-height: ${props => props.styles?.maxHeight || 'unset'};
`;

const getChildIndex = (index: number, isCheckable: boolean, isToggleable: boolean) => {
  if (isCheckable && isToggleable) {
    return index + 3;
  }
  if (isCheckable || isToggleable) {
    return index + 2;
  }
  return index + 1;
};

const TableWrap = styled.div<TableWrapProps>`
  ${props => {
    if (props.isInitialized) {
      return 'opacity: 1;';
    }
    return 'opacity: 0;';
  }}
  display: flex;
  ${props => props.styles?.flex && `flex: ${props.styles.flex};`}
  flex-direction: column;
  ${props => props.styles?.paddingBottom && `padding-bottom: ${props.styles.paddingBottom};`}
  width: ${props => (props.styles?.width ? props.styles?.width : '100%')};
  height: ${props => (props.styles?.height ? props.styles?.height : '100%')};
  ${props => props.styles?.maxHeight && `max-height: ${props.styles.maxHeight};`}
  ${props => props.styles?.maxWidth && `max-width: ${props.styles.maxWidth};`}
  ${props => props.styles?.margin && `margin: ${props.styles.margin};`}
  ${props => props.styles?.border && `border: ${props.styles.border};`}
   ${props => props.styles?.borderRadius && `border-radius: ${props.styles.borderRadius};`}
  ${props => props.styles?.borderRight && `border-right: ${props.styles.borderRight};`}
  box-sizing: ${props => props.styles?.boxSizing || 'border-box'};
  ${props => props.styles?.overflow && `overflow: ${props.styles.overflow};`}
  transition: opacity 300ms ease-in-out;

  ${TableHeaderWrap} + ${TableBodyWrap} {
    flex: 1;
    height: auto;
  }
  ${props => {
    if (props.widthValues) {
      return props.widthValues.map((widthValue, index) => {
        const childIndex = getChildIndex(index, props.isCheckable, props.isToggleable);
        if (props.type === 'fixed' && widthValue) {
          return `
          ${TableRowWrap} ${TableColumnWrap} {
            transition: width 0.1s ease-in-out;
          }
          ${TableRowWrap} ${TableColumnWrap}:nth-child(${childIndex}) {
            width: ${widthValue};
            flex: none;
          }
          `;
        }
        if (props.type === 'responsive' && widthValue) {
          return `
          ${TableRowWrap} ${TableColumnWrap}:nth-child(${childIndex}) {
            flex-basis: ${widthValue};
          }
          `;
        }
        return ``;
      });
    }
    return ``;
  }}
`;

export const TableColumn: React.FC<React.PropsWithChildren<TableColumnProps>> = ({
  className,
  onContainerElementReady,
  children,
  ...rest
}) => {
  const [containerEl, setContainerEl] = useState<HTMLDivElement | null>();

  useEffect(() => {
    if (containerEl && onContainerElementReady) {
      onContainerElementReady(containerEl);
    }
  }, [containerEl, onContainerElementReady]);

  return (
    <TableColumnWrap ref={element => setContainerEl(element)} className={className} {...rest}>
      {children}
    </TableColumnWrap>
  );
};

export const TableHeaderColumn: React.FC<React.PropsWithChildren<TableHeaderColumnProps>> = ({
  className,
  columnWidth,
  styles,
  onClick,
  children,
}) => {
  const { initTableWidth, updateTableWidth } = useContext(TableContext);
  const indexRef = useRef(-1);

  useEffect(() => {
    if (indexRef.current === -1) {
      indexRef.current = initTableWidth(columnWidth) - 1;
    } else if (updateTableWidth) {
      updateTableWidth(indexRef.current, columnWidth);
    }
  }, [initTableWidth, updateTableWidth, columnWidth]);

  return (
    <TableColumnWrap className={className} styles={styles} onClick={onClick}>
      {children}
    </TableColumnWrap>
  );
};

export const ToggleableColumn: React.FC<React.PropsWithChildren<ToggleableColumnProps>> = ({
  value,
  onToggle,
  disabled,
  className,
  label,
  toggleBackgroundColor,
  ...rest
}) => {
  return (
    <TableColumnWrap
      className={className}
      onClick={event => event.stopPropagation()}
      styles={{
        margin: '0 32px 0 0',
        width: '18px',
        whiteSpace: 'pre-wrap',
        wordBreak: 'break-all',
      }}
      {...rest}
    >
      <SwitchButton
        value={value}
        onToggle={onToggle}
        disabled={disabled}
        label={label}
        backgroundColor={toggleBackgroundColor}
      />
    </TableColumnWrap>
  );
};

export const SortableHeaderColumn: React.FC<React.PropsWithChildren<SortableHeaderColumnProps>> = ({
  className,
  columnWidth,
  sortKey,
  sortField,
  children,
  ...rest
}) => {
  const [currentSortType, setCurrentSortType] = useState<SortType>();
  const { initTableWidth, updateTableWidth, sortOption, onSortClick } = useContext(TableContext);
  const indexRef = useRef(-1);

  const handleSortClick = () => {
    if (onSortClick) {
      const nextType = !currentSortType || currentSortType === 'desc' ? 'asc' : 'desc';
      onSortClick({ name: sortKey, sort: nextType, field: sortField });
    }
  };

  const getSortType = useCallback(() => {
    if (sortOption) {
      const { name, sort, field } = sortOption;
      if (name === sortKey && sortField === field) {
        return sort;
      }
    }
    return undefined;
  }, [sortOption, sortKey, sortField]);

  useEffect(() => {
    setCurrentSortType(getSortType());
  }, [getSortType]);

  useEffect(() => {
    if (indexRef.current === -1) {
      indexRef.current = initTableWidth(columnWidth) - 1;
    } else if (updateTableWidth) {
      updateTableWidth(indexRef.current, columnWidth);
    }
  }, [initTableWidth, updateTableWidth, columnWidth]);

  return (
    <TableColumnWrap className={className} {...rest}>
      <SortableColumnWrap onClick={handleSortClick}>
        <SortableIcon sortType={currentSortType} />
        {children}
      </SortableColumnWrap>
    </TableColumnWrap>
  );
};

export const TableRow: React.FC<React.PropsWithChildren<TableRowProps>> = ({
  className,
  onClick,
  styles,
  children,
  dataTestid,
}) => {
  const [clickable, toggleClickable] = useState(false);
  const { type } = useContext(TableContext);

  const handleClick = (event: MouseEvent<HTMLDivElement>) => {
    if (onClick) {
      onClick(event);
    }
  };

  useEffect(() => {
    toggleClickable(!!onClick);
  }, [onClick]);

  return (
    <TableRowWrap
      data-testid={dataTestid}
      className={className}
      clickable={clickable}
      onClick={handleClick}
      styles={{
        display: type === 'fixed' ? 'inline-flex' : 'flex',
        ...styles,
      }}
    >
      {children}
    </TableRowWrap>
  );
};

export const TableHeader: React.FC<React.PropsWithChildren<TableHeaderProps>> = ({
  className,
  children,
  styles,
  rowStyle,
  columnStyle,
  onContainerElementReady,
}) => {
  const [containerEl, setContainerEl] = useState<HTMLDivElement | null>();

  useEffect(() => {
    if (containerEl && onContainerElementReady) {
      onContainerElementReady(containerEl);
    }
  }, [containerEl, onContainerElementReady]);

  return (
    <TableHeaderWrap className={className} styles={styles} columnStyle={columnStyle} ref={el => setContainerEl(el)}>
      <TableRow
        styles={{
          padding: '0 10px',
          backgroundColor: '#f7f8fa',
          onHover: {
            backgroundColor: '#f7f8fa',
          },
          ...rowStyle,
        }}
      >
        {children}
      </TableRow>
    </TableHeaderWrap>
  );
};

export const TableBody: React.FC<React.PropsWithChildren<TableBodyProps>> = ({
  className,
  onContainerElementReady,
  onScroll,
  children,
  ...rest
}) => {
  const [isPageable, toggleIsPageable] = useState(false);
  const { totalPages, currentPage, onPageSelect } = useContext(TableContext);
  const [containerEl, setContainerEl] = useState<HTMLDivElement | null>();

  useEffect(() => {
    if (containerEl && onContainerElementReady) {
      onContainerElementReady(containerEl);
    }
  }, [containerEl, onContainerElementReady]);

  useEffect(() => {
    toggleIsPageable(totalPages > 0 && !!onPageSelect);
  }, [totalPages, currentPage, onPageSelect]);

  return (
    <TableBodyWrap className={className} ref={el => setContainerEl(el)} onScroll={onScroll} tabIndex={0} {...rest}>
      {children}
      {isPageable && (
        <Pagination totalPages={totalPages} currentPage={currentPage} showPageNavigation onPageSelect={onPageSelect} />
      )}
    </TableBodyWrap>
  );
};

export const Table: React.FC<React.PropsWithChildren<TableProps>> = ({
  className,
  totalPages = 0,
  currentPage = 1,
  onPageSelect,
  checkable,
  toggleable,
  linkTo,
  sortOption,
  onSortClick,
  type = 'responsive',
  styles,
  onContainerElementReady,
  children,
}) => {
  // 공통 Start
  const [isInitialized, toggleIsInitialized] = useState(false);
  // 공통 End

  // Column Width 관련 Start
  const [isColumnWidthInitialized, toggleIsColumnWidthInitialized] = useState(false);
  const [widthValues, setWidthValues] = useState<string[]>([]);
  const internalWidthValues = useRef<string[]>([]);
  const widthTimer = useRef(0);
  const currentWidthValues = useRef<string[]>([]);
  // Column Width 관련 End

  const [containerEl, setContainerEl] = useState<HTMLDivElement | null>();
  const [elementOnHover, setElementOnHover] = useState<Element>();
  const setElementOnHoverTimerRef = useRef(-1);

  useEffect(() => {
    if (containerEl && onContainerElementReady) {
      onContainerElementReady(containerEl);
    }
  }, [containerEl, onContainerElementReady]);

  const initTableWidth = useCallback((columnWidth?: string) => {
    clearTimeout(widthTimer.current);
    const nextLength = currentWidthValues.current.push(columnWidth || '');

    widthTimer.current = window.setTimeout(() => {
      const nextBasisValues = [...internalWidthValues.current];
      let key = currentWidthValues.current.shift();
      while (key) {
        nextBasisValues.push(key);
        key = currentWidthValues.current.shift();
      }
      setWidthValues(nextBasisValues);
      internalWidthValues.current = nextBasisValues;
      toggleIsColumnWidthInitialized(true);
    });

    return nextLength;
  }, []);

  const updateTableWidth = useCallback((index: number, columnWidth?: string) => {
    clearTimeout(widthTimer.current);
    internalWidthValues.current[index] = columnWidth || '';

    widthTimer.current = window.setTimeout(() => {
      setWidthValues([...internalWidthValues.current]);
    }, 100);
  }, []);

  const handleMouseOver = (event: React.MouseEvent) => {
    if (event.target instanceof Element) {
      const targetElement = event.target;
      clearTimeout(setElementOnHoverTimerRef.current);
      setElementOnHoverTimerRef.current = window.setTimeout(() => {
        setElementOnHover(targetElement);
      }, 100);
    }
  };

  useEffect(() => {
    toggleIsInitialized(isColumnWidthInitialized);
  }, [isColumnWidthInitialized]);

  useEffect(() => {
    return () => {
      clearTimeout(setElementOnHoverTimerRef.current);
    };
  }, []);

  return (
    <TableContext.Provider
      value={{
        type,
        initTableWidth,
        updateTableWidth,
        currentPage,
        totalPages,
        onPageSelect,
        linkTo,
        sortOption,
        onSortClick,
        elementOnHover,
      }}
    >
      <TableWrap
        className={className}
        isInitialized={isInitialized}
        isCheckable={!!checkable}
        isToggleable={!!toggleable}
        widthValues={widthValues}
        type={type}
        styles={styles}
        ref={el => setContainerEl(el)}
        onMouseOver={handleMouseOver}
      >
        {children}
      </TableWrap>
    </TableContext.Provider>
  );
};

export default { Table };
