type Props = {
  width?: number;
  height?: number;
};

export const IconBang: React.FC<React.PropsWithChildren<Props>> = ({ width = 72, height = 72 }) => (
  <svg xmlns="http://www.w3.org/2000/svg" width={width} height={height} viewBox="0 0 72 72" fill="none">
    <circle cx="36" cy="36" r="34" stroke="#BDC9DA" strokeWidth="4" />
    <path d="M36.439 17.561V44.3415" stroke="#BDC9DA" strokeWidth="4" strokeLinecap="round" strokeLinejoin="round" />
    <path d="M36.439 53.561V54.0001" stroke="#BDC9DA" strokeWidth="4" strokeLinecap="round" strokeLinejoin="round" />
  </svg>
);
