import React, { useCallback, useMemo } from 'react';
import styled, { css } from 'styled-components';

import { Icon } from 'components/atoms/Icon';
import IconPaginationNext from 'images/icons/icon-pagination-next.png';
import IconPaginationPrev from 'images/icons/icon-pagination-prev.png';
import PageNavigation from 'components/commons/Pagination/PageNavigation';
import { ScreenReaderOnly } from 'components/commons/shared';

const getCurrentPageItems = (pageCount: number, totalPages: number, currentPage: number) => {
  let pageItems = new Array(totalPages).fill(undefined).map((item, index) => index + 1);
  if (totalPages > pageCount) {
    if (totalPages - currentPage < pageCount) {
      pageItems = pageItems.slice(pageCount * -1);
    } else {
      pageItems = pageItems.slice(currentPage - 1, currentPage - 1 + pageCount);
    }
  }

  return pageItems;
};

export interface PaginationProps extends Pageable {
  pageCount?: number;
  showPageNavigation?: boolean;
}

const Pagination: React.FC<React.PropsWithChildren<PaginationProps>> = ({
  totalPages = 0,
  currentPage = 1,
  pageCount = 5,
  showPageNavigation = false,
  onPageSelect,
}) => {
  const handleMoveToPage = useCallback(
    (nextPage: number) => {
      if (nextPage >= 1 && nextPage <= totalPages) {
        if (onPageSelect) {
          onPageSelect(nextPage);
        }
      }
    },
    [totalPages, onPageSelect]
  );

  const currentDisplayedPages = useMemo(
    () => getCurrentPageItems(pageCount, totalPages, currentPage),
    [pageCount, totalPages, currentPage]
  );

  const hasPreviousPages = useMemo(
    () => totalPages > pageCount && currentPage > 1,
    [totalPages, pageCount, currentPage]
  );
  const hasNextPages = useMemo(() => totalPages > currentPage + (pageCount - 1), [currentPage, pageCount, totalPages]);

  const hasPreviousPage = useMemo(() => currentPage === 1, [currentPage]);
  const hasNextPage = useMemo(() => currentPage >= totalPages, [currentPage, totalPages]);

  if (totalPages <= 0) {
    return null;
  }

  return (
    <PaginationWrap>
      <PaginationList>
        <PaginationItem>
          <StyledArrowButton
            data-testid="prev"
            disabled={hasPreviousPage}
            onClick={() => handleMoveToPage(currentPage - 1)}
          >
            <Icon url={IconPaginationPrev} width={6} height={12} />
            <ScreenReaderOnly>Previous Page</ScreenReaderOnly>
          </StyledArrowButton>
        </PaginationItem>
        {hasPreviousPages && (
          <PaginationItem>
            <StyledBaseButton
              data-testid="prev-pages"
              onClick={() => {
                handleMoveToPage(currentPage - pageCount < 1 ? 1 : currentPage - pageCount);
              }}
            >
              …<ScreenReaderOnly>Previous {pageCount} Items</ScreenReaderOnly>
            </StyledBaseButton>
          </PaginationItem>
        )}
        {currentDisplayedPages.map(page => {
          return (
            <PaginationItem key={`page-${page}`}>
              <StyledPageButton
                data-testid={`page-${page}`}
                active={page === currentPage}
                onClick={() => handleMoveToPage(page)}
              >
                {page}
              </StyledPageButton>
            </PaginationItem>
          );
        })}
        {hasNextPages && (
          <PaginationItem>
            <StyledBaseButton
              data-testid="next-pages"
              onClick={() => {
                handleMoveToPage(currentPage + pageCount > totalPages ? totalPages : currentPage + pageCount);
              }}
            >
              …<ScreenReaderOnly>Next {pageCount} Items</ScreenReaderOnly>
            </StyledBaseButton>
          </PaginationItem>
        )}
        <PaginationItem>
          <StyledArrowButton
            data-testid="next"
            disabled={hasNextPage}
            onClick={() => handleMoveToPage(currentPage + 1)}
          >
            <Icon url={IconPaginationNext} width={6} height={12} />
            <ScreenReaderOnly>Next Page</ScreenReaderOnly>
          </StyledArrowButton>
        </PaginationItem>
      </PaginationList>
      {showPageNavigation && (
        <StyledPageNavigation totalPages={totalPages} currentPage={currentPage} onPageSelect={handleMoveToPage} />
      )}
    </PaginationWrap>
  );
};

const StyledPageNavigation = styled(PageNavigation)`
  position: absolute;
  right: 0;
  top: 50%;
  transform: translate3d(0, -50%, 0);
`;

const PaginationItem = styled.li``;

const PaginationList = styled.ul`
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 10px 0;
  width: 100%;
  text-align: center;
  column-gap: 10px;
`;

const PaginationWrap = styled.div`
  position: relative;
  padding: 10px 0;
`;

const StyledArrowButton = styled.button`
  box-sizing: border-box;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 20px;
  height: 20px;
  border: 1px solid #e2e2e2;
  border-radius: 2px;
  background-color: #fff;

  &:disabled {
    cursor: not-allowed;
  }

  &:hover {
    background-color: transparent;
    box-shadow: none;
  }
`;

const StyledBaseButton = styled.button`
  background-color: transparent;
  padding: 0;
  border: 0;
  font-size: 12px;
  color: #bbbbbb;
  font-weight: normal;

  &:disabled {
    cursor: not-allowed;
  }

  &:hover {
    color: #000;
    font-weight: bold;
  }
`;

const StyledPageButton = styled(StyledBaseButton)<{ active: boolean }>`
  ${props =>
    props.active &&
    css`
      color: #000;
      font-weight: bold;
    `}
`;

export default Pagination;
