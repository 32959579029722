import React, { useCallback, useEffect, useRef, useState } from 'react';

import styled from 'styled-components';

export type SwitchButtonProps = {
  id?: string;
  value: boolean;
  onToggle?: (checked: boolean) => void;
  disabled?: boolean;
  label?: string;
  backgroundColor?: string;
  buttonColor?: string;
  className?: string;
  onElementReady?: (element: HTMLDivElement) => void;
  tabIndex?: number;
};

const SwitchButtonDiv = styled.div<{
  isOn: boolean;
  disabled?: boolean;
  $backgroundColor?: string;
  buttonColor?: string;
}>`
  position: relative;
  width: 34px;
  height: 14px;
  background-color: #d4dbdf;
  border-radius: 15.5px;
  box-sizing: border-box;
  cursor: ${props => (props.disabled ? 'not-allowed' : 'pointer')};
  opacity: ${props => (props.disabled ? 0.5 : 1)};
  user-select: none;
  ${props => {
    if (props.isOn) {
      return `
      background-color: ${props.$backgroundColor || 'var(--mantine-color-blue-2)'};
      `;
    }
  }}
  .button {
    position: absolute;
    top: 50%;
    left: 0;
    transform: translate3d(0, -50%, 0);
    transition: transform 0.1s ease-in-out;
    width: 18px;
    height: 18px;
    border-radius: 50%;
    box-shadow: 0 1px 1px 0 rgba(0, 0, 0, 0.2);
    box-sizing: border-box;
    background-color: #fafafa;
    cursor: ${props => (props.disabled ? 'not-allowed' : 'pointer')};
    ${props => {
      if (props.isOn) {
        return `
        transform: translate3d(16px, -50%, 0);
        background-color: ${props.buttonColor || 'var(--mantine-color-blue-6)'};
        `;
      }
    }}
  }

  &:focus,
  &:hover {
    box-shadow: 0px 1px 5px rgba(135, 135, 135, 0.25);

    .button {
      box-shadow: 0px 1px 5px rgba(135, 135, 135, 0.25);
    }
  }
`;

const SwitchContainer = styled.div`
  display: inline-flex;
  align-items: center;
  height: 20px;

  p {
    display: flex;
    align-items: center;

    padding-left: 10px;

    color: #7d7d7d;

    font-size: 12px;
    letter-spacing: -0.2px;
  }
`;

const SwitchButton: React.FC<React.PropsWithChildren<SwitchButtonProps>> = ({
  id,
  value,
  disabled = false,
  label,
  onToggle,
  backgroundColor,
  buttonColor,
  className,
  onElementReady,
  tabIndex,
}) => {
  const [checked, toggleChecked] = useState<boolean>(false);
  const elementRef = useRef<HTMLDivElement>();

  useEffect(() => {
    toggleChecked(value);
  }, [value]);

  const handleClick = useCallback(() => {
    if (!disabled) {
      onToggle?.(!checked);
    }
  }, [checked, disabled, onToggle]);

  const handleKeydown = (event: React.KeyboardEvent<HTMLDivElement>) => {
    if (disabled === false) {
      switch (event.key) {
        case 'Enter':
        case ' ':
          event.preventDefault();
          elementRef.current?.click();
          break;
        default:
          break;
      }
    }
  };

  return (
    <SwitchContainer
      id={id}
      ref={element => {
        if (element) {
          elementRef.current = element;
          onElementReady?.(element);
        }
      }}
      className={className}
      onClick={handleClick}
      onKeyDown={handleKeydown}
    >
      <SwitchButtonDiv
        role="button"
        tabIndex={tabIndex}
        isOn={checked}
        disabled={disabled}
        $backgroundColor={backgroundColor}
        buttonColor={buttonColor}
      >
        <div className="button" />
      </SwitchButtonDiv>
      {label && <p>{label}</p>}
    </SwitchContainer>
  );
};
export default SwitchButton;
