import { QueryClient } from 'react-query';
function configureQueryClient() {
  const queryClient = new QueryClient();

  queryClient.setDefaultOptions({
    queries: {
      retry: 0,
      refetchOnWindowFocus: false,
      refetchOnReconnect: true,
      refetchOnMount: true,
      keepPreviousData: true,
      meta: {
        showSpinner: true,
      },
    },
  });

  return {
    queryClient,
  };
}

export default configureQueryClient;
