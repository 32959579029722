import { HTMLAttributes, ReactNode, useEffect, useState } from 'react';

import styled from 'styled-components';

type Props = { text: ReactNode; reasons: string[] } & HTMLAttributes<HTMLDivElement>;

const MoreButton = styled.button`
  display: flex;
  align-items: center;
  justify-content: center;
  padding-top: 22px;
  width: 100%;
  border: 0;
  background-color: transparent;
`;

const ListItem = styled.li`
  padding: 0 16px;
  overflow: hidden;
  text-overflow: ellipsis;
  color: #555;
  font-weight: bold;
  font-size: 16px;
  line-height: 24px;
  letter-spacing: -0.266667px;
  text-align: center;
  white-space: nowrap;

  svg {
    margin-right: 7px;
  }
`;

const List = styled.ul`
  padding: 30px 0;
  width: 400px;
  min-height: 172px;
  border: 1px solid #e0e3e7;
  box-sizing: border-box;
  box-shadow: inset 0px 1px 3px rgba(0, 0, 0, 0.197124);
  border-radius: 6px;
  background-color: #f5f5f5;

  ${ListItem} + ${ListItem} {
    margin-top: 6px;
  }
`;

const Container = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: top;

  ${List} {
    margin: 33px 0 25px;
  }
`;

export const ReasonList: React.FC<React.PropsWithChildren<Props>> = ({ text, reasons, ...rest }) => {
  const [showAll, toggleShowAll] = useState(false);
  const [showButton, toggleShowButton] = useState(false);
  const [slicedReasons, setSlicedReasons] = useState<string[]>([]);

  const handleButtonClick = () => {
    toggleShowAll(!showAll);
  };

  useEffect(() => {
    setSlicedReasons(reasons.slice(0, 3));
  }, [reasons]);

  useEffect(() => {
    toggleShowButton(reasons.length > 3 && !showAll);
  }, [reasons, showAll]);

  return (
    <Container {...rest}>
      {text}
      {reasons.length > 0 && (
        <List>
          {(showAll ? reasons : slicedReasons).map(reason => (
            <ListItem key={reason}>
              <svg width="14" height="14" viewBox="0 0 14 14" fill="none" xmlns="http://www.w3.org/2000/svg">
                <circle cx="7" cy="7" r="6" fill="#BB0303" />
                <path
                  fillRule="evenodd"
                  clipRule="evenodd"
                  d="M0 7C0 3.136 3.129 0 6.993 0C10.864 0 14 3.136 14 7C14 10.864 10.864 14 6.993 14C3.129 14 0 10.864 0 7ZM1.4 7C1.4 10.094 3.906 12.6 7 12.6C10.094 12.6 12.6 10.094 12.6 7C12.6 3.906 10.094 1.4 7 1.4C3.906 1.4 1.4 3.906 1.4 7Z"
                  fill="#BB0303"
                />
                <path
                  fillRule="evenodd"
                  clipRule="evenodd"
                  d="M7.70005 3.5H6.30005V7.7H7.70005V3.5ZM7.70005 9.1H6.30005V10.5H7.70005V9.1Z"
                  fill="white"
                />
              </svg>
              {reason}
            </ListItem>
          ))}
          {showButton && (
            <li>
              <MoreButton type="button" onClick={handleButtonClick}>
                <svg width="12" height="7" viewBox="0 0 12 7" fill="none" xmlns="http://www.w3.org/2000/svg">
                  <path
                    d="M11.064 0L12 0.865L6.468 6.797C6.236 7.045 5.855 7.066 5.598 6.859L5.532 6.797L0 0.865L0.936 0L6 5.43L11.064 0Z"
                    fill="#909090"
                  />
                </svg>
              </MoreButton>
            </li>
          )}
        </List>
      )}
    </Container>
  );
};
