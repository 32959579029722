import { createStandardAction } from 'typesafe-actions';

type LocaleActionPayloadType = { locale: SpenditLocale };

export const SET_LOCALE = 'locale/SET_LOCALE';
export const SET_LOCALE_SUCCESS = 'locale/SET_LOCALE_SUCCESSS';

export const localeActions = {
  setLocale: createStandardAction(SET_LOCALE)<LocaleActionPayloadType>(),
  setLocaleSuccess: createStandardAction(SET_LOCALE_SUCCESS)<LocaleActionPayloadType>(),
};
