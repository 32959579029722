import { ActionType, createReducer } from 'typesafe-actions';
import {
  BULK_REPORTS_EXPORT_EVENT_ADDED,
  BULK_REPORTS_EXPORT_EVENT_REMOVED,
  CHECK,
  CHECK_ALL,
  EXPORT_ERP_RESERVE_SUCCESS,
  HIDE_BULK_REPORTS_EXPORT_RESULT_MODAL,
  SELECT_ALL_IN_THIS_PAGE,
  SHOW_BULK_REPORTS_EXPORT_RESULT_MODAL,
  UNCHECK,
  UNCHECK_ALL,
  UNSELECT_ALL_IN_THIS_PAGE,
  reportListActions,
} from 'modules/reports/list/actions';

import { GetReportsJobsResultResponse } from 'apis/ReportAPI';
import { produce } from 'immer';

type ReportListReducerState = {
  checkedAll: boolean;
  selectedReports: {
    [reportId: number]: Report;
  };
  exportErpReserve: {
    jobId?: string;
    resultModal: {
      visible: boolean;
      data?: GetReportsJobsResultResponse;
    };
  };
};

const initialState: ReportListReducerState = {
  checkedAll: false,
  selectedReports: {},
  exportErpReserve: {
    resultModal: {
      visible: false,
    },
  },
};

export const reportListReducer = createReducer<ReportListReducerState, ActionType<typeof reportListActions>>(
  initialState,
  {
    [CHECK]: (state, { payload }) =>
      produce(state, draft => {
        if (draft.checkedAll) {
          delete draft.selectedReports[payload.id];
        } else {
          draft.selectedReports[payload.id] = payload;
        }
      }),
    [UNCHECK]: (state, { payload }) =>
      produce(state, draft => {
        if (draft.checkedAll) {
          draft.selectedReports[payload.id] = payload;
        } else {
          delete draft.selectedReports[payload.id];
        }
      }),
    [CHECK_ALL]: state =>
      produce(state, draft => {
        draft.checkedAll = true;
        draft.selectedReports = {};
      }),
    [UNCHECK_ALL]: state =>
      produce(state, draft => {
        draft.checkedAll = false;
        draft.selectedReports = {};
      }),
    [SELECT_ALL_IN_THIS_PAGE]: (state, { payload }) =>
      produce(state, draft => {
        payload.forEach(expense => {
          draft.selectedReports[expense.id] = expense;
        });
      }),
    [UNSELECT_ALL_IN_THIS_PAGE]: (state, { payload }) =>
      produce(state, draft => {
        payload.forEach(expense => {
          delete draft.selectedReports[expense.id];
        });
      }),
    [EXPORT_ERP_RESERVE_SUCCESS]: (state, { payload }) =>
      produce(state, draft => {
        draft.exportErpReserve.jobId = payload.result.job_id;
      }),
    [BULK_REPORTS_EXPORT_EVENT_ADDED]: (state, { payload }) =>
      produce(state, draft => {
        draft.exportErpReserve.jobId = payload.event_id;
      }),
    [BULK_REPORTS_EXPORT_EVENT_REMOVED]: state =>
      produce(state, draft => {
        delete draft.exportErpReserve.jobId;
      }),
    [SHOW_BULK_REPORTS_EXPORT_RESULT_MODAL]: (state, { payload }) =>
      produce(state, draft => {
        draft.exportErpReserve.resultModal.visible = true;
        draft.exportErpReserve.resultModal.data = payload;
      }),
    [HIDE_BULK_REPORTS_EXPORT_RESULT_MODAL]: state =>
      produce(state, draft => {
        draft.exportErpReserve.resultModal.visible = false;
        delete draft.exportErpReserve.resultModal.data;
      }),
  }
);
