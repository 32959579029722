import React, { useCallback, useEffect, useRef, useState } from 'react';
import { TooltipArrowStyle, TooltipDirection, TooltipOption, TooltipStyle } from './types';

import Tooltip from 'components/commons/Tooltip/GlobalTooltip';

export type TooltipOnHoverProps = {
  tooltipContent: React.ReactNode;
  direction?: TooltipDirection;
  styles?: TooltipStyle;
  arrowStyles?: TooltipArrowStyle;
  disabled?: boolean;
  enableWhenOverflow?: boolean;
  autoPositioning?: boolean;
  location?: 'left' | 'right';
  /** React.cloneElement를 하기 위해 Plain text 가 아닌 태그로 감싼 children 필요 */
  children: React.ReactNode;
  /** true인 경우 text가 넘치면, 말줄임을 사용 */
  isEllipsis?: boolean;
};

const TOOLTIP_DELAY = 300;

export const TooltipOnHover: React.FC<React.PropsWithChildren<TooltipOnHoverProps>> = ({
  tooltipContent,
  direction,
  styles,
  arrowStyles,
  disabled,
  enableWhenOverflow,
  autoPositioning,
  location,
  children,
  isEllipsis,
}) => {
  const [internalTooltipOption, setInternalTooltipOption] = useState<TooltipOption>();
  const timerRef = useRef(0);

  const handleMouseOver = useCallback(
    (event: React.MouseEvent) => {
      const { target: eventTarget } = event;
      if (eventTarget instanceof HTMLElement) {
        const hasTextOverflow = eventTarget.scrollWidth > eventTarget.clientWidth;

        if (disabled) {
          return;
        }

        if (enableWhenOverflow && !hasTextOverflow) {
          return;
        }

        timerRef.current = window.setTimeout(() => {
          setInternalTooltipOption({
            direction,
            location,
            target: eventTarget,
            autoPositioning,
            styles: {
              padding: '6px',
              borderRadius: '4px',
              fontSize: '10px',
              lineHeight: '1.2',
              ...styles,
            },
            arrowStyles: {
              ...arrowStyles,
            },
          });
        }, TOOLTIP_DELAY);
      }
    },
    [direction, styles, disabled, location, enableWhenOverflow, autoPositioning]
  );

  const handleMouseOut = useCallback(() => {
    clearTimeout(timerRef.current);
    setInternalTooltipOption(undefined);
  }, []);

  useEffect(() => {
    return () => {
      clearTimeout(timerRef.current);
      setInternalTooltipOption(undefined);
    };
  }, []);

  return (
    <>
      {React.Children.toArray(children)
        .filter((child): child is React.ReactElement => React.isValidElement(child))
        .map(child => {
          return React.cloneElement(child, {
            onMouseOver: handleMouseOver,
            onMouseOut: handleMouseOut,
            className: isEllipsis ? `${child.props.className} ellipsis` : child.props.className,
          });
        })}
      <Tooltip option={internalTooltipOption}>{tooltipContent}</Tooltip>
    </>
  );
};
