import React, { useEffect, useState } from 'react';
import { translate, translateHTML } from 'utils/LocaleUtil';

import { BorderlessSelectBox } from 'components/commons/SelectBox/BorderlessSelectBox';
import styled from 'styled-components';

const PageInfo = styled.div`
  color: #808080;
  font-size: 12px;
`;

const PageNavigationWrap = styled.div`
  display: inline-flex;
  align-items: center;
`;

const PageNavigation: React.FC<React.PropsWithChildren<PageNavigationProps>> = ({
  totalPages,
  currentPage,
  className,
  hidePageInfo = false,
  onPageSelect,
}) => {
  const [pages, setPages] = useState<number[]>([]);

  const handleOnSelect = (page: number) => {
    if (onPageSelect) {
      onPageSelect(page);
    }
  };

  useEffect(() => {
    setPages(new Array(totalPages).fill(undefined).map((item, index) => index + 1));
  }, [totalPages]);

  if ((totalPages ?? 0) > 1) {
    return (
      <PageNavigationWrap className={className}>
        {!hidePageInfo && <PageInfo>{translateHTML('total_count_page', { total_count: totalPages })}</PageInfo>}
        <BorderlessSelectBox
          value={{ label: `${currentPage}`, value: currentPage }}
          options={pages.map(nextPage => ({ label: `${nextPage}`, value: nextPage }))}
          onValueChange={(values, target) => {
            if (target?.value) {
              handleOnSelect(target.value);
            }
          }}
          styles={{ width: '70px' }}
          toggleLabelStyle={{ fontSize: '13px', lineHeight: '1.54' }}
        />
        {!hidePageInfo && <PageInfo>{translate('page')}</PageInfo>}
      </PageNavigationWrap>
    );
  }
  return <></>;
};
export default PageNavigation;
