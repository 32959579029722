import { APIController, BASE_INSTANCE_KEY } from 'apis/bootstrap';
import { REACT_APP_API_URL, REACT_APP_OPEN_API_URL } from '../utils/EnvUtil';
import axios, { AxiosInstance, AxiosRequestConfig, AxiosResponse } from 'axios';
import { configureAxiosInstance, isLegacyInvalidSessionErrorResponse } from 'apis/apiUtils';

import { Store } from 'redux';
import { axiosActions } from 'modules/axios';
import qs from 'qs';

/**
 * @see https://spendit.atlassan.net/browse/WEB-2855 , https://spendit.atlassian.net/wiki/x/QwFItw
 * @description 레거시 API Client 인스턴스화 모듈, APIController로 점진적 변경 필요
 */

export const paramsSerializer: AxiosRequestConfig['paramsSerializer'] = params => {
  return qs.stringify(params, {
    arrayFormat: 'brackets',
    encodeValuesOnly: true,
  });
};

const instance = axios.create({
  baseURL: REACT_APP_API_URL,
  paramsSerializer,
});
const openApiInstance = axios.create({
  baseURL: REACT_APP_OPEN_API_URL,
  headers: { Accept: `application/vnd.spendit.v${process.env.REACT_APP_OPEN_API_VERSION ?? 1}+json` },
  paramsSerializer,
});

let _store: Store;

export const configureAxios = (store: Store): void => {
  _store = store;
  configureAxiosInstance(store, instance);
  configureAxiosInstance(store, openApiInstance);
};

export const setAuthenticationHeader = (token?: string | null): void => {
  // eslint-disable-next-line dot-notation
  instance.defaults.headers.common['Authentication'] = token || '';

  /**
   * 기존 코드에 영향을 주지않기 위해 동일 기능을 수행하나 인스턴스가 아닌 APIController로 config 조작하는 로직을 추가
   */
  APIController.overrideInstanceConfig(BASE_INSTANCE_KEY, {
    headers: {
      common: {
        Authentication: token || '',
      },
    },
  });
};

export const setBaseUrl = (url: string) => {
  instance.defaults.baseURL = url;
  /**
   * 기존 코드에 영향을 주지않기 위해 동일 기능을 수행하나 인스턴스가 아닌 APIController로 config 조작하는 로직을 추가
   */
  APIController.overrideInstanceConfig(BASE_INSTANCE_KEY, {
    baseURL: url,
  });
};

type ApiRequestFunction<T> = (...args: any[]) => Promise<AxiosResponse<T>>;

const withLegacyErrorResponseHandler = <T>(fn: ApiRequestFunction<T>, ...args: Parameters<ApiRequestFunction<T>>) =>
  new Promise<AxiosResponse<T>>((resolve, reject) => {
    fn(...args).then(
      response => {
        // Legacy Error 오류 타입 처리
        if (isLegacyInvalidSessionErrorResponse(response)) {
          _store.dispatch(axiosActions.globalErrorOccurred({ type: 'session_expired' }));
          // AxiosReponse를 AxiosError로 흘려보내기 위함
          reject({ response });
        } else {
          resolve(response);
        }
      },
      reason => {
        reject(reason);
      }
    );
  });

const toCustomApiInstance = (axiosInstance: AxiosInstance) => {
  return {
    instance: axiosInstance,
    post: <T = any>(...args: Parameters<typeof axiosInstance.post>) =>
      withLegacyErrorResponseHandler<T>(axiosInstance.post, ...args),
    put: <T = any>(...args: Parameters<typeof axiosInstance.put>) =>
      withLegacyErrorResponseHandler<T>(axiosInstance.put, ...args),
    get: <T = any>(...args: Parameters<typeof axiosInstance.get>) =>
      withLegacyErrorResponseHandler<T>(axiosInstance.get, ...args),
    delete: <T = any>(...args: Parameters<typeof axiosInstance.delete>) =>
      withLegacyErrorResponseHandler<T>(axiosInstance.delete, ...args),
    patch: <T = any>(...args: Parameters<typeof axiosInstance.patch>) =>
      withLegacyErrorResponseHandler<T>(axiosInstance.patch, ...args),
  };
};

//TODO : API, OpenAPI -> bootstrap.ts 의 API, OpenAPI로 점진적 변경 필요
const API = toCustomApiInstance(instance);
export const OpenAPI = toCustomApiInstance(openApiInstance);

export default API;
