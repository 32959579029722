import {
  EXPORT_ERP_RESERVE,
  EXPORT_ERP_RESERVE_FAILED,
  EXPORT_ERP_RESERVE_SUCCESS,
  reportListActions,
} from '../actions';
import {
  PostReportsExportDiffCheckResponse,
  PostReportsExportErpReserveResponse,
  postReportsExportDiffCheck,
  postReportsExportDiffCheckAll,
  postReportsExportErpReserve,
} from 'apis/ReportAPI';
import { call, fork, put, select, take } from 'redux-saga/effects';
import { fireError, showConfirm } from 'utils/SwalUtil';

import { ActionType } from 'typesafe-actions';
import ErrorHandler from 'utils/ErrorHandler';
import { PopupText } from 'utils/SwalUtil/templates/PopupText';
import { ReasonList } from 'utils/SwalUtil/templates/ReasonList';
import { SweetAlertResult } from 'sweetalert2';
import { reportListSelectors } from '../selectors';
import { translate } from 'utils/LocaleUtil';
import { uiActions } from 'modules/ui';

function* exportDiffCheck({
  checked_all,
  report_ids,
  filter,
}: ActionType<typeof reportListActions.exportErpReserve>['payload']) {
  if (checked_all) {
    return yield call(postReportsExportDiffCheckAll, {
      exclude_ids: report_ids,
      filter,
    });
  }

  return yield call(postReportsExportDiffCheck, {
    include_ids: report_ids,
    filter,
  });
}

function* showErpExportConfirm(
  reasons: {
    message: string;
    reportTitles: string[];
  }[]
) {
  const result: SweetAlertResult = yield call(showConfirm, {
    width: 510,
    text: (
      <>
        {reasons.map(({ message, reportTitles }, index) => (
          <ReasonList key={message} text={<PopupText>{message}</PopupText>} reasons={reportTitles} />
        ))}
        <PopupText>{translate('confirm_export_the_reports')}</PopupText>
      </>
    ),
    confirmButtonText: translate('confirm'),
    confirmButtonColor: '#DE3D3D',
  });

  return result.isConfirmed;
}

function* canExport(action: ActionType<typeof reportListActions.exportErpReserve>) {
  const isErpReserveExporting: ReturnType<typeof reportListSelectors.isErpReserveExporting> = yield select(
    reportListSelectors.isErpReserveExporting
  );

  // 이미 내보내기가 진행중인 경우
  if (isErpReserveExporting) {
    yield call(fireError, translate('error_while_exporting_reports_to_erp'));
    return false;
  }

  /**
   * 보고서 중복내보내기 체크
   * @see https://spendit.atlassian.net/browse/SPD-269
   */
  const {
    result: { different_report_count, different_report_titles, same_report_count, same_report_titles },
  }: PostReportsExportDiffCheckResponse = yield call(exportDiffCheck, action.payload);

  const hasDifferentReports = different_report_count > 0;
  const hasSameReports = same_report_count > 0;

  // 변경된 보고서 있음 and 동일한 보고서 없음
  if (hasDifferentReports && !hasSameReports) {
    return yield call(showErpExportConfirm, [
      {
        message: translate('message_report_has_been_edited'),
        reportTitles: different_report_titles,
      },
    ]);
  }

  // 변경된 보고서 없음 and 동일한 보고서 있음
  if (!hasDifferentReports && hasSameReports) {
    return yield call(showErpExportConfirm, [
      {
        message: translate('message_report_has_been_previously_exported'),
        reportTitles: same_report_titles,
      },
    ]);
  }

  // 변경된 보고서 있음 and 동일한 보고서 있음
  if (hasDifferentReports && hasSameReports) {
    return yield call(showErpExportConfirm, [
      {
        message: translate('message_report_has_been_previously_exported'),
        reportTitles: same_report_titles,
      },
      {
        message: translate('message_report_has_been_edited'),
        reportTitles: different_report_titles,
      },
    ]);
  }

  // 모든 보고서가 처음 내보내는 보고서인 경우
  return true;
}

function* exportErpReserve(action: ActionType<typeof reportListActions.exportErpReserve>) {
  try {
    const { checked_all, report_ids, filter } = action.payload;

    yield put(uiActions.showSpinner());
    const data: PostReportsExportErpReserveResponse = yield call(postReportsExportErpReserve, {
      checked_all,
      report_ids,
      filter: JSON.stringify(filter),
    });
    yield put(reportListActions.exportErpReserveSuccess(data));
  } catch (error) {
    yield call(ErrorHandler.fromAxios, error);
  } finally {
    yield put(uiActions.hideSpinner());
  }
}

export function* watchExportErpReserve() {
  while (true) {
    const action: ActionType<typeof reportListActions.exportErpReserve> = yield take(EXPORT_ERP_RESERVE);

    if (yield call(canExport, action)) {
      yield fork(exportErpReserve, action);

      const resultAction:
        | ActionType<typeof reportListActions.exportErpReserveSuccess>
        | ActionType<typeof reportListActions.exportErpReserveFailed> = yield take([
        EXPORT_ERP_RESERVE_SUCCESS,
        EXPORT_ERP_RESERVE_FAILED,
      ]);

      if (resultAction.type === EXPORT_ERP_RESERVE_SUCCESS) {
        yield put(reportListActions.uncheckAll());
      } else if (resultAction.type === EXPORT_ERP_RESERVE_FAILED) {
        yield call(ErrorHandler.fromAxios, resultAction.payload);
      }
    } else {
      yield put(reportListActions.exportErpReserveCancelled());
    }
  }
}
