import { ActionType, createReducer, createStandardAction } from 'typesafe-actions';
import { NAVIGATE_SUCCESS, paths, routerActions } from 'modules/router';
import { call, put, select, takeLatest } from 'redux-saga/effects';
import { hideSpinner, showSpinner } from 'modules/ui';
import { selectCurrentUser, sessionSelectors } from 'modules/session/selectors';

import ErrorHandler from 'utils/ErrorHandler';
import { RootState } from 'modules';
import { postConnectSpenditUser } from 'apis/ConnectAPI';
import { produce } from 'immer';
import provider from 'json/provider.json';
import { spenditHistory } from '@N/view/bootstrap/config/configureHistory';

export const selectConnectOption = (state: RootState) => state.connect.option;
export const selectConnectProvider = (state: RootState) => state.connect.option?.provider || 'slack';

export const INIT_CONNECT = 'connect/INIT_CONNECT';
export const CONNECT_SPENDIT_USER = 'connect/CONNECT_SPENDIT_USER';

export const connectActions = {
  initConnect: createStandardAction(INIT_CONNECT)<{ option: ConnectOption }>(),
  connectSpenditUser: createStandardAction(CONNECT_SPENDIT_USER)(),
};

function* handleNavigateSuccess(action: ActionType<typeof routerActions.navigateSuccess>) {
  const { currentPath } = action.payload;
  if (currentPath === paths.connect) {
    const option: ReturnType<typeof getConnectOption> = yield call(
      getConnectOption,
      new URL(`${window.location}`).searchParams
    );
    if (option) {
      yield put(connectActions.initConnect({ option }));
    } else {
      window.location.href = '/';
    }
  }
}

function* handleInitConnect(action: ActionType<typeof connectActions.initConnect>) {
  const { option } = action.payload;
  const isLoggedIn: ReturnType<typeof sessionSelectors.isLoggedIn> = yield select(sessionSelectors.isLoggedIn);

  if (isLoggedIn) {
    yield put(connectActions.connectSpenditUser());
  } else if (option.from === `${option.provider}_message_login`) {
    spenditHistory.push(paths.signIn);
  }
}

function getConnectOption(searchParams: URLSearchParams) {
  const params = searchParams;
  // eslint-disable-next-line no-shadow
  const provider: SpenditProvider = 'slack';
  let option: ConnectOption | undefined;
  if (params.has('platform') && params.has('app') && params.has('team') && params.has('id') && params.has('from')) {
    option = {
      provider,
      from: params.get('from') || '',
      app: params.get('app') || '',
      team: params.get('team') || '',
      id: params.get('id') || '',
    };
  }
  return option;
}

function* handleConnectSpenditUser() {
  const option: ReturnType<typeof selectConnectOption> = yield select(selectConnectOption);
  const currentUser: ReturnType<typeof selectCurrentUser> = yield select(selectCurrentUser);
  if (option && currentUser) {
    try {
      yield put(showSpinner());
      yield call(postConnectSpenditUser, provider[option.provider].connect_url, {
        id: Number(option.id),
        spenditUserId: currentUser.id,
      });
      spenditHistory.push(paths.connected);
    } catch (e) {
      yield call(ErrorHandler.fromAxios, e);
    } finally {
      yield put(hideSpinner());
    }
  } else {
    window.location.href = '/';
  }
}

export function* connectSaga() {
  yield takeLatest(NAVIGATE_SUCCESS, handleNavigateSuccess);
  yield takeLatest(INIT_CONNECT, handleInitConnect);
  yield takeLatest(CONNECT_SPENDIT_USER, handleConnectSpenditUser);
}

const initialState: ConnectInitialState = {
  option: undefined,
};

export const connectReducer = createReducer<ConnectInitialState, ActionType<typeof connectActions>>(initialState, {
  [INIT_CONNECT]: (state, { payload: { option } }) =>
    produce(state, draft => {
      draft.option = option;
    }),
});
