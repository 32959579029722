import { match as pathToRegexpMatch } from 'path-to-regexp';

const pathsWithoutAuth = {
  signUp: '/sign_up',
  signIn: '/sign_in',
  passwordReset: '/password_reset',
  passwordChange: '/password_change',
  invitation: '/invitation/:invitation_token',
  reportEditor: '/report/editor', // webview에서 넘어온 session_tokend을 이용해 보고서 내용을 보여줌
} as const;

const sso = {
  sso: '/sso',
  okta: `/sso/okta`,
  oktaCallback: `/okta/callback`,
};

const company = {
  root: '/company',
  info: {
    root: '/info',
    userAnalysis: '/user-analysis',
  },
  users: '/users',
  expenseSettings: '/expense-settings',
  admin: '/admin',
  cards: { root: '/cards', personal: '/personal' },
  banks: '/banks',
  bankDetail: '/banks/detail/:id',
  reimbursement: '/reimbursement',
  groups: {
    root: '/groups',
    detail: '/:id',
  },
  rule: '/rule',
  exportOptions: '/export_options',
  taxes: '/taxes',
  taxDetail: '/taxes/detail/:id',
  docCenterPolicy: '/doc_center_policy',
} as const;

export const paths = {
  root: '/',
  contact: '/contact',
  privacyPolicy: '/privacy_policy',
  termOfService: '/terms',
  connect: '/connect',
  connected: '/connected',
  company,
  ...sso,
  ...pathsWithoutAuth,
} as const;

const webviewPaths = [
  paths.privacyPolicy,
  paths.termOfService,
  paths.passwordChange,
  paths.passwordReset,
  paths.invitation,
  paths.reportEditor,
];

/**
 * @summary `sourcePath`가 `targetPath`형식에 match가 된다면 match 결과 반환. 그렇지 않다면 false 반환.
 */
export const match = <ParamType extends Record<string, unknown>>(sourcePath: string, targetPath: string) =>
  pathToRegexpMatch<ParamType>(targetPath)(sourcePath);

/**
 * @summary 로그인이 필요없는 path인지 여부
 *
 * `/sign_up`, `/sign_in`, `/password_reset`, `/password_change`, `/invitation/:invitation_token`, `/sso`
 */
export const isWithoutAuthPath = (path: string) => {
  return Object.values(pathsWithoutAuth).some(pathWithoutAuth => match(path, pathWithoutAuth) !== false);
};

/**
 * @summary SignIn이후 redirect가 가능한 path인지?
 * @description 이전 `previousPath`가 아래 중 하나일 경우 redirect가 불가능하다
 *
 * `/sign_up`, `/sign_in`, `/password_reset`, `/password_change`, `/invitation/:invitation_token`, `/sso`
 */
export const canRedirectAfterSignIn = (previousPath: string) => isWithoutAuthPath(previousPath) === false;

/**
 * 모바일에서 접근가능한 path인지
 */
export const canAccessFromMobile = (currentPath: string) => {
  return webviewPaths.some(webViewPath => match(currentPath, webViewPath) !== false);
};
