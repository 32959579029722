export const headingsTypography = {
  h1: {
    fontWeight: '700',
    fontSize: '30px',
  },
  h2: {
    fontWeight: '400',
    fontSize: '30px',
  },
  // subheadings 1
  h3: {
    fontWeight: '700',
    fontSize: '20px',
  },
  // subheadings 2
  h4: {
    fontWeight: '700',
    fontSize: '18px',
  },
  // subheadings 3
  h5: {
    fontWeight: '700',
    fontSize: '16px',
  },
} as const;

export const bodyTypography = {
  text1: {
    fontWeight: '400',
    fontSize: '16px',
  },
  text2: {
    fontWeight: '700',
    fontSize: '14px',
  },
  text3: {
    fontWeight: '500',
    fontSize: '14px',
  },
  text4: {
    fontWeight: '400',
    fontSize: '14px',
  },
  text5: {
    fontWeight: '700',
    fontSize: '12px',
  },
  text6: {
    fontWeight: '500',
    fontSize: '12px',
  },
  text7: {
    fontWeight: '400',
    fontSize: '12px',
  },
  text8: {
    fontWeight: '700',
    fontSize: '10px',
  },
  text9: {
    fontWeight: '400',
    fontSize: '10px',
  },
  text10: {
    fontWeight: '700',
    fontSize: '8px',
  },
};

export const fontConfig = {
  fontFamily: 'Spoqa Han Sans Neo',
  fontSmoothing: false,
};
