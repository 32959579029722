import API from 'apis/API';
import { ResultResponse } from 'apis/shared';

/**
 * GET /v4/groups
 */
export const getGroups = async (params: GetGroupsParams) => {
  const res = await API.get<GetGroupsResponse>('/v4/groups', {
    params: {
      filter: {
        name: params.filter?.name,
        member_name: params.filter?.memberName,
      },
      order: params.order,
      page: params.page,
      count_per_page: params.countPerPage,
    },
  });

  return res.data;
};

/**
 * GET /v4/groups/users/candidate
 */
export const getGroupsUsersCandidate = async (params: GetGroupsUsersCandidateParams) => {
  const res = await API.get<GetGroupsUsersCandidateResponse>('/v4/groups/users/candidate', {
    params: {
      filter: params.filter,
      page: params.page,
      count_per_page: params.countPerPage,
    },
  });

  return res.data;
};

/**
 * GET /v4/groups/policies/candidate
 * @description 그룹에 포함된 폴리시 목록도 반환
 */
export const getGroupsPoliciesCandidate = async (params: GetGroupsPoliciesCandidateParams) => {
  const res = await API.get<GetGroupsPoliciesCandidateResponse>('/v4/groups/policies/candidate', {
    params: {
      filter: {
        user_id: params.filter?.userId,
        initial: params.filter?.initial,
        name: params.filter?.name,
      },
      page: params.page,
      count_per_page: params.countPerPage,
    },
  });

  return res.data;
};

/**
 * GET /v4/groups/:id/users/candidate
 * @description 그룹에 포함된 유저 목록도 반환
 */
export const getGroupsUsersCandidateAll = async (groupId: number, params: GetGroupsUsersCandidateParams) => {
  const res = await API.get<GetGroupsUsersCandidateResponse>(`/v4/groups/${groupId}/users/candidate`, {
    params: {
      filter: params.filter,
      page: params.page,
      count_per_page: params.countPerPage,
    },
  });

  return res.data;
};

/**
 * POST /v4/groups
 */
export const postGroup = async (params: PostGroupParams) => {
  const res = await API.post('/v4/groups', {
    name: params.name,
    policy_ids: params.policyIds,
    user_ids: params.userIds,
    is_default: params.isDefault,
  });

  return res.data;
};

/**
 * GET /v4/groups/:id
 */
export const getGroup = async (groupId: number) => {
  const res = await API.get<GetGroupResponse>(`/v4/groups/${groupId}`);

  return res.data;
};

/**
 * GET /v4/groups/:id/all
 * @description 유저와 폴리시 limit 없는 버전
 */
export const getGroupAll = async (groupId: number) => {
  const res = await API.get<GetGroupResponse>(`/v4/groups/${groupId}/all`);

  return res.data;
};

/**
 * PUT /v4/groups/:id
 */
export const putGroup = async (groupId: number, params: PutGroupParams) => {
  const res = await API.put(`/v4/groups/${groupId}`, {
    name: params.name,
    add_policy_ids: params.addPolicyIds,
    remove_policy_ids: params.removePolicyIds,
    add_user_ids: params.addUserIds,
    remove_user_ids: params.removeUserIds,
    is_default: params.isDefault,
  });

  return res.data;
};

/**
 * DELETE /v4/groups/:id
 */
export const deleteGroup = async (groupId: number) => {
  const res = await API.delete(`/v4/groups/${groupId}`);

  return res.data;
};

/**
 * GET /v4/groups/:id/users
 */
export const getGroupUsers = async (groupId: number, params: GetGroupUsersParams) => {
  const res = await API.get<GetGroupUsersResponse>(`/v4/groups/${groupId}/users`, {
    params: {
      page: params.page,
      count_per_page: params.countPerPage,
      order: params.order,
      filter: params.filter,
    },
  });

  return res.data;
};

/**
 * PUT /v4/groups/:id/add_users
 */
export const addGroupUsers = async (groupId: number, params: PutGroupsAddUsersParams) => {
  const res = await API.put(`/v4/groups/${groupId}/add_users`, {
    user_ids: params.userIds,
  });

  return res.data;
};

/**
 * POST /v4/groups/:id/move_users
 */
export const moveGroupUsers = async (groupId: number, params: PostGroupsMoveUsersParams) => {
  const res = await API.post(`/v4/groups/${groupId}/move_users`, {
    group_id: params.groupId,
    checked_all: params.checkedAll,
    user_ids: params.userIds,
  });

  return res.data;
};

/**
 * DELETE /v4/groups/:id/remove_users
 */
export const deleteGroupUsers = async (groupId: number, params: DeleteGroupUsersParams) => {
  const res = await API.delete(`/v4/groups/${groupId}/remove_users`, {
    data: {
      checked_all: params.checkedAll,
      user_ids: params.userIds,
    },
  });

  return res.data;
};

/**
 * GET /v4/groups/:id/movable_groups
 */
export const getMovableGroups = async (groupId: number) => {
  const res = await API.get<GetMovableGroupsResponse>(`/v4/groups/${groupId}/movable_groups`);

  return res.data;
};

/**
 * GET /web/groups
 */
export const getGroupsPreVersion = async (params: GetGroupsPreVersionParams) => {
  const res = await API.get<GetGroupsPreVersionResponse>('/web/groups', {
    params: {
      page: params.page,
      count_per_page: params.countPerPage,
      order: params.order,
      filter: { name: params.filter.name },
    },
  });
  return res.data;
};

export type PostGroupBulkUploadResponse = ResultResponse<
  {
    group: string;
    linked_policies: string[];
  },
  | {
      group: string;
      code: 'duplicate_group' | 'existing_group';
    }
  | {
      group: string;
      code: 'non_existing_policy';
      target_policies: string[];
    }
>;

/**
 * POST /v4/groups/group_bulk_upload
 */
export const postGroupBulkUpload = async (file: File) => {
  const formData = new FormData();
  formData.append('file', file);

  const res = await API.post<PostGroupBulkUploadResponse>('/v4/groups/group_bulk_upload', formData);

  return res.data;
};

type PostGroupMemberFailReason =
  | 'non_existing_member'
  | 'duplicate_member'
  | 'non_existing_group'
  | 'in_other_group'
  | 'in_current_group';

export type PostGroupMemberBulkUploadResponse = {
  success_list: {
    email: string;
    linked_group: string;
  }[];
  fail_list: {
    email: string;
    reason: string;
    code: PostGroupMemberFailReason;
  }[];
};

/**
 POST /v4/groups/member_bulk_upload
 */
export const postGroupMemberBulkUpload = async (file: File) => {
  const formData = new FormData();
  formData.append('file', file);

  const res = await API.post<PostGroupMemberBulkUploadResponse>('/v4/groups/member_bulk_upload', formData);

  return res.data;
};

export interface GetGroupsDownloadExcelParams {
  groupIds: number[];
  target: 'all' | 'select';
}

interface GetGroupsDownloadExcelResponse {
  download_link: string;
}

/**
 * GET /v4/groups/download_excel
 */
export const getGroupsDownloadExcel = async (params: GetGroupsDownloadExcelParams) => {
  const res = await API.get<GetGroupsDownloadExcelResponse>('/v4/groups/download_excel', {
    params: {
      group_ids: params.groupIds,
      target: params.target,
    },
  });

  return res.data;
};
