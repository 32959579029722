import React, { useEffect, useMemo, useState } from 'react';
/* eslint-disable import/no-dynamic-require */
import { Table, TableBody, TableColumn, TableHeader, TableHeaderColumn, TableRow } from '../Table';

import { Icon } from '../../atoms/Icon';
import IconSuccess from 'images/icons/icon-success.png';
import IconWarningRed from 'images/icons/icon-warning-red.png';
import { ResultTableProps } from './types';
import { isNumber } from 'lodash-es';
import styled from 'styled-components';
import { translateHTML } from 'utils/LocaleUtil';
import { v4 as uuidv4 } from 'uuid';

const ResultTableContainer = styled.div<Styleable>`
  margin-bottom: 20px;
`;

const TableInfo = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  margin-bottom: 11px;
`;

const TableTitle = styled.div<Styleable<FontStyle>>`
  display: flex;
  font-weight: normal;
  line-height: normal;
  letter-spacing: -0.23px;
  font-size: ${props => props.styles?.fontSize || '14px'};
  color: ${props => props.styles?.color || '#555'};
  align-items: center;

  ${Icon} {
    margin-right: 6px;
  }
`;

const TableDescription = styled.div`
  margin: 2px 0 0 0px;
  color: #7d7d7d;
  font-size: 13px;
  letter-spacing: -0.2px;
  line-height: 1.5;
`;

const ResultCount = styled.div`
  margin-left: 5px;
  font-size: 11px;
`;

const ResultTableColumn = styled(TableColumn)`
  position: relative;
  & > div {
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
    line-height: normal;
  }
`;

const ResultTableBoby = styled(TableBody)`
  overflow-x: hidden;
  overflow-y: auto;

  > div:last-child {
    border-bottom: none;
  }

  &::-webkit-scrollbar {
    width: 4px;
    border-radius: 6px;
    display: initial;
  }
  &::-webkit-scrollbar-thumb {
    background-color: #d8d8d8;
    border-radius: 4.5px;
  }
`;

const ResultTable = ({
  title,
  description,
  headerItems,
  bodyItems,
  type = 'success',
  totalCount,
  resultCount,
  styles,
}: ResultTableProps) => {
  const [uniqId, setUniqId] = useState('');

  const resultTypeImage = useMemo(() => {
    if (type === 'success') {
      return IconSuccess;
    }
    return IconWarningRed;
  }, [type]);

  useEffect(() => {
    setUniqId(uuidv4());
  }, []);

  return (
    <ResultTableContainer style={styles?.containerStyles}>
      <TableInfo>
        {title && (
          <TableTitle styles={styles?.titleStyle}>
            <Icon url={`${resultTypeImage}`} width={14} height={14} />
            {title}
            {isNumber(totalCount) && isNumber(resultCount) && (
              <ResultCount>{translateHTML('csv_result', { total_count: totalCount, count: resultCount })}</ResultCount>
            )}
          </TableTitle>
        )}
        {description && <TableDescription>{description}</TableDescription>}
      </TableInfo>
      <Table styles={styles?.tableStyle}>
        <TableHeader
          styles={styles?.headerStyle}
          columnStyle={styles?.headerColumnStyle}
          rowStyle={styles?.headerRowStlye}
        >
          {headerItems.map(item => (
            <TableHeaderColumn
              key={`${item.key}-header-column-${uniqId}`}
              columnWidth={item.columnWidth}
              styles={item.styles}
            >
              {item.name}
            </TableHeaderColumn>
          ))}
        </TableHeader>
        <ResultTableBoby styles={{ paddingBottom: '0', ...styles?.tableBodyStyle }}>
          {bodyItems.map(item => {
            return (
              <TableRow key={`${item.key}-row-${uniqId}`} styles={styles?.tableRowStyle}>
                {item.columns.map(column => (
                  <ResultTableColumn
                    styles={{ ...styles?.bodyColumnStyle, ...column?.styles }}
                    key={`${item.key}-row-${column.key}-column-${uniqId}`}
                  >
                    {column.element}
                  </ResultTableColumn>
                ))}
              </TableRow>
            );
          })}
        </ResultTableBoby>
      </Table>
    </ResultTableContainer>
  );
};

export default ResultTable;
