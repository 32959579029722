var ObjectUtil = {
  isObject: function (o) {
    return typeof o === 'object' && !(o instanceof File);
  },

  isEmpty: function (o) {
    for (var k in o) return false;
    return true;
  },

  getKeyByValue: function (o, v) {
    for (var k in o) {
      if (Object.prototype.hasOwnProperty.call(o, k)) {
        if (o[k] === v) return k;
      }
    }
  },

  trimObj: function (obj) {
    if (!Array.isArray(obj) && typeof obj !== 'object') return obj;
    return Object.keys(obj).reduce(
      function (acc, key) {
        if (obj[key] == null) return acc;

        acc[key.trim()] = typeof obj[key] === 'string' ? obj[key].trim() : ObjectUtil.trimObj(obj[key]);
        return acc;
      },
      Array.isArray(obj) ? [] : {}
    );
  },

  removeDuplicates: function (arr) {
    let seen = {};
    let ret_arr = [];
    for (let i = 0; i < arr.length; i++) {
      if (!(arr[i] in seen)) {
        ret_arr.push(arr[i]);
        seen[arr[i]] = true;
      }
    }
    return ret_arr;
  },
};

export { ObjectUtil };
