import { Button } from 'components/atoms/Button';
import { DROPDOWN_CONTAINER_BASE_CLASSNAME } from 'components/commons/SelectBox/SelectBoxDropdown';
import { GLOBAL_CONTAINER_ID } from 'components/commons/GlobalPortal';
import { MODAL_CONTAINER_BASE_CLASSNAME } from 'components/commons/modal';
import { TASK_CARD_LIST_BASE_CLASSNAME } from 'components/commons/TaskCardList';
import { TOAST_CONTAINER_BASE_CLASSNAME } from 'components/commons/Toast/ToastContainer';
import { TOOLTIP_CONTAINER_BASE_CLASSNAME } from 'components/commons/Tooltip/GlobalTooltip';
import colors from 'theme/colors';
import { createGlobalStyle } from 'styled-components';

export const GlobalStyle = createGlobalStyle`
    :root {
    --grey-700: ${colors.grey[700]};
    --grey-600: ${colors.grey[600]};
    --grey-500: ${colors.grey[500]};
    --grey-400: ${colors.grey[400]};
    --grey-300: ${colors.grey[300]};
    --grey-200: ${colors.grey[200]};
    --grey-100: ${colors.grey[100]};
    --grey-0: ${colors.grey[0]};
    --red-800: ${colors.red[800]};
    --red-700: ${colors.red[700]};
    --red-600: ${colors.red[600]};
    --red-500: ${colors.red[500]};
    --red-400: ${colors.red[400]};
    --red-300: ${colors.red[300]};
    --red-200: ${colors.red[200]};
    --red-100: ${colors.red[100]};
    --red-50: ${colors.red[50]};
    --yellow-800: ${colors.yellow[800]};
    --yellow-700: ${colors.yellow[700]};
    --yellow-600: ${colors.yellow[600]};
    --yellow-500: ${colors.yellow[500]};
    --yellow-400: ${colors.yellow[400]};
    --yellow-300: ${colors.yellow[300]};
    --yellow-200: ${colors.yellow[200]};
    --yellow-100: ${colors.yellow[100]};
    --yellow-50: ${colors.yellow[50]};
    --green-800: ${colors.green[800]};
    --green-700: ${colors.green[700]};
    --green-600: ${colors.green[600]};
    --green-500: ${colors.green[500]};
    --green-400: ${colors.green[400]};
    --green-300: ${colors.green[300]};
    --green-200: ${colors.green[200]};
    --green-100: ${colors.green[100]};
    --green-50: ${colors.green[50]};
    --cyan-800: ${colors.cyan[800]};
    --cyan-700: ${colors.cyan[700]};
    --cyan-600: ${colors.cyan[600]};
    --cyan-500: ${colors.cyan[500]};
    --cyan-400: ${colors.cyan[400]};
    --cyan-300: ${colors.cyan[300]};
    --cyan-200: ${colors.cyan[200]};
    --cyan-100: ${colors.cyan[100]};
    --cyan-50: ${colors.cyan[50]};
    --blue-800: ${colors.blue[800]};
    --blue-700: ${colors.blue[700]};
    --blue-600: ${colors.blue[600]};
    --blue-500: ${colors.blue[500]};
    --blue-400: ${colors.blue[400]};
    --blue-300: ${colors.blue[300]};
    --blue-200: ${colors.blue[200]};
    --blue-100: ${colors.blue[100]};
    --blue-50: ${colors.blue[50]};
    --magenta-800: ${colors.magenta[800]};
    --magenta-700: ${colors.magenta[700]};
    --magenta-600: ${colors.magenta[600]};
    --magenta-500: ${colors.magenta[500]};
    --magenta-400: ${colors.magenta[400]};
    --magenta-300: ${colors.magenta[300]};
    --magenta-200: ${colors.magenta[200]};
    --magenta-100: ${colors.magenta[100]};
    --magenta-50: ${colors.magenta[50]};
    --navy-800: ${colors.navy[800]};
    --navy-700: ${colors.navy[700]};
    --navy-600: ${colors.navy[600]};
    --navy-500: ${colors.navy[500]};
    --navy-400: ${colors.navy[400]};
    --navy-300: ${colors.navy[300]};
    --navy-200: ${colors.navy[200]};
    --navy-100: ${colors.navy[100]};
    --navy-50: ${colors.navy[50]};
    }
    
  html, body, #root {
    position: relative;
  }
  
  html, #root{
      height:100%
  }
  
  body{
    background-color: #fff;
    height:100%;
      
      @media (min-width: 768px){

          // 서비스 기준 최소 가로 해상도
          min-width: 1280px;
          overflow-x:auto;
      }
  }

  *,
  *::before,
  *::after {
      box-sizing: unset;
  }

  input,
  button,
  textarea,
  select {
      font: inherit;
  }

  button,
  select {
      text-transform: initial;
  }

  a {
      text-decoration: none;
  }

  #application {
    height: 100%;
  }
  b, strong {
    font-weight: bold;
  }
  ${`#${GLOBAL_CONTAINER_ID}`} {
    position: fixed;
    top: 0;
    right: 0;
    left: 0;
    z-index: 1000;
  }

  ${`#${GLOBAL_CONTAINER_ID}`} > ${`.${TOOLTIP_CONTAINER_BASE_CLASSNAME}`} {
    z-index: 100;
  }

  ${`#${GLOBAL_CONTAINER_ID}`} > ${`.${DROPDOWN_CONTAINER_BASE_CLASSNAME}`} {
    z-index: 50;
  }
  ${`#${GLOBAL_CONTAINER_ID}`} > ${`.${TOAST_CONTAINER_BASE_CLASSNAME}`} {
    z-index: 10;
  }
  ${`#${GLOBAL_CONTAINER_ID}`} > ${`.${MODAL_CONTAINER_BASE_CLASSNAME}`} {
    z-index: 2;
  }
  ${`#${GLOBAL_CONTAINER_ID}`} > ${`.${TASK_CARD_LIST_BASE_CLASSNAME}`} {
    z-index: 1;
  }

  ${Button} + ${Button} {
    margin-left: 10px;
  }
  
  .div-pagination {
    position: relative;
    display: inline-block;
    width: 100%;
    max-width: 100%;
    text-align: center;
  }

  //swal
  .swal2-content #swal2-content,
  .swal2-html-container {
    font-size: 14px !important;
  }
  
  body.swal2-height-auto {
    height: 100% !important;
  }
  
  .swal2-modal .swal2-styled {
    margin: 0 !important;
    padding: 5px 33px !important;
    height: 40px;
  }

  .swal2-container {
    z-index: 10001 !important;
  }

  .wrapper-scroll::-webkit-scrollbar-track {
    border-radius: 10px;
  }
  
  .wrapper-scroll::-webkit-scrollbar {
    width: 7px;
  }
  
  .wrapper-scroll::-webkit-scrollbar-thumb {
    border-radius: 10px;
  }
  
  .hand {
    cursor: pointer;
  }

  .pointer {
    cursor: pointer;
  }

  .page-navigation {
    float: right;
  }



  /**
   * swal2 custom style
   */
    
  .swal2-popup{
    box-sizing: border-box;
    padding: 52px 10px;
    row-gap: 32px;
    min-width: 386px;
    max-width: 392px;
    width:auto;
    border-radius: 10px;
  }
    
  .swal2-icon{
    margin: 0 auto;
  }
  
  .swal2-checkbox{
      margin: 0 auto;
  }

  .annotationLayer .popup {
    display: none;
  }

  
  .swal2-html-container{
    display: flex;
    align-items: center;
    justify-content: center;
    margin: 0 auto !important;
    padding : 0 16px !important;
    color: ${colors.grey[600]} !important;
  }
    
  .swal2-actions{
    margin: 0 auto;
    max-width: 392px;
    gap:10px;
    font-size: 16px;
  }

  .fire-error-html-container important-note {
    font-weight: bold;
    color: ${colors.red[500]};
  }

  .fire-error-html-container a {
    text-decoration: underline;
    color: ${colors.grey[600]};
    font-weight: bold;
  }

  .swal-user-remove .swal2-checkbox .swal2-label {
    font-size: 14px;
    color: ${colors.grey[800]};
  }
  .swal-user-remove .swal2-popup {
    max-width: 524px;
  }
  .swal-user-remove .swal2-checkbox {
    padding: 0 16px;
    column-gap: 6px;
  }

  .cancel-button {
    box-sizing: border-box;
    display: inline-flex !important;
    justify-content: center;
    align-items: center;
      flex-grow: 1;
    height: 34px;
    padding: 10px 14px;

    font-size: 14px;
    font-weight: 700;

    color: ${colors.grey[600]} !important;
    border: 1px solid ${colors.grey[600]} !important;
    background-color: ${colors.grey[0]} !important;
    border-radius: 3px;

    &:hover {
      box-shadow: 0px 0px 4px 0px rgba(30, 108, 216, 0.40);
      background-image: unset !important;
    }

    &:active {
      background-color: ${colors.blue[100]} !important;
      background-image: unset !important;
    } 
  }
  
  .confirm-actions{
    display: grid !important;
    grid-template-columns: 1fr 1fr;
      font-size: 16px !important;
    gap:10px;
  }
  
  .deny-button {
    display: inline-flex !important;
    flex-grow: 1;
    justify-content: center;
    align-items: center;
    height: 40px;
    padding: 8px 34px;
    box-sizing: border-box;
    line-height: 24px;

    color: ${colors.grey[0]};
    font-size: 16px;
    font-weight: 700;

    background-color: ${colors.red[500]};
    border: none;
    border-radius: 5px;
  }

  .confirm-button {
    display: inline-flex !important;
      flex-grow: 1;
    justify-content: center;
    align-items: center;
    height: 40px;
    padding: 8px 34px;
    box-sizing: border-box;
    line-height: 24px;

    color: ${colors.grey[0]};
    font-weight: 700;

    background-color: ${colors.blue[500]};
    border: none;
    border-radius: 5px;

    &:hover {
      box-shadow: 0px 0px 4px 0px rgba(30, 108, 216, 0.40);
    }

    &:active {
      background-color: ${colors.blue[800]};
    }
  }
  .confirm-icon {
    border: unset;
    height: fit-content;
  }

  .warning-icon {
    border-color:${colors.navy[200]} !important;
    color:${colors.navy[200]} !important;
    }

  .confirm-html-container {
    color: ${colors.grey[500]};
  }
  
  .ellipsis {
    white-space: nowrap;
    overflow-x: hidden;
    text-overflow: ellipsis;
  }


  @keyframes card-blink {
    50% {
      opacity: 0;
    }
  }
`;

export const GlobalPrintPageStyle = createGlobalStyle`
  @page {
    size: A4;
  }

  @media print {
    body {
      transform: scale(0.7);
      transform-origin: top left;
      width: 142.857143%; // to simulate zoom : oldWidth / newScale => 100 / 0.7 
    }
    #spendit-global-nav-bar {
        visibility: hidden;
    }
    table {
      page-break-inside: auto;
    }
    thead {
      display: table-header-group;
      break-inside: auto;
    }
    tfoot {
      display: table-footer-group;
    }
  }
`;
