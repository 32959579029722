import { DEFAULT_LANGUAGE } from 'utils/LocaleUtil';
import { RootState } from 'modules/reducers';
import createCachedSelector from 're-reselect';

const selectSession = (state: RootState) => state.stores;
const selectSessionToken = (state: RootState) => selectSession(state).sessionToken;
const hasSessionToken = (state: RootState) => selectSessionToken(state) !== null;

export const selectCurrentUser = (state: RootState) => selectSession(state).user;
const hasCurrentUser = (state: RootState) => selectCurrentUser(state) !== null;

export const selectIsCurrentUserPolicyEditor = (state: RootState) => !!selectSession(state).user?.policy_editor;
export const selectIsCurrentUserCompanyOwner = (state: RootState) => !!selectSession(state).user?.is_company_owner;
export const selectCurrentUserId = (state: RootState) => selectCurrentUser(state)?.id;
export const selectCurrentUserRole = (state: RootState) => selectCurrentUser(state)?.role;
export const selectIsCurrentUserCompanyAdmin = (state: RootState) => selectCurrentUserRole(state) === 'company_admin';
export const selectCurrentUserThumbnailURL = (state: RootState) => selectCurrentUser(state)?.thumbnail_url || '';
export const selectCurrentUserToken = (state: RootState) => selectCurrentUser(state)?.session_token;
export const selectCurrentUserHasCompanyTaxes = (state: RootState) => !!selectCurrentUser(state)?.has_company_taxes;
export const selectCurrentUserCompanyId = (state: RootState) => selectCurrentUser(state)?.company_id;

export const selectIsLoggedIn = (state: RootState) => selectCurrentUser(state) !== null;

export const selectCurrentPolicy = (state: RootState) => selectSession(state).policy;
export const selectCurrentPolicyId = (state: RootState) => selectSession(state).policy?.id;
export const selectCurrentPolicyName = (state: RootState) => selectSession(state).policy?.name || '';

export const selectCurrentLanguage = (state: RootState) => {
  const currentUser = selectCurrentUser(state);

  return currentUser?.language || DEFAULT_LANGUAGE;
};

const selectMyPolicies = (state: RootState) => selectSession(state).myPolicies;

export const sessionSelectors = {
  currentUser: (state: RootState) => selectCurrentUser(state),
  hasCurrentUser: (state: RootState) => hasCurrentUser(state),
  hasSessionToken: (state: RootState) => hasSessionToken(state),
  isFreeUser: (state: RootState) => selectCurrentUser(state)?.contract_type === 'free',
  isLoggedIn: (state: RootState) => hasSessionToken(state) && hasCurrentUser(state),
  myPolicies: selectMyPolicies,
  isInMyPolicies: createCachedSelector(
    selectMyPolicies,
    (state: RootState, policyId: number | null) => policyId,
    (myPolicies, policyId) => myPolicies.find(myPolicy => myPolicy.id === policyId) !== undefined
  )((state, policyId) => policyId),
  privatePolicy: (state: RootState) => selectSession(state).privatePolicy,
  sessionToken: (state: RootState) => selectSessionToken(state),
  currentPolicy: (state: RootState) => selectCurrentPolicy(state),
  myGroups: (state: RootState) => selectSession(state).myGroups,
  currentLanguage: (state: RootState) => selectCurrentLanguage(state),
};
