import { ModalProps, ModalWrapperProps } from './types';

import React from 'react';
import classNames from 'classnames';
import styled from 'styled-components';

export const MODAL_CONTAINER_BASE_CLASSNAME = 'spendit-modal-container';

export const ModalContents = styled.div``;

export const ModalWrapper = styled.div<ModalWrapperProps>`
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: 800;
  overflow-y: auto;
  overflow-x: hidden;

  ${props => props.display && `display: ${props.display}`};
  ${props => props.alignItems && `align-items: ${props.alignItems}`};

  background-color: rgba(0, 0, 0, 0.5);

  ${ModalContents} {
    position: absolute;
    top: ${props => (props.alignCenter ? '50%' : props.top ?? '100px')};
    left: 50%;
    width: ${props => props.width || '700px'};
    background-color: #fff;
    border-radius: 10px;
    box-shadow: 0 0 30px rgba(0, 0, 0, 0.2);
    transform: translate3d(-50%, ${props => (props.alignCenter ? '-50%' : 0)}, 0);
    z-index: 900;
  }
`;

export const Background = styled.div`
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.5);
`;

/**
 * @deprecated
 */
const Modal: React.FC<React.PropsWithChildren<ModalProps>> = ({ className, containerClassName, children, ...rest }) => {
  return (
    <ModalWrapper {...rest} className={classNames(MODAL_CONTAINER_BASE_CLASSNAME, containerClassName)}>
      <ModalContents className={className}>{children}</ModalContents>
    </ModalWrapper>
  );
};

export default Modal;
