import React, { useState } from 'react';
import {
  SelectBoxDefaultOption,
  SelectBoxOption,
  SelectBoxOptionLabelStyle,
  SelectBoxOptionStyle,
} from 'components/commons/SelectBox/SelectBoxOption';
import { SelectBoxDropdown, SelectBoxDropdownStyle } from 'components/commons/SelectBox/SelectBoxDropdown';
import { SelectBoxToggle, SelectBoxToggleLabelProps } from 'components/commons/SelectBox/SelectBoxToggle';

import { Icon } from 'components/atoms/Icon';
import IconTriangleDownNavy from 'images/icons/icon-triangle-down-navy.png';
import { SelectBox } from 'components/commons/SelectBox';
import { SelectBoxOptionGroup } from 'components/commons/SelectBox/SelectBoxOptionGroup';
import { SelectBoxSearchInput } from 'components/commons/SelectBox/SelectBoxSearchInput';
import { isArray } from 'lodash-es';
import styled from 'styled-components';
import { v4 as uuidv4 } from 'uuid';

export type BorderlessSelectBoxProps<T> = {
  defaultLabel?: string;
  value?: ValueWithLabel<T>[] | ValueWithLabel<T>;
  options: ValueWithLabel<T>[];
  styles?: BoxStyle;
  dropdownStyles?: SelectBoxDropdownStyle;
  optionStyle?: SelectBoxOptionStyle;
  optionLabelStyle?: SelectBoxOptionLabelStyle;
  toggleLabelStyle?: SelectBoxToggleLabelProps;
  onValueChange?(values: ValueWithLabel<T>[], target?: ValueWithLabel<T>, isSelect?: boolean): void;
  onDropdownOpen?(): void;
  onDropdownClose?(): void;
  onScrollBottomReached?(): void;
  onSearch?(value: string): void;
  disabled?: boolean;
};

const StyledSelectBoxOption = styled(SelectBoxOption)`
  &:first-child.active {
    border-top-right-radius: 5px;
    border-top-left-radius: 5px;
  }
  &:last-child.active {
    border-bottom-right-radius: 5px;
    border-bottom-left-radius: 5px;
  }
`;

const StyledSelectBoxDefaultOption = styled(SelectBoxDefaultOption)`
  &:first-child.active {
    border-top-right-radius: 5px;
    border-top-left-radius: 5px;
  }
  &:last-child.active {
    border-bottom-right-radius: 5px;
    border-bottom-left-radius: 5px;
  }
`;

export const BorderlessSelectBox = <T,>({
  value = [],
  options,
  styles,
  dropdownStyles,
  optionStyle,
  optionLabelStyle,
  toggleLabelStyle,
  defaultLabel,
  onValueChange,
  onDropdownOpen,
  onDropdownClose,
  onScrollBottomReached,
  onSearch,
  disabled,
}: BorderlessSelectBoxProps<T>) => {
  const [id] = useState(uuidv4());

  return (
    <SelectBox
      width={styles?.width}
      values={isArray(value) ? value : [value]}
      onValueChange={onValueChange}
      defaultLabel={defaultLabel}
      styles={styles}
      onDropdownOpen={onDropdownOpen}
      onDropdownClose={onDropdownClose}
      onScrollBottomReached={onScrollBottomReached}
      onSearch={onSearch}
      searchTimeout={300}
      disabled={disabled}
    >
      <SelectBoxToggle
        backgroundColor="transparent"
        border="0"
        boxShadow="none"
        boxShadowOnOpen="none"
        color="#002855"
        fontSize="14px"
        iconStyle={{ width: '25px', height: '30px', borderLeft: '0' }}
        labelStyle={{ padding: '0 0 0 10px', ...toggleLabelStyle }}
        defaultLabelStyle={{ color: 'inherit', fontSize: 'inherit' }}
      >
        <Icon url={IconTriangleDownNavy} width={7} height={4} />
      </SelectBoxToggle>
      <SelectBoxDropdown width={dropdownStyles?.width} marginTop="7px" styles={dropdownStyles}>
        {onSearch && <SelectBoxSearchInput />}
        <SelectBoxOptionGroup>
          {defaultLabel && (
            <StyledSelectBoxDefaultOption
              labelStyle={{ fontSize: '14px', textAlign: 'left' }}
              styles={{ onActive: { padding: '0 13px', backgroundColor: '#f1f7fc' } }}
              hideIcon
            />
          )}
          {options.map(option => (
            <StyledSelectBoxOption
              key={`borderless-select-box-option-${id}-${option.value}`}
              option={option}
              styles={{
                padding: '0 13px',
                onActive: {
                  padding: '0 13px',
                  backgroundColor: '#f1f7fc',
                },
                ...optionStyle,
              }}
              labelStyle={{ fontSize: '14px', textAlign: 'left', ...optionLabelStyle }}
            />
          ))}
        </SelectBoxOptionGroup>
      </SelectBoxDropdown>
    </SelectBox>
  );
};
