import React, { useState } from 'react';
import styled, { css, keyframes } from 'styled-components';

import { Icon } from 'components/atoms/Icon';
import IconXWhite from 'images/icons/icon-x-white.svg';
import useUIActions from 'components/hooks/useUIActions';

const animateFadeIn = keyframes`
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
`;

const animateFadeOut = keyframes`
  from {
    opacity: 1;
  }
  to {
    opacity: 0;
  }
`;

const ToastBox = styled.div<ToastBoxProps>`
  position: relative;
  display: inline-flex;
  align-items: center;
  justify-content: flex-start;
  padding: 0 25px;
  width: 100%;
  height: 45px;
  border-radius: 4px;
  box-shadow: 0 2px 6px 0 rgba(0, 0, 0, 0.2);
  box-sizing: border-box;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;

  color: #fff;

  ${props => {
    switch (props.toastType) {
      case 'danger':
        return 'background-color: #e65858;';
      default:
        return 'background-color: #e65858;';
    }
  }}

  font-size: 14px;
  letter-spacing: -0.23px;

  cursor: pointer;

  ${props => {
    if (props.fadeIn) {
      return css`
        animation: ${animateFadeIn} ease 0.3s;
      `;
    }
    return css`
      animation: ${animateFadeOut} ease 0.3s;
    `;
  }}

  & > ${Icon} {
    position: absolute;
    top: 17px;
    right: 20px;
  }
`;

const Toast: React.FC<React.PropsWithChildren<ToastProps>> = ({ className, id, msg, onHideClick, ...rest }) => {
  const [fadeIn, toggleFadeIn] = useState(true);
  const { hideToast } = useUIActions();

  const hide = () => {
    if (onHideClick) {
      onHideClick(id);
    } else {
      hideToast(id);
    }
  };

  const handleOnClick = () => {
    toggleFadeIn(false);
  };

  const handleAnimationEnd = () => {
    if (!fadeIn) {
      hide();
    }
  };

  return (
    <ToastBox
      className={className}
      {...rest}
      fadeIn={fadeIn}
      title={msg}
      onClick={handleOnClick}
      onAnimationEnd={handleAnimationEnd}
    >
      {msg}
      <Icon url={IconXWhite} width={12} height={12} />
    </ToastBox>
  );
};

export default Toast;
