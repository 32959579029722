import styled, { css } from 'styled-components';

import { Input } from '@mantine/core';

export const defaultInputStyles = css`
  & .mantine-Input-input {
    padding: 10px 16px;
    background-color: ${({ theme }) => theme.colors.grey[0]};
    color: ${({ theme }) => theme.colors.grey[700]};
    border: 1px solid ${({ theme }) => theme.colors.grey[300]};

    &:hover:not(:disabled) {
      box-shadow: 0px 0px 4px 0px ${({ theme }) => theme.colors.grey[300]};
    }

    &:focus:not(:disabled) {
      border: 2px solid ${({ theme }) => theme.colors.blue[200]};
    }

    &:disabled {
      border: 1px solid ${({ theme }) => theme.colors.grey[300]};
      background-color: ${({ theme }) => theme.colors.grey[100]};
      color: ${({ theme }) => theme.colors.grey[400]};
    }
  }
`;
export const injectDefaultInputStyles = (inputComponent: typeof Input) => {
  return styled(inputComponent)`
    ${defaultInputStyles};
  `;
};
