import React, { useEffect, useState } from 'react';
import { TooltipContainerProps, TooltipOption } from './types';

import GlobalPortal from 'components/commons/GlobalPortal';
import classNames from 'classnames';
import colors from 'theme/colors';
import { getTooltipPosition } from 'components/commons/Tooltip/GlobalTooltip/util';
import styled from 'styled-components';

interface TooltipProps {
  option?: TooltipOption | null;
  dataPrivate?: boolean;
}

export const TOOLTIP_CONTAINER_BASE_CLASSNAME = 'spendit-tooltip-container';

const TooltipContainer = styled.div<TooltipContainerProps>`
  position: fixed;
  ${props => props.styles?.top && `top: ${props.styles.top};`}
  ${props => props.styles?.right && `right: ${props.styles.right};`}
  ${props => props.styles?.bottom && `bottom: ${props.styles.bottom};`}
  ${props => props.styles?.left && `left: ${props.styles.left};`}
  ${props => props.styles?.transform && `transform: ${props.styles.transform};`}
  ${props => props.styles?.boxShadow && `box-shadow: ${props.styles.boxShadow};`}
  padding: ${props => props.styles?.padding || '10px 15px'};
  ${props => props.styles?.width && `width: ${props.styles.width};`}
  min-width: ${props => props.styles?.minWidth || '12px'};
  max-width: ${props => props.styles?.maxWidth || '400px'};
  border-radius: ${props => props.styles?.borderRadius || '6px'};
  z-index: ${props => props.styles?.zIndex || '100'};

  background-color: ${props => props.styles?.backgroundColor || colors.grey[700]};
  color: ${props => props.styles?.color || colors.grey[0]};
  font-size: ${props => props.styles?.fontSize || '10px'};
  letter-spacing: -0.17px;
  ${props => props.styles?.lineHeight && `line-height: ${props.styles.lineHeight};`}
  ${props => props.styles?.textAlign && `text-align: ${props.styles.textAlign};`}
  white-space: ${props => props.styles?.whiteSpace || 'pre-line'};
  line-break: anywhere;

  &:after {
    position: absolute;
    content: '';
    ${props => props.arrowStyles?.top && `top: ${props.arrowStyles.top};`}
    ${props => props.arrowStyles?.right && `right: ${props.arrowStyles.right};`}
    ${props => props.arrowStyles?.bottom && `bottom: ${props.arrowStyles.bottom};`}
    ${props => props.arrowStyles?.left && `left: ${props.arrowStyles.left};`}
    ${props => props.arrowStyles?.transform && `transform: ${props.arrowStyles.transform};`}
    ${props => props.arrowStyles?.borderTop && `border-top: ${props.arrowStyles.borderTop};`}
    ${props => props.arrowStyles?.borderRight && `border-right: ${props.arrowStyles.borderRight};`}
    ${props => props.arrowStyles?.borderBottom && `border-bottom: ${props.arrowStyles.borderBottom};`}
    ${props => props.arrowStyles?.borderLeft && `border-left: ${props.arrowStyles.borderLeft};`}
  }
`;

const Tooltip: React.FC<React.PropsWithChildren<TooltipProps>> = ({ option, dataPrivate, children }) => {
  const [containerProps, setContainerProps] = useState<TooltipContainerProps>();
  useEffect(() => {
    if (option) {
      const { styles, arrowStyles } = getTooltipPosition(option);
      setContainerProps({
        styles: {
          ...styles,
          ...option.styles,
        },
        arrowStyles: {
          ...arrowStyles,
          ...option.arrowStyles,
        },
        onMouseLeave: option.onMouseLeave,
      });
    } else {
      setContainerProps(undefined);
    }
  }, [option]);

  return (
    <GlobalPortal show={!!containerProps}>
      <TooltipContainer
        {...containerProps}
        className={classNames(TOOLTIP_CONTAINER_BASE_CLASSNAME)}
        data-private={dataPrivate}
      >
        {children}
      </TooltipContainer>
    </GlobalPortal>
  );
};

export default Tooltip;
