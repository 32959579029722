import { ActionType, createReducer, createStandardAction } from 'typesafe-actions';
import { delay, put, takeLatest } from 'redux-saga/effects';

import { produce } from 'immer';
import { v4 as uuidv4 } from 'uuid';

type UIReducerState = {
  isSpinnerShow: boolean;
  alarm: string | null;
  toasts: ToastData[];
};

const SHOW_SPINNER = 'ui/SHOW_SPINNER';
const HIDE_SPINNER = 'ui/HIDE_SPINNER';

export const showSpinner = createStandardAction(SHOW_SPINNER)();
export const hideSpinner = createStandardAction(HIDE_SPINNER)();

const SHOW_ALARM = 'ui/SHOW_ALARM';
const HIDE_ALARM = 'ui/HIDE_ALARM';

export const showAlarm = createStandardAction(SHOW_ALARM)<string>();
export const hideAlarm = createStandardAction(HIDE_ALARM)();

export const SHOW_TOAST = 'ui/SHOW_TOAST';
export const HIDE_TOAST = 'ui/HIDE_TOAST';

export const uiActions = {
  showSpinner,
  hideSpinner,
  showAlarm,
  hideAlarm,
  showToast: createStandardAction(SHOW_TOAST)<{
    id?: string;
    toastType?: ToastType;
    msg: string;
    onHideClick?: (id: string) => void;
  }>(),
  hideToast: createStandardAction(HIDE_TOAST)<{ id: string }>(),
};

function* hideAlarmAsync() {
  yield delay(2000);
  yield put(hideAlarm());
}

export function* uiSaga() {
  yield takeLatest(SHOW_ALARM, hideAlarmAsync);
}

export const uiReducer = createReducer<UIReducerState, ActionType<typeof uiActions>>(
  {
    isSpinnerShow: false,
    alarm: null,
    toasts: [],
  },
  {
    [SHOW_SPINNER]: state => ({ ...state, isSpinnerShow: true }),
    [HIDE_SPINNER]: state => ({ ...state, isSpinnerShow: false }),
    [SHOW_ALARM]: (state, { payload }) => ({ ...state, alarm: payload }),
    [HIDE_ALARM]: state => ({ ...state, alarm: null }),
    [SHOW_TOAST]: (state, { payload }) => ({
      ...state,
      toasts: [
        ...state.toasts,
        {
          id: payload.id || uuidv4(),
          toastType: payload.toastType,
          msg: payload.msg,
          onHideClick: payload.onHideClick,
        },
      ],
    }),
    [HIDE_TOAST]: (state, { payload }) =>
      produce(state, draft => {
        const index = draft.toasts.findIndex(toast => toast.id === payload.id);

        if (index > -1) {
          draft.toasts.splice(index, 1);
        }
      }),
  }
);

export * from './selectors';
