import { RootState } from 'modules/reducers';
import createCachedSelector from 're-reselect';

export const selectTasks = (state: RootState) => state.taskList.taskQueue;

export const selectTaskById = createCachedSelector(
  selectTasks,
  (state: RootState, id: string) => id,
  (tasks, id) => tasks.find(task => task.id === id)
)((state, id) => id);
