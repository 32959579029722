const spenditRed = '#DE3D3D';
const spenditLightBlue = '#EBF6FF';
const spenditYellow = '#FFB235';
const spenditBlue = '#047DE0';
const spenditWhite = '#FFFFFF';
const successGreen = '#4BB543';
const focusBlue = '#6AB7FE';
const contextColor = '#333333';
const spenditLightBlueGray = '#AFC1D5';

const brightGray = '#EFEFEF';
const lightGray = '#DCDCDC';
const disabledGrayText = '#C4C4C4';
const warningGray = '#ACACAC';
const spenditGray = '#7A7A7A';

/**
 * @deprecated /themes/colors.ts로 이관
 */
export const legacyColors = {
  spenditGray,
  brightGray,
  spenditRed,
  spenditLightBlue,
  spenditYellow,
  spenditBlue,
  spenditWhite,
  spenditLightBlueGray,
  lightGray,
  successGreen,
  focusBlue,
  disabledGrayText,
  contextColor,
  warningGray,
} as const;
