import { useCallback, useState } from 'react';

type InitialValue = boolean;
type ToggleCallback = () => void;

export const useToggle = (initValue: InitialValue, toggleCallback?: ToggleCallback) => {
  const [bool, setBool] = useState<boolean>(initValue);

  const handleToggle = () => {
    setBool(current => !current);
    toggleCallback?.();
  };

  const handleSetTrue = useCallback(() => {
    setBool(true);
  }, []);

  const handleSetFalse = useCallback(() => {
    setBool(false);
  }, []);

  return {
    bool,
    handleToggle,
    handleSetTrue,
    handleSetFalse,
  };
};
