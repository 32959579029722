import React, { HTMLAttributes, useEffect, useMemo, useState } from 'react';
import { Security, useOktaAuth } from '@okta/okta-react';

import LoadingSpinner from 'components/utility/LoadingSpinner';
import OktaAuth from '@okta/okta-auth-js';
import { RestoreOriginalUriFunction } from '@okta/okta-react/bundles/types/OktaContext';
import { fireError } from 'utils/SwalUtil';
import { postMSAuthOAuthToken } from 'apis/SessionAPI';
import { sessionActions } from 'modules/session';
import { translate } from 'utils/LocaleUtil';
import { useDispatch } from 'react-redux';
import { useHistory } from 'react-router-dom';

type OktaSecurityProviderProps = HTMLAttributes<HTMLDivElement> & {
  children?: React.ReactNode;
};

/**
 * @Description Okta SSO 리다이렉트 과정에서 인증 이후 발급된 엑세스 토큰 등의 authentication
 * 정보를 컴포넌트들에게 Context Provider로 제공해주는 모듈
 *
 * @warning useOktaAuth() 는 useContext와 동일한 역할을 하여 컴포넌트 트리상 OktaSecurityProvider
 * 컴포넌트의 children으로 위치하는 컴포넌트 내에서 사용해야만 한다.
 *
 * @see Okta-React : https://github.com/okta/okta-react
 * @see Okta-Auth : https://github.com/okta/okta-auth-js
 */

interface OktaAuthConfig {
  clientId: string;
  issuer: string;
  redirectUri: string;
  pkce: boolean;
}

const DEFAULT_OKTA_CONFIG = {
  clientId: process.env.REACT_APP_OKTA_CLIENT_ID || '',
  issuer: process.env.REACT_APP_OKTA_ISSUER || '',
  redirectUri: process.env.REACT_APP_OKTA_REDIRECT_URI || '',
  pkce: true,
};

export const OktaSecurityProvider = ({ children, ...props }: OktaSecurityProviderProps) => {
  const [oktaConfig, setOktaConfig] = useState<OktaAuthConfig>(DEFAULT_OKTA_CONFIG);

  const oktaAuth = useMemo(() => {
    return new OktaAuth(oktaConfig);
  }, [oktaConfig]);
  const history = useHistory();

  const restoreOriginalUri: RestoreOriginalUriFunction = async (_oktaAuth, originalUri) => {
    history.replace('/sign_in');
  };

  return (
    <Security {...props} restoreOriginalUri={restoreOriginalUri} oktaAuth={oktaAuth}>
      {children}
    </Security>
  );
};

export const RedirectToOktaHostedLoginForm = () => {
  const { oktaAuth } = useOktaAuth();
  const handleLogin = async () => {
    await oktaAuth.signInWithRedirect();
  };

  handleLogin();

  return <LoadingSpinner />;
};

export const useObservingOktaAuthentication = () => {
  const { authState } = useOktaAuth();
  const dispatch = useDispatch();

  useEffect(() => {
    if (!authState) return;

    if (authState.isAuthenticated) {
      const handleRequestSessionData = async () => {
        const oktaAccessToken = authState.accessToken?.accessToken;
        if (!oktaAccessToken) {
          await fireError(translate('error_okta_token_invalid'));
          return;
        }

        const { token: sessionToken } = await postMSAuthOAuthToken(oktaAccessToken);

        dispatch(sessionActions.signInWithToken({ sessionToken }));
      };

      handleRequestSessionData();
    }
  }, [authState]);
};
