import { AsyncTask, TaskStatus, taskListActions } from 'modules/taskList';
import React, { useEffect, useState } from 'react';
import styled, { css, keyframes } from 'styled-components';

import { FlexColumn } from 'components/commons/Layout';
import { Icon } from 'components/atoms/Icon';
import IconHide from 'images/icons/icon-hide.svg';
import { translate } from 'utils/LocaleUtil';
import { useDispatch } from 'react-redux';

type Props = {
  task: AsyncTask;
};

const animateStripes = keyframes`
  from { 
    background-position: 6px 0;
  }
  to {
    background-position: 0 0;
  }
`;

const InfoContainer = styled.div`
  flex: 1;
  overflow: hidden;
`;

const Title = styled.p`
  width: 220px;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  color: #000000;
  font-size: 13px;
  letter-spacing: -0.22px;
  line-height: normal;
`;

const ProgressBar = styled.div<{ status: TaskStatus; percentage: number | null }>`
  ${props =>
    props.percentage !== null &&
    css`
      width: ${props.percentage}%;
    `}
  height: 6px;
  border-radius: 3px;
  ${props =>
    props.status === 'in-progress' &&
    css`
      animation: ${animateStripes} 1s linear infinite;
    `}
  ${props => {
    if (props.status === 'in-progress') {
      return `
      background-color: #007bff;
      background-image: linear-gradient(45deg,rgba(255,255,255,.15) 25%,transparent 25%,transparent 50%,rgba(255,255,255,.15) 50%,rgba(255,255,255,.15) 75%,transparent 75%,transparent);
      background-size: 6px 6px;
      `;
    }
    if (props.status === 'success') {
      return `background-color: #d1d1d1;`;
    }
    return `background-color: #e9ecef;`;
  }}
`;

const ProgressbarContainer = styled.div`
  margin-top: 4px;
  height: 6px;
  border-radius: 3px;
  background-color: #e9ecef;
`;

const ButtonRow = styled.div`
  display: flex;
  margin-top: 4px;
  height: 17px;
`;

const Description = styled.p<{ error?: boolean }>`
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  color: #7d7d7d;
  font-size: ${props => (props.error ? '9px' : '11px')};
  line-height: normal;
  letter-spacing: -0.18px;
`;

const StyledButton = styled.a<{ disabled?: boolean; primary?: boolean }>`
  padding-left: 10px;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  border: 0;

  color: ${props => (props.primary ? '#047cde' : '#7d7d7d')};
  background-color: transparent;
  font-size: 11px;
  font-weight: normal;
  line-height: normal;
  letter-spacing: -0.18px;
  text-align: right;
  text-decoration: none;
  ${props => props.disabled && 'cursor: not-allowed;'}
  &:hover {
    box-shadow: none;
    ${props => !props.disabled && 'text-decoration: underline;'}
  }
`;

const IconButton = styled.button`
  display: inline-block;
  position: absolute;
  padding: 0;
  background-color: transparent;
  border: 0;
  font-size: 0;
  &:hover {
    background-color: transparent;
    box-shadow: none;
  }
`;

const HideButton = styled(IconButton)`
  top: 10px;
  right: 13px;
  width: 15px;
  height: 15px;
`;

const DeleteButton = styled(IconButton)`
  top: 13px;
  right: 13px;
  width: 9px;
  height: 9px;
`;

export const TaskCardInfo: React.FC<React.PropsWithChildren<Props>> = ({ task: { id, status, info } }) => {
  const dispatch = useDispatch();
  const [percentage, setPercentage] = useState<number | null>(null);
  const [canCancel, toggleCanCancel] = useState(true);

  const handleHideClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    event.preventDefault();
    dispatch(taskListActions.hideCard({ id }));
  };

  const handleDeleteClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    event.preventDefault();
    dispatch(taskListActions.delete({ id }));
  };

  const handleCancelClick = (event: React.MouseEvent<HTMLAnchorElement>) => {
    event.preventDefault();
    if (canCancel) {
      dispatch(taskListActions.cancel({ id }));
    }
  };

  const handleConfirmClick = (event: React.MouseEvent<HTMLAnchorElement>) => {
    event.preventDefault();
    dispatch(taskListActions.confirm({ id }));
  };

  useEffect(() => {
    toggleCanCancel(percentage !== null && percentage < 90);
  }, [percentage]);

  useEffect(() => {
    if (info.totalCount) {
      const result = Math.floor(((info.progressCount ?? 0) / info.totalCount) * 100);
      setPercentage(result);
    } else {
      setPercentage(null);
    }
  }, [info]);

  return (
    <InfoContainer>
      {status === 'in-progress' && info.closeable && (
        <HideButton onClick={handleHideClick}>
          <Icon url={IconHide} width={15} height={15} />
        </HideButton>
      )}
      {(status === 'failed' || status === 'success') && (
        <DeleteButton onClick={handleDeleteClick}>
          <svg width="9" height="9" viewBox="0 0 9 9" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path
              fillRule="evenodd"
              clipRule="evenodd"
              d="M8.09357 0L4.5 3.59357L0.906429 0L0 0.906429L3.59357 4.5L0 8.09357L0.906429 9L4.5 5.40643L8.09357 9L9 8.09357L5.40643 4.5L9 0.906429L8.09357 0Z"
              fill="#022855"
            />
          </svg>
        </DeleteButton>
      )}
      <Title>{info.taskName}</Title>
      <ProgressbarContainer>
        <ProgressBar status={status} percentage={status === 'in-progress' ? percentage : 100} />
      </ProgressbarContainer>
      <ButtonRow>
        <FlexColumn justifyContent="flex-start" flexBasis={status === 'failed' ? '100%' : '75%'}>
          {!info.failedMessage && info.progressCount !== undefined && info.totalCount !== undefined && (
            <Description>
              {status === 'success' &&
                translate('label_total_success_counts_1', {
                  successCount: info.progressCount,
                  totalCount: info.totalCount,
                })}
              {status === 'in-progress' &&
                translate('label_processing_counts', {
                  successCount: info.progressCount,
                  totalCount: info.totalCount,
                })}
            </Description>
          )}
          {info.failedMessage && <Description error>{info.failedMessage}</Description>}
        </FlexColumn>
        {status !== 'failed' && (
          <FlexColumn justifyContent="flex-end" flexBasis="65%">
            {status === 'in-progress' && (
              <StyledButton href="#" onClick={handleCancelClick} disabled={!canCancel}>
                {canCancel && translate('label_cancel')}
                {/* {!canCancel && translate('error_cancellation_not_possible_during_registration')} */}
              </StyledButton>
            )}
            {status === 'success' && (
              <StyledButton href="#" primary onClick={handleConfirmClick}>
                {translate('label_open')}
              </StyledButton>
            )}
          </FlexColumn>
        )}
      </ButtonRow>
    </InfoContainer>
  );
};
