import { ActionType, createReducer, createStandardAction } from 'typesafe-actions';
import { put, takeLatest } from 'redux-saga/effects';

import { translate } from 'utils/LocaleUtil';
import { uiActions } from 'modules/ui';

const STATUS_CHANGE = 'networkStatus/STATUS_CHANGE';
const ONLINE = 'networkStatus/ONLINE';
const OFFLINE = 'networkStatus/OFFLINE';

export const networkStatusActions = {
  statusChange: createStandardAction(STATUS_CHANGE)<{ online: boolean }>(),
  online: createStandardAction(ONLINE)(),
  offline: createStandardAction(OFFLINE)(),
};

const NETWORK_ERROR_TOAST_ID = 'spend-toast-network-disconnected';

function* handleNetworkStatusChange(action: ReturnType<typeof networkStatusActions.statusChange>) {
  const isOnline = action.payload.online;
  if (isOnline) {
    yield put(networkStatusActions.online());
    yield put(uiActions.hideToast({ id: NETWORK_ERROR_TOAST_ID }));
    window.location.reload();
  } else {
    yield put(networkStatusActions.offline());
    yield put(
      uiActions.showToast({
        id: NETWORK_ERROR_TOAST_ID,
        toastType: 'danger',
        msg: translate('alert_network_disconnected'),
      })
    );
  }
}

export function* networkStatusSaga() {
  yield takeLatest(STATUS_CHANGE, handleNetworkStatusChange);
}

const initialState = {
  online: navigator.onLine,
};

export const networkStatusReducer = createReducer<typeof initialState, ActionType<typeof networkStatusActions>>(
  initialState,
  {
    [ONLINE]: () => ({ online: true }),
    [OFFLINE]: () => ({ online: false }),
  }
);

export default {
  networkStatusActions,
  networkStatusSaga,
  networkStatusReducer,
};
