import React from 'react';
import { createPortal } from 'react-dom';

export const GLOBAL_CONTAINER_ID = 'global-container';

export function getGlobalContainerElement(childQueries: string[] = []) {
  const queries = [`#${GLOBAL_CONTAINER_ID}`, ...childQueries];

  return document.querySelector(queries.join(' '));
}

const GlobalPortal: React.FC<React.PropsWithChildren<{ show?: boolean }>> = ({ show, children }) => {
  const globalContainerEl = getGlobalContainerElement();

  if (globalContainerEl) {
    if (show !== undefined) {
      return createPortal(<>{show && children}</>, globalContainerEl);
    }
    return createPortal(<>{children}</>, globalContainerEl);
  }
  return <></>;
};

export default GlobalPortal;
