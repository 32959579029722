import { ContextModalProps, modals } from '@mantine/modals';
import { getDeviceInfo, useDevice } from 'hooks/useDevice';

import AOSModal from '@N/view/components/WebView/Native/AOS/AOSModal';
import IOSModal from '@N/view/components/WebView/Native/IOS/IOSModal';
import { OS_TYPE } from 'consts/device';

export interface AppModalProps {
  modalBody: string;
  confirmButton: {
    onClick?: () => void;
    label?: string;
  };
  cancelButton?: {
    onClick: () => void;
    label?: string;
  };
}

const AppModal = ({ context, id, innerProps }: ContextModalProps<AppModalProps>) => {
  const { os } = useDevice();

  if (os === OS_TYPE.OS_IOS || os === OS_TYPE.OS_MACOS) {
    return <IOSModal innerProps={innerProps} id={id} context={context} />;
  } else {
    return <AOSModal innerProps={innerProps} id={id} context={context} />;
  }
};

const openAppModal = ({ cancelButton, confirmButton, modalBody }: Partial<AppModalProps>) => {
  const { os } = getDeviceInfo();
  return modals.openContextModal({
    modal: 'webView',
    centered: true,
    transitionProps: { transition: 'fade' },
    styles: {
      root: {},
      header: {
        display: 'none',
      },
      inner: {
        zIndex: 1000,
      },
      content: {
        padding: 0,
        borderRadius: os === OS_TYPE.OS_IOS || os === OS_TYPE.OS_MACOS ? 14 : 2,
        background: os === OS_TYPE.OS_IOS || os === OS_TYPE.OS_MACOS ? 'transparent' : 'white',
        maxWidth: os === OS_TYPE.OS_IOS || os === OS_TYPE.OS_MACOS ? '270px' : '280px',
        boxShadow:
          os === OS_TYPE.OS_IOS || os === OS_TYPE.OS_MACOS
            ? ''
            : '0px 3px 6px 0px rgba(0, 0, 0, 0.10), 0px 24px 48px 0px rgba(0, 0, 0, 0.20)',
      },
      overlay: {
        zIndex: 1000,
        background: 'rgba(0, 0, 0, 0.5)',
      },
    },
    innerProps: {
      modalBody,
      confirmButton,
      cancelButton,
    },
  });
};

export { AppModal, openAppModal };
