import { hideSpinner, showAlarm, showSpinner, uiActions } from 'modules/ui';

import { useCallback } from 'react';
import { useDispatch } from 'react-redux';

export default function useUIActions() {
  const dispatch = useDispatch();

  return {
    showSpinner: useCallback(() => dispatch(showSpinner()), [dispatch]),
    hideSpinner: useCallback(() => dispatch(hideSpinner()), [dispatch]),
    showAlarm: useCallback((alarm: string) => dispatch(showAlarm(alarm)), [dispatch]),
    hideToast: useCallback((id: string) => dispatch(uiActions.hideToast({ id })), [dispatch]),
  };
}
