import { appSelectors } from 'modules/app';
import { selectIsLocaleInitialized } from 'modules/locale';
import { useSelector } from 'react-redux';

const useInitializingConfig = () => {
  const isAppInitialized = useSelector(appSelectors.isInitialized);
  const isLocaleInitialized = useSelector(selectIsLocaleInitialized);

  return {
    isAppInitialized,
    isLocaleInitialized,
  };
};

export default useInitializingConfig;
