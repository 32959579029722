export const containsNode = (parentNode: Node | null = null, targetNode: Node | null = null) => {
  return !!parentNode && parentNode.contains(targetNode);
};

export const isNode = (target: any): target is Node => target instanceof Node;

export default {
  containsNode,
  isNode,
};
