import { ActionType, createStandardAction } from 'typesafe-actions';
import { call, put, takeLeading } from 'redux-saga/effects';

import { appActions } from 'modules/app';
import { fireError } from 'utils/SwalUtil';
import { sessionActions } from 'modules/session';
import { translate } from 'utils/LocaleUtil';

export type MaintenanceErrorResponse = {
  start: number;
  end: number;
};

type GlobalErrorPayloadType =
  | {
      type: 'session_expired' | 'unknown';
    }
  | {
      type: 'service_maintenance';
      data: MaintenanceErrorResponse;
    };

export const GLOBAL_ERROR_OCCURRED = 'axios/GLOBAL_ERROR_OCCURRED';

export const axiosActions = {
  globalErrorOccurred: createStandardAction(GLOBAL_ERROR_OCCURRED)<GlobalErrorPayloadType>(),
};

function* handleGlobalError({ payload }: ActionType<typeof axiosActions.globalErrorOccurred>) {
  if (payload.type === 'session_expired') {
    yield put(sessionActions.sessionExpired());
    yield call(fireError, translate('error_invalid_session_token'));
  } else if (payload.type === 'service_maintenance') {
    yield put(appActions.serviceUnavailable(payload.data));
  }
}

export function* axiosSaga() {
  yield takeLeading(GLOBAL_ERROR_OCCURRED, handleGlobalError);
}
