import 'moment/locale/ko';
import 'dayjs/locale/ko';

import dayjs from 'dayjs';
import intl from 'react-intl-universal';
import moment from 'moment';

export const DEFAULT_LANGUAGE = /^en\b/.test(window.navigator.language) ? 'en' : 'ko';

export const setLocale = async (locale: SpenditLocale = DEFAULT_LANGUAGE): Promise<void> => {
  document.documentElement.lang = locale;
  /**
   * @todo moment 제거
   */
  moment.locale(locale);
  dayjs.locale(locale);

  return intl.init({
    currentLocale: locale,
    locales: {
      en: require('locale/en.json'),
      ko: require('locale/ko.json'),
    },
  });
};

export const translate = (key: string, variables?: unknown): string => {
  return intl.get(key, variables);
};

export const translateHTML = (key: string, variables?: unknown): string => {
  return intl.getHTML(key, variables);
};
