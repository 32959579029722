import { useCallback, useRef } from 'react';

type CallbackFunction<TArgs> = (...args: TArgs[]) => void;

export const useDebounceCallback = <TArgs>(callback?: CallbackFunction<TArgs>, delay = 500) => {
  const timerRef = useRef(0);

  const debouncedCallback = useCallback<CallbackFunction<TArgs>>(
    (...args) => {
      clearTimeout(timerRef.current);
      timerRef.current = window.setTimeout(() => {
        callback?.(...args);
      }, delay);
    },
    [callback, delay]
  );

  return { debouncedCallback };
};
