import {
  BULK_REPORTS_EXPORT_EVENT_ADDED,
  BULK_REPORTS_EXPORT_EVENT_CHANGED,
  BULK_REPORTS_EXPORT_EVENT_REMOVED,
  reportListActions,
} from '../actions';
import { CONFIRM, taskListActions } from 'modules/taskList';
import {
  FIREBASE_CHILD_ADDED,
  FIREBASE_CHILD_CHANGED,
  FIREBASE_CHILD_REMOVED,
  firebaseActions,
} from 'modules/firebase';
import { GetReportsJobsResultResponse, getReportsJobsResult } from 'apis/ReportAPI';
import { all, call, fork, put, select, takeLatest } from 'redux-saga/effects';
import {
  isBulkReportsExportErrorEvent,
  isBulkReportsExportFirebaseEvent,
  isBulkReportsExportedEvent,
  isBulkReportsExportingEvent,
} from '../types';

import { ActionType } from 'typesafe-actions';
import ErrorHandler from 'utils/ErrorHandler';
import { Icon } from 'components/atoms/Icon';
import IconFileTaskFailed from 'images/icons/icon-file-task-failed.png';
import moment from 'moment';
import { reportListSelectors } from '../selectors';
import { translate } from 'utils/LocaleUtil';
import { uiActions } from 'modules/ui';
import { watchExportErpReserve } from './watchExportErpReserve';

const getTaskTitle = (timestamp: number) =>
  `${translate('label_csv_export_erp_exported')} ${moment(timestamp * 1000).format(
    translate('moment_date_time_seconds_format')
  )}`;

function* handleBulkReportsExportEventAddOrChanged(
  action:
    | ActionType<typeof reportListActions.bulkReportsExportEventAdded>
    | ActionType<typeof reportListActions.bulkReportsExportEventChanged>
) {
  const isAddEvent = action.type === BULK_REPORTS_EXPORT_EVENT_ADDED;
  const { payload: event } = action;
  const actionCreator = isAddEvent ? taskListActions.enqueue : taskListActions.update;

  if (isBulkReportsExportingEvent(event)) {
    yield put(
      actionCreator({
        id: event.event_id,
        type: 'download',
        status: 'in-progress',
        info: {
          taskName: getTaskTitle(event.data.job_started_at),
          progressCount: event.data.progress_count,
          totalCount: event.data.total_count,
        },
      })
    );
  } else if (isBulkReportsExportedEvent(event)) {
    const isAllFailed = event.data.success_count === 0;

    yield put(
      actionCreator({
        id: event.event_id,
        type: 'download',
        status: 'success',
        icon: isAllFailed ? <Icon url={IconFileTaskFailed} width={48} height={42} /> : null,
        info: {
          taskName: getTaskTitle(event.data.job_started_at),
          progressCount: event.data.success_count,
          totalCount: event.data.total_count,
          failedMessage: isAllFailed ? translate('error_export_reports_to_erp_failed') : null,
        },
      })
    );
  } else if (isBulkReportsExportErrorEvent(event)) {
    yield put(
      actionCreator({
        id: event.event_id,
        type: 'download',
        status: 'failed',
        info: {
          taskName: getTaskTitle(event.data.job_started_at),
          failedMessage: translate('error_export_reports_to_erp_failed_please_retry'),
        },
      })
    );
  }
}

function* handleBulkReportsExportEventRemoved(
  action: ActionType<typeof reportListActions.bulkReportsExportEventRemoved>
) {
  const { payload: event } = action;

  yield put(taskListActions.hideCard({ id: event.event_id }));
}

function* handleConfirmUpload(action: ActionType<typeof taskListActions.confirm>) {
  const { id: targetJobId } = action.payload;

  const currentJobId: ReturnType<typeof reportListSelectors.erpReserveExportJobId> = yield select(
    reportListSelectors.erpReserveExportJobId
  );

  if (targetJobId === currentJobId) {
    try {
      yield put(uiActions.showSpinner());
      const data: GetReportsJobsResultResponse = yield call(getReportsJobsResult, currentJobId);
      yield put(reportListActions.showBulkReportsExportResultModal(data));
      yield put(taskListActions.delete({ id: currentJobId }));
    } catch (error) {
      ErrorHandler.fromAxios(error);
    } finally {
      yield put(uiActions.hideSpinner());
    }
  }
}

function* watchReportList() {
  yield takeLatest(CONFIRM, handleConfirmUpload);
  yield takeLatest(
    [BULK_REPORTS_EXPORT_EVENT_ADDED, BULK_REPORTS_EXPORT_EVENT_CHANGED],
    handleBulkReportsExportEventAddOrChanged
  );
  yield takeLatest(BULK_REPORTS_EXPORT_EVENT_REMOVED, handleBulkReportsExportEventRemoved);
}

function* handleChildAdded(action: ActionType<typeof firebaseActions.childAdded>) {
  const { payload: event } = action;

  if (isBulkReportsExportFirebaseEvent(event)) {
    yield put(reportListActions.bulkReportsExportEventAdded(event));
  }
}

function* handleChildChanged(action: ActionType<typeof firebaseActions.childChanged>) {
  const { payload: event } = action;

  if (isBulkReportsExportFirebaseEvent(event)) {
    yield put(reportListActions.bulkReportsExportEventChanged(event));
  }
}

function* handleChildRemoved(action: ActionType<typeof firebaseActions.childRemoved>) {
  const { payload: event } = action;

  if (isBulkReportsExportFirebaseEvent(event)) {
    yield put(reportListActions.bulkReportsExportEventRemoved(event));
  }
}

export function* watchFirebaseChildEvents() {
  yield takeLatest(FIREBASE_CHILD_ADDED, handleChildAdded);
  yield takeLatest(FIREBASE_CHILD_CHANGED, handleChildChanged);
  yield takeLatest(FIREBASE_CHILD_REMOVED, handleChildRemoved);
}

export function* reporListSaga() {
  yield all([watchReportList, watchFirebaseChildEvents, watchExportErpReserve].map(saga => fork(saga)));
}
