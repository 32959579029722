import styled from 'styled-components';

export const ButtonWrapper = styled.div`
  display: flex;
  justify-content: end;
  height: 52px;
  padding: 8px;

  & button {
    border: none;
    padding: 8px 10px;

    color: #5856d6;
    font-family: Roboto;
    font-size: 14px;
    font-style: normal;
    font-weight: 500;
    line-height: 16px; /* 114.286% */
    letter-spacing: 0.75px;
    text-transform: uppercase;
  }
`;

export const ContentWrapper = styled.div`
  padding: 22px 24px 21px;

  color: rgba(60, 60, 67, 0.6);
  text-align: center;
  font-feature-settings: 'liga' off, 'clig' off;
  font-family: Roboto;
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: 24px; /* 150% */
`;
