import styled, { css } from 'styled-components';

import { DefaultTargetInput } from '@N/view/components/Combobox/index';
import { Input } from '@N/view/components/Inputs';
import { Combobox as MantineCombobox } from '@mantine/core';

export const injectDefaultTargetInputSelectorStyles = (targetInput: typeof Input | typeof DefaultTargetInput) => {
  return styled(targetInput)`
    & .mantine-Input-section:focus {
      & svg {
        color: ${({ theme }) => theme.mantineThemes.colors.blue[3]};
      }
    }
  `;
};

export const injectDefaultDropdownSelectorStyles = (Dropdown: typeof MantineCombobox.Dropdown) => {
  return styled(Dropdown)`
    .mantine-Combobox-option {
      &:hover {
        background-color: ${({ theme }) => theme.mantineThemes.colors.navy[2]};
      }

      &[data-combobox-active='true'] {
        background-color: ${({ theme }) => theme.mantineThemes.colors.blue[2]};
      }
    }

    & .mantine-Combobox-input {
      //border: none !important;
      height: 38px;
      border-radius: 6px 6px 0 0;
      margin: unset;
      //border-bottom: 1px solid ${({ theme }) => theme.colors.grey[300]} !important;
    }
  `;
};

export const injectDefaultOptionStyles = (option: typeof MantineCombobox.Option) => {
  return styled(option)`
    padding: 10px 16px;
    line-height: 18px;
    ${({ theme }) => css`
      font-size: ${theme.mantineThemes.other.bodyTexts.text4.fontSize};
      font-weight: ${theme.mantineThemes.other.bodyTexts.text4.fontWeight};
      color: ${theme.mantineThemes.colors.grey[8]};
    `}
  `;
};
