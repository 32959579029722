import API from 'apis/API';

export type GetVersionsResponse = {
  api: { git_branch: string; deploy_name: string };
};

/**
 * GET /v4/versions
 */
export const getVersions = async () => {
  const res = await API.get<GetVersionsResponse>('/v4/versions');

  return res.data;
};

/**
 * /v4/status
 */
export const getStatus = async () => {
  const res = await API.get('/v4/status');

  return res.data;
};
