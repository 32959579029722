import { AsyncTask, DownloadTask, LegacyDownloadTask } from './types';

import { AxiosError } from 'axios';
import { createStandardAction } from 'typesafe-actions';

export const REQUEST_DOWNLOAD = 'file/REQUEST_DOWNLOAD';
export const ENQUEUE_DOWNLOAD = 'file/ENQUEUE_DOWNLOAD';
export const START_DOWNLOAD = 'file/START_DOWNLOAD';
export const CANCEL_DOWNLOAD = 'file/CANCEL_DOWNLOAD';
export const DOWNLOAD_SUCCESS = 'file/DOWNLOAD_SUCCESS';
export const DOWNLOAD_FAILED = 'file/DOWNLOAD_FAILED';
export const HIDE_DOWNLOAD = 'file/HIDE_DOWNLOAD';
export const DEQUEUE_DOWNLOAD = 'file/DEQUEUE_DOWNLOAD';

export const downloadActions = {
  request: <T extends TRequestFn>(downloadTask: DownloadTask<T>) =>
    createStandardAction(REQUEST_DOWNLOAD)<DownloadTask<T>>()(downloadTask),
  enqueue: createStandardAction(ENQUEUE_DOWNLOAD)<LegacyDownloadTask>(),
  start: createStandardAction(START_DOWNLOAD)<{ id: string }>(),
  cancel: createStandardAction(CANCEL_DOWNLOAD)<{ id: string }>(),
  success: createStandardAction(DOWNLOAD_SUCCESS)<{
    id: string;
    fileName: string;
    fileExtension: string;
    fileURL: string;
  }>(),
  failed: createStandardAction(DOWNLOAD_FAILED)<{ id: string; error?: AxiosError }>(),
  hide: createStandardAction(HIDE_DOWNLOAD)<{ id: string }>(),
  dequeue: createStandardAction(DEQUEUE_DOWNLOAD)<{ id: string }>(),
};

export const ENQUEUE = 'taskList/ENQUEUE';
export const UPDATE = 'taskList/UPDATE';
export const CANCEL = 'taskList/CANCEL';
export const CONFIRM = 'taskList/CONFIRM';
export const DELETE = 'taskList/DELETE';
export const HIDE_CARD = 'taskList/HIDE_CARD';
export const DEQUEUE = 'taskList/DEQUEUE';
export const CLEAR = 'taskList/CLEAR';

export const taskListActions = {
  enqueue: createStandardAction(ENQUEUE)<AsyncTask>(),
  update: createStandardAction(UPDATE)<
    Pick<AsyncTask, 'id' | 'type' | 'status' | 'icon'> & {
      info: Partial<AsyncTask['info']> & Pick<AsyncTask['info'], 'taskName'>;
    }
  >(),
  cancel: createStandardAction(CANCEL)<{ id: string }>(),
  confirm: createStandardAction(CONFIRM)<{ id: string }>(),
  delete: createStandardAction(DELETE)<{ id: string }>(),
  hideCard: createStandardAction(HIDE_CARD)<{ id: string }>(),
  dequeue: createStandardAction(DEQUEUE)<{ id: string }>(),
  clear: createStandardAction(CLEAR)(),
};
