import { AxiosRequestConfig, AxiosResponse } from 'axios';

export type AxiosInterceptorType = 'req' | 'res';

type RequestInterceptorFn =
  | ((config: AxiosRequestConfig) => AxiosRequestConfig | Promise<AxiosRequestConfig>)
  | ((error: any) => any);

type ResponseInterceptorFn =
  | ((response: AxiosResponse) => AxiosResponse | Promise<AxiosResponse>)
  | ((error: any) => any);

export type AxiosInterceptorFn<T extends AxiosInterceptorType> = T extends 'req'
  ? RequestInterceptorFn
  : ResponseInterceptorFn;

export interface AxiosInterceptor<T extends AxiosInterceptorType> {
  type: T;
  interceptFn: {
    fulfilled?: AxiosInterceptorFn<T>;
    rejected?: AxiosInterceptorFn<T>;
  };
}

export type CustomInterceptorKey = string;
type OriginInterceptorKey = number;

export interface RegisterInterceptorConfig<T extends AxiosInterceptorType> {
  interceptorKey: CustomInterceptorKey;
  interceptor: AxiosInterceptor<T>;
}

export const getInterceptorType = (interceptor: AxiosInterceptor<AxiosInterceptorType>): AxiosInterceptorType => {
  return interceptor.type === 'req' ? 'req' : 'res';
};

/**
 * @see https://spendit.atlassian.net/wiki/spaces/FE/pages/3074949443/API+Client+Architecture
 */

class InterceptorManager {
  private readonly registeredInterceptors: Map<CustomInterceptorKey, AxiosInterceptor<AxiosInterceptorType>> = new Map<
    CustomInterceptorKey,
    AxiosInterceptor<AxiosInterceptorType>
  >();
  private readonly injectedInterceptorKeys: Map<CustomInterceptorKey, OriginInterceptorKey> = new Map<
    CustomInterceptorKey,
    OriginInterceptorKey
  >();

  public registerInterceptor<T extends AxiosInterceptorType>(config: RegisterInterceptorConfig<T>) {
    if (this.hasKeyAlreadyRegistered(config.interceptorKey)) {
      throw new Error('the interceptor key has already exist.');
    }
    this.registeredInterceptors.set(config.interceptorKey, config.interceptor);
  }

  public getInterceptor(key: CustomInterceptorKey) {
    const interceptor = this.registeredInterceptors.get(key);
    if (!interceptor) throw new Error('there is no instance to match with key.');
    return interceptor;
  }

  public getInterceptorOriginKey(key: CustomInterceptorKey) {
    const originKey = this.injectedInterceptorKeys.get(key);
    if (!originKey) throw new Error('there is no origin key to match.');
    return originKey;
  }

  public mapCustomKeyToOriginKey(custom: CustomInterceptorKey, origin: OriginInterceptorKey) {
    this.injectedInterceptorKeys.set(custom, origin);
  }

  public getRegisteredInterceptorCounts(): number {
    return this.registeredInterceptors.size;
  }

  public getRegisteredKeys() {
    return this.registeredInterceptors.keys();
  }

  private hasKeyAlreadyRegistered(key: CustomInterceptorKey): boolean {
    return this.registeredInterceptors.has(key);
  }
}
export default InterceptorManager;
