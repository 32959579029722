import { Route, Switch } from 'react-router-dom';

import React from 'react';
import RouteWithAuth from '@N/view/router/RouteWithAuth';
import RouteWithoutAuth from '@N/view/router/RouteWithoutAuth';
import { paths } from 'modules/router';

// etc
const Connect = React.lazy(() => import('@N/view/pages/Connect'));
const Connected = React.lazy(() => import('@N/view/pages/Connected'));
const TermOfService = React.lazy(() => import('@N/view/pages/TermOfService'));
const PrivacyPolicy = React.lazy(() => import('@N/view/pages/PrivacyPolicy'));
const LandingContact = React.lazy(() => import('components/web/landing/LandingContact'));

// auth
const PasswordChange = React.lazy(() => import('components/web/user/password_change'));
const PasswordReset = React.lazy(() => import('@N/view/pages/PasswordReset'));
const SSO = React.lazy(() => import('@N/view/pages/SSO'));
const SignIn = React.lazy(() => import('@N/view/pages/SignIn'));
const SignUp = React.lazy(() => import('@N/view/pages/SignUp'));
const Invitation = React.lazy(() => import('components/web/user/invitation'));

// webview
const WebViewReportEditor = React.lazy(() => import('@N/view/pages/WebView/ReportEditor'));

// okta sso
const RedirectToOktaHostedLoginForm = React.lazy(() =>
  import('@N/view/pages/SSO/Okta').then(module => ({ default: module.RedirectToOktaHostedLoginForm }))
);
const LoginCallback = React.lazy(() => import('@okta/okta-react').then(module => ({ default: module.LoginCallback })));

const ApplicationEntry = React.lazy(() => import('@N/view/pages/Entry/ApplicationEntry'));

export const AuthLayerRouter = () => {
  return (
    <Switch>
      <Route path={paths.contact} component={LandingContact} />
      <Route path={paths.privacyPolicy} component={PrivacyPolicy} />
      <Route path={paths.termOfService} component={TermOfService} />
      <Route path={paths.connect} component={Connect} />
      <Route path={paths.connected} component={Connected} />
      <RouteWithoutAuth path={paths.signIn}>
        <SignIn />
      </RouteWithoutAuth>
      <RouteWithoutAuth path={paths.sso} exact>
        <SSO />
      </RouteWithoutAuth>
      <RouteWithoutAuth path={paths.okta} exact>
        <RedirectToOktaHostedLoginForm />
      </RouteWithoutAuth>
      <RouteWithoutAuth path={paths.oktaCallback} exact>
        <LoginCallback />
      </RouteWithoutAuth>
      <RouteWithoutAuth path={paths.signUp}>
        <SignUp />
      </RouteWithoutAuth>
      <RouteWithoutAuth path={paths.invitation}>
        <Invitation />
      </RouteWithoutAuth>
      <RouteWithoutAuth path={paths.passwordReset}>
        <PasswordReset />
      </RouteWithoutAuth>
      <RouteWithoutAuth path={paths.passwordChange}>
        <PasswordChange />
      </RouteWithoutAuth>
      <RouteWithoutAuth path={paths.reportEditor}>
        <WebViewReportEditor />
      </RouteWithoutAuth>
      <RouteWithAuth path={paths.root}>
        <ApplicationEntry />
      </RouteWithAuth>
    </Switch>
  );
};
