import { Combobox } from '@mantine/core';

export const ComboboxResolver = Combobox.extend({
  defaultProps: {
    zIndex: 900,
    withinPortal: true,
  },

  styles: theme => ({
    dropdown: {
      boxSizing: 'border-box',
      padding: 0,
    },
    search: { boxSizing: 'border-box', border: 'none', borderBottom: `1px solid ${theme.colors.grey[4]}` },
    option: {
      borderRadius: 0,
      boxSizing: 'border-box',
    },
  }),
});
