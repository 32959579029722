import {
  GetReportsJobsResultResponse,
  PostReportsExportErpReserveParams,
  PostReportsExportErpReserveResponse,
} from 'apis/ReportAPI';

import { BulkReportsExportFirebaseEvent } from './types';
import { createStandardAction } from 'typesafe-actions';

export const CHECK = 'reports/list/CHECK';
export const UNCHECK = 'reports/list/UNCHECK';
export const CHECK_ALL = 'reports/list/CHECK_ALL';
export const UNCHECK_ALL = 'reports/list/UNCHECK_ALL';
export const SELECT_ALL_IN_THIS_PAGE = 'reports/list/SELECT_ALL_IN_THIS_PAGE';
export const UNSELECT_ALL_IN_THIS_PAGE = 'reports/list/UNSELECT_ALL_IN_THIS_PAGE';

export const EXPORT_ERP_RESERVE = 'reports/list/EXPORT_ERP_RESERVE';
export const EXPORT_ERP_RESERVE_SUCCESS = 'reports/list/EXPORT_ERP_RESERVE_SUCCESS';
export const EXPORT_ERP_RESERVE_FAILED = 'reports/list/EXPORT_ERP_RESERVE_FAILED';
export const EXPORT_ERP_RESERVE_CANCELLED = 'reports/list/EXPORT_ERP_RESERVE_CANCELLED';

export const BULK_REPORTS_EXPORT_EVENT_ADDED = 'reports/list/BULK_REPORTS_EXPORT_EVENT_ADDED';
export const BULK_REPORTS_EXPORT_EVENT_CHANGED = 'reports/list/BULK_REPORTS_EXPORT_EVENT_CHANGED';
export const BULK_REPORTS_EXPORT_EVENT_REMOVED = 'reports/list/BULK_REPORTS_EXPORT_EVENT_REMOVED';
export const SHOW_BULK_REPORTS_EXPORT_RESULT_MODAL = 'reports/list/SHOW_BULK_REPORTS_EXPORT_RESULT_MODAL';
export const HIDE_BULK_REPORTS_EXPORT_RESULT_MODAL = 'reports/list/HIDE_BULK_REPORTS_EXPORT_RESULT_MODAL';

export const reportListActions = {
  check: createStandardAction(CHECK)<Report>(),
  /**
   * @param id report ID
   */
  uncheck: createStandardAction(UNCHECK)<Report>(),
  checkAll: createStandardAction(CHECK_ALL)(),
  uncheckAll: createStandardAction(UNCHECK_ALL)(),
  selectAllInThisPage: createStandardAction(SELECT_ALL_IN_THIS_PAGE)<Report[]>(),
  unselectAllInThisPage: createStandardAction(UNSELECT_ALL_IN_THIS_PAGE)<Report[]>(),
  exportErpReserve: createStandardAction(EXPORT_ERP_RESERVE)<
    Pick<PostReportsExportErpReserveParams, 'checked_all' | 'report_ids'> & { filter?: Record<string, unknown> }
  >(),
  exportErpReserveSuccess: createStandardAction(EXPORT_ERP_RESERVE_SUCCESS)<PostReportsExportErpReserveResponse>(),
  exportErpReserveFailed: createStandardAction(EXPORT_ERP_RESERVE_FAILED)<unknown>(),
  exportErpReserveCancelled: createStandardAction(EXPORT_ERP_RESERVE_CANCELLED)(),

  bulkReportsExportEventAdded: createStandardAction(BULK_REPORTS_EXPORT_EVENT_ADDED)<BulkReportsExportFirebaseEvent>(),
  bulkReportsExportEventChanged: createStandardAction(
    BULK_REPORTS_EXPORT_EVENT_CHANGED
  )<BulkReportsExportFirebaseEvent>(),
  bulkReportsExportEventRemoved: createStandardAction(
    BULK_REPORTS_EXPORT_EVENT_REMOVED
  )<BulkReportsExportFirebaseEvent>(),
  showBulkReportsExportResultModal: createStandardAction(
    SHOW_BULK_REPORTS_EXPORT_RESULT_MODAL
  )<GetReportsJobsResultResponse>(),
  hideBulkReportsExportResultModal: createStandardAction(HIDE_BULK_REPORTS_EXPORT_RESULT_MODAL)(),
};
