import { Store } from 'redux';
import { networkStatusActions } from 'modules/networkStatus';

export const attachNetworkStateListeners = (store: Store): void => {
  window.addEventListener('offline', () => {
    store.dispatch(networkStatusActions.statusChange({ online: false }));
  });

  window.addEventListener('online', () => {
    store.dispatch(networkStatusActions.statusChange({ online: true }));
  });
};
