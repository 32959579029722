import GlobalPortal from 'components/commons/GlobalPortal';
import React from 'react';
import Toast from 'components/commons/Toast';
import styled from 'styled-components';
import useUI from 'components/hooks/useUI';

export const TOAST_CONTAINER_BASE_CLASSNAME = 'spendit-toast-container';

const StyledToast = styled(Toast)``;

const ToastList = styled.div`
  position: absolute;
  top: 71px;
  left: 50%;
  transform: translate3d(-50%, 0, 0);
  width: 450px;

  ${StyledToast} + ${StyledToast} {
    margin-top: 10px;
  }
`;

export const ToastContainer: React.FC<React.PropsWithChildren<unknown>> = () => {
  const { toasts } = useUI();

  return (
    <>
      <GlobalPortal>
        <ToastList className={TOAST_CONTAINER_BASE_CLASSNAME}>
          {toasts.map(toast => (
            <StyledToast key={toast.id} {...toast} />
          ))}
        </ToastList>
      </GlobalPortal>
    </>
  );
};
