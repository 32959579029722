import { RootState } from 'modules';
import { useSelector } from 'react-redux';

export default function useUI() {
  const toasts = useSelector((state: RootState) => state.ui.toasts);

  return {
    toasts,
  };
}
