import { Redirect, Route, RouteProps } from 'react-router-dom';

import React from 'react';
import { paths } from 'modules/router';
import { sessionSelectors } from 'modules/session/selectors';
import { useSelector } from 'react-redux';

const RouteWithAuth: React.FC<React.PropsWithChildren<RouteProps>> = ({ children, ...rest }) => {
  const isLoggedIn = useSelector(sessionSelectors.isLoggedIn);

  return (
    <Route
      {...rest}
      render={({ location }) => {
        if (isLoggedIn) {
          return children;
        }
        // if (
        //   location.pathname === '/' &&
        //   process.env.REACT_APP_ENV === 'production' &&
        //   process.env.REACT_APP_LANDING_URL
        // ) {
        //   window.location.href = process.env.REACT_APP_LANDING_URL;
        //   return <></>;
        // }
        return (
          <Redirect
            to={{
              pathname: paths.signIn,
              state: { from: location },
            }}
          />
        );
      }}
    />
  );
};

export default RouteWithAuth;
