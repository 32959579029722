import colors, { ColorKeysWithoutGrey } from '../../../theme/colors';
import styled, { keyframes } from 'styled-components';

import { ButtonVariant } from '../styles';

type SpinnerProps = {
  size?: number;
  colorScheme: ColorKeysWithoutGrey;
  variant: ButtonVariant;
};

const rotate = keyframes`
  0% {
    transform: rotate(360deg);
  }
100% {
    transform: rotate(0deg);
  }
`;

export const Spinner = styled.i<SpinnerProps>`
  border-radius: 50%;
  height: ${props => props.size}px;
  width: ${props => props.size}px;
  position: absolute;
  animation: ${rotate} 1s linear infinite;
  border-radius: 50%;

  //스피너 그라데이션 컬러
  ${({ colorScheme, variant, theme }) => {
    const color = theme.colors[colorScheme];
    return `background: conic-gradient(from 180deg,${variant === 'fill' ? colors.grey[0] : color[500]}, ${
      colors.grey[0]
    }00);`;
  }}

  &::after,
  &::before {
    content: '';
    position: absolute;
    border-radius: 50%;
  }

  &::after {
    top: 2px;
    right: 2px;
    bottom: 2px;
    left: 2px;
    // 가운데 중심부 색
    ${({ colorScheme, variant, theme }) => {
      const color = theme.colors[colorScheme];
      return `background-color:${variant === 'fill' ? color[300] : color[50]};`;
    }}
  }

  &::before {
    width: 2px;
    height: 2px;
    right: 50%;
    top: 100%;
    transform: translateX(50%) translateY(-100%);
    // 스피너의 끝 부분 둥글게 구현히기 위함
    ${({ colorScheme, variant, theme }) => {
      const color = theme.colors[colorScheme];
      return `background-color: ${variant === 'fill' ? theme.colors.grey[0] : color[500]}`;
    }}
  }
`;

export default Spinner;
