type Props = {
  width?: number;
  height?: number;
};

export const IconSuccess: React.FC<React.PropsWithChildren<Props>> = ({ width = 72, height = 72 }) => (
  <svg xmlns="http://www.w3.org/2000/svg" width={width} height={height} viewBox="0 0 72 72" fill="none">
    <circle cx="36" cy="36" r="34" stroke="#BFE1FF" strokeWidth="4" />
    <path
      d="M17.561 36.439L30.1513 48.5952C30.2298 48.6709 30.3545 48.6698 30.4316 48.5927L55.3171 23.7073"
      stroke="#BFE1FF"
      strokeWidth="4"
      strokeLinecap="round"
    />
  </svg>
);
