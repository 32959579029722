import $ from 'jquery';
import DatadogLogUtil from 'utils/DatadogLogUtil';
import { isSpenditErrorResponseData } from 'utils/ErrorHandler';
import { spenditHistory } from 'new_src/view/bootstrap/config/configureHistory';
import { v1 as uuidv1 } from 'uuid';

const buildResponseLogContext = (
  jqXHR: JQuery.jqXHR,
  options: JQuery.AjaxSettings,
  context: { [x: string]: any } = {}
) => {
  const requestId = options.headers?.['Spendit-Request-ID'];
  const requestTimestamp = Number(options.headers?.['Spendit-Request-Timestamp'] || '');
  const responseTimestamp = Date.now();
  const hasTimestamp = requestTimestamp > 0;

  return {
    statusCode: jqXHR.status,
    statusText: jqXHR.statusText,
    responseURL: options.url,
    requestId,
    responseTimestamp,
    duration: hasTimestamp ? (responseTimestamp - requestTimestamp) * 1_000_000 : 0,
    method: options.method,
    url: options.url,
    ...context,
  };
};

export default () => {
  $(document).ajaxSend((event, jqXHR, options) => {
    const requestId = uuidv1();
    const requestTimestamp = Date.now();
    const context = {
      requestId,
      requestTimestamp,
      method: options.method,
      url: options.url,
    };
    const msg = `jqueryAjax.request.onFulfilled - ${context.method} ${context.url}`;

    DatadogLogUtil.info(msg, context);

    jqXHR.setRequestHeader('Spendit-Request-ID', requestId);
    jqXHR.setRequestHeader('Spendit-Request-Timestamp', requestTimestamp.toString());
  });
  $(document).ajaxSuccess((event, jqXHR, options) => {
    const context = buildResponseLogContext(jqXHR, options);
    const msg = `jqueryAjax.response.onFulfilled - ${options.method} ${options.url} ${jqXHR.status} ${jqXHR.statusText}`;

    DatadogLogUtil.info(msg, context);
  });
  $(document).ajaxError((event, jqXHR, options) => {
    const data = jqXHR.responseJSON;

    const context = buildResponseLogContext(jqXHR, options, { data });
    const msg = `jqueryAjax.response.onRejected - ${options.method} ${options.url} ${jqXHR.status} ${jqXHR.statusText}`;

    DatadogLogUtil.debug(msg, context);

    if (isSpenditErrorResponseData(data)) {
      if (data.error.code === 40490) {
        spenditHistory.push('/');
      }
    }
  });
};
