import { TooltipContainerProps, TooltipOption } from './types';

/**
 * 툴팁 아래 삼각형의 너비(18px)보다 툴팁 내부 요소가 작은 경우 툴팁과 툽팁 아래 삼각형을 수직 정렬하기 위해 알아야하는 값
 */
const MIN_WIDTH = 18;

/**
 * text의 너비를 반환합니다.
 * 한글/영문/숫자의 길이가 아닌 텍스트의 너비에 따라 툴팁의 위치를 조정하기 위해 사용합니다.
 * @param text
 * @param fontOption
 */
const getTextWidthOffscreen = (text: string, fontOption?: FontStyle) => {
  const span = document.createElement('span');
  span.innerText = text;
  span.style.fontSize = fontOption?.fontSize ?? '10px';
  span.style.fontWeight = fontOption?.fontWeight ?? 'normal';
  span.style.fontFamily = fontOption?.fontFamily ?? 'sans-serif';
  span.style.letterSpacing = fontOption?.letterSpacing ?? 'normal';
  span.style.visibility = 'hidden';
  document.body.appendChild(span);
  const width = span.offsetWidth;
  document.body.removeChild(span);
  return width;
};

export const getTooltipPosition = (tooltipOption: TooltipOption): TooltipContainerProps => {
  const target = tooltipOption.target;
  const rect = target.getBoundingClientRect();
  const screenLeft = 0;
  const screenRight = window.innerWidth;
  const screenFirstCenter = Math.ceil(screenRight / 3);
  const screenSecondCenter = Math.ceil(screenRight / 3) * 2;
  const autoPositioning = tooltipOption.autoPositioning ?? true;
  const isMinimumWidth = getTextWidthOffscreen(target.innerText) <= MIN_WIDTH;

  const getTooltipLocation = (currentlocation?: string) => {
    if (tooltipOption?.location === 'right') {
      return `${rect.left + rect.width}px`;
    }
    if (tooltipOption?.location === 'left') {
      return `${rect.left}px`;
    }
    return currentlocation;
  };

  if (tooltipOption.direction === 'bottom') {
    return {
      styles: {
        top: `${rect.bottom + 10}px`,
        left: getTooltipLocation(`${rect.left + rect.width / 2}px`),
        transform: 'translate3d(-50%, 0, 0)',
      },
      arrowStyles: {
        bottom: '100%',
        left: '50%',
        transform: 'translate3d(-50%, 0, 0)',
        borderBottom: '5px solid #000',
        borderRight: '5px solid transparent',
        borderLeft: '5px solid transparent',
      },
    };
  }

  if (autoPositioning) {
    if (screenLeft <= rect.right && rect.right < screenFirstCenter) {
      return {
        styles: {
          top: `${rect.top - 10}px`,
          left: getTooltipLocation(`${rect.left - 10}px`),
          transform: 'translate3d(0, -100%, 0)',
          ...(isMinimumWidth && { textAlign: `center` }),
        },
        arrowStyles: {
          bottom: '0',
          left: isMinimumWidth ? '3.5px' : '10px',
          transform: 'translate3d(0, 100%, 0)',
          borderTop: '5px solid #000',
          borderRight: '5px solid transparent',
          borderLeft: '5px solid transparent',
        },
      };
    }

    if (screenFirstCenter <= rect.right && rect.right < screenSecondCenter) {
      return {
        styles: {
          top: `${rect.top - 10}px`,
          left: getTooltipLocation(`${rect.left + rect.width / 2}px`),
          transform: 'translate3d(-50%, -100%, 0)',
          ...(isMinimumWidth && { textAlign: `center` }),
        },
        arrowStyles: {
          bottom: '0',
          left: '50%',
          transform: 'translate3d(-50%, 100%, 0)',
          borderTop: '5px solid #000',
          borderRight: '5px solid transparent',
          borderLeft: '5px solid transparent',
        },
      };
    }

    if (screenSecondCenter < rect.right && rect.right <= screenRight) {
      return {
        styles: {
          top: `${rect.top - 10}px`,
          left: getTooltipLocation(`${rect.left + rect.width + 10}px`),
          transform: 'translate3d(-100%, -100%, 0)',
        },
        arrowStyles: {
          bottom: '0',
          right: '10px',
          transform: 'translate3d(-50%, 100%, 0)',
          borderTop: '5px solid #000',
          borderRight: '5px solid transparent',
          borderLeft: '5px solid transparent',
        },
      };
    }
  }

  return {
    styles: {
      top: `${rect.top - 10}px`,
      left: getTooltipLocation(`${rect.left + rect.width / 2}px`),
      transform: 'translate3d(-50%, -100%, 0)',
    },
    arrowStyles: {
      bottom: '0',
      left: '50%',
      transform: 'translate3d(-50%, 100%, 0)',
      borderTop: '5px solid #000',
      borderRight: '5px solid transparent',
      borderLeft: '5px solid transparent',
    },
  };
};
