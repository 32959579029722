import Button from 'components/Button';
import styled from 'styled-components';

export const ModalContentRoot = styled.div`
  padding: 48px 10px;
  border-radius: 10px;
  background-color: ${({ theme }) => theme.colors.grey[0]};
`;

export const ModalIconFallback = styled.div`
  width: 72px;
  height: 72px;
`;

export const ContentLayout = styled.div`
  display: flex;
  flex-direction: column;
  gap: 32px;
`;

export const ContentHeaderWrapper = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
`;

export const ContentBodyWrapper = styled.div`
  color: ${({ theme }) => theme.colors.grey[600]};
  text-align: center;
  font-size: 14px;
  font-weight: 400;
  line-height: normal;

  padding: 0 16px;

  & > p,
  & > span {
    overflow-wrap: break-word;
  }
`;

export const AlertModalButton = styled(Button)`
  padding: 7px 32px;
  line-height: normal;
  height: 40px;
  font-size: 16px;
  width: 100%;
  border-radius: 5px;
`;
