import {
  CHANGE_CURRENT_POLICY,
  FETCH_CURRENT_POLICY,
  FETCH_CURRENT_POLICY_FAILED,
  FETCH_CURRENT_POLICY_SUCCESS,
  FETCH_MY_POLICIES,
  FETCH_MY_POLICIES_FAILED,
  FETCH_MY_POLICIES_SUCCESS,
  GET_SESSION_DATA,
  GET_SESSION_DATA_FAILED,
  GET_SESSION_DATA_SUCCESS,
  GET_TOKEN,
  GET_TOKEN_FAILED,
  HIDE_SCRAPING_LOGIN_POPUP,
  HIDE_SCRAPING_LOGIN_POPUP_FAILED,
  SESSION_EXPIRED,
  SIGN_IN,
  SIGN_IN_ERROR,
  SIGN_IN_SUCCESS,
  SIGN_IN_WITH_TOKEN,
  SIGN_OUT,
  SIGN_OUT_SUCCESS,
  SIGN_UP,
  SIGN_UP_FAILED,
  SIGN_UP_SUCCESS,
  SignOutPayload,
  sessionActions,
} from 'modules/session/actions';
import { GetMyPolicyListResponse, getMyPolicyList, getPolicy } from 'apis/PolicyAPI';
import {
  GetSessionPayload,
  GetSessionResponse,
  PostSessionLoginResponse,
  getSession,
  postHideScrapingLogin,
  postSessionLogin,
} from 'apis/SessionAPI';
import {
  all,
  call,
  cancel,
  cancelled,
  fork,
  put,
  putResolve,
  select,
  take,
  takeLatest,
  takeLeading,
} from 'redux-saga/effects';
import { deleteSession, postUser, putChangeUserPolicy } from 'apis/UserAPI';
import { hideSpinner, showSpinner, uiActions } from 'modules/ui';
import { selectCurrentPolicy, sessionSelectors } from 'modules/session/selectors';
import { setLocale, translate } from 'utils/LocaleUtil';

import { ActionType } from 'typesafe-actions';
import ErrorHandler from 'utils/ErrorHandler';
import LocalStorage from 'components/utility/LocalStorage';
import { POLICY } from '@N/domain/entity/Policy/consts';
import axios from 'axios';
import { fireError } from 'utils/SwalUtil';
import { get } from 'lodash-es';
import { getGroups } from 'apis/GroupsAPI';
import randomBytes from 'randombytes';
import { setAuthenticationHeader } from 'apis/API';

type SignInActionType =
  | ActionType<typeof sessionActions.signIn>
  | ActionType<typeof sessionActions.signInWithToken>
  | ActionType<typeof sessionActions.signInAfterSignUp>;

function* fetchMyGroups(user: SpenditUser) {
  const data: GetGroupsResponse = yield call(getGroups, { filter: { memberName: user.email } });

  return data;
}

function* handleInvokeSessionAsyncEffect(action: ActionType<typeof sessionActions.getSessionDataSuccess>) {
  const { user } = action.payload;

  yield put(sessionActions.fetchMyPolicies());
  yield put(
    sessionActions.setCurrentPolicy({
      ...user.policy,
      personal: user.policy.policy_type === POLICY.TYPE.PERSONAL,
    })
  );

  const { groups } = yield call(fetchMyGroups, user);
  const successPayload = { user, groups };
  yield put(sessionActions.setCurrentUser({ user }));
  yield put(sessionActions.signInSuccess(successPayload));
}

function* handleInvokeSessionSyncEffect({ sessionToken, user }: { sessionToken: string; user: SpenditUser }) {
  yield call(setAuthenticationHeader, sessionToken);
  /**
   *  @description setLocale액션을 통한 locale변경시점보다 실제 앱이 마운트 되는 시점이 더 빨라 locale값이 불일치 하는 이슈를 대응
   *  @see https://spendit.atlassian.net/browse/WEB-2679
   */
  yield call(setLocale, user.language);
}

function* handleGetSessionToken(action: ActionType<typeof sessionActions.getToken>) {
  const source = axios.CancelToken.source();

  try {
    const {
      user: { session_token },
    }: PostSessionLoginResponse = yield call(
      postSessionLogin,
      {
        ...action.payload,
        device_type: 'web',
        device_token: randomBytes(20).toString('hex'),
      },
      source.token
    );
    /**
     * 로그인 성공 시, URL의 쿼리 파람을 지운다. 남겨둘 시 로그인 후
     * RouthWithoutAuth에서 (로그인된 상태 && AuthService.isSSOLoginRequest())가 true가 되어
     * 무한루프가 발생하므로 sign_in페이지에서 벗어날 수 없다
     */
    const url = window.location.origin + window.location.pathname;
    window.history.replaceState({ path: url }, '', url);
    yield call(setAuthenticationHeader, session_token);
    yield put(sessionActions.getTokenSuccess({ sessionToken: session_token }));
  } catch (error) {
    yield put(sessionActions.getTokenFailed(error));
  } finally {
    if (yield cancelled()) {
      yield call(source.cancel);
    }
  }
}

function* handleGetSessionDataWithToken(action: ActionType<typeof sessionActions.getSessionData>) {
  const cancelToken = axios.CancelToken.source();
  const sessionToken: ReturnType<typeof sessionSelectors.sessionToken> = yield action.payload?.sessionToken ??
    select(sessionSelectors.sessionToken);

  if (!sessionToken) {
    yield putResolve(
      sessionActions.getSessionDataFailed({
        type: 'GetSessionDataFailed',
        error: new Error("translate('session_saga_failed_error'"),
      })
    );

    return fireError(translate('session_saga_failed_error')).then(res => {
      LocalStorage.remove('persist:stores');
      window.location.reload();
    });
  }

  try {
    yield put(showSpinner());
    const getSessionPayload: GetSessionPayload = { authentication: sessionToken };
    const { user }: GetSessionResponse = yield call(getSession, getSessionPayload, cancelToken.token);
    yield handleInvokeSessionSyncEffect({ sessionToken, user });
    yield put(sessionActions.getSessionDataSuccess({ user }));
  } catch (error) {
    yield put(sessionActions.getSessionDataFailed({ type: 'GetSessionDataFailed', error }));
  } finally {
    if (yield cancelled()) {
      yield call(cancelToken.cancel);
    }
    yield put(hideSpinner());
  }
}

function* requestSignInByAction(action: SignInActionType) {
  try {
    yield put(showSpinner());

    if (action.type === SIGN_IN) {
      yield put(sessionActions.getToken(action.payload));
      yield take(sessionActions.getTokenSuccess);
    }

    const token = action.type !== SIGN_IN ? action.payload.sessionToken : undefined;
    yield put(sessionActions.getSessionData({ sessionToken: token }));

    yield take(sessionActions.getSessionDataSuccess);
  } catch (error) {
    yield put(sessionActions.signInError(error));
  } finally {
    yield put(hideSpinner());
  }
}

function* requestSignOut(payload?: SignOutPayload) {
  try {
    const withRequest = payload?.withRequest ?? true;

    if (withRequest) {
      yield call(deleteSession);
    }
    yield call(setAuthenticationHeader, null);
  } catch (error) {
    yield call(ErrorHandler.fromAxios, error);
  } finally {
    yield put(sessionActions.signOutSuccess());
  }
}

function* signInOutFlow() {
  while (true) {
    const action: SignInActionType = yield take([SIGN_IN, SIGN_IN_WITH_TOKEN, SIGN_UP_SUCCESS]);
    const task = yield fork(requestSignInByAction, action);

    const nextAction: ActionType<typeof sessionActions.signOut> | ActionType<typeof sessionActions.signInError> =
      yield take([SIGN_OUT, SIGN_IN_ERROR, SESSION_EXPIRED, GET_TOKEN_FAILED, GET_SESSION_DATA_FAILED]);

    if (nextAction.type === SIGN_OUT) {
      yield cancel(task);
      yield fork(requestSignOut, nextAction.payload);

      yield take(SIGN_OUT_SUCCESS);
    }
  }
}

function* handleSignInError({ payload }: ActionType<typeof sessionActions.signInError>) {
  yield call(ErrorHandler.fromAxios, payload);
}

function* handleSignUp(action: ReturnType<typeof sessionActions.signUp>) {
  const { email, name, password } = action.payload;

  try {
    yield put(showSpinner());
    const {
      user: { session_token },
    }: PostUserResponse = yield call(postUser, {
      email: email.trim(),
      name: name.trim(),
      password,
      deviceType: 'web',
      deviceToken: randomBytes(20).toString('hex'),
    });
    yield put(sessionActions.signUpSuccess({ sessionToken: session_token }));
  } catch (error) {
    yield put(sessionActions.signUpFailed(error));
  } finally {
    yield put(hideSpinner());
  }
}

function* handleSignUpSuccess({ payload: { sessionToken } }: ReturnType<typeof sessionActions.signUpSuccess>) {
  yield put(sessionActions.getSessionData({ sessionToken }));
}

function* handleSignUpFailed({ payload: error }: ReturnType<typeof sessionActions.signUpFailed>) {
  const errorCode = get(error, 'response.data.error.code');

  if (errorCode === 40001) {
    yield call(fireError, translate('error_user_email_invalid'));
  } else if (errorCode === 40002) {
    yield call(fireError, translate('error_user_exceeds'));
  } else if (errorCode === 40003) {
    yield call(fireError, translate('error_user_email_exists'));
  } else {
    yield call(ErrorHandler.fromAxios, error);
  }
}

function* handleChangeCurrentPolicy(action: ActionType<typeof sessionActions.changeCurrentPolicy>) {
  const { nextPolicy, silence } = action.payload;
  yield put(uiActions.showSpinner());

  try {
    yield call(putChangeUserPolicy, { user: { policyId: nextPolicy.id } });
    yield put(sessionActions.setCurrentPolicy(nextPolicy));

    if (!silence) {
      yield put(uiActions.showAlarm(translate('policy_changed')));
    }
  } catch (error) {
    yield call(ErrorHandler.fromAxios, error);
  } finally {
    yield put(uiActions.hideSpinner());
  }
}

function* handleFetchMyPolicies() {
  try {
    const data: GetMyPolicyListResponse = yield call(getMyPolicyList);
    yield put(sessionActions.fetchMyPoliciesSuccess(data));
  } catch (error) {
    yield put(sessionActions.fetchMyPoliciesFailed(error));
  }
}

function* handleFetchMyPoliciesSuccess(action: ActionType<typeof sessionActions.fetchMyPoliciesSuccess>) {
  const { policies, private_policy } = action.payload;
  const currentPolicy: ReturnType<typeof selectCurrentPolicy> = yield select(selectCurrentPolicy);

  if (currentPolicy?.personal === false) {
    const found = policies.find(policy => policy.id === currentPolicy.id);

    /**
     * currentPolicy가 조회한 폴리시 목록에 없는 경우, privatePolicy를 세팅해준다.
     */
    if (found === undefined) {
      yield put(sessionActions.changeCurrentPolicy({ nextPolicy: private_policy, silence: true }));
    }
  }
}

function* handleFetchMyPoliciesFailed(action: ActionType<typeof sessionActions.fetchMyPoliciesFailed>) {
  yield call(ErrorHandler.fromAxios, action.payload);
}

function* handleHideScrapingLoginPopup() {
  try {
    yield put(showSpinner());
    yield call(postHideScrapingLogin);
    yield put(sessionActions.hideScrapingLoginPopupSuccess());
  } catch (error) {
    yield put(sessionActions.hideScrapingLoginPopupFailed(error));
  } finally {
    yield put(hideSpinner());
  }
}

function* handleHideScrapingLoginPopupFailed(action: ActionType<typeof sessionActions.hideScrapingLoginPopupFailed>) {
  yield call(ErrorHandler.fromAxios, action.payload);
}

function* handleFetchCurrentPolicy() {
  const currentPolicy: ReturnType<typeof sessionSelectors.currentPolicy> = yield select(sessionSelectors.currentPolicy);

  if (currentPolicy) {
    try {
      yield put(uiActions.showSpinner());
      const response: GetPolicyResponse = yield call(getPolicy, currentPolicy.id);
      yield put(sessionActions.fetchCurrentPolicySuccess(response));
    } catch (error) {
      yield put(sessionActions.fetchCurrentPolicyFailed(error));
    } finally {
      yield put(uiActions.hideSpinner());
    }
  }
}

function* handleFetchCurrentPolicySuccess(action: ActionType<typeof sessionActions.fetchCurrentPolicySuccess>) {
  const { policy } = action.payload;
  yield put(
    sessionActions.setCurrentPolicy({
      ...policy,
      label: policy.name,
      personal: policy.policy_type === POLICY.TYPE.PERSONAL,
    })
  );
}

function* handleFetchCurrentPolicyFailed(action: ActionType<typeof sessionActions.fetchCurrentPolicyFailed>) {
  yield call(ErrorHandler.fromAxios, action.payload);
}

function* handleSessionSagaFailed(
  action: ActionType<typeof sessionActions.getSessionDataFailed> | ActionType<typeof sessionActions.getTokenFailed>
) {
  yield call(() => {
    ErrorHandler.fromAxios(action.payload).then(() => {
      if (action.payload.type === 'GetSessionDataFailed') {
        LocalStorage.remove('persist:stores');
        window.location.reload();
      }
    });
  });
}

function* watcherSaga() {
  yield takeLeading(GET_TOKEN, handleGetSessionToken);
  yield takeLeading(GET_SESSION_DATA, handleGetSessionDataWithToken);
  yield takeLeading(GET_SESSION_DATA_SUCCESS, handleInvokeSessionAsyncEffect);
  yield takeLeading(SIGN_IN_ERROR, handleSignInError);
  yield takeLeading(SIGN_UP, handleSignUp);
  yield takeLeading(SIGN_UP_SUCCESS, handleSignUpSuccess);
  yield takeLeading(SIGN_UP_FAILED, handleSignUpFailed);
  yield takeLeading(CHANGE_CURRENT_POLICY, handleChangeCurrentPolicy);
  yield takeLatest(FETCH_MY_POLICIES, handleFetchMyPolicies);
  yield takeLatest(FETCH_MY_POLICIES_SUCCESS, handleFetchMyPoliciesSuccess);
  yield takeLatest(FETCH_MY_POLICIES_FAILED, handleFetchMyPoliciesFailed);
  yield takeLeading(HIDE_SCRAPING_LOGIN_POPUP, handleHideScrapingLoginPopup);
  yield takeLeading(HIDE_SCRAPING_LOGIN_POPUP_FAILED, handleHideScrapingLoginPopupFailed);
  yield takeLatest(FETCH_CURRENT_POLICY, handleFetchCurrentPolicy);
  yield takeLatest(FETCH_CURRENT_POLICY_SUCCESS, handleFetchCurrentPolicySuccess);
  yield takeLatest(FETCH_CURRENT_POLICY_FAILED, handleFetchCurrentPolicyFailed);
  yield takeLatest(GET_TOKEN_FAILED, handleSessionSagaFailed);
  yield takeLatest(GET_SESSION_DATA_FAILED, handleSessionSagaFailed);
}

/**
 * 저장된 sessionToken이 존재하지만 currentUser가 없는 경우 SIGN_IN_SUCCESS 액션을 take한다.
 */
export function* waitForSignInSuccess() {
  const hasCurrentUser: ReturnType<typeof sessionSelectors.hasCurrentUser> = yield select(
    sessionSelectors.hasCurrentUser
  );
  const hasSessionToken: ReturnType<typeof sessionSelectors.hasSessionToken> = yield select(
    sessionSelectors.hasSessionToken
  );

  if (hasSessionToken && hasCurrentUser === false) {
    yield take(SIGN_IN_SUCCESS);
  }
}

export function* sessionSagas() {
  yield all([fork(watcherSaga), fork(signInOutFlow)]);
}
