/**
 * 폴리시 타입
 */
const POLICY_TYPE_PERSONAL = 'personal';
const POLICY_TYPE_CORPORATE = 'corporate';
const POLICY_FEATURE_TYPE_EXPENSE = 'expense';
const POLICY_FEATURE_TYPE_GENERAL_APPROVAL = 'general_approval';

const TYPE = {
  PERSONAL: POLICY_TYPE_PERSONAL,
  CORPORATE: POLICY_TYPE_CORPORATE,
} as const;

/**
 * 기존 폴리시는 비용만 지원하고 있었으나, 일반 결재도 지원하게 되면서 폴리시 기능 타입을 추가하게 되었습니다.
 */
const FEATURE_TYPE = {
  EXPENSE: POLICY_FEATURE_TYPE_EXPENSE,
  GENERAL_APPROVAL: POLICY_FEATURE_TYPE_GENERAL_APPROVAL,
};

export const POLICY = {
  TYPE,
  FEATURE_TYPE,
} as const;

/**
 * 폴리시 복사가능한 아이템
 */

export const COPYABLE_ITEM = {
  MEMBER: 'member',
  EXPENSE_POLICY: 'expense_policy',
  REPORT_POLICY: 'report_policy',
  CATEGORY: 'category',
  REFUND_POLICY: 'refund_policy',
  TAG: 'tag',
  ERP_ACCOUNT: 'erp_account',
  PLAN_POLICY: 'plan_policy',
} as const;

export const COPYABLE_ITEM_VALUES = [
  COPYABLE_ITEM.MEMBER,
  COPYABLE_ITEM.EXPENSE_POLICY,
  COPYABLE_ITEM.REPORT_POLICY,
  COPYABLE_ITEM.CATEGORY,
  COPYABLE_ITEM.REFUND_POLICY,
  COPYABLE_ITEM.TAG,
  COPYABLE_ITEM.ERP_ACCOUNT,
  COPYABLE_ITEM.PLAN_POLICY,
] as const;

/**
 * 일반 결재 폴리시의 경우 복사 가능한 아이템이 두 가지임
 */
export const COPYABLE_ITEM_VALUES_FOR_GENERAL_APPROVAL = [COPYABLE_ITEM.MEMBER, COPYABLE_ITEM.REPORT_POLICY] as const;
