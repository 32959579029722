import { createContext } from 'react';

export const TableContext = createContext<TableContextType>({
  type: 'responsive',
  initTableWidth: (): number => -1,
  totalPages: 0,
  currentPage: 1,
});

export default { TableContext };
