import { AxiosInstance, AxiosRequestConfig } from 'axios';
import AxiosInstanceManager, { InstanceKey } from 'apis/instance/InstanceManager';
import InterceptorManager, {
  AxiosInterceptorFn,
  AxiosInterceptorType,
  CustomInterceptorKey,
  RegisterInterceptorConfig,
  getInterceptorType,
} from 'apis/interceptor/InterceptorManager';

import _ from 'lodash-es';

/**
 * @see https://spendit.atlassian.net/wiki/spaces/FE/pages/3074949443/API+Client+Architecture
 */

export class AxiosController {
  private instanceManager: AxiosInstanceManager;
  private interceptorManager: InterceptorManager;
  private defaultRequestConfiguration: AxiosRequestConfig = {};
  constructor(_instanceManager: AxiosInstanceManager, _interceptorManager: InterceptorManager) {
    this.instanceManager = _instanceManager;
    this.interceptorManager = _interceptorManager;
  }

  public getInstance(key: InstanceKey): AxiosInstance {
    return new Proxy(this.instanceManager.get(key).instanceOrigin, {
      get: (target, p, receiver) => {
        return this.instanceManager.get(key).instanceOrigin[p];
      },
    });
  }

  public registerInstance(key: InstanceKey, config?: AxiosRequestConfig) {
    return this.instanceManager.register(key, _.merge(this.defaultRequestConfiguration, config));
  }
  public registerInterceptor(config: RegisterInterceptorConfig<AxiosInterceptorType>) {
    this.interceptorManager.registerInterceptor(config);
  }

  public overrideInstanceConfig(targetInstanceKey: InstanceKey, config?: AxiosRequestConfig) {
    if (!this.instanceManager.hasInstanceBeenRegistered(targetInstanceKey)) {
      throw new Error(`there is no instance to match with the key-${targetInstanceKey}.`);
    }
    const prevConfig = this.instanceManager.get(targetInstanceKey).config;
    const newConfig = _.merge(prevConfig, config);

    this.registerInstance(targetInstanceKey, newConfig);
    this._injectAllInterceptorsToTarget(targetInstanceKey);
  }

  public injectInterceptorToAPIInstance(targetInstanceKey: InstanceKey, interceptorKey: CustomInterceptorKey) {
    const interceptor = this.interceptorManager.getInterceptor(interceptorKey);
    const targetInstanceOrigin = this.instanceManager.get(targetInstanceKey).instanceOrigin;

    if (getInterceptorType(interceptor) === 'req') {
      const interceptorId = targetInstanceOrigin.interceptors.request.use(
        interceptor.interceptFn.fulfilled as AxiosInterceptorFn<'req'>,
        interceptor.interceptFn.rejected as AxiosInterceptorFn<'req'>
      );
      this.interceptorManager.mapCustomKeyToOriginKey(interceptorKey, interceptorId);
    } else {
      const interceptorId = targetInstanceOrigin.interceptors.response.use(
        interceptor.interceptFn.fulfilled as AxiosInterceptorFn<'res'>,
        interceptor.interceptFn.rejected as AxiosInterceptorFn<'res'>
      );
      this.interceptorManager.mapCustomKeyToOriginKey(interceptorKey, interceptorId);
    }
  }

  public ejectInterceptor(targetInstanceKey: InstanceKey, interceptorKey: CustomInterceptorKey) {
    const targetInstanceOrigin = this.instanceManager.get(targetInstanceKey).instanceOrigin;
    const interceptorId = this.interceptorManager.getInterceptorOriginKey(interceptorKey);

    targetInstanceOrigin.interceptors.request.eject(interceptorId);
    targetInstanceOrigin.interceptors.response.eject(interceptorId);
  }

  public setDefaultRequestConfiguration(config: AxiosRequestConfig) {
    // 1. member mutate
    this.defaultRequestConfiguration = config;

    // 2. update all registered instance
    Array.from(this.instanceManager.getRegisteredInstanceEntries()).forEach(([key, instance]) => {
      this.registerInstance(key, instance.config);
    });
  }

  public initPipelines() {
    const registeredInstances = Array.from(this.instanceManager.getRegisteredInstanceKeys());

    if (this.instanceManager.getRegisteredInstanceCount() === 0) {
      throw new Error('There is no instance to be injected with interceptor');
    }

    if (this.interceptorManager.getRegisteredInterceptorCounts())
      registeredInstances.forEach(instanceKey => {
        this._injectAllInterceptorsToTarget(instanceKey);
      });
  }

  private _injectAllInterceptorsToTarget(targetInstanceKey: InstanceKey) {
    if (!this.instanceManager.hasInstanceBeenRegistered(targetInstanceKey)) {
      throw new Error(`there is no instance to match with the key-${targetInstanceKey}.`);
    }

    // 가장 마지막으로 등록된 인터셉터가 먼저 적용되느 ㄴ이슈가 존재
    Array.from(this.interceptorManager.getRegisteredKeys())
      .reverse()
      .forEach(interceptorKey => {
        this.injectInterceptorToAPIInstance(targetInstanceKey, interceptorKey);
      });
  }
}
