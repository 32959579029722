import { Task, downloadActions, isAsyncTask, isLegacyDownloadTask, taskListActions } from 'modules/taskList';
import styled, { css, keyframes } from 'styled-components';

import { DownloadTaskCardInfo } from 'components/commons/TaskCardList/DownloadTaskCardInfo';
import React from 'react';
import { TaskCardInfo } from 'components/commons/TaskCardList/TaskCardInfo';
import { TaskIcon } from './TaskIcon';
import { useDispatch } from 'react-redux';

type OwnProps = {
  task: Task;
};

type Props = OwnProps & Styleable;

const animateFadeIn = keyframes`
  from {
    transform: translate3d(0, -30px, 0);
    opacity: 0;
  }
  to {
    transform: translate3d(0, 0, 0);
    opacity: 1;
  }
`;

const animateFadeOut = keyframes`
  from {
    transform: translate3d(0, 0, 0);
    opacity: 1;
  }
  to {
    transform: translate3d(0, 30px, 0);
    opacity: 0;
  }
`;

const Container = styled.li<{ fadeIn: boolean }>`
  display: flex;
  position: relative;
  padding: 11px 20px 0;
  width: 345px;
  height: 73px;
  border-radius: 8px;
  box-shadow: 0 2px 6px 0 rgba(0, 0, 0, 0.2);
  box-sizing: border-box;

  background-color: #fff;

  ${props => {
    if (props.fadeIn) {
      return css`
        animation: ${animateFadeIn} ease 0.3s;
      `;
    }
    return css`
      animation: ${animateFadeOut} ease 0.3s;
    `;
  }}
`;

const TaskCard: React.FC<React.PropsWithChildren<Props>> = ({ className, task }) => {
  const dispatch = useDispatch();

  const handleAnimationEnd = () => {
    if (task.animation === 'fadeOut') {
      if (isLegacyDownloadTask(task)) {
        dispatch(downloadActions.dequeue({ id: task.id }));
      } else {
        dispatch(taskListActions.dequeue({ id: task.id }));
      }
    }
  };

  return (
    <Container className={className} fadeIn={task.animation === 'fadeIn'} onAnimationEnd={handleAnimationEnd}>
      <TaskIcon task={task} />
      {isLegacyDownloadTask(task) && <DownloadTaskCardInfo task={task} />}
      {isAsyncTask(task) && <TaskCardInfo task={task} />}
    </Container>
  );
};

export default TaskCard;
