import { RootState } from 'modules/reducers';
import createCachedSelector from 're-reselect';

const selectReportList = (state: RootState) => state.reports.list;
const selectSelectedReports = (state: RootState) => selectReportList(state).selectedReports;
const selectExportErpReserve = (state: RootState) => selectReportList(state).exportErpReserve;
const selectExportErpReserveResultModal = (state: RootState) => selectExportErpReserve(state).resultModal;
const selectCurrentExportErpReserveJobId = (state: RootState) => selectExportErpReserve(state).jobId;

const isCheckedAll = (state: RootState) => selectReportList(state).checkedAll;

export const reportListSelectors = {
  selectedReports: (state: RootState) => selectSelectedReports(state),
  isCheckedAll: (state: RootState) => isCheckedAll(state),
  isChecked: createCachedSelector(
    selectSelectedReports,
    isCheckedAll,
    (state: RootState, reportId: number) => reportId,
    (selectedReports, isCheckedAll, reportId) => {
      if (isCheckedAll) {
        return selectedReports[reportId] === undefined;
      }
      return selectedReports[reportId] !== undefined;
    }
  )((state, reportId) => reportId),
  erpReserveExportJobId: (state: RootState) => selectCurrentExportErpReserveJobId(state),
  isErpReserveExporting: (state: RootState) => selectCurrentExportErpReserveJobId(state) !== undefined,
  isBulkReportsExportResultModalVisible: (state: RootState) => selectExportErpReserveResultModal(state).visible,
  bulkReportsExportResultModalData: (state: RootState) => selectExportErpReserveResultModal(state).data,
};
