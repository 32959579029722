import { FirebaseChildEvent } from 'modules/firebase';

export type BulkReportsExportFirebaseEvent = FirebaseChildEvent<
  'bulk_reports_exporting' | 'bulk_reports_exported' | 'bulk_reports_export_error'
>;

export const isBulkReportsExportingEvent = (
  event: FirebaseChildEvent
): event is FirebaseChildEvent<'bulk_reports_exporting'> => event.event_type === 'bulk_reports_exporting';

export const isBulkReportsExportedEvent = (
  event: FirebaseChildEvent
): event is FirebaseChildEvent<'bulk_reports_exported'> => event.event_type === 'bulk_reports_exported';

export const isBulkReportsExportErrorEvent = (
  event: FirebaseChildEvent
): event is FirebaseChildEvent<'bulk_reports_export_error'> => event.event_type === 'bulk_reports_export_error';

export const isBulkReportsExportFirebaseEvent = (event: FirebaseChildEvent): event is BulkReportsExportFirebaseEvent =>
  isBulkReportsExportingEvent(event) || isBulkReportsExportedEvent(event) || isBulkReportsExportErrorEvent(event);
