import { ActionType, createReducer } from 'typesafe-actions';
import { SET_LOCALE_SUCCESS, localeActions } from 'modules/locale/actions';

import { DEFAULT_LANGUAGE } from 'utils/LocaleUtil';
import { produce } from 'immer';

type LocaleState = {
  initialized: boolean;
  current: string;
};

export const localeReducer = createReducer<LocaleState, ActionType<typeof localeActions>>(
  { current: DEFAULT_LANGUAGE, initialized: false },
  {
    [SET_LOCALE_SUCCESS]: (state, { payload }) =>
      produce(state, draft => {
        draft.initialized = true;
        draft.current = payload.locale;
      }),
  }
);
