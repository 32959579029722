import 'firebase/auth';
import 'firebase/database';

import { ActionType, createReducer, createStandardAction } from 'typesafe-actions';
import { GetFirebaseTokenResponse, getFirebaseToken } from 'apis/FirebaseAPI';
import {
  SIGN_IN_SUCCESS as SESSION_SIGN_IN_SUCCESS,
  SIGN_OUT_SUCCESS as SESSION_SIGN_OUT_SUCCESS,
} from 'modules/session';
import { all, call, fork, put, select, take, takeLatest } from 'redux-saga/effects';
import {
  createAuthStateChannel,
  createChildEventChannel,
  hasApp,
  initializeFirebase,
  setPersistence,
  signInWithCustomToken,
  signOutFirebase,
} from 'modules/firebase/utils';

import { RootState } from 'modules';
import firebase from 'firebase/app';
import { isReactAppEnv } from 'utils/EnvUtil';
import { produce } from 'immer';

export type FirebaseChildEventData = {
  card_bulk_uploading: {
    file_name: string;
    progress_count: number;
    total_count: number;
  };
  card_bulk_uploaded: {
    file_name: string;
    progress_count: number;
    total_count: number;
  };
  card_bulk_upload_error: {
    file_name: string;
    error_code: string;
    error_message: string;
  };
  bulk_reports_exporting: {
    progress_count: number;
    total_count: number;
    job_started_at: number;
  };
  bulk_reports_exported: {
    total_count: number;
    success_count: number;
    fail_count: number;
    job_started_at: number;
    job_ended_at: number;
  };
  bulk_reports_export_error: {
    error_code: string;
    error_message: string;
    job_started_at: number;
  };
};

export type FirebaseChildEvent<K extends keyof FirebaseChildEventData = any> = {
  user_id: number;
  event_id: string;
  event_type: K;
  data: FirebaseChildEventData[K];
  /**
   * timestamp
   */
  ts: number;
};

type FirebaseReducerState = {
  initialized: boolean;
};

type PayloadWithRefPath = { refPath: string };

const INITIALIZE = 'firebase/INITIALIZE';
const SIGN_IN = 'firebase/SIGN_IN';
const SIGN_IN_SUCCESS = 'firebase/SIGN_IN_SUCCESS';
const SIGN_IN_FAILED = 'firebase/SIGN_IN_FAILED';
const SIGN_OUT = 'firebase/SIGN_OUT';
const SIGN_OUT_SUCCESS = 'firebase/SIGN_OUT_SUCCESS';
const SIGN_OUT_FAILED = 'firebase/SIGN_OUT_FAILED';

export const FIREBASE_CHILD_ADDED = 'firebase/CHILD_ADDED';
export const FIREBASE_CHILD_CHANGED = 'firebase/CHILD_CHANGED';
export const FIREBASE_CHILD_REMOVED = 'firebase/CHILD_REMOVED';

const FAILURE = 'firebase/FAILURE';

export const firebaseActions = {
  initialize: createStandardAction(INITIALIZE)(),
  signIn: createStandardAction(SIGN_IN)(),
  signInSuccess: createStandardAction(SIGN_IN_SUCCESS)<firebase.User>(),
  signInFailed: createStandardAction(SIGN_IN_FAILED)<unknown>(),
  signOut: createStandardAction(SIGN_OUT)(),
  signOutSuccess: createStandardAction(SIGN_OUT_SUCCESS)(),
  signOutFailed: createStandardAction(SIGN_OUT_FAILED)<unknown>(),
  childAdded: createStandardAction(FIREBASE_CHILD_ADDED)<FirebaseChildEvent>(),
  childChanged: createStandardAction(FIREBASE_CHILD_CHANGED)<FirebaseChildEvent>(),
  childRemoved: createStandardAction(FIREBASE_CHILD_REMOVED)<FirebaseChildEvent>(),
  failure: createStandardAction(FAILURE)<unknown>(),
};

export const firebaseReducer = createReducer<FirebaseReducerState, ActionType<typeof firebaseActions>>(
  { initialized: false },
  {
    [INITIALIZE]: state =>
      produce(state, draft => {
        draft.initialized = true;
      }),
  }
);

const selectFirebaseInitialized = (state: RootState) => state.firebase.initialized;

function* handleSessionSignInSuccess() {
  yield put(firebaseActions.signIn());
}

function* handleSessionSignOutSuccess() {
  const isInitialized: ReturnType<typeof selectFirebaseInitialized> = yield select(selectFirebaseInitialized);

  if (isInitialized) {
    yield put(firebaseActions.signOut());
  }
}

function* handleAuthStateChange() {
  const authChannel: ReturnType<typeof createAuthStateChannel> = yield call(createAuthStateChannel);

  try {
    while (true) {
      const { user, error }: { user?: firebase.User | null; error?: firebase.auth.Error } = yield take(authChannel);

      if (user) {
        yield put(firebaseActions.signInSuccess(user));
      } else if (error) {
        yield put(firebaseActions.failure(error));
      } else {
        yield put(firebaseActions.signOutSuccess());
      }
    }
  } catch (error) {
    yield put(firebaseActions.failure(error));
  } finally {
    authChannel.close();
  }
}

function* handleInitialize() {
  yield call(initializeFirebase);
  yield fork(handleAuthStateChange);
}

function* handleFirebaseSignIn() {
  try {
    // Firebase 인증 토큰 발급
    const { token }: GetFirebaseTokenResponse = yield call(getFirebaseToken);
    const isAppInitialized: ReturnType<typeof hasApp> = yield call(hasApp);

    if (!isAppInitialized) {
      // Firebase App 초기화
      yield put(firebaseActions.initialize());
    }

    yield call(setPersistence);
    yield call(signInWithCustomToken, token);
  } catch (error) {
    yield put(firebaseActions.signInFailed(error));
  }
}

function* handleChildAdded(payload: PayloadWithRefPath) {
  const { refPath } = payload;

  const childAddedChannel: ReturnType<typeof createChildEventChannel> = yield call(
    createChildEventChannel,
    'child_added',
    refPath
  );

  try {
    while (true) {
      const event: FirebaseChildEvent = yield take(childAddedChannel);

      yield put(firebaseActions.childAdded(event));
    }
  } catch (error) {
    yield put(firebaseActions.failure(error));
  } finally {
    childAddedChannel.close();
  }
}

function* handleChildChanged(payload: PayloadWithRefPath) {
  const { refPath } = payload;

  const childChangedChannel: ReturnType<typeof createChildEventChannel> = yield call(
    createChildEventChannel,
    'child_changed',
    refPath
  );

  try {
    while (true) {
      const event: FirebaseChildEvent = yield take(childChangedChannel);

      yield put(firebaseActions.childChanged(event));
    }
  } catch (error) {
    yield put(firebaseActions.failure(error));
  } finally {
    childChangedChannel.close();
  }
}

function* handleChildRemoved(payload: PayloadWithRefPath) {
  const { refPath } = payload;

  const childRemovedChannel: ReturnType<typeof createChildEventChannel> = yield call(
    createChildEventChannel,
    'child_removed',
    refPath
  );

  try {
    while (true) {
      const event: FirebaseChildEvent = yield take(childRemovedChannel);

      yield put(firebaseActions.childRemoved(event));
    }
  } catch (error) {
    yield put(firebaseActions.failure(error));
  } finally {
    childRemovedChannel.close();
  }
}

function* handleFirebaseSignInSuccess(action: ActionType<typeof firebaseActions.signInSuccess>) {
  const { uid } = action.payload;
  const env = isReactAppEnv('production') ? 'production' : 'staging';
  const refPath = `${env}/users/${uid}`;

  yield all([
    fork(handleChildAdded, { refPath }),
    fork(handleChildChanged, { refPath }),
    fork(handleChildRemoved, { refPath }),
  ]);
}

function* handleFirebaseSignOut() {
  try {
    yield call(signOutFirebase);
  } catch (error) {
    yield put(firebaseActions.signOutFailed(error));
  }
}

export function* firebaseSaga() {
  yield takeLatest(SESSION_SIGN_IN_SUCCESS, handleSessionSignInSuccess);
  yield takeLatest(SESSION_SIGN_OUT_SUCCESS, handleSessionSignOutSuccess);
  yield takeLatest(INITIALIZE, handleInitialize);
  yield takeLatest(SIGN_IN, handleFirebaseSignIn);
  yield takeLatest(SIGN_IN_SUCCESS, handleFirebaseSignInSuccess);
  yield takeLatest(SIGN_OUT, handleFirebaseSignOut);
}
