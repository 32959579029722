const colors = {
  grey: {
    800: '#000000',
    700: '#212121',
    600: '#6B6B6B',
    500: '#989898',
    400: '#BDBDBD',
    300: '#DCDCDC',
    200: '#EEEEEE',
    100: '#F8F8F8',
    50: '#FFFFFF',
    0: '#FFFFFF',
  },
  red: {
    800: '#780D01',
    700: '#9F1701',
    600: '#C00000',
    500: '#D50000',
    400: '#F56141',
    300: '#FFA68F',
    200: '#FFCABB',
    100: '#FEE8E7',
    50: '#FFF6F5',
    0: '#FFFFFF',
  },
  yellow: {
    800: '#EE5A00',
    700: '#FE7F00',
    600: '#FF9712',
    500: '#FFAB00',
    400: '#FFDC1C',
    300: '#FDF16B',
    200: '#FEF697',
    100: '#FFFAC1',
    50: '#FFFDE5',
    0: '#FFFFFF',
  },
  green: {
    800: '#054310',
    700: '#015613',
    600: '#006F18',
    500: '#00A03B',
    400: '#00C351',
    300: '#70D788',
    200: '#C4EDCC',
    100: '#E6F8EA',
    50: '#F6FFF8',
    0: '#FFFFFF',
  },
  cyan: {
    800: '#00574F',
    700: '#00736E',
    600: '#018E90',
    500: '#00B2BD',
    400: '#00C4D0',
    300: '#51D6DB',
    200: '#83E3E4',
    100: '#B4F0F0',
    50: '#EAFFFF',
    0: '#FFFFFF',
  },
  blue: {
    800: '#3548B8',
    700: '#3968D8',
    600: '#3A7AEB',
    500: '#3C8EFF',
    400: '#4EABFF',
    300: '#96CEFF',
    200: '#BFE1FF',
    100: '#E4F3FF',
    50: '#F2F9FF',
    0: '#FFFFFF',
  },
  magenta: {
    800: '#540067',
    700: '#6F0275',
    600: '#8A0092',
    500: '#BF2FB1',
    400: '#CB5BBE',
    300: '#DB8CD1',
    200: '#E9BAE3',
    100: '#F6E4F3',
    50: '#FFF8FE',
    0: '#FFFFFF',
  },
  navy: {
    800: '#0D2852',
    700: '#04386A',
    600: '#20528A',
    500: '#486A98',
    400: '#6A84A9',
    300: '#93A6C1',
    200: '#BDC9DA',
    100: '#E5E9F0',
    50: '#F6F7F9',
    0: '#FFFFFF',
  },
} as const;

export type ColorKeysWithoutGrey = Exclude<keyof typeof colors, 'grey'>;

type GreyColorWeight = keyof typeof colors.grey;
type OthersColorWeight = keyof (typeof colors)[ColorKeysWithoutGrey];

type GreyColors = (typeof colors.grey)[GreyColorWeight];
type OthersColors = (typeof colors)[ColorKeysWithoutGrey][OthersColorWeight];

export type Colors = GreyColors | OthersColors;

export default colors;
