import { Icon } from '../../atoms/Icon';
import IconFileTaskDownloading from 'images/icons/icon-file-task-downloading.png';
import IconFileTaskFailed from 'images/icons/icon-file-task-failed.png';
import IconFileTaskSuccess from 'images/icons/icon-file-task-success.png';
import IconFileTaskUploading from 'images/icons/icon-file-task-uploading.png';
import { Task } from 'modules/taskList';
import styled from 'styled-components';

type Props = {
  task: Task;
};

const IconContainer = styled.div`
  padding: 5px 0 3px;
  width: 60px;
`;

export const TaskIcon: React.FC<React.PropsWithChildren<Props>> = ({ task }) => {
  if (task.icon) {
    return <IconContainer>{task.icon}</IconContainer>;
  }

  return (
    <IconContainer>
      {task.status === 'downloading' && <Icon url={IconFileTaskDownloading} width={48} height={42} />}
      {task.status === 'in-progress' && (
        <>
          {task.type === 'upload' && <Icon url={IconFileTaskUploading} width={48} height={42} />}
          {task.type === 'download' && <Icon url={IconFileTaskDownloading} width={48} height={42} />}
        </>
      )}
      {task.status === 'success' && <Icon url={IconFileTaskSuccess} width={48} height={42} />}
      {task.status === 'failed' && <Icon url={IconFileTaskFailed} width={48} height={42} />}
    </IconContainer>
  );
};
