import type { Context } from '@datadog/browser-core';
import { datadogLogs } from '@datadog/browser-logs';

let initialized = false;

const initGlobalContext = () => {
  const environment = process.env.REACT_APP_ENV;
  const context = {
    environment,
    service: 'spendit-webapp',
  };
  datadogLogs.setLoggerGlobalContext(context);
};

const DatadogLogUtil = {
  init: () => {
    const environment = process.env.REACT_APP_ENV;

    if (environment !== 'production') {
      return;
    }

    const clientToken = process.env.REACT_APP_DATA_DOG_CLIENT_TOKEN;

    if (clientToken) {
      datadogLogs.init({
        clientToken,
        datacenter: 'us',
        forwardErrorsToLogs: true,
        sampleRate: 100,
      });
      initGlobalContext();
      initialized = true;
    }
  },

  /**
   * Configure user context
   */
  configureUser: ({ id, email, name }: { id: number; email: string; name: string }) => {
    if (!initialized) return;

    datadogLogs.addLoggerGlobalContext('usr.id', id);
    datadogLogs.addLoggerGlobalContext('usr.email', email);
    datadogLogs.addLoggerGlobalContext('usr.name', name);
  },

  /**
   * Set extra data
   */
  setExtra: ({ companyId, companyName }: { companyId: number; companyName: string }) => {
    if (!initialized) return;

    datadogLogs.addLoggerGlobalContext('usr.company_id', companyId);
    datadogLogs.addLoggerGlobalContext('usr.company_name', companyName);
  },

  /**
   * Clear user context
   */
  clearUser: () => {
    if (!initialized) return;

    initGlobalContext();
  },

  error: (message: string, context: Context) => {
    if (!initialized) return;

    datadogLogs.logger.error(message, context);
  },

  info: (message: string, context: Context) => {
    if (!initialized) return;

    datadogLogs.logger.info(message, context);
  },

  warn: (message: string, context: Context) => {
    if (!initialized) return;

    datadogLogs.logger.warn(message, context);
  },

  debug: (message: string, context: Context) => {
    if (!initialized) return;

    datadogLogs.logger.debug(message, context);
  },
};

export default DatadogLogUtil;
