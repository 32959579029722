import React, { useEffect, useRef } from 'react';
import lottie, { AnimationConfigWithData, AnimationItem } from 'lottie-web';

import styled from 'styled-components';

const LottieContainer = styled.div<{ styles?: BoxSizeStyle }>`
  ${props => props.styles?.width && `width: ${props.styles.width};`}
  ${props => props.styles?.height && `height: ${props.styles.height};`}
`;

type Props = {
  options: Omit<AnimationConfigWithData, 'container'>;
} & Styleable<BoxSizeStyle>;

const Lottie: React.FC<React.PropsWithChildren<Props>> = ({ className, styles, options }) => {
  const lottieContainerRef = useRef<HTMLDivElement>(null);
  const animationInstanceRef = useRef<AnimationItem>();

  useEffect(() => {
    if (lottieContainerRef.current) {
      animationInstanceRef.current?.destroy();

      const nextAnimationInstance = lottie.loadAnimation({
        ...options,
        container: lottieContainerRef.current,
      });

      animationInstanceRef.current = nextAnimationInstance;
    }
  }, [options]);

  return <LottieContainer className={className} styles={styles} ref={lottieContainerRef} />;
};

export default Lottie;
