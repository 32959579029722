import { Container } from '@mantine/core';
import { css } from 'styled-components';

export const ContainerResolver = Container.extend({
  styles: {
    root: {
      boxSizing: 'border-box',
      margin: 0,
      padding: 0,

      '&:hover': {
        backgroundColor: 'red',
      },
    },
  },
});

const stlye = css``;
