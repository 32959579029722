import { translate, translateHTML } from 'utils/LocaleUtil';

import React from 'react';
import { SpenditLogo } from 'components/templates/shared/SpenditLogo';
import { appSelectors } from 'modules/app';
import imageMaintenance1x from 'images/image-maintenance.png';
import imageMaintenance2x from 'images/image-maintenance@2x.png';
import imageMaintenance3x from 'images/image-maintenance@3x.png';
import moment from 'moment';
import styled from 'styled-components';
import { useSelector } from 'react-redux';

const Logo = styled(SpenditLogo)``;

const TimeBox = styled.div`
  padding: 12px 28px 18px;
  margin: 0 auto;
  width: 520px;
  height: 60px;
  border-radius: 10px;
  box-sizing: border-box;
  background-color: #efefef;

  color: #000;
  font-size: 22px;
  font-weight: bold;
  line-height: 1.36;
  letter-spacing: -0.37px;
  text-align: center;
`;

const Paragraph = styled.p`
  color: #333;
  font-size: 20px;
  line-height: 1.5;
  letter-spacing: -0.33px;
`;

const Header = styled.h2`
  color: #000;
  font-size: 50px;
  font-weight: bold;
  letter-spacing: -0.83px;
`;

const MaintenanceImage = styled.img`
  width: 160px;
  height: 160px;
`;

const Box = styled.div`
  text-align: center;

  ${Header}, ${Paragraph}, ${TimeBox} {
    margin-top: 30px;
  }
`;

const Container = styled.div`
  position: relative;
  padding: 330px 50px 50px;
  width: 100%;
  box-sizing: border-box;
  background-color: #fff;
  text-align: center;

  ${Logo} {
    margin-top: 344px;
  }
`;

const Maintenance: React.FC<React.PropsWithChildren<unknown>> = () => {
  const startDate = useSelector(appSelectors.maintenanceStartDate);
  const endDate = useSelector(appSelectors.maintenanceEndDate);

  return (
    <Container>
      <Box>
        <MaintenanceImage
          src="image-src.png"
          srcSet={`${imageMaintenance1x} 1x, ${imageMaintenance2x} 2x, ${imageMaintenance3x} 3x`}
          alt="service under maintenance"
        />
        <Header>{translate('title_service_maintenance')}</Header>
        <Paragraph>{translateHTML('desc_service_maintenance')}</Paragraph>
        {startDate && endDate && (
          <TimeBox>
            {moment(startDate).format(translate('maintenance_dateformat'))} ~{' '}
            {moment(endDate).format(translate('maintenance_dateformat'))}
          </TimeBox>
        )}
      </Box>
      <Logo width={120} height={32} black />
    </Container>
  );
};

export default Maintenance;
