import { ActionType, createReducer } from 'typesafe-actions';
import { AsyncTask, LegacyDownloadTask, Task, isAsyncTask, isLegacyDownloadTask } from './types';
import {
  CLEAR,
  DEQUEUE,
  DEQUEUE_DOWNLOAD,
  DOWNLOAD_FAILED,
  DOWNLOAD_SUCCESS,
  ENQUEUE,
  ENQUEUE_DOWNLOAD,
  HIDE_CARD,
  HIDE_DOWNLOAD,
  START_DOWNLOAD,
  UPDATE,
  downloadActions,
  taskListActions,
} from './actions';

import { produce } from 'immer';

type ReducerState = {
  taskQueue: Task[];
};

const initialState: ReducerState = {
  taskQueue: [],
};

export const taskListReducer = createReducer<
  ReducerState,
  ActionType<typeof downloadActions> | ActionType<typeof taskListActions>
>(initialState, {
  [ENQUEUE_DOWNLOAD]: (state, { payload }) =>
    produce(state, draft => {
      draft.taskQueue.push({
        ...payload,
        animation: 'fadeIn',
        status: 'waiting',
      });
    }),
  [START_DOWNLOAD]: (state, { payload }) =>
    produce(state, draft => {
      const found = draft.taskQueue.find(task => task.id === payload.id);
      if (found) {
        found.status = 'downloading';
      }
    }),
  [DOWNLOAD_SUCCESS]: (state, { payload }) =>
    produce(state, draft => {
      const found = draft.taskQueue.find(task => task.id === payload.id) as LegacyDownloadTask | AsyncTask;
      if (isLegacyDownloadTask(found)) {
        found.task.fileURL = payload.fileURL;
        found.status = 'success';
      }
    }),
  [DOWNLOAD_FAILED]: (state, { payload }) =>
    produce(state, draft => {
      const found = draft.taskQueue.find(task => task.id === payload.id);
      if (found) {
        found.status = 'failed';
      }
    }),
  [HIDE_DOWNLOAD]: (state, { payload }) =>
    produce(state, draft => {
      const found = draft.taskQueue.find(task => task.id === payload.id);
      if (found) {
        found.animation = 'fadeOut';
      }
    }),
  [DEQUEUE_DOWNLOAD]: (state, { payload }) =>
    produce(state, draft => {
      const index = draft.taskQueue.findIndex(task => task.id === payload.id);
      draft.taskQueue.splice(index, 1);
    }),

  [ENQUEUE]: (state, { payload }) =>
    produce(state, draft => {
      draft.taskQueue.push({
        ...payload,
        animation: 'fadeIn',
      });
    }),
  [HIDE_CARD]: (state, { payload }) =>
    produce(state, draft => {
      const found = draft.taskQueue.find(task => task.id === payload.id);
      if (found) {
        found.animation = 'fadeOut';
      }
    }),
  [DEQUEUE]: (state, { payload }) =>
    produce(state, draft => {
      const index = draft.taskQueue.findIndex(task => task.id === payload.id);
      draft.taskQueue.splice(index, 1);
    }),
  [UPDATE]: (state, { payload }) =>
    produce(state, draft => {
      const found = draft.taskQueue.find(task => task.id === payload.id) as LegacyDownloadTask | AsyncTask;

      if (isAsyncTask(found)) {
        found.status = payload.status;
        found.icon = payload.icon;

        if (payload.info.progressCount) {
          found.info.progressCount = payload.info.progressCount;
        }
        if (payload.info.totalCount) {
          found.info.totalCount = payload.info.totalCount;
        }
        if (payload.info.failedMessage) {
          found.info.failedMessage = payload.info.failedMessage;
        }
        // 카드를 가린경우 기존 Task가 없기에 새로 생성
      } else if (payload.status === 'success') {
        draft.taskQueue.push({
          id: payload.id,
          type: 'upload',
          status: payload.status,
          animation: 'fadeIn',
          info: {
            ...payload.info,
          },
        });
      }
    }),

  [CLEAR]: () => initialState,
});
