import { END, eventChannel } from 'redux-saga';

import firebase from 'firebase/app';

export const FIREBASE_APP_NAME = process.env.REACT_APP_FIREBASE_APP_NAME;

export function initializeFirebase() {
  firebase.initializeApp(
    {
      apiKey: process.env.REACT_APP_FIREBASE_API_KEY,
      authDomain: process.env.REACT_APP_FIREBASE_AUTH_DOMAIN,
      databaseURL: process.env.REACT_APP_FIREBASE_DATABASE_URL,
      projectId: process.env.REACT_APP_FIREBASE_PROJECT_ID,
      storageBucket: process.env.REACT_APP_FIREBASE_STORAGE_BUCKET,
      messagingSenderId: process.env.REACT_APP_FIREBASE_MESSAGING_SENDER_ID,
      appId: process.env.REACT_APP_FIREBASE_APP_ID,
      measurementId: process.env.REACT_APP_FIREBASE_MEASUREMENT_ID,
    },
    FIREBASE_APP_NAME
  );
}

export function hasApp() {
  return firebase.apps.find(app => app.name === FIREBASE_APP_NAME) !== undefined;
}

export async function signOutFirebase() {
  return firebase.app(FIREBASE_APP_NAME).auth().signOut();
}

export async function setPersistence() {
  return firebase.app(FIREBASE_APP_NAME).auth().setPersistence(firebase.auth.Auth.Persistence.SESSION);
}

export async function signInWithCustomToken(token: string) {
  return firebase.app(FIREBASE_APP_NAME).auth().signInWithCustomToken(token);
}

export function createAuthStateChannel() {
  return eventChannel(emitter => {
    const unsubscribe = firebase
      .app(FIREBASE_APP_NAME)
      .auth()
      .onAuthStateChanged(
        user => {
          emitter({ user });
        },
        error => {
          emitter({ error });
        }
      );

    return unsubscribe;
  });
}

/**
 * {refPath}의 firebase.database에서 {eventType} 이벤트를 받아서 emit하는 eventChannel 생성 및 반환
 */
export function createChildEventChannel(eventType: firebase.database.EventType, refPath: string) {
  return eventChannel(emitter => {
    const unsubscribe = firebase
      .app(FIREBASE_APP_NAME)
      .auth()
      .onAuthStateChanged(user => {
        if (!user) {
          // Sign Out시 channel close
          emitter(END);
        }
      });
    const ref = firebase.app(FIREBASE_APP_NAME).database().ref(refPath);

    const handler = (snapshot: firebase.database.DataSnapshot) => {
      emitter(snapshot.val());
    };

    ref.on(eventType, handler);

    return () => {
      unsubscribe();
      ref.off(eventType, handler);
    };
  });
}
