import React, { ChangeEvent, useEffect, useState } from 'react';
import styled, { css } from 'styled-components';

import { Tooltip } from '@N/view/components/Tooltip';
import colors from 'theme/colors';

const StyledInput = styled.input.attrs(props => ({
  type: props.type || 'text',
}))<InputStyleProps>`
  display: inline-block;
  line-height: 1;
  padding: ${props => props.styles?.padding || '6.5px 15px'};
  ${props => props.styles?.paddingLeft && `padding-left: ${props.styles.paddingLeft};`}
  ${props => props.styles?.width && `width: ${props.styles?.width};`}
  ${props => props.styles?.height && `height: ${props.styles?.height};`}
  ${props => props.styles?.boxSizing && `box-sizing: ${props.styles?.boxSizing};`}
  border: ${props => props.styles?.border || 'solid 1px #d4d4d4'};
  border-radius: ${props => props.styles?.borderRadius || '6px'};
  box-shadow: ${props => {
    if (props.hasError) {
      return '0 0 0 2px #ff7b7b';
    }
    return props.styles?.boxShadow || 'none';
  }};
  vertical-align: middle;

  ${props => props.styles?.backgroundColor && `background-color: ${props.styles?.backgroundColor};`}
  color: ${props => props.styles?.color || '#1e1e1e'};

  font-size: ${props => props.styles?.fontSize || '13px'};
  font-weight: ${props => props.styles?.fontWeight || 'normal'};
  ${props => props.styles?.letterSpacing && `letter-spacing: ${props.styles?.letterSpacing};`}
  text-align: ${props => props.styles?.textAlign || 'left'};

  ${({ styles }) =>
    styles?.onHover?.boxShadow &&
    css`
      &:hover {
        box-shadow: ${styles.onHover.boxShadow};
      }
    `}

  &:focus {
    border: ${props => props.styles?.onFocus?.border || '1px solid #b5d3ff'};
    box-shadow: ${props => {
      if (props.hasError) {
        return '0 0 0 2px #ff7b7b';
      }
      return props.styles?.onFocus?.boxShadow || '0 0 0px 1px #b5d3ff';
    }};
    ${props => props.styles?.onFocus?.backgroundColor && `background-color: ${props.styles?.onFocus.backgroundColor};`}
    ${props => props.styles?.onFocus?.fontWeight && `font-weight: ${props.styles?.onFocus.fontWeight};`}
  }
  &:disabled {
    background-color: ${props => props.styles?.backgroundColor || '#f9f9f9'};
    color: ${props => props.styles?.color || colors.grey[400]};
    cursor: not-allowed;
  }

  &::placeholder {
    color: #b7b7b7;
  }
`;

const InputComponent: React.FC<React.PropsWithChildren<InputProps>> = ({
  value,
  validators = [],
  onChange,
  onValidationError,
  onValueChange,
  onInputElLoad,
  className,
  styles,
  ...rest
}) => {
  const [inputEl, setInputEl] = useState<HTMLInputElement>();
  const [opened, setOpened] = useState(false);
  const [isOverflow, setIsOverflow] = useState(false);

  const validate = (nextValue: string, event: ChangeEvent<HTMLInputElement>) => {
    const errors = validators.reduce<ValidationErrors>(
      (prev, current) => ({
        ...prev,
        ...(current(nextValue) || {}),
      }),
      {}
    );
    const valid = Object.keys(errors).length === 0;

    if (valid) {
      if (onChange) {
        onChange(event);
      }
      if (onValueChange) {
        onValueChange(nextValue);
      }
    } else if (onValidationError) {
      onValidationError(errors);
    }
  };

  const handleInputChange = (event: ChangeEvent<HTMLInputElement>) => {
    const nextValue = event.target.value;
    validate(nextValue, event);
  };

  useEffect(() => {
    const handleKeyDown = (event: KeyboardEvent) => {
      const { key } = event;
      if (key === 'ArrowUp' || key === 'ArrowRight' || key === 'ArrowDown' || key === 'ArrowLeft') {
        event.stopPropagation();
      }
    };

    const handleFocus = () => {
      setOpened(false);
    };
    const handleMouseOver = () => {
      setOpened(true);
    };
    const handleMouseOut = () => {
      setOpened(false);
    };

    const handleResize = () => {
      if (inputEl) {
        setIsOverflow(inputEl.scrollWidth > inputEl.clientWidth);
      }
    };

    if (inputEl) {
      inputEl.addEventListener('keydown', handleKeyDown);
      inputEl.addEventListener('focus', handleFocus);
      inputEl.addEventListener('mouseover', handleMouseOver);
      inputEl.addEventListener('mouseout', handleMouseOut);
      window.addEventListener('resize', handleResize);
    }

    return () => {
      if (inputEl) {
        inputEl.removeEventListener('keydown', handleKeyDown);
        inputEl.removeEventListener('focus', handleFocus);
        inputEl.removeEventListener('mouseover', handleMouseOver);
        inputEl.removeEventListener('mouseout', handleMouseOut);
        window.removeEventListener('resize', handleResize);
      }
    };
  }, [inputEl]);

  useEffect(() => {
    if (inputEl) {
      setIsOverflow(inputEl.scrollWidth > inputEl.clientWidth);
    }
  }, [inputEl, value]);

  return (
    <Tooltip label={value} withArrow={true} opened={isOverflow && opened}>
      <StyledInput
        ref={obj => {
          if (obj) {
            setInputEl(obj);
            if (onInputElLoad) {
              onInputElLoad(obj);
            }
          }
        }}
        className={className}
        value={value ?? ''}
        onChange={handleInputChange}
        styles={styles}
        {...rest}
      />
    </Tooltip>
  );
};

export const InputGroupAddon = styled.button.attrs(attrs => ({
  type: attrs.type || 'button',
}))<InputGroupAddonProps>`
  position: absolute;
  top: 0;
  ${props => props.styles?.left && `left: ${props.styles.left};`}
  right: ${props => props.styles?.right || '1px'};
  display: ${props => props.styles?.display || 'flex'};
  align-items: center;
  justify-content: center;
  padding: 0;
  margin: 0 !important;
  width: ${props => props.styles?.width || '34px'};
  height: ${props => props.styles?.height || '34px'};
  border: ${props => props.styles?.border || '1px solid #d3d3d3'};
  border-right: ${props => props.styles?.borderRight || 0};
  ${props => props.styles?.borderLeft && `border-left: ${props.styles?.borderLeft};`}
  border-top-right-radius: ${props => props.styles?.borderTopRightRadius || '6px'};
  border-bottom-right-radius: ${props => props.styles?.borderBottomRightRadius || '6px'};
  border-bottom-left-radius: ${props => props.styles?.borderBottomLeftRadius || 0};
  border-top-left-radius: ${props => props.styles?.borderTopLeftRadius || 0};
  box-shadow: ${props => props.styles?.boxShadow || 'none'};
  box-sizing: border-box;
  background-color: transparent;
  margin-right: 0px !important;

  &:disabled {
    cursor: not-allowed;
  }
`;

export const InputGroup = styled.div<InputGroupProps>`
  position: relative;
  ${props => props.styles?.width && `width: ${props.styles?.width}`};
  ${props => props.styles?.height && `height: ${props.styles?.height}`};
  ${props => props.styles?.boxSizing && `box-sizing: ${props.styles?.boxSizing}`};
  ${props => props.styles?.borderBottom && `border-bottom: ${props.styles?.borderBottom};`}
  border-radius: ${props => props.styles?.borderRadius || '6px'};
  ${props => props.styles?.borderTopRightRadius && `border-top-right-radius: ${props.styles?.borderTopRightRadius};`};
  ${props =>
    props.styles?.borderBottomRightRadius && `border-bottom-right-radius: ${props.styles?.borderBottomRightRadius};`};
  ${props =>
    props.styles?.borderBottomLeftRadius && `border-bottom-left-radius: ${props.styles?.borderBottomLeftRadius};`};
  ${props => props.styles?.borderTopLeftRadius && `border-top-left-radius: ${props.styles?.borderTopLeftRadius};`};
  background-color: #fff;

  input {
    padding: ${props => props.inputStyle?.padding || '6.5px 34px 6.5px 15px'};
    width: ${props => props.inputStyle?.width || '100%'};
    height: ${props => props.inputStyle?.height || '34px'};
    box-sizing: ${props => props.inputStyle?.boxSizing || `border-box`};
    border: ${props => props.inputStyle?.border || 'solid 1px #d4d4d4'};
    border-radius: ${props => props.inputStyle?.borderRadius || '6px'};
    ${props =>
      props.inputStyle?.borderTopRightRadius && `border-top-right-radius: ${props.inputStyle?.borderTopRightRadius};`};
    ${props =>
      props.inputStyle?.borderBottomRightRadius &&
      `border-bottom-right-radius: ${props.inputStyle?.borderBottomRightRadius};`};
    ${props =>
      props.inputStyle?.borderBottomLeftRadius &&
      `border-bottom-left-radius: ${props.inputStyle?.borderBottomLeftRadius};`};
    ${props =>
      props.inputStyle?.borderTopLeftRadius && `border-top-left-radius: ${props.inputStyle?.borderTopLeftRadius};`};
    vertical-align: middle;
    ${props => props.inputStyle?.backgroundColor && `background-color: ${props.inputStyle?.backgroundColor};`}
    color: ${props => props.inputStyle?.color || '#1e1e1e'};
    font-size: ${props => props.inputStyle?.fontSize || '13px'};
    font-weight: ${props => props.inputStyle?.fontWeight || 'normal'};
    text-align: ${props => props.inputStyle?.textAlign || 'left'};
  }

  input:disabled {
    color: ${({ theme }) => theme.colors.grey[400]};
  }

  input:focus {
    border: ${props => props.inputStyle?.onFocus?.border || '1px solid #d4d4d4 !important'};
    box-shadow: ${props => props.inputStyle?.onFocus?.boxShadow || '0 0 0 1px rgb(181, 211, 255)'};
    ${props =>
      props.inputStyle?.onFocus?.backgroundColor && `background-color: ${props.inputStyle?.onFocus.backgroundColor};`}
  }

  ${props =>
    props.inputStyle?.placeHolderStyle &&
    css`
      input::placeholder {
        text-align: ${props.inputStyle.placeHolderStyle.textAlign || 'left'};
      }
    `}

  & > ${InputGroupAddon} {
    box-shadow: none;
  }
`;

export default styled(InputComponent)``;
