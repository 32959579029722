import { Input, InputWrapper } from '@mantine/core';

/** @desc InputWrapper와 같이 Input의 기본 구성 요소 컴포넌트이면서, 개별적으로 사용될 수 있는 Mantine Component는 별도의 Resolver를 사용해야 단독 사용시 스타일링이 옳바르게 적용됩니다. */
export const InputWrapperResolver = InputWrapper.extend({
  styles: {
    root: {
      width: '100%',
    },
  },
});

export const InputResolver = Input.extend({
  styles: theme => ({
    input: {
      boxSizing: 'border-box',
      ...theme.other.bodyTexts.text4,
    },
  }),
});
