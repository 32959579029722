import { Badge, BadgeFactory, PartialVarsResolver } from '@mantine/core';

const badgeVariablesResolver: PartialVarsResolver<BadgeFactory> = (theme, props) => {
  if (props.size === 'md') {
    return {
      root: {
        '--badge-height': '18px',
        '--badge-padding-x': '10px',
        '--badge-line-height': '18px',
      },
    };
  }

  return { root: {} };
};

export const BadgeResolver = Badge.extend({
  vars: badgeVariablesResolver,
  styles: {
    root: {
      boxSizing: 'border-box',
      lineHeight: 'normal',
      fontWeight: 500,
    },
    label: {
      textTransform: 'none',
    },
  },
});
