import { MaintenanceErrorResponse, axiosActions } from 'modules/axios';
import axios, { AxiosError, AxiosInstance, AxiosRequestConfig, AxiosResponse } from 'axios';
import { isAWSSlowDownErrorResponse, isSpenditErrorResponseData } from 'utils/ErrorHandler';

import type { Context } from '@datadog/browser-core';
import DatadogLogUtil from 'utils/DatadogLogUtil';
import LogRocketUtil from 'utils/LogRocketUtil';
import { SpenditApiVersions } from './shared';
import { Store } from 'redux';
import { fireError } from 'utils/SwalUtil';
import { spenditHistory } from '@N/view/bootstrap/config/configureHistory';
import { v1 as uuidv1 } from 'uuid';

type InvalidSessionErrorResponse = { code: 502; message: string };

export const isLegacyInvalidSessionErrorResponse = (
  response: AxiosResponse
): response is AxiosResponse<InvalidSessionErrorResponse> => response.data?.code === 502;

export const isMaintenanceErrorResponse = (
  payload?: AxiosResponse
): payload is AxiosResponse<MaintenanceErrorResponse> => {
  /**
   * AWS의 S3 url을 이용해 요청하는 경우(putEditorContent) 503 에러가 발생할 수 있음. 이 경우 유지보수중 에러로 처리하지 않음.
   */
  if (isAWSSlowDownErrorResponse(payload)) {
    return false;
  }
  return payload?.status === 503;
};

export type ResponseLogContextType = {
  statusCode: number;
  statusText: string;
  responseURL: string;
  requestId: string;
  responseTimestamp: number;
  duration: number;
  responseData?: Context;
} & Pick<AxiosRequestConfig, 'method' | 'params' | 'url'>;

export const isAxiosResponse = (response: AxiosResponse | AxiosError): response is AxiosResponse => 'data' in response;

export const extractErrorCodeFromAxiosError = (error: AxiosError): number => {
  return error.response.data.error.code;
};

/**
 * 로깅을 위한 컨텍스트 정보 생성
 */
const buildResponseLogContext = (response: AxiosResponse | AxiosError): ResponseLogContextType => {
  const requestId = response.config.headers['Spendit-Request-ID'];
  const requestTimestamp = Number(response.config.headers['Spendit-Request-Timestamp']);
  const { method, params, url } = response.config;
  const { status: statusCode, statusText, responseURL } = response.request;
  const responseTimestamp = Date.now();

  return {
    statusCode,
    statusText,
    responseURL,
    requestId,
    responseTimestamp,
    duration: (responseTimestamp - requestTimestamp) * 1_000_000,
    method,
    params,
    responseData: isAxiosResponse(response) ? response?.data : response.response?.data,
    url,
  };
};

// For HTTP 2.0 Multiplexing
const MAX_REQUESTS_COUNT = 5;
const INTERVAL_MS = 10;

export const configureAxiosInstance = (store: Store, axiosInstance: AxiosInstance) => {
  let pendingRequests = 0;

  axiosInstance.interceptors.request.use(
    axiosRequestConfig => {
      return new Promise(resolve => {
        const { method, params, url, timeout, data } = axiosRequestConfig;

        const interval = setInterval(() => {
          if (pendingRequests < MAX_REQUESTS_COUNT) {
            pendingRequests += 1;
            clearInterval(interval);

            const requestId = uuidv1();
            const requestTimestamp = Date.now();

            DatadogLogUtil.info(`axios.request.onFulfilled - ${method} ${url}`, {
              requestId,
              requestTimestamp,
              method,
              params,
              data,
              url,
            });

            axiosRequestConfig.headers['X-LogRocket-URL'] = LogRocketUtil.getSessionURL();
            axiosRequestConfig.headers['Spendit-Request-ID'] = requestId;
            axiosRequestConfig.headers['Spendit-Request-Timestamp'] = requestTimestamp;
            axiosRequestConfig.timeout = timeout ?? Number(process.env.REACT_APP_DEFAULT_API_TIMEOUT);

            resolve(axiosRequestConfig);
          }
        }, INTERVAL_MS);
      });
    },
    err => {
      DatadogLogUtil.debug(`axios.request.onRejected`, err);

      return Promise.reject(err);
    }
  );

  axiosInstance.interceptors.response.use(
    response => {
      pendingRequests = Math.max(0, pendingRequests - 1);
      const context = buildResponseLogContext(response);
      const msg = `axios.response.onFulfilled - ${context.method} ${context.url} ${context.statusCode} ${context.statusText}`;
      DatadogLogUtil.info(msg, context);
      return response;
    },
    error => {
      pendingRequests = Math.max(0, pendingRequests - 1);
      if (axios.isAxiosError(error)) {
        const context = buildResponseLogContext(error);
        let msg = `axios.response.onRejected - ${context.method} ${context.url} ${context.statusCode} ${context.statusText}`;

        if (isMaintenanceErrorResponse(error.response)) {
          store.dispatch(axiosActions.globalErrorOccurred({ type: 'service_maintenance', data: error.response.data }));
        } else if (error.response) {
          const data = error.response.data;
          Object.assign(context, data);
          if (isSpenditErrorResponseData(data)) {
            if (data.error.code === 40490) {
              spenditHistory.push('/');
            }
          }
        } else if (error.request) {
          const { code, message } = error.toJSON() as { code?: string; message: string };

          msg += ` [${code}] ${message}`;

          if (code === 'ECONNABORTED') {
            // timeout 오류
            fireError('서버로부터 응답이 없습니다.');
          } else {
            // 그 외 (네트워크 오류)
            // fireError(translate('alert_network_disconnected'));
          }
        } else {
          msg += ` ${error.message}`;
          fireError('오류가 발생했습니다. 관리자에게 문의해주세요.');
        }

        DatadogLogUtil.debug(msg, context);
      }

      return Promise.reject(error);
    }
  );
};

export const getUrlWithApiVersion = (version: SpenditApiVersions, ...path: string[]) => {
  const paths = [version, ...path];
  return `/${paths.join('/')}`;
};
