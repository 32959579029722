import styled, { keyframes } from 'styled-components';

import GlobalPortal from 'components/commons/GlobalPortal';
import React from 'react';

const animateRotate = keyframes`
  0% {
    transform: rotate(0);
  }

  100% {
    transform: rotate(360deg);
   }
`;

const LoadingSpinnerContainer = styled.div`
  position: fixed;
  z-index: 10000;
  text-align: center;
  padding-top: 25%;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.01);
`;

const Loader = styled.div`
  margin: 0 auto;
  width: 35px;
  height: 35px;
  border: 8px solid rgba(152, 209, 255, 0.25);
  border-top-color: #98d1ff;
  border-radius: 50%;
  position: relative;
  animation: 1s ${animateRotate} linear infinite;
`;

export const LoadingSpinner: React.FC<React.PropsWithChildren<unknown>> = () => {
  return (
    <GlobalPortal>
      <LoadingSpinnerContainer>
        <Loader />
      </LoadingSpinnerContainer>
    </GlobalPortal>
  );
};

export default LoadingSpinner;
