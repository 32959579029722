import { DEFAULT_THEME, createTheme } from '@mantine/core';
import { InputResolver, InputWrapperResolver } from '@N/view/components/Inputs/resolver';
import { bodyTypography, fontConfig, headingsTypography } from 'theme/headingsTypography';

import { AccordionResolver } from '@N/view/components/Accordion/resolver';
import { AnchoreResolver } from '@N/view/components/Anchor/resolver';
import { BadgeResolver } from '@N/view/components/Badge/resolver';
import { ComboboxResolver } from '@N/view/components/Combobox';
import { ContainerResolver } from '@N/view/components/Container/resolver';
import { DividerResolver } from '@N/view/components/Divder/resolver';
import { FlexResolver } from '@N/view/components/Flex/resolver';
import { MantineThemeColors } from '@mantine/core/lib/core/MantineProvider/theme.types';
import { ModalResolver } from '@N/view/components/Modal';
import { RadioResolver } from '@N/view/components/Radio/resolver';
import colors from 'theme/colors';
import { createRGBAWithHex } from 'utils/Color';

type SpenditColorKeys = keyof typeof colors;
export type SpenditColors = (typeof colors)[SpenditColorKeys];

export type SpenditTheme = {
  colors: typeof colors;
  typography: typeof headingsTypography;
  createRGBAWithHex: typeof createRGBAWithHex;
  mantineColors: MantineThemeColors;
  mantineThemes: typeof mantineTheme;
};

const createMantineColors = () => {
  const result = {};

  Object.keys(colors).forEach((key, index) => {
    if (key === 'grey') {
      result['gray'] = Object.values(colors[key]);
    }
    result[key] = Object.values(colors[key]);
  });

  return result as MantineThemeColors;
};

export const mantineTheme = createTheme({
  ...DEFAULT_THEME,
  ...fontConfig,
  focusRing: 'auto',
  /** @see https://mantine.dev/styles/rem/#rem-units-scaling */
  scale: 1.6,
  white: colors.grey[0],
  black: colors.grey[700],
  cursorType: 'pointer',
  colors: createMantineColors(),
  primaryShade: 5,
  primaryColor: 'blue',
  headings: {
    sizes: headingsTypography,
  },
  radius: {
    xs: '2px',
    sm: '3px',
    md: '4px',
    lg: '6px',
    xl: '10px',
  },
  defaultRadius: 'md',
  spacing: {
    '02': '2px',
    '04': '4px',
    '06': '6px',
    '08': '8px',
    '10': '10px',
    '12': '12px',
    '16': '16px',
    '20': '20px',
    '24': '24px',
    '32': '32px',
    '40': '40px',
    '48': '48px',
    '52': '52px',
    '64': '64px',
    '80': '80px',
    '96': '96px',
    '160': '160px',
  },
  fontSizes: {
    xs: '8px',
    sm: '10px',
    md: '12px',
    lg: '14px',
    xl: '16px',
  },
  shadows: {
    md: `0px 0px 4px 0px ${createRGBAWithHex(colors.grey[700], 0.2)}`,
  },
  other: {
    bodyTexts: bodyTypography,
  },
  components: {
    Modal: ModalResolver,
    Combobox: ComboboxResolver,
    Radio: RadioResolver,
    Input: InputResolver,
    InputWrapper: InputWrapperResolver,
    Badge: BadgeResolver,
    Container: ContainerResolver,
    Anchor: AnchoreResolver,
    Accordion: AccordionResolver,
    Flex: FlexResolver,
    Divider: DividerResolver,
  },
});

export const defaultTheme: SpenditTheme = {
  colors,
  typography: headingsTypography,
  createRGBAWithHex,
  mantineColors: createMantineColors(),
  mantineThemes: mantineTheme,
};

export * from 'theme/LegacyColors';
