type Props = {
  width?: number;
  height?: number;
};

export const IconQuestion: React.FC<React.PropsWithChildren<Props>> = ({ width = 72, height = 72 }) => (
  <svg width={width} height={height} viewBox="0 0 72 72" fill="none" xmlns="http://www.w3.org/2000/svg">
    <circle cx="36" cy="36" r="34" stroke="#BDC9DA" strokeWidth="4" />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M36 52C37.1046 52 38 52.8954 38 54V54.439C38 55.5436 37.1046 56.439 36 56.439C34.8954 56.439 34 55.5436 34 54.439V54C34 52.8954 34.8954 52 36 52Z"
      fill="#BDC9DA"
    />
    <path
      d="M27 26.6319C27 23.2184 29.1391 16.3914 35.913 16.3914C42.687 16.3914 44.529 21.7961 44.8261 24.4984C45.1232 25.9207 44.9152 29.6187 41.7065 33.0322C37.6957 37.2991 35.4674 38.1525 35.913 44.9795"
      stroke="#BDC9DA"
      strokeWidth="4"
      strokeLinecap="round"
    />
  </svg>
);
