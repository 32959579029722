import Fail from 'components/jsons/fail.json';
import Lottie from 'components/commons/Lottie';
import React from 'react';
import Success from 'components/jsons/success.json';
import styled from 'styled-components';

const ResultTableTitleContainer = styled.div`
  flex: 1;
  display: flex;
  flex-direction: row;
  margin: 0 auto 18px;
  justify-content: center;

  > h1 {
    align-items: center;
    display: flex;
    margin: 0 6px;

    font-size: 16px;
    font-weight: bold;

    color: #333333;
  }
`;

type Props = {
  title: string | JSX.Element;
  type: 'success' | 'fail';
};

const ResultTableTitle: React.FC<React.PropsWithChildren<Props>> = ({ title, type = 'success' }) => {
  return (
    <ResultTableTitleContainer>
      <Lottie
        options={{ animationData: type === 'success' ? Success : Fail, loop: false, autoplay: true }}
        styles={{ width: '35px', height: '35px' }}
      />
      <h1>{title}</h1>
    </ResultTableTitleContainer>
  );
};

export default ResultTableTitle;
