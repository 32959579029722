import { SSOLoginType, SignInFormType, SignUpFormType } from './types';
import { createAction, createStandardAction } from 'typesafe-actions';

import { AxiosError } from 'axios';
import { GetMyPolicyListResponse } from 'apis/PolicyAPI';

export type SignInAfterSignUpPayload = {
  sessionToken: string;
};

export type SignInWithTokenPayload = {
  sessionToken: string;
};

export type SignOutPayload = {
  /**
   * deleteSession Request를 하는지 여부 (기본 true)
   */
  withRequest?: boolean;
};

export type GetSessionDataPayload = {
  sessionToken?: string;
};
export type GetSessionDataSuccessPayload = {
  user: SpenditUser;
};

export type GetTokenPayload = SignInFormType | SSOLoginType;

export type GetTokenSuccessPayload = {
  sessionToken: string;
};

export type SignUpSuccessPayload = {
  sessionToken: string;
};

export type SetCurrentUserPayload = {
  user: SpenditUser;
};

export type GetSessionDataFailedPayload = {
  type: 'GetSessionDataFailed';
  error?: unknown;
};
export type GetTokenFailedPayload = {
  type: 'GetTokenFailed';
  error?: unknown;
};

export const GET_TOKEN = 'session/GET_TOKEN';
export const GET_TOKEN_SUCCESS = 'session/GET_TOKEN_SUCCESS';
export const GET_TOKEN_FAILED = 'session/GET_TOKEN_FAILED';

export const GET_SESSION_DATA = 'session/GET_SESSION_DATA';
export const GET_SESSION_DATA_SUCCESS = 'session/GET_SESSION_DATA_SUCCESS';
export const GET_SESSION_DATA_FAILED = 'session/GET_SESSION_DATA_FAILED';

export const SESSION_EXPIRED = 'session/SESSION_EXPIRED';
export const SIGN_IN_AFTER_SIGN_UP = 'session/SIGN_IN_AFTER_SIGN_UP';
export const SIGN_IN_WITH_TOKEN = 'session/SIGN_IN_WITH_TOKEN';
export const SIGN_IN = 'session/SIGN_IN';
export const SIGN_IN_SUCCESS = 'session/SIGN_IN_SUCCESS';
export const SIGN_IN_ERROR = 'session/SIGN_IN_ERROR';
export const SIGN_OUT = 'session/SIGN_OUT';
export const SIGN_OUT_SUCCESS = 'session/SIGN_OUT_SUCCESS';
export const SIGN_UP = 'session/SIGN_UP';
export const SIGN_UP_SUCCESS = 'session/SIGN_UP_SUCCESS';
export const SIGN_UP_FAILED = 'session/SIGN_UP_FAILED';

export const SET_CURRENT_USER = 'session/SET_CURRENT_USER';

export const FETCH_MY_POLICIES = 'session/FETCH_MY_POLICIES';
export const FETCH_MY_POLICIES_SUCCESS = 'session/FETCH_MY_POLICIES_SUCCESS';
export const FETCH_MY_POLICIES_FAILED = 'session/FETCH_MY_POLICIES_FAILED';

export const HIDE_SCRAPING_LOGIN_POPUP = 'session/HIDE_SCRAPING_LOGIN_POPUP';
export const HIDE_SCRAPING_LOGIN_POPUP_SUCCESS = 'session/HIDE_SCRAPING_LOGIN_POPUP_SUCCESS';
export const HIDE_SCRAPING_LOGIN_POPUP_FAILED = 'session/HIDE_SCRAPING_LOGIN_POPUP_FAILED';

export const FETCH_CURRENT_POLICY = 'session/FETCH_CURRENT_POLICY';
export const FETCH_CURRENT_POLICY_SUCCESS = 'session/FETCH_CURRENT_POLICY_SUCCESS';
export const FETCH_CURRENT_POLICY_FAILED = 'session/FETCH_CURRENT_POLICY_FAILED';
export const SET_CURRENT_POLICY = 'session/SET_CURRENT_POLICY';
export const CHANGE_CURRENT_POLICY = 'session/CHANGE_CURRENT_POLICY';
export const UPDATE_CURRENT_POLICY = 'session/UPDATE_CURRENT_POLICY';

export const UPDATE_COMPANY_NAME = 'session/UPDATE_COMPANY_NAME';

export const sessionActions = {
  getSessionData: createStandardAction(GET_SESSION_DATA)<GetSessionDataPayload>(),
  getSessionDataSuccess: createStandardAction(GET_SESSION_DATA_SUCCESS)<GetSessionDataSuccessPayload>(),
  getSessionDataFailed: createStandardAction(GET_SESSION_DATA_FAILED)<GetSessionDataFailedPayload>(),
  getToken: createStandardAction(GET_TOKEN)<GetTokenPayload>(),
  getTokenSuccess: createStandardAction(GET_TOKEN_SUCCESS)<GetTokenSuccessPayload>(),
  getTokenFailed: createStandardAction(GET_TOKEN_FAILED)<GetTokenFailedPayload>(),
  sessionExpired: createStandardAction(SESSION_EXPIRED)(),
  signInAfterSignUp: createStandardAction(SIGN_IN_AFTER_SIGN_UP)<SignInAfterSignUpPayload>(),
  signInWithToken: createStandardAction(SIGN_IN_WITH_TOKEN)<SignInWithTokenPayload>(),
  signIn: createStandardAction(SIGN_IN)<SignInFormType | SSOLoginType>(),
  signInSuccess: createStandardAction(SIGN_IN_SUCCESS)<{ user: SpenditUser; groups: CompanyGroup[] }>(),
  signInError: createStandardAction(SIGN_IN_ERROR)<unknown>(),
  signOut: createAction(SIGN_OUT, action => {
    return (payload?: SignOutPayload) => action(payload);
  }),
  signOutSuccess: createStandardAction(SIGN_OUT_SUCCESS)(),
  signUp: createStandardAction(SIGN_UP)<SignUpFormType>(),
  signUpSuccess: createStandardAction(SIGN_UP_SUCCESS)<SignUpSuccessPayload>(),
  signUpFailed: createStandardAction(SIGN_UP_FAILED)<unknown>(),
  setCurrentUser: createStandardAction(SET_CURRENT_USER)<SetCurrentUserPayload>(),
  fetchMyPolicies: createStandardAction(FETCH_MY_POLICIES)(),
  fetchMyPoliciesSuccess: createStandardAction(FETCH_MY_POLICIES_SUCCESS)<GetMyPolicyListResponse>(),
  fetchMyPoliciesFailed: createStandardAction(FETCH_MY_POLICIES_FAILED)<AxiosError>(),
  hideScrapingLoginPopup: createStandardAction(HIDE_SCRAPING_LOGIN_POPUP)(),
  hideScrapingLoginPopupSuccess: createStandardAction(HIDE_SCRAPING_LOGIN_POPUP_SUCCESS)(),
  hideScrapingLoginPopupFailed: createStandardAction(HIDE_SCRAPING_LOGIN_POPUP_FAILED)<AxiosError>(),
  fetchCurrentPolicy: createStandardAction(FETCH_CURRENT_POLICY)(),
  fetchCurrentPolicySuccess: createStandardAction(FETCH_CURRENT_POLICY_SUCCESS)<GetPolicyResponse>(),
  fetchCurrentPolicyFailed: createStandardAction(FETCH_CURRENT_POLICY_FAILED)<AxiosError>(),
  setCurrentPolicy: createStandardAction(SET_CURRENT_POLICY)<SessionCurrentPolicy>(),
  changeCurrentPolicy: createStandardAction(CHANGE_CURRENT_POLICY)<{
    nextPolicy: SessionCurrentPolicy;
    silence?: boolean;
  }>(),
  updateCurrentPolicy: createStandardAction(UPDATE_CURRENT_POLICY)<{ name: string }>(),
  updateCompanyName: createStandardAction(UPDATE_COMPANY_NAME)<string | null>(),
};
