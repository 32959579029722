import Input, { InputGroup, InputGroupAddon } from 'components/commons/Input';
import React, { useContext, useState } from 'react';

import { Icon } from 'components/atoms/Icon';
import IconSearch from 'images/icons/search-icon.png';
import { SelectBoxContext } from 'components/commons/SelectBox/SelectBoxContext';
import styled from 'styled-components';

type SelectBoxSearchInput = {
  placeholder?: string;
  padding?: string;
  maxLength?: number;
} & Styleable;

const StyledInputGroup = styled(InputGroup).attrs(() => ({
  styles: {
    borderBottom: '1px solid #002855',
    borderRadius: '0',
    borderTopRightRadius: '6px',
    borderTopLeftRadius: '6px',
  },
  inputStyle: {
    padding: '6.5px 40px 6.5px 12px !important',
    height: '40px',
    border: '0',
    borderRadius: '0',
    borderTopRightRadius: '6px',
    borderTopLeftRadius: '6px',
    backgroundColor: '#fff',
    fontSize: '12px',
    lineHeight: '20px',
    onFocus: {
      border: '0 !important',
      boxShadow: 'none',
    },
  },
}))``;

const StyledInputGroupAddon = styled(InputGroupAddon).attrs(() => ({
  styles: {
    border: '0',
    boxShadow: 'none',
  },
}))``;

const SelectBoxSearchInputContainer = styled.li`
  & > ${StyledInputGroup} > button {
    width: 40px !important;
    height: 40px !important;
  }
`;

export const SelectBoxSearchInput: React.FC<React.PropsWithChildren<SelectBoxSearchInput>> = ({
  className,
  placeholder,
  padding,
  maxLength,
}) => {
  const [value, setValue] = useState('');
  const { onSearch } = useContext(SelectBoxContext);

  const handleValueChange = (nextValue: string) => {
    setValue(nextValue);
    if (onSearch) {
      onSearch(nextValue);
    }
  };

  return (
    <SelectBoxSearchInputContainer>
      <StyledInputGroup className={className}>
        <Input
          value={value}
          autoFocus
          onValueChange={handleValueChange}
          placeholder={placeholder}
          styles={{ padding, border: '0 !important', boxShadow: 'none !important' }}
          maxLength={maxLength}
        />
        <StyledInputGroupAddon>
          <Icon url={IconSearch} width={14} height={14} />
        </StyledInputGroupAddon>
      </StyledInputGroup>
    </SelectBoxSearchInputContainer>
  );
};
