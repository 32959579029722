import styled, { css } from 'styled-components';

import { Icon } from 'components/atoms/Icon';
import { NavLink } from 'react-router-dom';
import React from 'react';

export const PAGE_BACKGROUND_COLOR = '#f7f8fA';

type FlexColumnProps = {
  display?: string;
  flexDirection?: FlexDirection;
  justifyContent?: FlexJustifyContent;
  alignItems?: FlexAlignItems;
  flex?: string;
  flexGrow?: string;
  flexBasis?: string;
  columnWidth?: string;
  columnHeight?: string;
  padding?: string;
  columnGap?: number;
  rowGap?: number;
};

export const FlexColumn = styled.div<FlexColumnProps>`
  display: ${props => props.display || 'flex'};
  flex: ${props => props.flex || 1};
  ${props => props.flexGrow && `flex-grow: ${props.flexGrow};`}
  ${props => props.flexBasis && `flex-basis: ${props.flexBasis};`}
  flex-direction: ${props => props.flexDirection || 'row'};
  justify-content: ${props => props.justifyContent || 'center'};
  align-items: ${props => props.alignItems || 'center'};
  ${props => props.columnWidth && `width: ${props.columnWidth};`}
  ${props => props.columnHeight && `height: ${props.columnHeight};`}
  ${props => props.padding && `padding: ${props.padding};`}
  ${props => props.columnGap && `column-gap: ${props.columnGap}px;`}
  ${props => props.rowGap && `row-gap: ${props.rowGap}px;`}
  min-width: 0;
`;

export type FlexRowProps = {
  flexDirection?: FlexDirection;
  justifyContent?: FlexJustifyContent;
  alignItems?: FlexAlignItems;
  flex?: string;
  flexWrap?: FlexWrap;
  flexBasis?: string;
  rowWidth?: string;
  rowHeight?: string;
  padding?: string;
  columnGap?: number;
  rowGap?: number;
  position?: React.CSSProperties['position'];
  left?: React.CSSProperties['left'];
  right?: React.CSSProperties['right'];
  top?: React.CSSProperties['top'];
  bottom?: React.CSSProperties['bottom'];
};

export const FlexRow = styled.div<FlexRowProps>`
  display: flex;
  flex: ${props => props.flex || 'none'};
  flex-direction: ${props => props.flexDirection || 'row'};
  ${props => props.flexBasis && `flex-basis: ${props.flexBasis};`}
  ${props => props.flexWrap && `flex-wrap: ${props.flexWrap};`}
  justify-content: ${props => props.justifyContent || 'flex-end'};
  align-items: ${props => props.alignItems || 'flex-end'};
  ${props => props.position && `position: ${props.position};`}
  ${props => props.left && `left: ${props.left};`}
  ${props => props.right && `right: ${props.right};`}
  ${props => props.top && `top: ${props.top};`}
  ${props => props.bottom && `bottom: ${props.bottom};`}
  ${props => props.rowWidth && `width: ${props.rowWidth};`}
  ${props => props.rowHeight && `height: ${props.rowHeight};`}
  ${props => props.padding && `padding: ${props.padding};`}
  ${props => props.columnGap && `column-gap: ${props.columnGap}px;`}
  ${props => props.rowGap && `row-gap: ${props.rowGap}px;`}
    min-width: 0;
`;

/**
 * Page Title
 * @description <Page />의 자식 컴포넌트로 사용
 * @example
 * <Page>
 *  <PageTitle>Title</PageTitle>
 * </Page>
 * to
 * <section>
 *  <h2>Title</h2>
 * </section>
 */
export const PageTitle = styled.h2`
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 6px;
  padding: 11px 0;
  width: 100%;
  height: 36px;
  color: #022855;
  background-color: #e6eaee;
  box-sizing: border-box;
  font-size: 14px;
  font-weight: 700;
  text-align: center;
  transition: 0s !important;
  line-height: 14px;
`;

/**
 * Page Menu
 * @description <Page />의 자식 컴포넌트로 사용
 * @example
 * <Page>
 *  <PageMenu>...</PageMenu>
 * </Page>
 * to
 * <section>
 *  <ul>...</ul>
 * </section>
 */
export const PageMenu = styled.ul`
  box-sizing: border-box;
  width: 100%;
  min-height: 39px;
  background-color: #fff;
  border-bottom: 1px solid #e8e8e8;
  box-sizing: border-box;
  text-align: center;
  white-space: nowrap;
  overflow-x: auto;
  overflow-y: hidden;
  ::-webkit-scrollbar {
    display: none;
  }
  z-index: 100;
`;

const MenuItem: React.FC<
  React.PropsWithChildren<{
    to: string;
    className?: string;
    onlyActiveOnIndex?: boolean;
    onClick?(event: React.MouseEvent): void;
  }>
> = ({ to, className, onlyActiveOnIndex, onClick, children }) => (
  <li className={className}>
    <NavLink to={to} activeClassName="selected" exact={onlyActiveOnIndex} onClick={e => onClick && onClick(e)}>
      {children}
    </NavLink>
  </li>
);

/**
 * Page Menu Item
 * @description <PageMenu />의 자식 컴포넌트로 사용
 * @example
 * <Page>
 *  <PageMenu>
 *    <PageMenuItem to="/company" className="test">사원</PageMenuItem>
 *  </PageMenu>
 * </Page>
 * to
 * <section>
 *  <ul>
 *    <li class="test"><a href="/company">사원</a></li>
 *  </ul>
 * </section>
 */
export const PageMenuItem = styled(MenuItem)`
  display: inline-block;
  box-sizing: border-box;
  padding: 0 18px;
  a {
    position: relative;
    display: block;
    padding: 11px 0;
    border-bottom: 1px solid #fff;
    color: #333;
    cursor: pointer;
    font-size: 14px;
    line-height: 14px;
    font-weight: 700;
    text-decoration: none;
  }
  a.selected,
  a:hover {
    border-bottom: 2px solid #047cde;
    color: #047cde;
  }
`;

export const BetaIcon = styled(Icon)`
  position: absolute;
  top: 11px;
  right: -21px;
`;

type BodyType = 'small' | 'medium' | 'large';

export const PageContent = styled.div`
  margin: 0 auto;
  padding: 30px 0;
`;

const getBodyWidthCssByType = (bodyType?: BodyType, padding?: string, maxWidth?: string, boxSizing?: BoxSizingType) => {
  switch (bodyType) {
    case 'small':
      return css`
        width: 100%;

        ${PageContent} {
          min-width: 1024px;
          max-width: 1024px;
        }
      `;
    case 'medium':
      return css`
        width: 100%;

        ${PageContent} {
          min-width: 1024px;
          max-width: 1600px;
        }
      `;
    case 'large':
      return css`
        width: 100%;

        ${PageContent} {
          min-width: 1024px;
          max-width: 1920px;
        }
      `;
    default:
      return css`
        padding: ${padding || '0 35px'};
        ${maxWidth && `max-width: ${maxWidth};`}
        width: 100%;
        box-sizing: ${boxSizing || 'border-box'};
      `;
  }
};

type PageBodyProps = {
  display?: string;
  padding?: string;
  paddingBottom?: number;
  maxWidth?: string;
  boxSizing?: BoxSizingType;
  backgroundColor?: string;
  bodyType?: BodyType;
};

/**
 * Page Body
 * @example
 * <Page>
 *  <PageBody></PageBody>
 * </Page>
 * to
 * <section>
 *  <div></div>
 * </section>
 */
export const PageBody = styled.div<PageBodyProps>`
  position: relative;

  width: 100%;
  box-sizing: border-box;
  display: ${props => props.display || 'block'};
  flex: 1;
  overflow-y: auto;
  background-color: ${props => props.backgroundColor || PAGE_BACKGROUND_COLOR};
  font-size: 14px;
  transition: 0s;

  ${props => getBodyWidthCssByType(props.bodyType, props.padding, props.maxWidth, props.boxSizing)}
  ${props => props.paddingBottom && `padding-bottom: ${props.paddingBottom}px;`}
`;

export const ContentWrap = styled.div<{ styles?: BoxStyle }>`
  margin: 0 auto;
  ${props => props.styles?.padding && `padding: ${props.styles.padding};`}
  width: 100%;
  height: ${props => props.styles?.height ?? 'auto'};
  max-width: 1600px;
`;

export const SectionToolbar = styled.div<{ styles?: BoxStyle }>`
  left: 0;
  padding: ${props => props.styles?.padding || '20px 44px 20px'};
  width: 100%;
  background-color: ${props => props.styles?.backgroundColor || PAGE_BACKGROUND_COLOR};
  border-bottom: ${props => props.styles?.borderBottom || '1px solid #efefef'};
  box-sizing: border-box;
  z-index: ${props => props.styles?.zIndex || 300};
  transition: left 0.2s ease-in-out, width 0.2s ease-in-out;

  .button-list {
    position: relative;
  }
`;

export const SectionContentList = styled.div`
  display: flex;
  flex-direction: column;
  max-width: 100%;
  height: 100%;
  box-sizing: border-box;
`;

export const LeftPane = styled.div<{ styles?: BoxStyle }>`
  display: ${props => props.styles?.display || 'block'};
  ${props => props.styles?.flexDirection && `flex-direction: ${props.styles.flexDirection};`}
  width: ${props => props.styles?.width || '240px'};
  height: 100%;
  overflow-y: ${props => props.styles?.overflowY || 'auto'};
  border-right: ${props => props.styles?.borderRight || '1px solid #ddd'};
  box-sizing: border-box;
  background-color: ${props => props.styles?.backgroundColor || '#fafafa'};
  z-index: 301;
`;

export const RightPane = styled.div`
  flex: 1;
  height: 100%;
  overflow-y: auto;
`;

export const SplitView = styled.div<{ styles?: BoxStyle }>`
  display: flex;
  ${props => props.styles?.flex && `flex: ${props.styles.flex};`}
  width: ${props => props.styles?.width || '100%'};
  height: ${props => props.styles?.height || '100%'};
  ${props => props.styles?.overflow && `overflow: ${props.styles.overflow};`}

  @media print {
    height: auto;
  }
`;

/**
 * Page
 * @example
 * <Page></Page>
 * to
 * <section></section>
 */
export const Page = styled.section`
  display: flex;
  flex-direction: column;
  background-color: #f7f8fa;

  ${PageMenu} + ${PageBody} {
    margin: 0 auto;
  }
  ${PageBody} {
    margin: 0 auto;
  }
`;

export const Aside = styled.aside`
  z-index: 301;
  width: 240px;
  height: 100%;
  background-color: #fff;
  box-shadow: 0px 0px 4px 0px rgba(33, 33, 33, 0.2);
`;

const StyledPage = styled.div`
  display: flex;
  height: 100%;

  @media print {
    height: auto;
  }
`;

const StyledContainer = styled.div`
  display: flex;
  position: relative;
  height: 100% !important;
  width: 100%;
  box-sizing: border-box;

  ${Page} {
    position: relative;
    width: 100%;
    height: 100%;
    overflow-x: auto;
    overflow-y: auto;
  }

  @media (max-width: 999px) {
    ${Aside} {
      display: none;
    }
    ${Aside} + ${Page} {
      left: 0;
      width: 100%;
    }
    ${Aside} + ${Page} ${SectionToolbar} {
      left: 0;
      width: 100%;
    }
  }

  @media print {
    padding-top: 0;
    height: auto !important;

    ${Page} {
      height: auto;
      overflow-y: visible;
    }
  }
`;

/**
 * @deprecated
 * 기본 Layout 컴포넌트
 * @param id Element ID Attribute
 * @example
 * <Layout id="feed">
 *  ...
 * </Layout>
 * to
 * <div id="feed">
 *  <div>
 *    ...
 *  </div>
 * </div>
 */
export const Layout: React.FC<React.PropsWithChildren<{ id?: string; className?: string }>> = ({
  id,
  className,
  children,
}) => {
  return (
    <StyledPage id={id} className={className} data-testid={id}>
      <StyledContainer className="spendit-container">{children}</StyledContainer>
    </StyledPage>
  );
};
