import { CancelTokenSource } from 'axios';

export type DownloadTask<TDownloadRequestFn extends TRequestFn = any> = {
  id: string;
  fileName: string;
  fileExtension: string;
  description?: string;
  request: {
    func: TDownloadRequestFn;
    args: Parameters<TDownloadRequestFn>;
  };
  cancellation: CancelTokenSource;
  fileURL?: string;
};

export type TaskStatus = 'waiting' | 'in-progress' | 'success' | 'failed';
export type TaskType = 'upload' | 'download';
export type TaskAnimationType = 'fadeIn' | 'fadeOut';

export type LegacyDownloadTask = {
  id: string;
  type: 'legacy';
  status: TaskStatus | 'downloading';
  icon?: React.ReactNode;
  animation?: TaskAnimationType;
  task: DownloadTask<TRequestFn>;
};

export type AsyncTask = {
  id: string;
  type: TaskType;
  status: TaskStatus;
  icon?: React.ReactNode | null;
  animation?: TaskAnimationType;
  info: {
    taskName: string;
    progressCount?: number;
    totalCount?: number;
    failedMessage?: string | null;
    closeable?: boolean;
  };
};

export type Task = AsyncTask | LegacyDownloadTask;

export const isLegacyDownloadTask = (task?: Task): task is LegacyDownloadTask => task?.type === 'legacy';

export const isAsyncTask = (task?: Task): task is AsyncTask => task !== undefined && task.type !== 'legacy';
