import styled from 'styled-components';

export const ButtonWrapper = styled.div`
  display: flex;
  justify-content: center;
  border-top: 1px solid ${props => props.theme.colors.grey[300]};
  height: 44px;
  background: #f2f2f7;
  backdrop-filter: blur(27.182817459106445px);

  & button {
    border: none;
    width: 100%;
    color: #0a7aff;
    font-feature-settings: 'liga' off, 'clig' off;
    font-family: 'SF Pro Text', sans-serif;
    font-size: 17px;
    font-style: normal;
    font-weight: 400;
    line-height: 22px; /* 129.412% */
    letter-spacing: -0.408px;
    background-color: transparent;
  }

  & button:nth-child(2) {
    border-left: 1px solid ${props => props.theme.colors.grey[300]};
  }
`;

export const ContentWrapper = styled.div`
  padding: 19px 16px;
  color: #000;
  text-align: center;
  font-feature-settings: 'liga' off, 'clig' off;
  font-family: 'SF Pro Text', sans-serif;
  font-size: 13px;
  font-style: normal;
  font-weight: 400;
  line-height: 18px; /* 138.462% */
  letter-spacing: -0.078px;
  background: #f2f2f7;
  backdrop-filter: blur(27.182817459106445px);
`;
