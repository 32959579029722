import { Redirect, Route, RouteProps } from 'react-router-dom';
import { paths, routerSelector } from 'modules/router';

import React from 'react';
import { selectConnectOption } from 'modules/connect';
import { sessionSelectors } from 'modules/session/selectors';
import { useAuthService } from '@N/service/inbound/Auth/adapter';
import { useAuthenticateApplication } from '@N/application/Auth/Authenticate';
import { useSelector } from 'react-redux';

const RouteWithoutAuth: React.FC<React.PropsWithChildren<RouteProps>> = ({ children, ...rest }) => {
  const isLoggedIn = useSelector(sessionSelectors.isLoggedIn);
  const connectOption = useSelector(selectConnectOption);
  const previousPath = useSelector(routerSelector.previousPath);
  const AuthService = useAuthService();
  const Authenticate = useAuthenticateApplication();

  return (
    <Route
      {...rest}
      render={({ location }) => {
        // 로그인된 상태에서 SSO 로그인 요청이 들어온 경우, SSO로 로그인을 시도하기 위해 로그아웃 처리한다
        if (isLoggedIn && AuthService.isSSOLoginRequest()) {
          Authenticate.handleSignOut();
          return;
        }

        if (!isLoggedIn) {
          return children;
        }

        let nextPath: string = paths.root;

        if (connectOption) {
          // Slack 연동중인 경우 로그인 이 후 다시 연동 flow로 돌아간다
          nextPath = paths.connect;
        } else if (previousPath) {
          // 이전 path가 존재하는 경우 이전 path로 redirect
          nextPath = previousPath;
        }

        return (
          <Redirect
            to={{
              pathname: nextPath,
              state: { from: location },
            }}
          />
        );
      }}
    />
  );
};

export default RouteWithoutAuth;
