import { REACT_APP_API_URL, REACT_APP_OPEN_API_URL } from 'utils/EnvUtil';

import { AxiosController } from 'apis/AxiosController';
import AxiosInstanceManager from 'apis/instance/InstanceManager';
import { AxiosRequestConfig } from 'axios';
import InterceptorManager from 'apis/interceptor/InterceptorManager';
import { Store } from 'redux';
import { paramsSerializer } from 'apis/API';
import requestPipeline from 'apis/interceptor/request';
import responsePipeline from 'apis/interceptor/response';

/**
 * @see https://spendit.atlassian.net/wiki/spaces/FE/pages/3074949443/API+Client+Architecture
 *
 * @desc  1. Axios Instance는 고유의 키를 통해 관리됩니다. 인터셉터 추가, config 변경 등의 작업시 항상 AxiosController의 메서드와 인스턴스 키값을 통해 안전하게 조작할 수 있도록 구현헀습니다.
 *        2. AxiosController는 AxiosInstanceManager와 InstanceManager를 주입받아야 합니다.
 *        3. 해당 모듈에서 Axios Intance를 등록(생성)하고 인터셉터 파이프라인과 글로벌 config를 주입합니다.
 *        4. API의 호출 시점은 항상 해당 모듈의 initAxiosConfig 함수의 실행이 완료된 이후가 되어야합니다.
 *        5. init에 필요한 의존성을 가져오기 위해서는 initAxiosConfig 함수의 파라미터 타입을 수정합니다.
 *        6. 추가적인 인스턴스가 필요한 상황일 경우 registerInstance()를 활용하면 됩니다.
 *        7. initPipeline 함수를 통해 response, request 모듈의 파이프라인을 자동으로 모두 주입합니다. 필요한 경우 APIController의 eject 메서드를 활용하여 특정 인스턴스에서 특정 인터셉터만 제거할 수 있습니다.
 */

export const BASE_INSTANCE_KEY = 'base';
export const OPEN_API_INSTANCE_KEY = 'open_api';

export const DEFAULT_PIPE_CONFIG: AxiosRequestConfig['pipe'] = {
  requestId: undefined,
  requestTimeStamp: undefined,
  pendingRequests: undefined,
  useCamelize: undefined,
};

const APIController = new AxiosController(new AxiosInstanceManager(), new InterceptorManager());

//파이프라인 구성하는 인터셉터들 등록
const initRequestPipeline = () => {
  Array.from(Object.entries(requestPipeline())).forEach(([interceptorKey, interceptor]) => {
    APIController.registerInterceptor({ interceptorKey, interceptor });
  });
};

const initResponsePipeline = (store: Store) => {
  Array.from(Object.entries(responsePipeline(store))).forEach(([interceptorKey, interceptor]) => {
    APIController.registerInterceptor({ interceptorKey, interceptor });
  });
};

APIController.registerInstance(BASE_INSTANCE_KEY, {
  baseURL: REACT_APP_API_URL,
});

APIController.registerInstance(OPEN_API_INSTANCE_KEY, {
  baseURL: REACT_APP_OPEN_API_URL,
  headers: { Accept: `application/vnd.spendit.v${process.env.REACT_APP_OPEN_API_VERSION ?? 1}+json` },
});

const API = APIController.getInstance(BASE_INSTANCE_KEY);
const OpenAPI = APIController.getInstance(OPEN_API_INSTANCE_KEY);

/**
 * @description AxiosController를 통한 API Instance의 사용을 위해 다음 단계의 초기화 과정을 거칩니다
 *
 *              1. 사용할 API 인스턴스 생성 (복수 생성에 자유로움)
 *              2. global configuration set : 모든 API Instance에 공통적으로 적용되는 Configuration 설정
 *              3. 인터셉터 파이프라인 등록 : request, response 각각에 대해 거쳐갈 인터셉터를 매니저에 등록
 *              4. 파이프라인 주입 : 등록된 인터셉터 파이프라인을, 생성되어있는 모든 API Instance에 주입
 */
export const initAxiosConfig = (store: Store) => {
  APIController.setDefaultRequestConfiguration({
    paramsSerializer,
    pipe: DEFAULT_PIPE_CONFIG,
  });

  initRequestPipeline();
  initResponsePipeline(store);

  APIController.initPipelines();
};

export { APIController, API, OpenAPI };
export default API;
