import { Modal } from '@mantine/core';
import colors from 'theme/colors';

export const ModalResolver = Modal.extend({
  styles: {
    root: {},
    overlay: {
      zIndex: 800,
    },
    inner: {
      zIndex: 800,
      boxSizing: 'border-box',
    },
    content: {
      zIndex: 801,
      borderRadius: '10px',
      padding: '26px',
      overflow: 'hidden',
      boxSizing: 'border-box',
    },
    header: {
      minHeight: 'auto',
      padding: '0',
      paddingBottom: '32px',
    },
    title: {
      color: colors.grey[700],
      fontSize: '20px',
      fontWeight: 700,
      lineHeight: 'normal',
      flexGrow: 1,
    },
    body: {
      padding: '0',
      height: '100%',
    },
  },
});
