const LocalStorage = {
  set: (key: string, value: any) => {
    localStorage[key] = value;
    return localStorage[key];
  },
  get: (key: string, defaultValue?: any) => {
    return localStorage[key] || defaultValue;
  },
  setObject: (key: string, value: any) => {
    localStorage[key] = JSON.stringify(value);
    return localStorage[key];
  },
  getObject: (key: string) => {
    return JSON.parse(localStorage[key] || null);
  },
  clear: () => {
    return localStorage.clear();
  },
  remove: (key: string) => {
    return localStorage.removeItem(key);
  },
};

export default LocalStorage;
