import React, { useCallback, useEffect, useRef, useState } from 'react';
import { containsNode, isNode } from 'utils/DOMUtil';

import { DROPDOWN_CONTAINER_BASE_CLASSNAME } from 'components/commons/SelectBox/SelectBoxDropdown';
import { SelectBoxContext } from 'components/commons/SelectBox/SelectBoxContext';
import { getGlobalContainerElement } from 'components/commons/GlobalPortal';
import { isArray } from 'lodash-es';
import styled from 'styled-components';
import { useToggle } from 'hooks/useToggle';

type SelectBoxContainerProps = {
  disabled?: boolean;
  width?: string;
} & Styleable<BoxStyle>;

type SelectBoxProps = {
  autoPositioning?: boolean;
  defaultLabel?: string;
  hasError?: boolean;
  multi?: boolean;
  onApplyButtonClick?(): void;
  onDropdownClose?(): void;
  onDropdownOpen?(): void;
  onScrollBottomReached?(): void;
  onSearch?(value: string): void;
  onValueChange?(values: ValueWithLabelAndStyle<any>[], target?: ValueWithLabelAndStyle<any>, isSelect?: boolean): void;
  searchTimeout?: number;
  values?: ValueWithLabelAndStyle<any>[] | ValueWithLabelAndStyle<any>;
} & SelectBoxContainerProps;

const SelectBoxContainer = styled.div<SelectBoxContainerProps>`
  position: relative;
  display: flex;
  width: ${props => props.width || props.styles?.width || '100%'};
  ${props => props.styles?.height && `height: ${props.styles.height};`}
  ${props => props.styles?.marginRight && `margin-right: ${props.styles.marginRight};`}
  ${props => props.styles?.marginLeft && `margin-left: ${props.styles.marginLeft};`}
  ${props => props.styles?.float && `float: ${props.styles.float};`}
`;

export const SelectBox: React.FC<React.PropsWithChildren<SelectBoxProps>> = ({
  autoPositioning,
  children,
  className,
  defaultLabel,
  disabled,
  hasError,
  multi,
  onApplyButtonClick,
  onDropdownClose,
  onDropdownOpen,
  onScrollBottomReached,
  onSearch,
  onValueChange,
  searchTimeout,
  values,
  ...rest
}) => {
  const [currentValues, setCurrentValues] = useState<ValueWithLabel<any>[]>([]);
  const [toggleElementResized, setToggleElementResized] = useState(false);
  const [searchValue, setSearchValue] = useState<string>('');
  const selectBoxContainerElRef = useRef<HTMLDivElement>(null);
  const searchTimerRef = useRef(0);
  const { bool: open, handleSetTrue, handleSetFalse } = useToggle(false);

  const closeDropdown = useCallback(() => {
    handleSetFalse();
    setSearchValue('');
  }, [handleSetFalse]);

  const handleCloseDropdown = useCallback(() => {
    closeDropdown();
    if (onDropdownClose) {
      onDropdownClose();
    }
  }, [closeDropdown, onDropdownClose]);

  const openDropdown = useCallback(() => {
    handleSetTrue();
    if (onDropdownOpen) {
      onDropdownOpen();
    }
  }, [handleSetTrue, onDropdownOpen]);

  const handleSelect = (option: ValueWithLabel<any>) => {
    const nextSelectedValues = multi ? [...currentValues, option] : [option];

    if (onValueChange) {
      onValueChange(nextSelectedValues, option, true);
    }

    if (!multi) {
      handleCloseDropdown();
    }
  };

  const handleUnselect = (index: number) => {
    const nextSelectedValues = [...currentValues];
    const option = nextSelectedValues.splice(index, 1)[0];

    if (onValueChange) {
      onValueChange(nextSelectedValues, option);
    }
  };

  const handleDefaultSelect = () => {
    if (onValueChange) {
      onValueChange([]);
    }

    if (!multi) {
      handleCloseDropdown();
    }
  };

  const handleRemoveClick = (value: ValueWithLabel<any>) => {
    const index = currentValues.findIndex(option => option.value === value.value);

    if (index > -1) {
      handleUnselect(index);
    }
  };

  const handleSearch = (value: string) => {
    if (onSearch) {
      setSearchValue('');

      if (searchTimeout !== undefined) {
        clearTimeout(searchTimerRef.current);
        searchTimerRef.current = window.setTimeout(() => {
          onSearch(value);
        }, searchTimeout);
      } else {
        onSearch(value);
      }
    } else {
      setSearchValue(value);
    }
  };

  const handleApplyButtonClick = () => {
    if (onApplyButtonClick) {
      onApplyButtonClick();
    }
    closeDropdown();
  };

  const handleToggleElementResized = useCallback((resized: boolean) => {
    setToggleElementResized(resized);
  }, []);

  useEffect(() => {
    const handleDocumentClick = (event: MouseEvent) => {
      const { target } = event;
      const dropdownContainerElement = getGlobalContainerElement([`.${DROPDOWN_CONTAINER_BASE_CLASSNAME}`]);

      if (
        open &&
        isNode(target) &&
        !containsNode(selectBoxContainerElRef.current, target) &&
        !containsNode(dropdownContainerElement, target)
      ) {
        handleCloseDropdown();
      }
    };

    if (open) {
      document.addEventListener('click', handleDocumentClick);
    }

    return () => {
      document.removeEventListener('click', handleDocumentClick);
    };
  }, [open, handleCloseDropdown]);

  useEffect(() => {
    if (values !== undefined) {
      if (isArray(values)) {
        setCurrentValues([...values]);
      } else {
        setCurrentValues([values]);
      }
    } else {
      setCurrentValues([]);
    }
  }, [values]);

  return (
    <SelectBoxContainer
      ref={selectBoxContainerElRef}
      className={className}
      onKeyDown={e => e.stopPropagation()}
      {...rest}
    >
      <SelectBoxContext.Provider
        value={{
          autoPositioning,
          closeDropdown: handleCloseDropdown,
          defaultLabel,
          disabled: !!disabled,
          handleApplyButtonClick,
          hasError: !!hasError,
          multi,
          onDefaultOptionSelect: handleDefaultSelect,
          onOptionSelect: handleSelect,
          onOptionUnselect: handleUnselect,
          onRemoveClick: handleRemoveClick,
          onScrollBottomReached,
          onSearch: handleSearch,
          open,
          openDropdown,
          searchValue,
          selectBoxContainerElRef,
          values: currentValues,
          onToggleElementResized: handleToggleElementResized,
          toggleElementResized,
        }}
      >
        {children}
      </SelectBoxContext.Provider>
    </SelectBoxContainer>
  );
};
