import * as S from '@N/view/components/WebView/Native/AOS/AOSModal/styles';

import { AppModalProps } from '@N/view/components/WebView/AppModal';
import { ContextModalProps } from '@mantine/modals';
import React from 'react';
import { translate } from 'utils/LocaleUtil';

const AOSModal = ({ context, id, innerProps }: ContextModalProps<AppModalProps>) => {
  const close = () => context.closeModal(id);

  return (
    <>
      <S.ContentWrapper>{innerProps.modalBody}</S.ContentWrapper>
      <S.ButtonWrapper>
        <button
          onClick={
            innerProps.confirmButton?.onClick
              ? () => {
                  innerProps.confirmButton?.onClick();
                  close();
                }
              : close
          }
        >
          {innerProps.confirmButton?.label || translate('ok')}
        </button>
        {innerProps?.cancelButton && (
          <button
            onClick={
              innerProps.cancelButton?.onClick
                ? () => {
                    innerProps.cancelButton?.onClick();
                    close();
                  }
                : close
            }
          >
            {innerProps.cancelButton?.label || translate('cancel')}
          </button>
        )}
      </S.ButtonWrapper>
    </>
  );
};

export default AOSModal;
