import API from 'apis/API';

export type GetFirebaseTokenResponse = {
  token: string;
};

/**
 * GET /v4/firebase/token
 */
export const getFirebaseToken = async () => {
  const res = await API.get<GetFirebaseTokenResponse>('/v4/firebase/token');

  return res.data;
};

type JobCompleteResponse = {
  job_id: string;
  success: boolean;
  code: number;
};

/**
 * PUT /v4/firebase/jobs/:job_id/cancel
 */
export const cancelFirebaseJob = async (jobId: string) => {
  const res = await API.put<JobCompleteResponse>(`/v4/firebase/jobs/${jobId}/cancel`);

  return res.data;
};

/**
 * DELETE /v4/firebase/jobs/:job_id
 */
export const deleteFirebaseJob = async (jobId: string) => {
  const res = await API.delete<JobCompleteResponse>(`/v4/firebase/jobs/${jobId}`);

  return res.data;
};

/**
 * POST /v4/firebase/sample_event
 */
export const postFirebaseSampleEvent = async () => {
  const res = await API.post('/v4/firebase/sample_event');

  return res.data;
};

/**
 * POST /v4/firebase/sample_event_end
 */
export const postFirebaseSampleEventEnd = async () => {
  const res = await API.post('/v4/firebase/sample_event_end');

  return res.data;
};
