import '@mantine/core/styles/Tooltip.css';

import { Tooltip as MantineTooltip, TooltipProps as MantineTooltipProps } from '@mantine/core';

import { Flex } from '@N/view/components/Flex';
import { Text } from '@N/view/components/Text';
import { defaultTheme } from 'theme';
import styled from 'styled-components';

/**
 * @NoneHeadlessComponent
 * @issues Tooltip children이 SVG Component인 경우 정상작동하지 않아 wrapper로 한 번 감싸주어야 함
 * @see https://www.figma.com/design/t8VINTS1iMOuGc9YmmFyxI/%5BJANGBOO%5D-%EC%8A%A4%ED%8C%AC%EB%94%A7-%EB%94%94%EC%9E%90%EC%9D%B8-%EC%8B%9C%EC%8A%A4%ED%85%9C?node-id=364-350&m=dev
 */

const TOOLTIP_MAX_WIDTH = 420;
const TOOLTIP_MAX_WIDTH_IN_MODAL = 320;

type TooltipBGColor = 'black' | 'white';

export interface TooltipProps extends MantineTooltipProps {
  title?: string;
  color?: TooltipBGColor;
  adjustWidthInModal?: boolean;
}

const TitleTooltip = ({ title, label, color }: Pick<TooltipProps, 'title' | 'label' | 'color'>) => {
  return (
    <Flex direction={'column'}>
      <Flex style={{ borderBottom: `0.5px solid ${color === 'black' ? 'white' : '#212121'}` }} mb={3} pb={3}>
        <Text fw={'500'} size={'sm'}>
          {title}
        </Text>
      </Flex>
      {label}
    </Flex>
  );
};

const StyledLabel = styled.div`
  & > p {
    font-size: 10px;
    line-height: 13px;
  }
`;

export const Tooltip = ({
  color = 'black',
  title,
  label,
  adjustWidthInModal = false,
  children,
  ...rest
}: TooltipProps) => {
  return (
    <MantineTooltip
      label={title ? <TitleTooltip label={label} title={title} color={color} /> : <StyledLabel>{label}</StyledLabel>}
      color={color === 'black' ? defaultTheme.colors.grey[700] : defaultTheme.colors.grey[0]}
      arrowRadius={1}
      arrowSize={8}
      zIndex={1000}
      radius={6}
      styles={{
        tooltip: {
          maxWidth: `${adjustWidthInModal ? TOOLTIP_MAX_WIDTH_IN_MODAL : TOOLTIP_MAX_WIDTH}px`,
          whiteSpace: 'normal',
          boxShadow: '0px 0px 9px 4px rgba(0, 0, 0, 0.08)',
          color: color === 'black' ? defaultTheme.colors.grey[0] : defaultTheme.colors.grey[700],
          padding: 6,
        },
      }}
      withArrow
      {...rest}
    >
      {children}
    </MantineTooltip>
  );
};
