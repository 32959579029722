import $ from 'jquery';
import { HttpQueryUtil } from '../utility/http_query_util';
import { REACT_APP_API_URL } from '../../utils/EnvUtil';

var path = {
  home: '/',
  feeds: '/feeds',
  feed: '/feed',
  contact: '/contact',
  contact_company_size: '/contact/company_sizes',
  intercom: '/intercom',
  sign_up: '/user',
  current_policy: '/user/current_policy',
  invite: '/user/invite',
  activate: '/user/activate',
  re_invite: '/user/re_invite',
  user: '/user',
  mine: '/mine',
  confirm_invitation: '/user/confirm_invitation',
  change_policy: '/user/change_policy',
  change_role: '/user/change_role',
  potential_matches: '/expense/potential_matches',
  merge_expenses: '/expense/merge',
  ignore_potential_match: '/expense/potential_match/ignore',
  expenses: '/expenses',
  expense_auto_upload: '/expense_auto_upload',
  expenses_map: '/expense/map',
  expenses_report: '/expenses_report',
  expense: '/expense',
  expense_recover: '/expense/recover',
  expense_meta: '/expense/meta',
  expense_meta_for_new: '/expense/meta_for_new',
  expense_users: '/expense/users',
  expense_delete_image: '/expense/image',
  expense_update_fields: '/expense/fields',
  expense_attendees: '/expense/attendees',
  split_expense: '/expense/split',
  reports: '/reports',
  erp_export_ids: '/reports/erp_report_ids',
  erp_overview: '/reports/erp_overview',
  reports_meta: '/reports/meta',
  report_images: '/reports/images',
  report_bulk_action: '/reports/bulk_action',
  report_bulk_final_approve: '/reports/bulk_final_approve',
  report: '/report',
  search_reports: '/search_reports',
  report_submit: '/report/submit',
  report_expenses: '/report/expenses',
  report_comment: '/report/comment',
  report_comments: '/report/comments',
  report_histories: '/report/histories',
  report_open: '/report/open',
  report_file_delete: '/report/delete',
  report_approve: '/report/approve',
  report_cancel: '/report/cancel',
  report_complete_refund: '/report/complete_refund',
  report_refund: '/report/refund',
  report_cancel_approve: '/report/cancel_approve',
  report_deny: '/report/deny',
  report_close: '/report/close',
  report_share: '/report/share',
  report_custom_field: '/report/custom_field',
  attachments: '/attachments',
  refund_histories: '/refund_histories',
  refund_history_meta: '/refund_history/meta',
  refund_history: '/refund_history',
  erp_accounts: '/erp_accounts',
  copy_erp_account: '/copy_erp_account',
  other_erp_accounts: '/other_erp_accounts',
  erp_account: '/erp_account',
  erp_account_options: '/erp_account_options',
  policies: '/policies',
  policies_meta: '/policy_meta',
  policies_for_update: '/policies_for_update',
  duplicate_policy: '/policy/duplicate',
  my_policy_list: '/my_policy_list',
  policy: '/policy',
  policy_overview: '/policy_overview',
  report_policy: '/report_policy',
  search_policies: '/policy/search',
  search_categories: '/search_category',
  report_policy_custom_field: '/report_policy/custom_field',
  report_policy_bulk_custom_field: '/report_policy/bulk_custom_field',
  expense_policy: '/expense_policy',
  policy_members: '/policy_members',
  policy_members_meta: '/policy_members/meta',
  refund_policy_members: '/refund_policy_members',
  policy_member: '/policy_member',
  policy_approvers: '/policy_approvers',
  policy_submit_type: '/policy_submit_type',
  update_policy_member: '/policy_member',
  policy_duplicate: '/policy/duplicate',
  plan: '/plan',
  plans: '/plans',
  plan_items: '/plan/items',
  plan_policy: '/plan_policy',
  report_plans: '/report_plans',
  report_plan_items: '/report_plan_items',
  custom_report_plan_item: '/report_plan_item/custom',
  report_plan_item: '/report_plan_item',
  company: '/company',
  company_user: '/company/user',
  company_users: '/company/users',
  company_approvers: '/company/approvers',
  company_users_meta: '/company/users_meta',
  import_users: '/import_users',
  search_users: '/search_users',
  company_admin: '/company/admin',
  company_admins: '/company/admins',
  company_currency: '/company/currency',
  company_logo: '/company/logo',
  main_categories: '/main_categories',
  user_categories: '/user_categories',
  personal_categories: '/personal_categories',
  user_category: '/user_category',
  personal_category: '/personal_category',
  category: '/category',
  cards: '/cards',
  user_card: '/user_card',
  user_card_login: '/user_card/login',
  user_card_public: '/user_card/public',
  user_card_import: '/user_card/import',
  user_cards: '/user_cards',
  user_card_users: '/user_card/users',
  user_card_all_company_users: '/user_card/company_users',
  user_card_company_users: '/user_card/company_card_users',
  user_card_policies: '/user_card/policies',
  user_card_limitations: '/user_card/limitations',
  card_transaction: '/card_transaction',
  card_transaction_image: '/card_transaction/image',
  card_transactions: '/card_transactions',
  card_transactions_meta: '/card_transaction/meta',
  card_transaction_to_expense: '/card_transaction/expense',
  card_transaction_filter: '/card_transaction/filter',
  billing_transactions: '/billing_transactions',
  banks: '/banks',
  user_bank: '/user_bank',
  user_banks: '/user_banks',
  user_bank_policies: '/user_bank/policies',
  user_bank_login: '/user_bank/login',
  bank_transaction: '/bank_transaction',
  bank_transactions: '/bank_transactions',
  bank_transaction_image: '/bank_transaction/image',
  bank_transaction_meta: '/bank_transaction_meta',
  bank_transaction_to_expense: '/bank_transaction/expense',
  groups: '/groups',
  group_users: '/group_users',
  group: '/group',
  group_members: '/group_members',
  group_member: '/group_member',
  expense_rules: '/expense_rules',
  expense_rule: '/expense_rule',
  expense_rule_expenses: '/expense_rule_expenses',
  expense_rule_meta: '/expense_rule_meta',
  show_policy: '/expense/policy',
  preference: '/preference',
  hide_scraping_login: '/hide_scraping_login',
  auto_scan: '/auto_scan',
  auto_scan_records: '/auto_scan_records',
  merchants: '/merchants',
  merchant_category: '/merchant_category',
  merchant: '/merchant',
  password_change: '/change_password',

  verify_payment: '/verify_payment',

  bills: '/bills',
  bill_download: '/bill/download',
  bill_pending: '/bill/pending',
  bill_payment_complete: '/bill/payment_complete',
  payment: '/payment',
  payments: '/payments',
  projects: '/projects',
  project_meta: '/project_meta',
  project_tags: '/tags',
  search_projects: '/search_projects',
  search_tags: '/search_tags',
  import_projects: '/import_projects',
  project: '/project',

  attendees: '/attendees',
  attendee_meta: '/attendee/meta',
  attendee_image_delete: '/attendee/image',
  internal_attendees: '/attendees/internal',
  external_attendees: '/attendees/external',
  attendee: '/attendee',
  attendee_expenses: '/attendee/expenses',
  attendee_expenses_meta: '/attendee/expenses_meta',
  attendee_expenses_users: '/attendee/users',
  user_expenses: '/attendee/user_expenses',
  search_attendee: '/attendee/search',
  send_reset_password_link: '/send_reset_password_link',
  reset_password: '/reset_password',
  check_valid_report_link: '/check_valid_link',
  pie_graph_data: '/stats/pie_graph_data',
  chart_data_with_date: '/stats/chart_data_with_date',
  sub_graph_data: '/stats/sub_graph_data',

  card_transaction_download: '/card_transaction_download',
  bank_transaction_download: '/bank_transaction_download',
  policy_categories: '/policy_categories',
  sub_help_links: '/sub_help_links',
  help_link: '/help_link',
  csv_invite: '/user/csv_invite',
  user_report_collector: '/user/report_collector',
  user_report_collector_update: '/user/report_collector_update',

  scraping_recent: '/scraping_recent',
  billing_recent: '/billing_recent',
  transaction_upload: '/card_transaction/create_by_files',
  delete_transactions: '/card_transaction/delete_transactions',

  company_taxes: '/company_taxes',
  company_tax: '/company_tax',
  tax_account: '/tax_account',
  tax_transactions: '/tax_transactions',
  tax_transaction_image: '/tax_transaction/image',
  transmit_tax_transaction: '/tax_transaction/transmit',
  tax_transaction_to_expense: '/company_tax/expense',
  company_tax_policies: '/company_tax/policies',
  company_tax_check: '/company_tax/check',
  company_tax_admins: '/company_tax/admins',

  doc_center_policy: '/doc_center_policy',
  doc_center_pdf: '/expense/doc_center_pdf',
  expense_doc_center: '/expense/doc_center',

  meta_for_duplicate: '/policy/meta_for_duplicate',

  export_users: '/company/export_users',
  export_cards: '/user_cards/export',
  export_banks: '/user_banks/export',

  export_policy_member: '/policy_members/export',
  export_categories: '/categories/export',
  export_tages: '/projects/export',

  import_policy_members: '/policy_members/import',
  import_tages: '/projects/import',
  import_categories: '/categories/import',

  delay_change_password: '/user/delay_password_change',
};

let storeForBasePath = undefined;

/**
 * @warning Jquery 기반의 API호출 구문을 전부 제거한 후 해당 함수도 제거해야 합니다.
 * @see https://spendit.atlassian.net/browse/WEB-2976
 */
export const injectStoreToJQueryAPI = store => {
  storeForBasePath = store;
};

function execute_to_url(method, url, data, session_token, headers = null, url_prefix) {
  let send_data;

  if (method === 'GET') {
    send_data = HttpQueryUtil.UrlParams(data);
  } else {
    send_data = HttpQueryUtil.FormData(data);
  }

  let params = {
    method: method,
    url: `${storeForBasePath.getState().env?.url ?? REACT_APP_API_URL}/${url_prefix || 'web'}${url}`,
    data: send_data,
    processData: false,
    contentType: false,
    cache: false,
    timeout: 1000000,
  };

  let http_headers = headers ? headers : {};
  if (session_token) $.extend(http_headers, { Authentication: session_token });
  $.extend(params, { headers: http_headers });

  return $.ajax(params);
}

function execute_url(method, url, data) {
  let send_data;

  if (method === 'GET') {
    send_data = HttpQueryUtil.UrlParams(data);
  } else {
    send_data = HttpQueryUtil.FormData(data);
  }

  let params = {
    method: method,
    url: url,
    data: send_data,
    processData: false,
    contentType: false,
    cache: true,
    Accept: 'application/json',
  };

  return $.ajax(params);
}

export { execute_to_url as ApiExecute, path as ApiPath, execute_url as ExecuteUrl };
