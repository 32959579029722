import { selectCurrentPolicy, selectCurrentUser, sessionActions, sessionSelectors } from 'modules/session';
import { useDispatch, useSelector } from 'react-redux';

import { AuthService } from '@N/service/inbound/Auth/port';
import { ModuleManagerService } from '@N/service/outbound/ModuleManager/adapter';
import { MyPolicy } from 'apis/PolicyAPI';

export const useAuthService = (): AuthService => {
  const dispatch = useDispatch();
  const currentUser: SpenditUser = useSelector(selectCurrentUser);
  const currentUserPolicy = useSelector(selectCurrentPolicy);

  const userPrivatePolicy = useSelector(sessionSelectors.privatePolicy);
  const userMyPolicies = useSelector(sessionSelectors.myPolicies);

  const getCurrentUserId = () => currentUser?.id;
  const getCurrentUserInfo = () => currentUser;
  const getCurrentUserName = () => currentUser.name;
  const getCurrentUserEmail = () => currentUser.email;
  const getCurrentUserCompanyName = () => currentUser.company_name;

  const getUserCurrentPolicy = () => currentUserPolicy;

  const getUserPrivatePolicy = () => userPrivatePolicy;

  const getUserSelectablePolicies = (): MyPolicy[] => {
    const privatePolicy = getUserPrivatePolicy();
    const result = [privatePolicy, ...userMyPolicies.filter(myPolicy => !myPolicy.disabled)];

    if (result[0] === undefined && result.length === 1) return [];

    return result;
  };

  const getCurrentUserThumbnailURL = () => {
    return currentUser.thumbnail_url;
  };

  const getCurrentUserRole = () => {
    return currentUser.role;
  };

  const getDefaultThumbnailSVG = () => ModuleManagerService.getAsyncSVGImport('user_others');

  const getSSOQueryParams = () => {
    const searchParams = new URLSearchParams(window.location.search);
    const loginMethod = searchParams.get('login_method');
    const companyId = searchParams.get('company_id');
    const jwt = searchParams.get('jwt') || searchParams.get('kAuth');
    return { loginMethod, companyId, jwt };
  };
  const isSSOLoginRequest = () => {
    const { loginMethod, companyId, jwt } = getSSOQueryParams();
    return Boolean(loginMethod && companyId && jwt);
  };

  /** Don't use Directly */
  const signOutGlobalSession = () => {
    dispatch(sessionActions.signOut());
  };

  const changeUserCurrentPolicy = (targetPolicy: MyPolicy) => {
    dispatch(sessionActions.changeCurrentPolicy({ nextPolicy: targetPolicy }));
  };

  return {
    getCurrentUserId,
    getCurrentUserInfo,
    getCurrentUserThumbnailURL,
    getDefaultThumbnailSVG,
    getCurrentUserEmail,
    getCurrentUserCompanyName,
    getCurrentUserName,
    getUserCurrentPolicy,
    getUserPrivatePolicy,
    getUserSelectablePolicies,
    getCurrentUserRole,
    getSSOQueryParams,
    isSSOLoginRequest,
    signOutGlobalSession,
    changeUserCurrentPolicy,
  };
};
