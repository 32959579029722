import {
  AxiosInterceptor,
  AxiosInterceptorFn,
  AxiosInterceptorType,
  CustomInterceptorKey,
} from 'apis/interceptor/InterceptorManager';
import {
  ResponseLogContextType,
  isAxiosResponse,
  isLegacyInvalidSessionErrorResponse,
  isMaintenanceErrorResponse,
} from 'apis/apiUtils';
import axios, { AxiosError, AxiosResponse } from 'axios';
import { deleteRequestKey, isPipeObjectExist, makeRequestKey } from 'apis/interceptor/request';

import { DEFAULT_PIPE_CONFIG } from 'apis/bootstrap';
import DatadogLogUtil from 'utils/DatadogLogUtil';
import { Store } from 'redux';
import { axiosActions } from 'modules/axios';
import { camelizeObject } from 'utils/parser';
import { fireError } from 'utils/SwalUtil';
import { isSpenditErrorResponseData } from 'utils/ErrorHandler';
import { spenditHistory } from '@N/view/bootstrap/config/configureHistory';

/**
 * @see https://spendit.atlassian.net/wiki/spaces/FE/pages/3074949443/API+Client+Architecture
 *
 * @description 1. API Response의 interceptor를 선언하는 모듈로, 레거시 인터셉터들이 있어 책임분리가 진행되지 않은 인터셉터도 존재합니다.
 *              2. Interceptor 작성방법은 다음과 같습니다.
 *                2-1. 응답 인터셉터의 경우 다음 타입의 함수를 작성하면 됩니다. AxiosInterceptorFn<'res'> = (response: AxiosResponse) => {}
 *                2-2. 작성한 인터셉터를 responsePipeline에 등록합니다. 이 때 key값은 interceptor를 참조할 수 있는 고유한 키값이 됩니다.
 *                     ex) responseEntryInterceptor의 경우 다음과 같이 eject 가능합니다. APIController.ejectInterceptor(BASE_INSTANCE_KEY, 'entryRes');
 *                2-3. 하나의 인터셉터 키값에 대해 fulfilled, rejected 두 경우에 대한 인터셉터를 작성할 수 있습니다.
 *              3. initResponsePipeline()함수로부터 의존성을 주입받을 수 있습니다. ex:) store
 *              4. response.config.pipe값을 통해 인터셉터간 컨텍스트를 공유할 수 있습니다. 또한 필요한 경우 pipe의 타입을 수정하여 필요한 값을 추가, 제거, 변경 가능합니다. (/apis/shared.ts)
 */

const buildResponseLogContext = (response: AxiosResponse | AxiosError): ResponseLogContextType => {
  const requestId = response.config.headers['Spendit-Request-ID'];
  const requestTimestamp = Number(response.config.headers['Spendit-Request-Timestamp']);
  const { method, params, url } = response.config;
  const { status: statusCode, statusText, responseURL } = response.request;
  const responseTimestamp = Date.now();

  return {
    statusCode,
    statusText,
    responseURL,
    requestId: requestId as string,
    responseTimestamp,
    duration: (responseTimestamp - requestTimestamp) * 1_000_000,
    method,
    params,
    responseData: isAxiosResponse(response) ? response?.data : response.response?.data,
    url,
  };
};

const responseEntryInterceptor: AxiosInterceptorFn<'res'> = (response: AxiosResponse) => {
  const {
    config: { pipe },
  } = response;
  if (!isPipeObjectExist(pipe)) {
    throw new Error('there is no pipe to reference');
  }

  // TODO : 고도화 필요, 현재로서는 의미없는 로직
  pipe.pendingRequests = Math.max(0, pipe.pendingRequests - 1);

  return response;
};

const responseLogInterceptor: AxiosInterceptorFn<'res'> = (response: AxiosResponse) => {
  const context = buildResponseLogContext(response);
  const msg = `axios.response.onFulfilled - ${context.method} ${context.url} ${context.statusCode} ${context.statusText}`;
  DatadogLogUtil.info(msg, context);
  return response;
};

const responseFulfilledLegacyErrorInterceptor: AxiosInterceptorFn<'res'> = (store: Store) => {
  return (response: AxiosResponse) => {
    if (isLegacyInvalidSessionErrorResponse(response)) {
      store.dispatch(axiosActions.globalErrorOccurred({ type: 'session_expired' }));
      // AxiosReponse를 AxiosError로 흘려보내기 위함
      throw new Error('session expired');
    }
    return response;
  };
};

const responseCamelizeInterceptor: AxiosInterceptorFn<'res'> = (response: AxiosResponse) => {
  const camelizedData = camelizeObject(response.data);
  if (response.config.pipe?.useCamelize) {
    return { ...response, data: camelizedData };
  }
  return response;
};
const responseLegacyErrorInterceptor: AxiosInterceptorFn<'res'> = (store: Store) => {
  return (error: any) => {
    if (axios.isAxiosError(error)) {
      const context = buildResponseLogContext(error);
      let msg = `axios.response.onRejected - ${context.method} ${context.url} ${context.statusCode} ${context.statusText}`;

      if (isMaintenanceErrorResponse(error.response)) {
        store.dispatch(axiosActions.globalErrorOccurred({ type: 'service_maintenance', data: error.response.data }));
      } else if (error.response) {
        const data = error.response.data;
        Object.assign(context, data);
        if (isSpenditErrorResponseData(data)) {
          if (data.error.code === 40490) {
            spenditHistory.push('/');
          }
        }
      } else if (error.request) {
        const { code, message } = error.toJSON() as { code?: string; message: string };

        msg += ` [${code}] ${message}`;

        if (code === 'ECONNABORTED') {
          // timeout 오류
          fireError('서버로부터 응답이 없습니다.');
        } else {
          // 그 외 (네트워크 오류)
          // fireError(translate('alert_network_disconnected'));
        }
      } else {
        msg += ` ${error.message}`;
        fireError('오류가 발생했습니다. 관리자에게 문의해주세요.');
      }

      DatadogLogUtil.debug(msg, context);
    }

    return Promise.reject(error);
  };
};

const responseResetPipeInterceptor: AxiosInterceptorFn<'res'> = (response: AxiosResponse) => {
  if (response.config && response.config.pipe) {
    // eslint-disable-next-line no-param-reassign
    response.config.pipe = DEFAULT_PIPE_CONFIG;
  }
  return response;
};

const responseAbortDuplicatedRequestsInterceptor: AxiosInterceptorFn<'res'> = (response: AxiosResponse) => {
  deleteRequestKey(makeRequestKey(response.config));
  return response;
};

const responsePipeline = (store: Store): Record<CustomInterceptorKey, AxiosInterceptor<AxiosInterceptorType>> => ({
  entryRes: {
    type: 'res',
    interceptFn: {
      fulfilled: responseEntryInterceptor,
    },
  },
  logRes: {
    type: 'res',
    interceptFn: {
      fulfilled: responseLogInterceptor,
    },
  },
  camelizeRes: {
    type: 'res',
    interceptFn: {
      fulfilled: responseCamelizeInterceptor,
    },
  },
  errorRes: {
    type: 'res',
    interceptFn: {
      fulfilled: responseFulfilledLegacyErrorInterceptor(store),
      rejected: responseLegacyErrorInterceptor(store),
    },
  },
  resetPipeRes: {
    type: 'res',
    interceptFn: {
      fulfilled: responseResetPipeInterceptor,
      rejected: responseResetPipeInterceptor,
    },
  },
  abortRes: {
    type: 'res',
    interceptFn: {
      fulfilled: responseAbortDuplicatedRequestsInterceptor,
      rejected: responseAbortDuplicatedRequestsInterceptor,
    },
  },
});

export default responsePipeline;
