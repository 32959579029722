type Props = {
  width?: number;
  height?: number;
};

export const IconError: React.FC<React.PropsWithChildren<Props>> = ({ width = 72, height = 72 }) => (
  <svg xmlns="http://www.w3.org/2000/svg" width={width} height={height} viewBox="0 0 72 72" fill="none">
    <circle cx="36" cy="36" r="34" stroke="#FFCABB" strokeWidth="4" />
    <path d="M21 51L51 21" stroke="#FFCABB" strokeWidth="4" strokeLinecap="round" />
    <path d="M21 21L51 51" stroke="#FFCABB" strokeWidth="4" strokeLinecap="round" />
  </svg>
);
