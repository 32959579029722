import React, { useCallback, useEffect, useState } from 'react';
import { SelectBoxDropdownItem, SelectBoxDropdownSplitter } from 'components/commons/SelectBox/SelectBoxDropdown';

import { Flex } from '@N/view/components/Flex';
import { Icon } from 'components/atoms/Icon';
import IconCheckBlue from 'images/icons/icon-check-blue.svg';
import IconTriangleDown from 'images/icons/icon-triangle-down.svg';
import IconTriangleUp from 'images/icons/icon-triangle-up.svg';
import { Text } from '@N/view/components/Text';
import colors from 'theme/colors';
import styled from 'styled-components';

type SelectBoxOptionGroupProps = {
  title?: string;
  foldable?: boolean;
  expand?: boolean;
  splitterStyles?: React.CSSProperties;
  titleStyles?: React.CSSProperties;
  showCheckIcon?: boolean;
} & Styleable;

const SelectBoxOptionGroupContainer = styled(SelectBoxDropdownItem)``;

const StyledSelectBoxDropdownSplitter = styled(SelectBoxDropdownSplitter)<{ foldable?: boolean; isFolded?: boolean }>`
  height: 40px;
  border-top: 1px solid ${colors.grey[300]};
  ${props => !props.isFolded && `border-bottom: 1px solid ${colors.grey[300]};`}
  box-sizing: border-box;

  background-color: #fff;
  color: #000;

  font-size: 12px;
  line-height: 3.3;

  ${props => props.foldable && 'cursor: pointer;'}
`;

export const SelectBoxOptionGroup: React.FC<React.PropsWithChildren<SelectBoxOptionGroupProps>> = ({
  className,
  title,
  foldable,
  expand,
  children,
  splitterStyles,
  titleStyles,
  showCheckIcon = false,
  ...rest
}) => {
  const [isFolded, toggleIsFolded] = useState(!expand);
  const [showChildren, toggleShowChildren] = useState(!foldable || !isFolded);

  const getIconImageURL = useCallback(() => {
    if (isFolded) {
      return IconTriangleDown;
    }
    return IconTriangleUp;
  }, [isFolded]);

  const handleOnClick = () => {
    if (foldable) {
      toggleIsFolded(!isFolded);
    }
  };

  useEffect(() => {
    toggleIsFolded(!expand);
  }, [expand]);

  useEffect(() => {
    toggleShowChildren(!foldable || !isFolded);
  }, [foldable, isFolded]);

  return (
    <SelectBoxOptionGroupContainer className={className} {...rest} onClick={handleOnClick}>
      <ul>
        {title && (
          <StyledSelectBoxDropdownSplitter foldable={foldable} isFolded={isFolded} style={{ ...splitterStyles }}>
            <Flex align="center" columnGap={8}>
              {foldable && <Icon url={getIconImageURL()} width={10} height={6} />}
              <Text style={{ ...titleStyles }}>{title}</Text>
            </Flex>
            {showCheckIcon && <Icon url={IconCheckBlue} width={16} height={11} />}
          </StyledSelectBoxDropdownSplitter>
        )}
        {showChildren && children}
      </ul>
    </SelectBoxOptionGroupContainer>
  );
};
