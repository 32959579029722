import { fireError } from 'utils/SwalUtil';
import { postMSAuthOAuthToken } from 'apis/SessionAPI';
import { sessionActions } from 'modules/session';
import { translate } from 'utils/LocaleUtil';
import { useDispatch } from 'react-redux';
import { useOktaAuth } from '@okta/okta-react';

export const useOktaService = (): OktaService => {
  const { authState, oktaAuth } = useOktaAuth();
  const dispatch = useDispatch();

  const isOktaAuthenticated = () => {
    return authState.isAuthenticated;
  };

  const handleRequestSessionData = async () => {
    const oktaAccessToken = authState.accessToken?.accessToken;
    if (!oktaAccessToken) {
      await fireError(translate('error_okta_token_invalid'));
      return;
    }

    const { token: sessionToken } = await postMSAuthOAuthToken(oktaAccessToken);

    dispatch(sessionActions.signInWithToken({ sessionToken }));
  };

  const resetOktaSession = () => {
    if (isOktaAuthenticated()) {
      oktaAuth.clearStorage();
    }
  };

  return {
    isOktaAuthenticated,
    handleRequestSessionData,
    resetOktaSession,
  };
};
