import API from '../API';
import { CancelToken } from 'axios';

/**
 * GET /v4/policies
 */
export const getPolicies = async (params: GetPoliciesParam) => {
  const res = await API.get<GetPoliciesResponse>('/v4/policies', {
    params: {
      page: params.page,
      count_per_page: params.countPerPage,
      order: params.order,
      filter: {
        user_id: params.filter?.userId,
        initial: params.filter?.initial,
        name: params.filter?.name,
      },
    },
  });

  return res.data;
};

/**
 * PUT /v4/policies/:id
 */
export const putPolicy = async (policyId: number, params?: PutPolicyParam) => {
  const res = await API.put<PutPolicyResponse>(`/v4/policies/${policyId}`, {
    name: params?.name,
    owner_id: params?.ownerId,
    policy_type: params?.policyType,
    disabled: params?.disabled,
    restrict_parent_with_child: params?.restrict_parent_with_child,
  });

  return res.data;
};

/**
 * PUT /web/policy
 * @param id policy ID
 * @param params
 */
export const updatePolicy = async (id: number, params: UpdatePolicyParam) => {
  const res = await API.put<UpdatePolicyResponse>('/web/policy', {
    id,
    policy: JSON.stringify(params),
  });

  return res.data;
};

export type PolicyInviteMembersSuccess = { email: string };

export type PolicyInviteMembersFail = { email: string; message: string; cd: string };

type PolicyInviteMembersParams = { emails: string[]; agree_additional_user: boolean };

type PolicyInviteMembersResponse = {
  result: { successes: PolicyInviteMembersSuccess[]; fails: PolicyInviteMembersFail[] };
};

/**
 * POST /v4/policies/:id/invite_members
 * @param id policy ID
 */
export const inviteMembers = async (id: number, params: PolicyInviteMembersParams) => {
  const res = await API.post<PolicyInviteMembersResponse>(`/v4/policies/${id}/invite_members`, {
    emails: params.emails,
    agree_additional_user: params.agree_additional_user,
  });

  return res.data;
};

/**
 * POST /web/policies_for_update
 * @param id
 * @param filter
 */
export const getCompanyPolicies = async (id: number, filter?: { name?: string }) => {
  const res = await API.get<GetCompanyPoliciesResponse>(`/v4/policies/${id}/list_for_update`, {
    data: { id, filter },
    params: { filter },
  });

  return res.data;
};

/**
 * POST /web/report_policy/bulk_custom_field
 * @param params
 */
export const updateReportPolicyCustomField = async (params: {
  ids: number[];
  company_id: number;
  checked_all: boolean;
  id: number;
  report_custom_field: any;
}) => {
  const data = {
    ids: params.ids,
    company_id: params.company_id,
    checked_all: params.checked_all,
    id: params.id,
    report_custom_field: params.report_custom_field,
  };
  const res = await API.post<{ custom_fields: CustomField[] }>('/web/report_policy/bulk_custom_field', data);

  return res.data;
};

/**
 * PUT /web/policies
 * @param params
 */
export const updatePolicies = async (params: any) => {
  const res = await API.put('/web/policies', params);

  return res.data;
};

/**
 * POST /v4/policies/:id/refund_members/export
 */
export const exportRefundMembers = async (policyId: number, params: ExportRefundMembersParam) => {
  const res = await API.post<ExportRefundMembersResponse>(`/v4/policies/${policyId}/refund_members/export`, {
    encoding: params.encoding,
    checked_all: params.checkedAll,
    member_ids: params.ids,
  });

  return res.data;
};

/*
 * POST /web/policy/search
 */
export const searchPolicies = async (params: SearchPoliciesParam) => {
  const res = await API.post<SearchPoliciesResponse>('/web/policy/search', {
    page: params.page,
    count_per_page: params.countPerPage,
    filter: JSON.stringify(params.filter),
  });

  return res.data;
};

/**
 * GET /web/policy
 */
export const getPolicy = async (policyId: number) => {
  const res = await API.get<GetPolicyResponse>(`/web/policy/${policyId}`);

  return res.data;
};

/**
 * POST /web/policy_meta
 */
export const postPolicyMeta = async (params: PostPolicyMetaParam) => {
  const res = await API.post<PostPolicyMetaResponse>('/web/policy_meta', params);

  return res.data;
};

/**
 * DELETE /web/policy
 */
export const deletePolicy = async (params: DeletePolicyParam) => {
  const res = await API.delete<DeletePolicyResponse>('/web/policy', { data: { id: params.id } });

  return res.data;
};

/**
 * POST /web/policy
 */
export const postPolicy = async (params: PostPolicyParam) => {
  const res = await API.post<PostPolicyResponse>('/web/policy', {
    policy_type: params.policyType,
    policy_feature_type: params.policyFeatureType,
  });

  return res.data;
};

/**
 * GET /v4/policies/:id/main_categories
 */
export const getPolicyMainCategories = async (policyId: number, params: GetPolicyMainCategoriesParams) => {
  const res = await API.get<GetPolicyMainCategoriesResponse>(`/v4/policies/${policyId}/main_categories`, {
    params: {
      page: params.page,
      count_per_page: params.countPerPage,
      filter: params.filter,
    },
  });

  return res.data;
};

/**
 * GET /v4/policies/:id/erp_tags
 * @description ERP 부모 태그 목록
 */
export const getPoliciesErpTags = async (policyId: number, params: GetPoliciesErpTagsParams) => {
  const res = await API.get<GetPoliciesErpTagsResponse>(`/v4/policies/${policyId}/erp_tags`, {
    params: {
      filter: params.filter,
      order: params.order,
      page: params.page,
      count_per_page: params.countPerPage,
    },
  });

  return res.data;
};

/**
 * GET /web/policy_overview
 */
export const getPolicyOverview = async (params: GetPolicyOverviewParams) => {
  const res = await API.get<GetPolicyOverviewResponse>(`/web/policy_overview/${params.id}`);

  return res.data;
};

/**
 * GET /v4/policies/:id/erp_tags/:tag_id/tags
 * @description ERP 하위 태그 목록
 */
export const getPoliciesErpTagsChildren = async (
  policyId: number,
  parentTagId: number,
  params: GetPoliciesErpTagsChildrenParams
) => {
  const res = await API.get<GetPoliciesErpTagsChildrenResponse>(
    `/v4/policies/${policyId}/erp_tags/${parentTagId}/tags`,
    {
      params: {
        order: params.order,
        page: params.page,
        count_per_page: params.countPerPage,
      },
    }
  );

  return res.data;
};

type GetPolicyCategoriesParams = {
  id: number;
  page: number;
  countPerPage: number;
  order?: SortOption;
};

export type ReceiptRuleType = 'under_policy' | 'required' | 'not_required';

export type GetPolicyCategoriesResponse = {
  category_policies: {
    id: number;
    category_name: string;
    is_sub_category: boolean;
    time_limit: string;
    price_limit: string;
    check_distance: boolean;
    receipt_rule: string;
    required_field: {
      check_memo: boolean;
      check_tag: boolean;
      attendee_required: boolean;
    };
    receipt_rule_type: ReceiptRuleType;
  }[];
  meta: {
    page_count: number;
  };
};

/**
 * GET /web/policy_categories
 */
export const getPolicyCategories = async (params: GetPolicyCategoriesParams) => {
  const res = await API.get<GetPolicyCategoriesResponse>('/web/policy_categories', {
    params: {
      id: params.id,
      page: params.page,
      count_per_page: params.countPerPage,
      order: params.order,
    },
  });

  return res.data;
};

export type ReportCustomFieldOwnerType = 'policy' | 'report';

export type ReportCustomFieldOption = ValueWithLabel<string | null> & { type?: string; toggle?: boolean };

export type ReportCustomFieldDropdownValue = ValueWithLabel<string> & { toggle: boolean };

export type ReportCustomFieldLocationValue = {
  category_name: string;
  image_url: string;
  label: string;
  location: {
    address: string;
    latitude: string;
    longitude: string;
  };
  name: string;
  place_id: string;
  predefined: boolean;
  value: number;
};

export type ReportCustomFieldBase = {
  field_index: number;
  owner_type: ReportCustomFieldOwnerType;
  field_title: string;
  used_csv_export_field: boolean;
  is_required: boolean;
  hint: string;
};

export type ReportCustomFieldText = ReportCustomFieldBase & {
  field_type: 'text';
  initial_value: string;
};

export type ReportCustomFieldTextarea = ReportCustomFieldBase & {
  field_type: 'textarea';
  initial_value: string;
};

export type ReportCustomFieldDate = ReportCustomFieldBase & {
  field_type: 'date';
  initial_value: string;
};

export type ReportCustomFieldDropdown = ReportCustomFieldBase & {
  field_type: 'dropdown';
  initial_value: ReportCustomFieldDropdownValue;
  options: ReportCustomFieldOption[];
};

export type ReportCustomFieldLocation = ReportCustomFieldBase & {
  field_type: 'location';
  initial_value: ReportCustomFieldLocationValue;
};

export type ReportCustomField =
  | ReportCustomFieldText
  | ReportCustomFieldTextarea
  | ReportCustomFieldDate
  | ReportCustomFieldDropdown
  | ReportCustomFieldLocation;

export type GetPoliciesReportCustomFieldsResponse = {
  report_custom_fields: ReportCustomField[];
};

/**
 * GET /v4/policies/:id/report_custom_fields
 */
export const getPoliciesReportCustomFields = async (id: number, cancelToken?: CancelToken) => {
  const res = await API.get<GetPoliciesReportCustomFieldsResponse>(`/v4/policies/${id}/report_custom_fields`, {
    cancelToken,
  });

  return res.data;
};

export type PostReportCustomFieldsBulkAddByIdsParams = {
  company_id: number;
  policy_id: number;
  include_ids: number;
  report_custom_fields: ReportCustomField[];
};

export type PostReportCustomFieldsBulkAddByIdsResponse = {
  report_custom_fields: ReportCustomField[];
};

/**
 * POST /v4/report_custom_fields/bulk_add_by_ids
 */
export const postReportCustomFieldsBulkAddByIds = async (params: PostReportCustomFieldsBulkAddByIdsParams) => {
  const res = await API.post<PostReportCustomFieldsBulkAddByIdsResponse>(
    '/v4/report_custom_fields/bulk_add_by_ids',
    params
  );

  return res.data;
};

export type PostReportCustomFieldsBulkAddByAllPoliciesParams = {
  checked_all: boolean;
  company_id: number;
  policy_id: number;
  exclude_ids: number[];
  report_custom_fields: ReportCustomField[];
};

export type PostReportCustomFieldsBulkAddByAllPoliciesResponse = {
  report_custom_fields: ReportCustomField[];
};

/**
 * POST /v4/report_custom_fields/bulk_add_by_all_policies
 */
export const postReportCustomFieldsBulkAddByAllPolicies = async (
  params: PostReportCustomFieldsBulkAddByAllPoliciesParams
) => {
  const res = await API.post<PostReportCustomFieldsBulkAddByAllPoliciesResponse>(
    '/v4/report_custom_fields/bulk_add_by_all_policies',
    params
  );

  return res.data;
};

export type MyPolicy = {
  budget_policy: {
    info_showable: boolean;
    member_visibility_limitable: boolean;
    over_price_limitable: boolean;
  };
  disabled: boolean;
  edit_distance: boolean;
  id: number;
  label: string;
  name: string;
  open_daily_wage: boolean;
  open_time: boolean;
  personal: boolean;
  policy_type: PolicyType;
  report_auto_title: boolean;
  value: number;
};

export type GetMyPolicyListResponse = {
  current_policy: MyPolicy;
  policies: MyPolicy[];
  private_policy: MyPolicy;
};

/**
 * GET /web/my_policy_list
 */
export const getMyPolicyList = async () => {
  const res = await API.get<GetMyPolicyListResponse>('/web/my_policy_list');

  return res.data;
};

type PutPoliciesCategoriesOrderParams = {
  categories: PolicyCategory[];
};

/**
 * PUT /v4/policies/:id/categories/order
 */

export const putPoliciesCategoriesOrder = async (policyId: number, params: PutPoliciesCategoriesOrderParams) => {
  const res = await API.put(`/v4/policies/${policyId}/categories/order`, params);

  return res.data;
};

type GetPoliciesErpConfigsParams = {
  project_id?: number;
};

type GetPoliciesErpConfigsResponse = {
  erp_configs: {
    id: number;
    display_name: string;
  }[];
};

/**
 * GET /v4/policies/:id/erp_configs
 */
export const getPoliciesErpConfigs = async (policyId: number, params: GetPoliciesErpConfigsParams) => {
  const res = await API.get<GetPoliciesErpConfigsResponse>(`/v4/policies/${policyId}/erp_configs`, { params });

  return res.data;
};

export type TotalRefundAmountResponse = {
  total_refund_amount: number;
  currency: string;
};

export type PostPoliciesTotalRefundAmountParams = {
  include_ids: number[];
};

/**
 * POST /v4/policies/:id/total_refund_amount
 */
export const postPoliciesTotalRefundAmount = async (policyId: number, payload: PostPoliciesTotalRefundAmountParams) => {
  const res = await API.post<TotalRefundAmountResponse>(`/v4/policies/${policyId}/total_refund_amount`, payload);

  return res.data;
};

export type PostPoliciesAllTotalRefundAmountParams = {
  exclude_ids: number[];
};

/**
 * POST /v4/policies/:id/all_total_refund_amount
 */
export const postPoliciesAllTotalRefundAmount = async (
  policyId: number,
  payload: PostPoliciesAllTotalRefundAmountParams
) => {
  const res = await API.post<TotalRefundAmountResponse>(`/v4/policies/${policyId}/all_total_refund_amount`, payload);

  return res.data;
};

export type GetPoliciesApproverListParams = {
  page: number;
  count_per_page: number;
  approver_role: string;
  approver_ids?: number[];
  final_approver_ids?: number[];
  exclude_ids?: number[];
  filter: {
    name?: string;
    group_id?: number;
  };
};

export type PoliciesApproverListPolicyMember = {
  id: number;
  user_id: number;
  name: string;
  email: string;
  role: string;
  group_id: number;
  group_name: string;
  thumbnail_url: string | null;
};

export type PoliciesApproverListMeta = {
  total_pages: number;
};

export type GetPoliciesApproverListResponse = {
  policy_members: PoliciesApproverListPolicyMember[];
  meta: PoliciesApproverListMeta;
};

/**
 * GET /v4/policies/approver_list
 */
export const getPoliciesApproverList = async (params: GetPoliciesApproverListParams) => {
  const res = await API.get<GetPoliciesApproverListResponse>('/v4/policies/approver_list', {
    params,
  });

  return res.data;
};

export interface PostPolicyDuplicateParams {
  id: number;
  elements: string[];
}

export const postPolicyDuplicate = async (params: PostPolicyDuplicateParams) => {
  const res = await API.post('/web/policy/duplicate', params);

  return res.data;
};

interface GetPolicyMetaForDuplicateParams {
  id: number;
}

interface GetPolicyMetaForDuplicateResponse {
  result: { category_count: number; tag_count: number; plan_count: number };
}

export const getPolicyMetaForDuplicate = async (params: GetPolicyMetaForDuplicateParams) => {
  const res = await API.get<GetPolicyMetaForDuplicateResponse>('/web/policy/meta_for_duplicate', { params });

  return res.data;
};

/**
 * GET /v4/default_policy
 */
export const getDefaultPolicy = async () => {
  const res = await API.get<GetDefaultPolicy>('/v4/default_policy');

  return res.data;
};

/**
 * PATCH /v4/policies/:id/report_policy
 * @param policyId
 * @param params
 */
export const patchReportPolicy = async (policyId: number, params: PatchReportPolicyParams) => {
  const res = await API.patch<PatchReportPolicyResponse>(`/v4/policies/${policyId}/report_policy`, params);

  return res.data;
};

/**
 * POST /v4/policies/:id/report_policy/editor_templates
 * @param policyId
 * @param params
 */
export const postReportPolicyEditorTemplates = async (policyId: number, params: PostAddEditorTemplateParams) => {
  const res = await API.post<PostAddEditorTemplateResponse>(
    `/v4/policies/${policyId}/report_policy/editor_templates`,
    params
  );

  return res.data;
};

/**
 * DELETE /v4/policies/:policyId/report_policy/editor_templates/:templateId
 * @param policyId
 * @param templateId
 */
export const deleteReportPolicyEditorTemplates = async (policyId: number, templateId: number) => {
  const res = await API.delete(`/v4/policies/${policyId}/report_policy/editor_templates/${templateId}`);

  return res.data;
};

/**
 * PATCH /v4/policies/:policyId/report_policy/editor_templates/:templateId
 * @param policyId
 * @param templateId
 * @param params
 */
export const patchReportPolicyEditorTemplates = async (
  policyId: number,
  templateId: number,
  params: PatchEditorTemplateParams
) => {
  const res = await API.patch<PatchReportPolicyEditorTemplateResponse>(
    `/v4/policies/${policyId}/report_policy/editor_templates/${templateId}`,
    params
  );

  return res.data;
};

/**
 * GET /v4/policies/:policyId/report_policy/editor_templates/:templateId/upload_url
 * @param policyId
 * @param templateId
 */
export const getReportPolicyEditorTemplatesUploadUrl = async (policyId: number, templateId: number) => {
  const res = await API.get<GetEditorTemplateUploadUrlResponse>(
    `/v4/policies/${policyId}/report_policy/editor_templates/${templateId}/upload_url`
  );

  return res.data;
};

/**
 * POST S3 URL에 템플릿 내용을 업데이트
 * @param url
 * @param content
 */
export const putEditorContent = async (url: string, content: string) => {
  const file = new Blob([content], { type: 'text/html' });
  const res = await API.put(url, file, {
    headers: {
      'Content-Type': file.type,
    },
  });

  return res.data;
};

/**
 * POST /v4/policies/:policyId/report_policy/editor_templates/:templateId/upload_complete
 * @param policyId
 * @param templateId
 */
export const postReportPolicyEditorTemplatesUploadComplete = async (policyId: number, templateId: number) => {
  const res = await API.post(`/v4/policies/${policyId}/report_policy/editor_templates/${templateId}/upload_complete`);

  return res.data;
};

/**
 * url은 서버에서 내려주는 S3 URL
 * @param url
 */
export const getEditorTemplateFormDownloadURL = async (url: string): Promise<{ content: string; id: number }> => {
  const res = await API.get(url);
  const urlWithoutQuery = res.config.url.split('?')[0];
  const editorId = Number(urlWithoutQuery.split('/').pop());

  return {
    content: res.data,
    id: editorId,
  };
};

/**
 * POST /v4/policies/:id/report_policy/editor_templates/:id/copy_to_other_policies
 * 폴리시 에디터 템플릿을 타 폴리시에 벌크 복사
 * @param policyId
 * @param templateId
 * @param params
 */
export const postReportPolicyEditorTemplatesCopyToOtherPolicies = async (
  policyId: number,
  templateId: number,
  params: { ids: number[]; checked_all: boolean }
) => {
  const res = await API.post<PostCopyEditorTemplateResponse>(
    `/v4/policies/${policyId}/report_policy/editor_templates/${templateId}/copy_to_other_policies`,
    params
  );
  return res.data;
};
