import '@mantine/core/styles/Modal.css';

import * as MantineModal from '@mantine/modals';

import {
  AlertModalTemplate,
  AlertModalTemplateProps,
} from '@N/view/components/Modal/AlertModalTemplate/AlertModalTemplate';
import { ConfirmModalTemplate, OpenConfirmModalProps } from '@N/view/components/Modal/ConfirmModalTemplate';

import { Modal } from '@mantine/core';
import { ModalResolver } from '@N/view/components/Modal/resolver';
import { randomId } from '@mantine/hooks';

const openConfirmModal = (props: OpenConfirmModalProps) => {
  const modalID = props.id ?? randomId();
  return MantineModal.openModal({
    ...props,
    modalId: modalID,
    children: <ConfirmModalTemplate {...props} id={modalID} />,
  });
};

const openAlertModal = ({ modalId, ...modalProps }: AlertModalTemplateProps) => {
  const uniqueModalID = modalId ?? randomId();
  return MantineModal.openModal({
    modalId: uniqueModalID,
    withCloseButton: false,
    centered: true,
    styles: {
      content: {
        padding: 0,
      },
    },
    children: <AlertModalTemplate modalId={uniqueModalID} {...modalProps} />,
  });
};

const ModalManager = { ...MantineModal, openConfirmModal, openAlertModal };

export { ModalResolver, ConfirmModalTemplate, ModalManager, Modal };
