import Button, { ButtonProps } from 'components/Button';
import { Container, Flex, Group, GroupProps } from '@mantine/core';

import { ConfirmModalProps } from '@mantine/modals/lib/ConfirmModal';
import { Divider } from '@N/view/components/Divder';
import { ModalSettings } from '@mantine/modals/lib/context';
import React from 'react';
import { useModals } from '@mantine/modals';

export type OpenConfirmModalProps = ModalSettings &
  Omit<ConfirmModalProps, 'groupProps'> & {
    id: string;
    cancelProps?: ButtonProps;
    confirmProps?: ButtonProps;
    leftButtonGruopProps?: GroupProps;
    rightButtonGruopProps?: GroupProps;
  };

export const ConfirmModalTemplate = ({
  id,
  cancelProps,
  confirmProps,
  labels = { cancel: 'cancel', confirm: 'confirm' },
  closeOnConfirm = true,
  closeOnCancel = true,
  leftButtonGruopProps,
  rightButtonGruopProps,
  onCancel,
  onConfirm,
  children,
}: OpenConfirmModalProps) => {
  const { cancel: cancelLabel, confirm: confirmLabel } = labels;
  const ctx = useModals();

  const handleCancel = (event: React.MouseEvent<HTMLButtonElement>) => {
    typeof cancelProps?.onClick === 'function' && cancelProps?.onClick(event);
    typeof onCancel === 'function' && onCancel();
    closeOnCancel && ctx.closeModal(id);
  };

  const handleConfirm = (event: React.MouseEvent<HTMLButtonElement>) => {
    typeof confirmProps?.onClick === 'function' && confirmProps?.onClick(event);
    typeof onConfirm === 'function' && onConfirm();
    closeOnConfirm && ctx.closeModal(id);
  };

  return (
    <Flex direction={'column'} h={'100%'}>
      {children}
      <Divider mt={'42px'} mb={'13px'} />
      <Group grow={true}>
        <Container>
          <Group justify={'flex-start'} gap={'6px'} {...leftButtonGruopProps}>
            {leftButtonGruopProps?.children}
          </Group>
        </Container>
        <Container>
          <Group justify="flex-end" gap={'6px'} {...rightButtonGruopProps}>
            {rightButtonGruopProps?.children ?? (
              <>
                <Button onClick={handleCancel} variant={'outline'} size={'medium'} {...cancelProps}>
                  {cancelProps?.children || cancelLabel}
                </Button>
                <Button onClick={handleConfirm} variant={'fill'} size={'medium'} {...confirmProps}>
                  {confirmProps?.children || confirmLabel}
                </Button>
              </>
            )}
          </Group>
        </Container>
      </Group>
    </Flex>
  );
};
