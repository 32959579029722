import '@mantine/core/styles/Combobox.css';

import { ComboboxOptionProps, ComboboxSearchProps, InputProps, Combobox as MantineCombobox } from '@mantine/core';
import React, { DetailedHTMLProps, InputHTMLAttributes } from 'react';
import {
  injectDefaultDropdownSelectorStyles,
  injectDefaultOptionStyles,
  injectDefaultTargetInputSelectorStyles,
} from '@N/view/components/Combobox/styles';

import { Center } from '@N/view/components/Center';
import { ReactComponent as CheckIcon } from '@N/view/assets/icons/check.svg';
import { ComboboxResolver } from '@N/view/components/Combobox/resolver';
import { ReactComponent as DownwardFilledArrowIcon } from '@N/view/assets/icons/downward_filled_arrow.svg';
import { Flex } from '@N/view/components/Flex';
import { Icon } from 'components/atoms/Icon';
import { Input } from '@N/view/components/Inputs';
import SearchIcon from 'images/icons/search-icon.png';
import { defaultTheme } from 'theme';

export type InputMockedProps = Omit<
  Omit<DetailedHTMLProps<InputHTMLAttributes<HTMLInputElement>, HTMLInputElement>, 'ref'>,
  'component' | keyof InputProps
> &
  InputProps;
export const DefaultTargetInput: React.ComponentType<InputMockedProps> = props => {
  return (
    <Input
      component={'input'}
      rightSection={
        <DownwardFilledArrowIcon width={12} height={12} color={defaultTheme.mantineThemes.colors.grey[7]} />
      }
      {...props}
    />
  );
};

/** @HeadlessComponent */
const Combobox = MantineCombobox;

Combobox.Dropdown = injectDefaultDropdownSelectorStyles(Combobox.Dropdown);
Combobox.DefaultTargetInput = injectDefaultTargetInputSelectorStyles(
  DefaultTargetInput
) as React.ComponentType<InputMockedProps>;
Combobox.Option = injectDefaultOptionStyles(Combobox.Option);

export const SearchWithIcon = (props: ComboboxSearchProps) => {
  return (
    <Combobox.Search
      rightSection={
        props.rightSection ?? <Icon width={13} height={13} url={SearchIcon} color={defaultTheme.colors.grey[700]} />
      }
      {...props}
    />
  );
};

export type ActiveOptionProps = ComboboxOptionProps & {
  useActiveIcon?: boolean;
};
export const ActiveOption: React.ComponentType<ActiveOptionProps> = ({ useActiveIcon = true, children, ...props }) => {
  return (
    <Combobox.Option h={38} {...props}>
      <Flex w={'100%'} maw={'100%'} h={'100%'} justify={'space-between'}>
        {children}
        {useActiveIcon && props.active && (
          <Center h={'100%'} w={'auto'}>
            <CheckIcon width={12} height={12} color={defaultTheme.mantineThemes.colors.blue[8]} />
          </Center>
        )}
      </Flex>
    </Combobox.Option>
  );
};

Combobox.SearchWithIcon = SearchWithIcon;
Combobox.ActiveOption = ActiveOption;

export { Combobox, ComboboxResolver };
