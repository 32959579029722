import React, { Suspense } from 'react';

import { AuthLayerRouter } from '@N/view/router/layer/Auth';
import { GlobalModalContainer } from '@N/view/bootstrap/GlobalModalContainer';
import LoadingSpinner from 'components/utility/LoadingSpinner';
import Maintenance from '@N/view/bootstrap/Maintenance';
import { useApplicationMaintenance } from '@N/view/bootstrap/hooks/useApplicationMaintenance';
import useInitializingConfig from '@N/view/bootstrap/hooks/useInitializingConfig';
import { useObservingOktaAuthentication } from '@N/view/pages/SSO/Okta';
import { useSyncBrowserHistory } from '@N/view/bootstrap/hooks/useSyncBrowserHistory';

export const Entry = () => {
  const isUnderMaintenance = useApplicationMaintenance();
  const { isLocaleInitialized, isAppInitialized } = useInitializingConfig();

  // sync global state with browser history
  useSyncBrowserHistory();
  useObservingOktaAuthentication();

  // 서비스 점검시 렌더링, 레거시 로직이라 분석 필요
  if (isUnderMaintenance) {
    return <Maintenance />;
  }

  if (!isAppInitialized || !isLocaleInitialized) {
    return <></>;
  }

  return (
    <Suspense fallback={<LoadingSpinner />}>
      <AuthLayerRouter />
      <GlobalModalContainer />
    </Suspense>
  );
};
