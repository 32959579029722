import { RefObject, createContext } from 'react';

export type SelectBoxContextType = {
  autoPositioning?: boolean;
  closeDropdown?(): void;
  defaultLabel?: string;
  disabled: boolean;
  handleApplyButtonClick?(): void;
  hasError: boolean;
  multi?: boolean;
  onDefaultOptionSelect?(): void;
  onOptionSelect?(value: ValueWithLabel<any>): void;
  onOptionUnselect?(index: number): void;
  onRemoveClick?(option: ValueWithLabel<any>): void;
  onScrollBottomReached?(): void;
  onSearch?(value: string): void;
  open: boolean;
  openDropdown?(): void;
  searchValue: string;
  selectBoxContainerElRef?: RefObject<HTMLDivElement>;
  values: ValueWithLabelAndStyle<any>[];
  onToggleElementResized?(changed: boolean): void;
  toggleElementResized?: boolean;
};

export const SelectBoxContext = createContext<SelectBoxContextType>({
  disabled: false,
  hasError: false,
  open: false,
  searchValue: '',
  values: [],
});
