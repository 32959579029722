import { ReactComponent as DeleteIcon } from '@N/view/assets/icons/delete.svg';
import { Flex } from '@N/view/components/Flex';
import { ModalHeaderProps } from './types';
import React from 'react';
import { defaultTheme } from 'theme';
import styled from 'styled-components';

export const StyledCloseButton = styled.button`
  display: block;
  padding: 0;
  border: 0;
`;

export const StyledTitle = styled.h2`
  color: ${({ theme }) => theme.colors.grey[900]};
  font-size: 20px;
  font-weight: bold;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: -0.33px;
`;

export const StyledHeader = styled.header`
  display: flex;
  align-items: center;
  justify-content: space-between;

  padding: 26px 26px 32px 26px;
  border-top-left-radius: 10px;
  border-top-right-radius: 10px;
`;

export const StyledInfo = styled.div`
  display: flex;
  align-items: center;
  gap: 4px;
  color: ${({ theme }) => theme.colors.grey[400]};
  cursor: default;

  & p {
    margin-top: 2px;
    text-decoration: underline;
    font-weight: 700;
  }
`;

/**
 * @deprecated
 */
const ModalHeader: React.FC<React.PropsWithChildren<ModalHeaderProps>> = ({
  className,
  onHideClick,
  children,
  titleHint,
}) => {
  return (
    <StyledHeader className={className}>
      <Flex align={'center'} gap={6}>
        <StyledTitle>{children}</StyledTitle>
        {titleHint}
      </Flex>
      {Boolean(onHideClick) && (
        <StyledCloseButton type="button" onClick={onHideClick} aria-label="close">
          <DeleteIcon color={defaultTheme.colors.grey[500]} width={18} height={18} />
        </StyledCloseButton>
      )}
    </StyledHeader>
  );
};
export default ModalHeader;
