import { DEVICE_TYPE, OS_TYPE } from 'consts/device';
import { useEffect, useState } from 'react';

import { map } from 'lodash-es';

const OSTypeList = map(OS_TYPE, item => item);
const DeviceTypeList = map(DEVICE_TYPE, item => item);
export type OSType = (typeof OSTypeList)[number];
export type DeviceType = (typeof DeviceTypeList)[number];

export const getDeviceInfo = () => {
  const userAgent = navigator.userAgent;
  let os: OSType = OS_TYPE.OS_UNKNOWN;
  let device: DeviceType = DEVICE_TYPE.DEVICE_UNKNOWN;

  // OS 검사
  if (userAgent.indexOf('Win') !== -1) os = OS_TYPE.OS_WINDOWS;
  else if (userAgent.indexOf('Mac') !== -1) os = OS_TYPE.OS_MACOS;
  else if (userAgent.indexOf('Linux') !== -1) os = OS_TYPE.OS_LINUX;
  else if (/Android/.test(userAgent)) os = OS_TYPE.OS_ANDROID;
  else if (/iPhone|iPad|iPod/.test(userAgent)) os = OS_TYPE.OS_IOS;

  // Device 검사
  if (/Mobile|Android|iP(ad|hone|od)|IEMobile|Opera Mini/i.test(userAgent)) {
    device = DEVICE_TYPE.DEVICE_MOBILE;
  } else {
    device = DEVICE_TYPE.DEVICE_DESKTOP;
  }

  return { os, device };
};

export const useDevice = () => {
  const [deviceInfo, setDeviceInfo] = useState<{ os: OSType; device: DeviceType }>({
    os: OS_TYPE.OS_UNKNOWN,
    device: DEVICE_TYPE.DEVICE_UNKNOWN,
  });

  useEffect(() => {
    setDeviceInfo(getDeviceInfo());
  }, []);

  return deviceInfo;
};
