// utils
import axios, { AxiosError, AxiosRequestConfig, AxiosResponse } from 'axios';
import { get, isFinite } from 'lodash-es';

import { SpenditErrorResponse } from 'apis/shared';
import { SweetAlertResult } from 'sweetalert2';
import { fireError } from 'utils/SwalUtil';
import { translate } from 'utils/LocaleUtil';

/**
 * HTTP status와 custom error code, text로 오류 메시지 표시
 *
 */
const handler = ({ status, code, msg, field }: { status: number; code: number; msg: string; field: string }) => {
  switch (status) {
    case 400:
      if (code === 100002) {
        return fireError(translate('error_file_name_too_long'));
      }

      if (code === 40002) {
        if (field === 'description') {
          return fireError(translate('alarm_require_memo'));
        }
      }
      /**
       * @see https://spendit.atlassian.net/wiki/spaces/PM/pages/2947383550/20
       * @description promo20 오류 코드: 40010(사용가능 인원 20명 초과시 발생 에러)
       * 사용자 role에 따른 에러 문구 구분
       * ca: error_promo20_user_exceeds_promo20_error_ca
       * po: error_promo20_user_exceeds_promo20_error_po
       * pa/normal: error_promo20_user_exceeds_promo20_error_user
       */
      if (code === 40010) {
        return fireError(translate(`error_promo20_user_exceeds_${field}`));
      }
      /**
       * @description promo20 오류 코드: 40011(카드/계좌 초과시 발생 에러)
       * 사용자 role에 따른 에러 문구 구분
       * ca: error_promo20_card_exceeds_promo20_error_ca
       * po: error_promo20_card_exceeds_promo20_error_po
       * pa/normal: error_promo20_card_exceeds_promo20_error_user
       */
      if (code === 40011) {
        return fireError(translate(field ? `error_promo20_card_exceeds_${field}` : 'error_promo20_card_exceeds'));
      }
      /**
       * @description promo20 오류 코드: 40012(폴리시 3개 초과시 발생 에러)
       * 사용자 role에 따른 에러 문구 구분
       * ca: error_promo20_policy_exceeds_promo20_error_ca
       * po: error_promo20_policy_exceeds_promo20_error_po
       * pa/normal: error_promo20_card_exceeds_promo20_error_user
       */
      if (code === 40012) {
        return fireError(translate(`error_promo20_policy_exceeds_${field}`));
      }
      /**
       * @description promo20 오류 코드: 40013(멀티지정 승인모드 선택시 발생 에러)
       * 사용자 role에 따른 에러 문구 구분
       * ca: error_promo20_multi_approval_mode_promo20_error_ca
       * pa: error_promo20_multi_approval_mode_promo20_error_po
       * pa/normal: error_promo20_multi_approval_mode_promo20_error_user
       */
      if (code === 40013) {
        return fireError(translate(`error_promo20_multi_approval_mode_${field}`));
      }
      /**
       * @description promo20 오류 코드: 40014(활성/비활성화 적용시 발생 에러)
       */
      if (code === 40014) {
        return fireError(translate('client_error'));
      }
      break;
    case 403:
      if (code === 40301) {
        if (field === 'spent_at') {
          return fireError(translate('alarm_can_not_modify_date'));
        }
      }
      // 보고서 권한 없음
      if (code === 40390) {
        return fireError(translate('error_invalid_authority_report'));
      }
      // 지출 권한 없음
      if (code === 40391) {
        return fireError(translate('error_invalid_authority_expense'));
      }

      break;
    case 404:
      if (code === 40490) {
        return fireError(translate('error_access_forbidden'));
      }
      break;
    case 409:
      if (code === 40901) {
        if (field === 'name') {
          return fireError(translate('alert_approval_line_same_name'));
        }
      } else if (code === 40902) {
        if (field === 'approver') {
          return fireError(translate('alert_approval_line_member_exceeds'));
        }
      }
      break;
    case 500:
      return fireError(translate('server_error'));
    default:
      if (msg) {
        return fireError(msg);
      }
  }
  return Promise.resolve({
    isConfirmed: false,
    isDenied: false,
    isDismissed: false,
  });
};

/**
 * Request Method/URL 정보와 SpenditError 정보를 조합하여 다국어 메시징 키를 생성
 * @param code SpenditError.code
 * @param field SpenditError.field
 * @param cd SpenditError.cd
 * @see https://spendit.atlassian.net/wiki/spaces/API/pages/352321691/API
 */
export const makeMessageKey = (config: AxiosRequestConfig, code: number, field: string, cd?: string) => {
  if (cd) {
    return `error_${cd}`;
  }
  const method = config.method || '';
  const baseUrl = config.baseURL || '';
  const url = config.url || '';
  const urlToken = url
    .replace(`${baseUrl}`, '')
    .split(/\//g)
    .filter(token => token === '' || !isFinite(Number(token)))
    .join('_');

  return `error_${method.toLowerCase()}${urlToken}_${field ? `${field}_` : ''}${code}`;
};

export const isSpenditErrorResponseData = <T extends SpenditErrorResponse>(obj: any): obj is T => {
  return !!obj && !!obj.error && !!obj.error.code;
};

/**
 * @description AWS에 너무 많은 요청을 보낸 경우 오는 에러
 */
export const isAWSSlowDownErrorResponse = (payload: AxiosResponse) => {
  if (
    payload?.status === 503 &&
    payload?.statusText === 'Slow Down' &&
    payload?.request.responseURL.includes('amazonaws')
  ) {
    return true;
  }
  return false;
};

/**
 * @description AWS S3에 접근 권한 없거나 내용이 없는 경우 오는 에러
 * 보고서 에디터 양식을 새로 추가만하고, 내용을 한 번도 저장한 적이 없는 경우에도 내려옴
 */
export const isAWSNoAccessErrorResponse = (payload: AxiosResponse) => {
  if ((payload?.status === 403 || payload?.status === 404) && payload?.request.responseURL.includes('amazonaws')) {
    return true;
  }
  return false;
};

type LegacyError = {
  code: number;
  field: string;
  msg: string;
};

export const isLegacyError = (obj: any): obj is LegacyError => {
  return obj && obj.msg;
};

export const isTimeout = (error: AxiosError) => error.code === 'ECONNABORTED';

/**
 * Handle Axios Error
 */
export const fromAxios = (error?: unknown): Promise<SweetAlertResult> => {
  if (axios.isAxiosError(error)) {
    const { response, config } = error;
    if (response) {
      const errorData = response.data;

      if (isSpenditErrorResponseData(errorData)) {
        const { code, msg, field, cd } = errorData.error;
        const messageKey = makeMessageKey(config, code, field, cd);
        const errorMsg = translate(messageKey);
        if (errorMsg) {
          return fireError(errorMsg);
        }
        return handler({
          status: response.status,
          code,
          msg,
          field,
        });
      }

      if (isLegacyError(errorData)) {
        return fireError(errorData.msg);
      }

      if (errorData.message) {
        return fireError(errorData.message);
      }
    }
  }
  console.error(error);
  return Promise.resolve({
    isConfirmed: false,
    isDenied: false,
    isDismissed: false,
  });
};

/**
 * Handle jQuery XMLHttpRequest
 * @param {jqXHR} xhr
 */
export const fromJQuery = (xhr: JQuery.jqXHR) => {
  const code = get(xhr, 'responseJSON.code');
  const field = get(xhr, 'responseJSON.field');
  handler({
    status: xhr.status,
    code,
    msg: xhr.responseText,
    field,
  });
};

export default {
  fromAxios,
  fromJQuery,
  isTimeout,
};
