import * as S from './styles';

import React, { ComponentProps, MouseEventHandler, Suspense } from 'react';

import Button from 'components/Button';
import { Grid } from '@N/view/components/Grid';
import { Group } from '@mantine/core';
import { Text } from '@N/view/components/Text';
import { translate } from 'utils/LocaleUtil';
import { useModals } from '@mantine/modals';

type AlertModalVariant = 'notification' | 'question' | 'success' | 'fail';
type AlertModalActionType = 'confirm' | 'default';

export type AlertModalTemplateProps = {
  modalId?: string;
  variant?: AlertModalVariant;
  type?: AlertModalActionType;
  content: string | React.ReactNode;
  onConfirm?: () => void;
  onCancel?: () => void;
  closeOnConfirm?: boolean;
  closeOnCancel?: boolean;
  buttonProps?: {
    cancelButtonProps?: ComponentProps<typeof Button>;
    confirmButtonProps?: ComponentProps<typeof Button>;
  };
};

export const AlertModalTemplate = ({
  modalId,
  variant = 'notification',
  type = 'confirm',
  content,
  onCancel,
  onConfirm,
  closeOnCancel = true,
  closeOnConfirm = true,
  buttonProps,
}: AlertModalTemplateProps) => {
  const modalCtx = useModals();
  const AlertIcon = React.lazy(async () => ({ default: (await import(`./alert_${variant}_icon.svg`)).ReactComponent }));

  const handleConfirm: MouseEventHandler<any> = event => {
    typeof buttonProps?.confirmButtonProps?.onClick === 'function' && buttonProps?.confirmButtonProps?.onClick(event);
    typeof onConfirm === 'function' && onConfirm();

    closeOnConfirm && modalCtx.closeModal(modalId);
  };

  const handleCancel: MouseEventHandler<any> = event => {
    typeof buttonProps?.cancelButtonProps?.onClick === 'function' && buttonProps?.cancelButtonProps?.onClick(event);
    typeof onCancel === 'function' && onCancel();

    closeOnCancel && modalCtx.closeModal(modalId);
  };

  const ConfirmButtonChildren = buttonProps?.confirmButtonProps?.children ?? translate('confirm');
  const CancelButtonChildren = buttonProps?.cancelButtonProps?.children ?? translate('cancel');

  return (
    <S.ModalContentRoot>
      <S.ContentLayout>
        <S.ContentHeaderWrapper>
          <Suspense fallback={<S.ModalIconFallback />}>
            <AlertIcon />
          </Suspense>
        </S.ContentHeaderWrapper>
        <S.ContentBodyWrapper>{typeof content === 'string' ? <Text>{content}</Text> : content}</S.ContentBodyWrapper>
        {type === 'default' ? (
          <Group>
            <S.AlertModalButton
              variant={'fill'}
              {...buttonProps?.confirmButtonProps}
              onClick={handleConfirm}
              ref={null}
            >
              {ConfirmButtonChildren}
            </S.AlertModalButton>
          </Group>
        ) : (
          <Grid justify={'center'}>
            <Grid.Col span={3}>
              <S.AlertModalButton
                variant={'fill'}
                {...buttonProps?.confirmButtonProps}
                onClick={handleConfirm}
                ref={null}
              >
                {ConfirmButtonChildren}
              </S.AlertModalButton>
            </Grid.Col>
            <Grid.Col span={3}>
              <S.AlertModalButton
                variant={'outline'}
                {...buttonProps?.cancelButtonProps}
                onClick={handleCancel}
                ref={null}
              >
                {CancelButtonChildren}
              </S.AlertModalButton>
            </Grid.Col>
          </Grid>
        )}
      </S.ContentLayout>
    </S.ModalContentRoot>
  );
};
