const OS_WINDOWS = 'Windows';
const OS_MACOS = 'MacOS';
const OS_LINUX = 'Linux';
const OS_ANDROID = 'Android';
const OS_IOS = 'iOS';
const OS_UNKNOWN = 'Unknown';

const DEVICE_MOBILE = 'Mobile';
const DEVICE_DESKTOP = 'Desktop';
const DEVICE_UNKNOWN = 'Unknown';

export const OS_TYPE = {
  OS_WINDOWS,
  OS_MACOS,
  OS_LINUX,
  OS_ANDROID,
  OS_IOS,
  OS_UNKNOWN,
} as const;

export const DEVICE_TYPE = {
  DEVICE_MOBILE,
  DEVICE_DESKTOP,
  DEVICE_UNKNOWN,
} as const;
