import styled, { css } from 'styled-components';

import { Button } from 'components/atoms/Button';
import { GetReportsJobsResultResponse } from 'apis/ReportAPI';
import Modal from 'components/commons/modal';
import ModalBody from 'components/commons/modal/ModalBody';
import ModalFooter from 'components/commons/modal/ModalFooter';
import ModalHeader from 'components/commons/modal/ModalHeader';
import ResultTable from 'components/commons/ResultTable';
import ResultTableTitle from 'components/commons/ResultTable/ResultTableTitle';
import { translate } from 'utils/LocaleUtil';

type Props = {
  data: GetReportsJobsResultResponse;
  onConfirmClick?(): void;
  onHideClick?(): void;
};

const Description = styled.p`
  color: #555;
  font-size: 12px;
  line-height: 18px;
  text-align: center;
  letter-spacing: -0.233333px;

  b {
    font-size: 13px;
    line-height: 19px;
  }
`;

const ErrorMsg = styled.p`
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  color: #333;
  font-size: 13px;
  line-height: 19px;
  letter-spacing: -0.216667px;
`;

const ReportName = styled.p`
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  color: #333;
  font-size: 13px;
  line-height: 19px;
  letter-spacing: -0.216667px;
`;

const tableStyle: BoxStyle = {
  maxHeight: '300px',
  borderRadius: '2px',
  border: '1px solid #d5d5d5',
};

const headerStyle: BoxStyle = {
  borderTop: '0',
};

const headerColumnStyle: BoxStyle & FontStyle = {
  color: '#7d7d7d',
  fontSize: '12px',
};

const tableRowStyle: TableRowStyle = {
  height: '40px',
};

const Footer = styled(ModalFooter)<{ successAll?: boolean }>`
  ${props => {
    if (props.successAll) {
      return css`
        & > div {
          border-top: 0;
        }
      `;
    }
  }}
`;

export const BulkReportsExportResultModal: React.FC<React.PropsWithChildren<Props>> = ({
  data,
  onConfirmClick,
  onHideClick,
}) => {
  return (
    <Modal width="700px">
      <ModalHeader onHideClick={onHideClick}>{translate('title_erp_export_results')}</ModalHeader>
      <ResultTableTitle title={translate('title_erp_export_complete')} type={'success'} />
      <Description>
        <b>
          {`[${translate('label_total_success_counts_2', {
            successCount: data.success_count,
            totalCount: data.total_reports_count,
          })}]`}
        </b>
        <br />
        {data.fails_count > 0 && translate('file_upload_desc_sub')}
      </Description>
      {data.fails_count > 0 && (
        <ModalBody>
          <ResultTable
            styles={{
              tableStyle,
              headerStyle,
              headerColumnStyle,
              tableRowStyle,
            }}
            title={translate('title_export_failed_list')}
            description={translate('desc_export_failed_list')}
            totalCount={data.total_reports_count}
            resultCount={data.fails_count}
            type="fail"
            headerItems={[
              {
                key: 'failed-reportName',
                name: translate('report'),
                columnWidth: '283px',
                styles: {
                  fontSize: '12px',
                },
              },
              {
                key: 'failed-errorMsg',
                name: translate('label_error_message'),
                columnWidth: '374px',
                styles: {
                  fontSize: '12px',
                },
              },
            ]}
            bodyItems={data.fails.map((fail, index) => {
              return {
                key: `failed-row-${index}`,
                columns: [
                  {
                    key: `failed-row-${index}-reportName`,
                    element: <ReportName>{fail.report_title}</ReportName>,
                  },
                  {
                    key: `failed-row-${index}-errorMsg`,
                    element: <ErrorMsg>{fail.message}</ErrorMsg>,
                  },
                ],
              };
            })}
          />
        </ModalBody>
      )}
      <Footer successAll={data.fails_count === 0}>
        <Button buttonType="primary" buttonSize="medium" onClick={onConfirmClick}>
          {translate('confirm')}
        </Button>
      </Footer>
    </Modal>
  );
};
