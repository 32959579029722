import { SET_LOCALE, localeActions } from 'modules/locale/actions';
import { SIGN_IN_SUCCESS, sessionActions } from 'modules/session';
import { call, put, takeLatest } from 'redux-saga/effects';

import { ActionType } from 'typesafe-actions';
import { setLocale } from 'utils/LocaleUtil';

/**
 * @deprecated sessionSaga 내에서 locale config를 제어하기 때문에 실질적으로 영향력이 없는 사가
 */
function* handleSignInSuccess({ payload: { user } }: ActionType<typeof sessionActions.signInSuccess>) {
  yield put(localeActions.setLocale({ locale: user.language }));
}

/**
 * @deprecated sessionSaga 내에서 locale config를 제어하기 때문에 실질적으로 영향력이 없는 사가
 */
function* handleSetLocale(action: ReturnType<typeof localeActions.setLocale>) {
  try {
    yield call(setLocale, action.payload.locale);
    yield put(localeActions.setLocaleSuccess(action.payload));
  } catch (error) {
    console.log('Error occurs while initialize localization.', error);
  }
}

export function* localeSaga() {
  yield takeLatest(SIGN_IN_SUCCESS, handleSignInSuccess);
  yield takeLatest(SET_LOCALE, handleSetLocale);
}
