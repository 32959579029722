import { Text as MantineText, TextProps as MantineTextProps } from '@mantine/core';
import React, { ReactNode, useRef, useState } from 'react';
import { Tooltip, TooltipProps } from '@N/view/components/Tooltip';

interface TextProps extends MantineTextProps {
  children: ReactNode;
  dataPrivate?: boolean;
  tooltipProps?: Partial<TooltipProps>;
}

export const Text: React.FC<TextProps> = ({
  children,
  truncate = true,
  dataPrivate = false,
  tooltipProps,
  ...props
}) => {
  const textRef = useRef<HTMLDivElement>(null);
  const [isTruncated, setIsTruncated] = useState(false);

  const handleMouseEnter = () => {
    setIsTruncated(textRef.current.scrollWidth > textRef.current.clientWidth);
  };

  return (
    <Tooltip label={children} disabled={!isTruncated} withArrow {...tooltipProps}>
      <MantineText
        ref={textRef}
        truncate={truncate}
        data-private={dataPrivate}
        onMouseEnter={handleMouseEnter}
        {...props}
      >
        {children}
      </MantineText>
    </Tooltip>
  );
};
