import { useEffect, useRef } from 'react';

import { routerActions } from 'modules/router';
import { useDispatch } from 'react-redux';
import { useLocation } from 'react-router-dom';

export const useSyncBrowserHistory = () => {
  const dispatch = useDispatch();
  const { pathname: to } = useLocation();
  const fromRef = useRef<string>();

  useEffect(() => {
    const from = fromRef.current;

    if (from !== to) {
      dispatch(routerActions.navigate({ from, to }));
      fromRef.current = to;
    }
  }, [dispatch, to]);
};
