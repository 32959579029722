import styled, { css, keyframes } from 'styled-components';

import { ExpensePayment } from 'apis/ExpenseAPI/models';

export type IconType = {
  width?: number;
  height?: number;
  url?: string;
  margin?: string;
};

const animateLoader = keyframes`
  0% {
    transform: rotate(0);
  }

  100% {
    transform: rotate(360deg);
  }
`;

export const LoadingIcon = styled.i<{ size?: string; borderWidth?: string }>`
  display: inline-block;
  width: ${props => props.size || '12px'};
  height: ${props => props.size || '12px'};
  border: ${props => props.borderWidth || '2px'} solid rgba(152, 209, 255, 0.25);
  border-top-color: #98d1ff;
  border-radius: 50%;
  ${css`
    animation: ${animateLoader} 1s linear infinite;
  `}
`;

export const Icon = styled.i<IconType & Styleable<MarginStyle>>`
  display: inline-flex;
  align-items: center;
  justify-content: center;
  flex-shrink: 0;
  width: ${props => props.width || '12'}px;
  height: ${props => props.height || '12'}px;
  ${props => {
    if (props.url) {
      return css`
        background-repeat: no-repeat;
        background-position: center;
        background-size: ${props.width ?? '12'}px ${props.height ?? '12'}px;
        background-image: url(${props.url});
      `;
    }
  }}
  ${props => props.styles?.margin && `margin: ${props.styles.margin};`}
`;

type IconMemberProp = {
  url: string;
};

export const IconMember = styled((props: IconMemberProp) => (
  <Icon width={34} height={18} url={props.url} />
))<IconMemberProp>``;

export type PaymentIconType = {
  payment: ExpensePayment;
  imageUrl?: string;
  width: number;
  height: number;
} & ComponentStyles<MarginStyle>;

export const S3IconLink = styled.a.attrs(() => ({ target: '_blank' }))`
  display: flex;
  align-items: center;
`;
