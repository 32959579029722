import LogRocket from 'logrocket';
import LogrocketFuzzySanitizer from 'logrocket-fuzzy-search-sanitizer';

let initialized = false;

const privateFieldNames = [
  'password',
  'device_token',
  'session_id',
  'account_id',
  'account_password',
  'reqAccount',
  'reqAccountPass',
  'reqIdentity',
  'reqUserId',
];

/**
 * 라이브러리의 type이 업데이트되지 않아 타입 오류가 발생합니다.
 * 임시로 any로 캐스팅해서 사용하겠습니다.
 */
const { requestSanitizer, responseSanitizer }: { requestSanitizer: any; responseSanitizer: any } =
  LogrocketFuzzySanitizer.setup([...privateFieldNames]);

function initialize() {
  const appId = process.env.REACT_APP_LOG_ROCKET_APP_ID;
  if (appId) {
    LogRocket.init(appId, {
      network: {
        requestSanitizer,
        responseSanitizer,
      },
    });
    initialized = true;
  }
}

function identify(user: SpenditUser) {
  if (initialized) {
    LogRocket.identify(user.id.toString(), {
      name: user.name,
      email: user.email,
      companyId: user.company_id,
      companyName: user.company_name,
      contractType: user.contract_type,
    });
  }
}

function getSessionURL() {
  return initialized ? LogRocket.sessionURL : null;
}

const LogRocketUtil = {
  initialize,
  identify,
  getSessionURL,
};

export default LogRocketUtil;
