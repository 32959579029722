import GlobalPortal from 'components/commons/GlobalPortal';
import React from 'react';
import TaskCard from 'components/commons/TaskCardList/TaskCard';
import { selectTasks } from 'modules/taskList';
import styled from 'styled-components';
import { useSelector } from 'react-redux';

export const TASK_CARD_LIST_BASE_CLASSNAME = 'spendit-task-card-list-container';

const StyledTaskCard = styled(TaskCard)``;

const TaskList = styled.ul`
  display: flex;
  flex-direction: column-reverse;
  position: fixed;
  right: 89px;
  bottom: 30px;
  width: 345px;

  ${StyledTaskCard} + ${StyledTaskCard} {
    margin-bottom: 5px;
  }
`;

export const TaskCardList: React.FC<React.PropsWithChildren<unknown>> = () => {
  const tasks = useSelector(selectTasks);

  return (
    <>
      <GlobalPortal>
        <TaskList className={TASK_CARD_LIST_BASE_CLASSNAME}>
          {tasks.map(task => (
            <StyledTaskCard key={task.id} task={task} />
          ))}
        </TaskList>
      </GlobalPortal>
    </>
  );
};
