import { ModalBodyProps } from './types';
import React from 'react';
import styled from 'styled-components';

export const StyledBody = styled.div<ModalBodyProps>`
  padding: ${props => props.padding || '0 26px'};
  margin-bottom: ${props => props.marginBottom || '42px'};
  height: ${props => props.height || 'auto'};
`;

/**
 * @deprecated
 */
const ModalBody: React.FC<React.PropsWithChildren<ModalBodyProps>> = ({ className, children, ...rest }) => {
  return (
    <StyledBody className={className} {...rest}>
      {children}
    </StyledBody>
  );
};

export default ModalBody;
